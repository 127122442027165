import { cloneDeep } from 'lodash';
import { i18nService } from '@core/i18n/I18nService';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { modalService } from '@core/modals/ModalService';
import { assetPopertiesGroupByMap } from '@src/pages/CreateWidgetPage/widgets.utils';
import {
  allowedOperationsMap,
  singleValueWidgets,
  tagFormatMap,
  variableFormatMap,
} from '@core/mapsAndDefinitions';
import { getSharedRule } from '@core/canvas/widgets/sharedAttributes';
import { heatmap } from '@core/canvas/widgets/heatmap';

/**
 * get metrics and return the total length of metrics
 * (if tag include 3 operation all the three are counted)
 * [{oper: [1, 2, 3]}, {oper: []}] => return 4
 * @param metrics
 * @param scope
 */

//Metrics
export const metricsSelectedChange = (
  val,
  widgetData,
  idx,
  selectType,
  metricsSelected,
  setWidgetData
) => {
  const rule = getConfigValueFromWidgetSettings(widgetData.type, 'dragAndDropRules');

  /**
   * if removing operation
   * or there is no maxMetrics limit
   * or there is limit ut metrics length is lower than the limit
   * or its a single select
   */
  if (
    val < metricsSelected[idx].operation ||
    typeof rule.maxMetrics !== 'number' ||
    metricsSelected.length < rule.maxMetrics ||
    selectType === 'SingleSelect'
  ) {
    const updatedMetricsSelected = cloneDeep(metricsSelected);
    updatedMetricsSelected[idx].operation = val.value;

    openConfirmInitializeWidget(
      widgetData,
      setWidgetData,
      widgetData.groupBy,
      updatedMetricsSelected
    );
  } else {
    openDndErrorMessage(
      rule.maxMetricsErrorMessage || 'maxMetrics',
      rule.errorHeader,
      true,
      widgetData.type,
      widgetData.scope
    );
  }
};

const openDndErrorMessage = (
  dndError,
  dndErrorHeader = 'general.invalid-action',
  isConfirm,
  type,
  scope
) => {
  let rules = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
  if (type === 'asset' && rules?.maxMetrics) {
    rules = { ...rules, maxMetrics: 5 };
  }

  dndAlertTextMap[dndError] &&
    modalService.openModal('confirm', {
      headerText: dndErrorHeader,
      text: dndAlertTextMap[dndError],
      type: type.charAt(0).toUpperCase() + type.slice(1).replaceAll('_', ' '),
      ...rules,
      scope: i18nService.translate(`create-widget-page.create-widget.step-two.${scope}`),
      maxGrouping: rules?.maxGrouping && rules.maxGrouping(scope),
      iconType: 'attention_image',
      confirmText: isConfirm && 'general.confirm',
      showCloseBtn: true,
    });
};

export const getSelectType = (tagType, scope, type) => {
  if (
    (tagType === 'numberType' || tagType === 'floatType') &&
    (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE')
  ) {
    return 'SingleSelect';
  } else return null;
};

export const buildOptions = (option, type) => {
  return {
    id: option.id,
    tagType: type,
    type:
      type === 'VARIABLE'
        ? variableFormatMap[option.valueType]
        : type === 'TAG'
        ? tagFormatMap[option.format]
        : tagFormatMap[option.type],
    name: option.name,
  };
};

export const getFilteredData = (
  scope,
  data,
  groupBy,
  metrics,
  shouldExcludeAssetProperties,
  shouldExcludeNonNumericVariables,
  isSingleValueWidget,
  hiddenAssetProperties
) => {
  const dataWithoutHidden = hiddenAssetProperties
    ? data.filter(
        (item) => !(item.tagType === 'ASSET_PROPERTY' && hiddenAssetProperties.includes(item.id))
      )
    : data;

  return dataWithoutHidden.filter((d) => {
    const isNotAllowed = !!(
      (shouldExcludeAssetProperties && d.tagType === 'ASSET_PROPERTY') ||
      (shouldExcludeNonNumericVariables && d.tagType === 'VARIABLE' && d.type !== 'numberType')
    );

    const isUsedInGroupBy = groupBy.some((g) => g.valueId === d.id && g.valueType === d.tagType);
    const isUsedInDataAndMetrics = metrics.some(
      (m) => m.valueId === d.id && m.valueType === d.tagType && !m.hide
    );

    switch (scope) {
      case 'AGGREGATED_DATA':
      case 'AGGREGATED_LAST_VALUE':
        if (!isNotAllowed && !isUsedInGroupBy) {
          if (isSingleValueWidget) {
            if (!isUsedInDataAndMetrics) {
              return d;
            }
          } else if (!isSingleValueWidget && d.availableOperations?.length) {
            return d;
          }
        }
        return;
      default:
        if (!(isNotAllowed || isUsedInGroupBy || isUsedInDataAndMetrics)) {
          return d;
        }
        return;
    }
  });
};

export const getAvailableOperations = (
  metrics: any[],
  tag: { id: number; tagType: string; type: string },
  scope: string
) => {
  let usedOperations = [];

  const allowedOperations =
    allowedOperationsMap.get(scope)?.filter((operation) => {
      if (tag.tagType === 'ALARM_INFO' && tag.id === 5) {
        return allowedOperationsMap.get('alarms').includes(operation);
      } else if (tag.tagType === 'VARIABLE') {
        if (['numberType', 'floatType'].includes(tag.type)) {
          return allowedOperationsMap.get(tag.tagType).includes(operation);
        }
        return null;
      } else {
        return allowedOperationsMap.get(tag.type).includes(operation);
      }
    }) || null;

  usedOperations.push(
    ...metrics
      .filter(
        (metric) =>
          metric.operation && metric.valueId === tag.id && metric.valueType === tag.tagType
      )
      ?.map((m) => m.operation)
  );

  return Array.isArray(allowedOperations)
    ? allowedOperations.filter((ao) => !usedOperations.includes(ao))
    : null;
};

const typesCanNotGroupBy = ['numberType', 'floatType'];
const dndAlertTextMap = {
  maxGrouping: 'create-widget-page.create-widget.step-three.grouping-alert-text',
  maxMetrics: 'create-widget-page.create-widget.step-three.metrics-alert-text',
  maxAny: 'create-widget-page.create-widget.step-three.max-any-alert-text',
  lastValue: 'create-widget-page.create-widget.step-three.last-value-unavailable-grouping',
  nonNumerical: 'create-widget-page.create-widget.step-three.non-numerical',
  nonNumericalAnyScope: 'create-widget-page.create-widget.step-three.non-numerical.any-scope',
  nonNumericalAndBoolean: 'create-widget-page.create-widget.step-three.non-numerical-and-boolean',
  noTimeStampOnAggregate: 'create-widget-page.create-widget.step-three.no-timeStamp-on-aggregate',
  noTimeStampOnLastValue: 'create-widget-page.create-widget.step-three.no-timeStamp-on-last-value',
  noTimeStampOnAggregateByLastValue:
    'create-widget-page.create-widget.step-three.no-timeStamp-on-aggregate-by-last-value',
  noDateOnLastValue: 'create-widget-page.create-widget.step-three.no-date-on-last-value',
  noDurationOnAggregate: 'create-widget-page.create-widget.step-three.no-duration-on-aggregate',
  cannotChangeGroupingOnLine:
    'create-widget-page.create-widget.step-three.canot-change-grouping-on-line',
  lineMaxMetrics: 'create-widget-page.create-widget.step-three.line-only-4-metrics',
  heatmapMaxMetrics: 'create-widget-page.create-widget.step-three.heatmap.one-metric-allowed-error',
  heatmapOneMetricAllowed:
    'create-widget-page.create-widget.step-three.heatmap.one-metric-allowed-error',
  heatmapOneGroupingAllowed:
    'create-widget-page.create-widget.step-three.heatmap.one-grouping-allowed-error',
};

export const getNewMetrics = (newIdx, data, scope, type) => {
  return {
    order: newIdx,
    valueType: data.tagType || data.valueType,
    valueId: data.id || data.valueId,
    operation: newMetricOperation(data.type, type, scope),
    type: data.type,
    name: data.name,
    selectType: getSelectType(data.type, scope, type),
  };
};

const getNewGrouping = (newIdx, data) => {
  return {
    order: newIdx,
    valueType: data.tagType || data.valueType,
    valueId: data.id || data.valueId,
    operation: data.type === 'dateTimeType' ? 'DAILY' : null,
    type: data.type,
    name: data.name,
    isDropDown: data.type === 'dateTimeType',
    isDropDownValue: {
      value: 'DAILY',
      label: `enum.DAILY`,
    },
  };
};

const getAvilableData = (data) => {
  return {
    tagType: data.valueType,
    id: data.valueId,
    type: data.type,
    name: data.name,
  };
};

const addToGroupValidator = (
  type,
  draggableItem,
  scope,
  _groupBy,
  _metrics,
  sourceDroppableId,
  isRemove = false
) => {
  const rule = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
  if (isRemove && rule.groupingDataValidation) {
    return rule.groupingDataValidation(scope, draggableItem, _groupBy, _metrics);
  } else if (isRemove) {
    return null;
  }

  if (type === 'value') return 'valueGrouping';

  if (scope === 'LAST_VALUE' && type !== 'pie') {
    return 'lastValue';
  }
  if (
    scope == 'LAST_VALUE' &&
    (draggableItem?.id === 7 || draggableItem?.valueId === 7) &&
    (draggableItem?.tagType === 'ASSET_PROPERTY' || draggableItem?.valueType === 'ASSET_PROPERTY')
  ) {
    return 'noTimeStampOnLastValue';
  }

  const maxGrouping = rule.maxGrouping && rule.maxGrouping(scope);
  if (!rule) {
    throw new Error(`No dragAndDropRule for widget of type ${type}!`);
  }
  if (typeof maxGrouping === 'number' && _groupBy.length >= maxGrouping) {
    return 'maxGrouping';
  }
  if (typeof rule.maxAny === 'number' && _groupBy.length + _metrics.length >= rule.maxAny) {
    // Its OK to move from metrics to groupBy in this case.
    return sourceDroppableId === 'metrics' ? null : 'maxAny';
  }
  if (rule.groupingDataValidation) {
    return rule.groupingDataValidation(scope, draggableItem, _groupBy, _metrics);
  }
  return null;
};

// Will return null if OK; or string of: 'maxMetrics' | 'maxAny' if there is an error.
const addToMetricsValidator = (
  type,
  draggableItem,
  _metrics,
  _groupBy,
  scope,
  sourceDroppableId
) => {
  const rule = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
  if (!rule) {
    throw new Error(`No dragAndDropRule for widget of type ${type}!`);
  }
  if (
    type === 'line' &&
    scope === 'RAW_DATA' &&
    !['numberType', 'floatType'].includes(draggableItem.type)
  ) {
    return `nonNumericalAnyScope`;
  }
  if (
    draggableItem.tagType === 'VARIABLE' &&
    !getSharedRule(
      'allowedScopesByVariableType',
      draggableItem.type
    ).allowedScopesByVariableType.includes(scope)
  ) {
    return `nonNumericalAnyScope`;
  }
  if (rule.maxMetrics) {
    const max =
      typeof rule.maxMetrics === 'function' ? rule.maxMetrics(scope, _groupBy) : rule.maxMetrics;
    if (max && _metrics.length >= max) {
      return rule.maxMetricsErrorMessage || 'maxMetrics';
    }
  }
  if (rule.metricsDataValidation) {
    return rule.metricsDataValidation(scope, draggableItem.type);
  }
  if (typeof rule.maxAny === 'number' && _groupBy.length + _metrics.length >= rule.maxAny) {
    // Its OK to move from groupBy to metrics in this case.
    return sourceDroppableId === 'groupBy' ? null : 'maxAny';
  }
  return null;
};

const newMetricOperation = (tagType, type, scope) => {
  if (type === 'pie' && scope === 'LAST_VALUE') {
    return 'SUM';
  } else if (
    tagType !== 'numberType' &&
    tagType !== 'floatType' &&
    ['AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'].includes(scope)
  ) {
    return 'COUNT';
  } else {
    return null;
  }
};

const availableDataSwitch = async (
  widgetData,
  sourceDroppableId,
  metrics,
  newIdx,
  prevIdx,
  availableData,
  setAvailableData,
  groupBy,
  setWidgetData,
  draggableId,
  type,
  scope,
  _groupBy,
  _metrics
) => {
  let dndError;
  switch (sourceDroppableId) {
    case 'availableData':
      if (newIdx === prevIdx) {
        return; // No need to reorder.
      }
      // Reorder.
      const tagToMove = availableData[prevIdx];
      availableData.splice(prevIdx, 1);
      availableData.splice(newIdx, 0, tagToMove);
      return setAvailableData([...availableData]);
    case 'groupBy':
      dndError = addToGroupValidator(
        type,
        availableData[prevIdx],
        scope,
        _groupBy,
        _metrics,
        sourceDroppableId,
        true
      );
      // Remove the item from groupBy.
      if (!dndError) {
        var groupingRemove = openConfirmInitializeWidget(
          widgetData,
          setWidgetData,
          groupBy.filter((t) => `${t.order}grouping` !== draggableId),
          metrics
        );
        if (groupingRemove) {
          // Add the item to availableData.
          const newData = getAvilableData(groupBy[prevIdx]);

          availableData.splice(newIdx, 0, newData);
          setAvailableData([...availableData]);
        }
      } else {
        const rules = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
        openDndErrorMessage(dndError, rules.errorHeader, true, widgetData.type, widgetData.scope);
      }
      return;

    case 'metrics':
      // Remove the item from metrics.
      if (metrics[prevIdx]?.valueType !== 'CALCULATION') {
        var metricsRemove = await openConfirmInitializeWidget(
          widgetData,
          setWidgetData,
          groupBy,
          metrics.filter((t) => `${t.order}metrics` !== draggableId)
        );
        if (metricsRemove) {
          // Add the item to availableData.
          const newDataFromMetrics = getAvilableData(metrics[prevIdx]);
          availableData.splice(newIdx, 0, newDataFromMetrics);
          setAvailableData([...availableData]);
        }
        return;
      }
  }
};

const groupBySwitch = async (
  widgetData,
  sourceDroppableId,
  setAvailableData,
  availableData,
  scope,
  metrics,
  draggableId,
  newIdx,
  prevIdx,
  type,
  _groupBy,
  groupBy,
  setWidgetData,
  customization,
  _metrics
) => {
  let dndError;
  switch (sourceDroppableId) {
    case 'availableData':
      // Add item to groupBy
      dndError = addToGroupValidator(
        type,
        availableData[prevIdx],
        scope,
        _groupBy,
        _metrics,
        sourceDroppableId
      );
      if (
        !dndError &&
        !typesCanNotGroupBy.includes(availableData[prevIdx].type) &&
        assetPopertiesGroupByMap.includes(availableData[prevIdx].id)
      ) {
        const newGrouping = getNewGrouping(newIdx, availableData[prevIdx]);
        _groupBy.splice(newIdx, 0, newGrouping);
        _groupBy.map((item, idx) => (item.order = idx));

        var add = await openConfirmInitializeWidget(
          widgetData,
          setWidgetData,
          [..._groupBy],
          metrics
        );
        if (add) {
          // Remove item from availableData
          setAvailableData(
            availableData.filter((t) => `${t.id}_${t.tagType}_data` !== draggableId)
          );
        }
        return;
      } else {
        const rules = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
        const header =
          dndError === 'noTimeStampOnAggregateByLastValue'
            ? 'create-widget-page.create-widget.step-three.no-timeStamp-on-aggregate-by-last-value.header'
            : '';
        openDndErrorMessage(dndError, rules.errorHeader || header, true, type, scope);
        return;
      }

    case 'groupBy':
      if (newIdx === prevIdx) {
        return; // No need to reorder.
      }
      // Reorder.
      const tagToMove = _groupBy[prevIdx];
      _groupBy.splice(prevIdx, 1);
      _groupBy.splice(newIdx, 0, tagToMove);
      _groupBy.map((item, idx) => (item.order = idx));

      return setWidgetData((prevState) => ({
        ...prevState,
        groupBy: [..._groupBy],
      }));
    case 'metrics':
      // Add item to groupBy
      dndError = addToGroupValidator(
        type,
        metrics[prevIdx],
        scope,
        _groupBy,
        _metrics,
        sourceDroppableId
      );
      if (!dndError && !typesCanNotGroupBy.includes(metrics[prevIdx].type)) {
        const newGrouping = getNewGrouping(newIdx, metrics[prevIdx]);
        _groupBy.splice(newIdx, 0, newGrouping);
        _groupBy.map((item, idx) => (item.order = idx));

        const updatedMetrics = metrics.filter((t) => `${t.order}metrics` !== draggableId);

        return await openConfirmInitializeWidget(
          widgetData,
          setWidgetData,
          [..._groupBy],
          updatedMetrics
        );
      } else {
        const rules = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
        openDndErrorMessage(dndError, rules.errorHeader, true, type, scope);
        return;
      }
  }
};

const metricsSwitch = async (
  widgetData,
  sourceDroppableId,
  draggableId,
  groupBy,
  customization,
  setAvailableData,
  setWidgetData,
  type,
  availableData,
  prevIdx,
  newIdx,
  _metrics,
  scope,
  _groupBy
) => {
  let dndError;
  switch (sourceDroppableId) {
    case 'availableData':
      // Add item to metrics
      dndError = addToMetricsValidator(
        type,
        availableData[prevIdx],
        _metrics,
        _groupBy,
        scope,
        sourceDroppableId
      );
      if (!dndError) {
        const newMetrics = getNewMetrics(newIdx, availableData[prevIdx], scope, type);
        _metrics.splice(newIdx, 0, newMetrics);
        _metrics.map((item, idx) => (item.order = idx));

        var add = await openConfirmInitializeWidget(widgetData, setWidgetData, groupBy, [
          ..._metrics,
        ]);
        if (add) {
          // Remove item from availableData
          setAvailableData(
            availableData.filter(
              (t) =>
                `${t.id}_${t.tagType}_data` !== draggableId ||
                _metrics.filter((m) => m.valueId === t.id && m.valueType == t.tagType)?.length <
                  t.availableOperations?.length
            )
          );
        }
        return;
      } else {
        const rules = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
        openDndErrorMessage(dndError, rules.errorHeader, true, type, scope);
        return;
      }

    case 'groupBy':
      // Add item to metrics
      dndError = addToMetricsValidator(
        type,
        groupBy[prevIdx],
        _metrics,
        _groupBy,
        scope,
        sourceDroppableId
      );

      const groupDndError = addToGroupValidator(
        type,
        groupBy[prevIdx],
        _metrics,
        _groupBy,
        scope,
        sourceDroppableId,
        true
      );
      if (!dndError && !groupDndError) {
        const newMetrics = getNewMetrics(newIdx, groupBy[prevIdx], scope, type);
        _metrics.splice(newIdx, 0, newMetrics);
        _metrics.map((item, idx) => (item.order = idx));

        return await openConfirmInitializeWidget(
          widgetData,
          setWidgetData,
          groupBy.filter((t) => `${t.order}grouping` !== draggableId),
          [..._metrics]
        );
      } else {
        const rules = getConfigValueFromWidgetSettings(type, 'dragAndDropRules');
        openDndErrorMessage(dndError || groupDndError, rules.errorHeader, true, type, scope);
        return;
      }

    case 'metrics':
      if (newIdx === prevIdx) {
        return; // No need to reorder.
      }
      // Reorder.
      const tagToMove = _metrics[prevIdx];
      _metrics.splice(prevIdx, 1);
      _metrics.splice(newIdx, 0, tagToMove);
      _metrics.map((item, idx) => (item.order = idx));

      return setWidgetData((prevState) => ({
        ...prevState,
        metrics: [..._metrics],
        customization,
      }));
  }
};

export const openConfirmInitializeWidget = (
  widgetData,
  setWidgetData,
  groupByData,
  metricsData
) => {
  const { filters, sorts, customization } = widgetData;
  const unResetKeys = getConfigValueFromWidgetSettings(widgetData.type, 'unResetKeys');
  const getDefaultFilters = getConfigValueFromWidgetSettings(widgetData.type, 'getDefaultFilters');
  const defaultFilters = getDefaultFilters ? getDefaultFilters(widgetData.scope) : [];
  if (
    (filters.length &&
      !unResetKeys?.includes('filters') &&
      defaultFilters.length !== filters.length) ||
    (sorts.length && !unResetKeys?.includes('sorts')) ||
    (customization && !unResetKeys?.includes('customization'))
  ) {
    return modalService
      .openModal('confirm', {
        iconType: 'attention_image',
        text: !widgetData.eventTemplateId
          ? 'create-widget-page.create-widget.step-two.change-initialize'
          : 'create-event-data-source-page.create-event-data-source.step-two.change-initialize',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        headerText: !widgetData.eventTemplateId
          ? 'create-widget-page.create-widget.confirm-widget-changes.header'
          : 'create-event-data-source-page.create-event-data-source.confirm-data-source-changes.header',
        showCloseBtn: true,
      })
      .then((confirm) => {
        if (confirm) {
          setWidgetData((prevState) => ({
            ...prevState,
            groupBy: groupByData,
            metrics: metricsData,
            filters: !unResetKeys?.includes('filters') ? defaultFilters || [] : prevState.filters,
            sorts: !unResetKeys?.includes('sorts') ? [] : prevState.sorts,
            customization: !unResetKeys?.includes('customization') ? null : prevState.customization,
            customizationMetrics: [],
            navigateFilterBy: widgetData.type === 'heatmap' ? [] : prevState.navigateFilterBy,
            navigationDashboard: widgetData.type === 'heatmap' ? [] : prevState.navigationDashboard,
          }));
        }
        return confirm;
      });
  } else {
    setWidgetData((prevState) => ({
      ...prevState,
      groupBy: groupByData,
      metrics: metricsData,
    }));
    return true;
  }
};

export const onDragAndDropGroupingAndMeasures = (
  result,
  customization,
  availableData,
  setWidgetData,
  setAvailableData,
  widgetData
) => {
  const { type, groupBy, metrics, scope } = widgetData;
  const { source, destination } = result;
  const draggableId = result.draggableId;

  if (!destination) {
    return;
  }
  const newIdx = destination.index;
  const prevIdx = source.index;

  const _metrics = cloneDeep(metrics);
  const _groupBy = cloneDeep(groupBy);

  switch (destination.droppableId) {
    case 'availableData':
      return availableDataSwitch(
        widgetData,
        source.droppableId,
        metrics,
        newIdx,
        prevIdx,
        availableData,
        setAvailableData,
        groupBy,
        setWidgetData,
        draggableId,
        type,
        scope,
        _groupBy,
        _metrics
      );

    case 'groupBy':
      return groupBySwitch(
        widgetData,
        source.droppableId,
        setAvailableData,
        availableData,
        scope,
        metrics,
        draggableId,
        newIdx,
        prevIdx,
        type,
        _groupBy,
        groupBy,
        setWidgetData,
        customization,
        _metrics
      );

    case 'metrics':
      return metricsSwitch(
        widgetData,
        source.droppableId,
        draggableId,
        groupBy,
        customization,
        setAvailableData,
        setWidgetData,
        type,
        availableData,
        prevIdx,
        newIdx,
        _metrics,
        scope,
        _groupBy
      );
  }
};

export function getFilteredOperations(metricsTag, availableData, widgetType, scope, options) {
  const isSingleValueWidget = singleValueWidgets.includes(widgetType);

  const allowedOperations =
    metricsTag.valueType === 'VARIABLE'
      ? getOperationsAsOptions(allowedOperationsMap.get(metricsTag.valueType))
      : widgetType === 'alarms'
      ? getOperationsAsOptions(allowedOperationsMap.get(widgetType))
      : scope === 'AGGREGATED_LAST_VALUE'
      ? getOperationsAsOptions(allowedOperationsMap.get(scope))
      : options;

  const filteredOperations =
    availableData.find((ad) => ad.id === metricsTag.valueId)?.availableOperations || [];

  return isSingleValueWidget
    ? allowedOperations.filter((ao) => metricsTag.operation !== ao.value)
    : allowedOperations.filter((ao) => filteredOperations.includes(ao.value));
}

export function getOperationsAsOptions(operations: string[]) {
  return operations.map((op) => ({ value: op, label: `enum.${op}` }));
}
