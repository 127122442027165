import Gauge from '@components/widgets/Gauge';
import {
  stepThreeDefaultValidation,
  hasValue,
  getGaugeWidgetsCustomizationServerChanges,
} from '../widget.utils';
import {
  WidgetData,
  RadialGaugeCustomization,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { WidgetMapItem } from '../widgetMap.interface';
import { isEmpty } from 'lodash';

export const stepFourGaugeValidation = (widgetData: WidgetData) => {
  const { customization } = widgetData;

  if (isEmpty(customization)) return;
  const { ticks, startAngle, degreesSize, zones } = customization as RadialGaugeCustomization;

  return (
    hasValue(startAngle) &&
    hasValue(degreesSize) &&
    hasValue(ticks.majorStart) &&
    hasValue(ticks.majorEnd) &&
    hasValue(ticks.majorInterval) &&
    zones.every((zone) => (hasValue(zone.minValue) ? hasValue(zone.maxValue) : true))
  );
};

export const radialGauge: WidgetMapItem = {
  id: 'radial_gauge',
  name: 'radial_gauge',
  component: Gauge,
  minWidth: 150,
  minHeight: 150,
  images: {
    canvas: '/assets/images/gauge_empty_state_image.svg',
    thumbnail: 'gauge',
  },
  editModalSize: {},
  settings: {
    enableSaveAsDraftOnStepValidation: {
      4: true,
    },
    dataChangesToHandle: [
      'groupBy',
      'metrics',
      'filters',
      'sorts',
      'calculations',
      'customizationMetrics',
      'localTags',
    ],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
      4: (widgetData) => stepFourGaugeValidation(widgetData),
    },
    expansionsPanelDisable: ['sort'],
    dragAndDropRules: {
      maxGrouping: (scope) => 0,
      maxMetrics: 1,
      excludeAssetProperties: (scope) => scope === 'LAST_VALUE',
      excludeNonNumericVariables: () => true,
    },
    assetIsNotDisabled: ['floatType', 'numberType'],
    showPreviewData: ['metrics', 'groupBy'],
    disabledScope: ['RAW_DATA'],
    similarWidgets: () => [{ widgetType: 'value' }],
    languageKeys: ['title', 'units'],
    customizationServerChanges: getGaugeWidgetsCustomizationServerChanges,
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => widgetData.scope === 'LAST_VALUE',
  },
};
