import React, { useMemo, useState } from 'react';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';
import { i18nService } from '@core/i18n/I18nService';
import { colorMap } from '@core/statusColorMap';
import { convertToDefaultDateFormat } from '@core/utils';

const useStyles = makeStyles((theme: any) => ({
  panel: {
    marginBottom: 13,
    boxShadow: theme.app.boxShadowGrey,
    borderRadius: 8,
    backgroundColor: 'var(--systemContentBackground)',
    '&:before': {
      height: 0,
    },
  },
  panelRounded: {
    height: 50,
  },
  panelDetailsWrapper: {
    padding: '0 24px 24px',
  },
  panelDetails: {
    width: '100%',
    display: 'flex',
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
    flex: 1,
  },
  fieldWrapper: {
    marginBottom: 9,
    height: 21,
    fontSize: 14,
    color: 'var(--systemFont)',
    display: 'flex',
  },
  header: {
    fontWeight: 'bold',
    display: 'flex',
    width: 124,
    paddingRight: 10,
    height: 'fit-content',
  },
  status: {
    color: ({ evaluatedStatus }: any) =>
      status !== 'ACTIVE' && colorMap[status] ? colorMap[status] : 'var(--systemFont)',
  },
  column: {
    marginRight: 33,
  },
  field: {
    width: 130,
  },
}));

const Panel = (props) => {
  const {
    licence,
    status,
    description,
    catalogNumber,
    subscriptionKey,
    startDate,
    endDate,
    isOpen,
    isActive,
  } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const classes = useStyles(props);

  const evaluatedStatus = isActive
    ? status
    : status == 'ACTIVE' || status == 'ABOUT_TO_EXPIRE' || status == 'EXPIRED_GRACE'
    ? 'PENDING'
    : status;

  const statusData = useMemo(
    () => i18nService.translate(`enum.subscription.plc.${evaluatedStatus}`, languageId),
    [evaluatedStatus, languageId]
  );

  return (
    <ExpansionPanel defaultExpanded={isOpen} className={classes.panel}>
      <ExpansionPanelSummary className={classes.panelRounded} expandIcon={<ExpandMoreIcon />}>
        <I18n className={classes.label}>{licence}</I18n>
        <span className={classes.status} title={statusData}>
          {statusData}
        </span>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetailsWrapper}>
        <div className={classes.panelDetails}>
          <div className={classes.column}>
            <div className={classes.fieldWrapper}>
              <div className={classes.header}>
                <I18n element="div">asset-subscription.description</I18n>:
              </div>
              <div className={classNames(classes.field, 'ellipsis-overflow')} title={description}>
                {description}
              </div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.header}>
                <I18n element="div">asset-subscription.catalogNumber</I18n>:
              </div>
              <div>{catalogNumber}</div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.header}>
                <I18n element="div">asset-subscription.subscriptionKey</I18n>:
              </div>
              <div>{subscriptionKey}</div>
            </div>
          </div>
          <div className={classes.column}>
            <div className={classes.fieldWrapper} />
            <div className={classes.fieldWrapper}>
              <div className={classes.header}>
                <I18n element="div">asset-subscription.startDate</I18n>:
              </div>
              <div className={classes.field}>{convertToDefaultDateFormat(startDate)}</div>
            </div>
            <div className={classes.fieldWrapper}>
              <div className={classes.header}>
                <I18n element="div">asset-subscription.endDate</I18n>:
              </div>
              <div>{convertToDefaultDateFormat(endDate)}</div>
            </div>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Panel;
