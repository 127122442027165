import React, { useMemo } from 'react';
import { i18nService } from '@i18n/I18nService';
import { useSelector } from '@redux/useSelector';
import { I18nProps } from './I18n.interface';
import { capitalizeFirstLetterOfEveryWord, convertBooleanToTranslatedString } from '@core/utils';

function I18n(props: I18nProps) {
  const {
    style,
    className,
    element,
    title,
    children,
    noEllipsis,
    noUserSelect = false,
    capitalizeFirstLeters = false,
    addColon = false,
    ...rest
  } = props;
  const languageId = useSelector((state) => state.config.languageId);

  const updatedStyle = noUserSelect ? { ...style, userSelect: 'none' } : style;

  const textToDisplay = useMemo(() => {
    return typeof children === 'string'
      ? capitalizeFirstLeters
        ? capitalizeFirstLetterOfEveryWord(i18nService.translate(children, undefined, rest))
        : i18nService.translate(children, undefined, rest)
      : typeof children === 'boolean'
      ? convertBooleanToTranslatedString(children)
      : children;
  }, [children, rest, languageId]);

  const titleDisplay = useMemo(() => {
    return i18nService.translate(title || textToDisplay);
  }, [title, textToDisplay]);

  return textToDisplay && typeof textToDisplay === 'string' && textToDisplay.includes('\r\n') ? (
    <div
      title={capitalizeFirstLeters ? capitalizeFirstLetterOfEveryWord(titleDisplay) : titleDisplay}>
      {textToDisplay.split('\r\n').map((line, idx) =>
        line === '' ? (
          <div key={idx} style={{ height: '10px' }}></div>
        ) : (
          React.createElement(
            element || 'span',
            {
              key: idx,
              className: `${className ? className : ''} ${noEllipsis ? '' : 'ellipsis-overflow'}`,
              style: updatedStyle,
            },
            line
          )
        )
      )}
    </div>
  ) : (
    React.createElement(
      element || 'span',
      {
        className: `${className ? className : ''} ${noEllipsis ? '' : 'ellipsis-overflow'}`,
        title: capitalizeFirstLeters
          ? capitalizeFirstLetterOfEveryWord(titleDisplay)
          : titleDisplay,
        style: updatedStyle,
      },
      addColon ? `${textToDisplay}:` : textToDisplay
    )
  );
}

export default I18n;
