import LineWidget from '@components/widgets/LineWidget';
import { WidgetMapItem } from '../widgetMap.interface';
import { omit } from 'lodash';
import { buildMetricByOperation, stepFourLineAndColumnsValidation } from '../widget.utils';
import { assetsPropsMap } from '@pages/CreateWidgetPage/widgets.utils';

const similarWidgets = (scope) => {
  if (scope === 'RAW_DATA') return [{ widgetType: 'table' }];
  const sm: any[] = [{ widgetType: 'columns' }, { widgetType: 'table' }];

  if (scope !== 'LAST_VALUE') {
    sm.push({
      widgetType: 'pie',
      getSideEffectCfg: () => ({
        goToStep: 3,
        condition: (widgetData) =>
          widgetData.metrics &&
          buildMetricByOperation(
            Array.isArray(widgetData.metrics) ? widgetData.metrics : [widgetData.metrics]
          ).length > 1,
        selectCfg: {
          isMulti: false,
          getOptionLabel: (option) => option.displayName,
          getOptionValue: (option) => option.displayName,
          options: (widgetData) => buildMetricByOperation(widgetData.metrics),
        },
        generateSelectedData: (newData) => {
          return omit(newData, ['displayName']);
        },
        changedKey: 'metrics',
        title: 'change-widget-data-modal.pie-similar-widget-message',
      }),
    });
  } else {
    sm.push({
      widgetType: 'pie',
      getSideEffectCfg: () => ({
        goToStep: 3,
        condition: (widgetData) => true,
      }),
    });
  }

  return sm;
};

const defaultGrouping = [
  { id: 7, type: 'dateTimeType' },
  { id: 1, type: 'stringType' },
  { id: 2, type: 'stringType' },
].map((option, idx) => ({
  isDropDown: false,
  isDropDownValue: null,
  name: `create-widget-page.create-widget.asset-properties.${assetsPropsMap[option.id]}`,
  operation: null,
  order: idx,
  type: option.type,
  valueId: option.id,
  valueType: 'ASSET_PROPERTY',
}));

const defaultAssetsProperties = [
  { id: 1, name: 'create-widget-page.create-widget.asset-properties.ASSET_ID', type: 'STRING' },
  {
    id: 2,
    name: 'create-widget-page.create-widget.asset-properties.ASSET_NAME',
    type: 'STRING',
  },
  {
    id: 7,
    name: 'create-widget-page.create-widget.asset-properties.DATE',
    type: 'DATE',
  },
];

const defaultSorting = [
  {
    isDropDown: false,
    isDropDownValue: null,
    label: 'Timestamp',
    name: `create-widget-page.create-widget.asset-properties.${assetsPropsMap[1]}`,
    operation: null,
    order: 0,
    sortBy: 'ASCENDING',
    type: 'string',
    value: 1,
    valueId: 1,
    valueType: 'ASSET_PROPERTY',
  },
  {
    isDropDown: false,
    isDropDownValue: null,
    label: 'Timestamp',
    name: `create-widget-page.create-widget.asset-properties.${assetsPropsMap[7]}`,
    operation: null,
    order: 1,
    sortBy: 'ASCENDING',
    type: 'dateTimeType',
    value: 7,
    valueId: 7,
    valueType: 'ASSET_PROPERTY',
  },
];

export const line: WidgetMapItem = {
  id: 'line',
  name: 'Line',
  component: LineWidget,
  minWidth: 328,
  fullSize: true,
  minHeight: 168,
  images: {
    canvas: '/assets/images/empty_line.svg',
    thumbnail: 'line',
  },
  editModalSize: {},
  settings: {
    dataChangesToHandle: ['metrics', 'filters', 'groupBy', 'calculations', 'localTags'],
    getWidgetDataOnlyInPreview: true,
    defaultAssetProperties: [1, 2, 7],
    disabledScope: ['LAST_VALUE', 'AGGREGATED_LAST_VALUE'],
    stepTwoRadioBoxText: { RAW_DATA: { 'rd-top-text': 'raw-data-line-text' } },
    expansionsPanelDisable: ['sort'],
    extraMetricsText: {
      value: (scope) =>
        `create-widget-page.create-widget.step-three.line-empty-metrics-${
          scope === 'RAW_DATA' ? 'raw-data' : 'aggregated'
        }`,
      condition: () => true,
    },
    showPreviewData: ['metrics', 'groupBy'],
    enableExportCsv: () => true,
    setDefaultDataByStep: {
      2: (scope, setWidgetData, widgetData) =>
        setWidgetData((prevState) => ({
          ...prevState,
          groupBy: defaultGrouping,
          sorts: defaultSorting,
          exportFormats: ['CSV'],
        })),
    },
    setAllDefaultData: (setWidgetData) => {
      setWidgetData((prevState) => ({
        ...prevState,
        assetProperties: defaultAssetsProperties,
        groupBy: defaultGrouping,
        sorts: defaultSorting,
      }));
    },
    stepValidation: {
      3: (widgetData) =>
        widgetData.scope === 'AGGREGATED_DATA'
          ? !widgetData.groupBy.length ||
            !widgetData.metrics.length ||
            widgetData.metrics.some((m) => m.valueType !== 'CALCULATION' && !m.operation)
          : !widgetData.metrics.length,
      4: (widgetData) => stepFourLineAndColumnsValidation(widgetData),
    },
    dragAndDropRules: {
      maxGrouping: () => null,
      errorHeader: 'create-widget-page.create-widget.step-three.line-errors-header',
      groupingDataValidation: () => 'cannotChangeGroupingOnLine',
      excludeNonNumericVariables: () => true,
    },
    unResetKeys: ['sorts'],
    maxWidgetsOnCanvas: 6,
    similarWidgets,
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => true,
    languageKeys: ['columnsDict.*', 'yAxesDict.*', 'thresholdDict.*', 'subtitle_text'],
    customizationServerChanges: (customization) => ({
      ...customization,
      columnsDict:
        customization.columns &&
        customization.columns.reduce(
          (result, column) =>
            column.operation
              ? {
                  ...result,
                  [`${column.valueType?.toLowerCase()}.${
                    column.id
                  }.${column.operation?.toLowerCase()}`]: column.displayName,
                }
              : {
                  ...result,
                  [`${column.valueType?.toLowerCase()}.${column.id}`]: column.displayName,
                },
          {}
        ),
      yAxesDict: customization.yAxes.reduce(
        (result, yAxis) =>
          yAxis.operation
            ? {
                ...result,
                [`${yAxis.valueType?.toLowerCase()}.${yAxis.id}.${yAxis.operation.toLowerCase()}`]:
                  yAxis.displayName,
              }
            : {
                ...result,
                [`${yAxis.valueType?.toLowerCase()}.${yAxis.id}`]: yAxis.displayName,
              },
        {}
      ),
      thresholdDict:
        customization.threshold?.length &&
        customization.threshold.reduce(
          (result, t) => ({
            ...result,
            [`threshold.${t.id}`]: t.displayName,
          }),
          {}
        ),
    }),
  },
};
