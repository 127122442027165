import * as isEqual from 'lodash/isEqual';
import { cssVarsService } from '@core/CssVarsService';
import history from '@core/history';
import { Subject } from '@core/Subject';
import { modalService } from '@core/modals/ModalService';
import { dispatch, getState } from '@redux/store';
import {
  addCanvasWidget,
  setCanvasHeight,
  selectWidgetById,
  clearWidgetSelection,
  removeCanvasWidget,
  editCanvasWidgetById,
  setCanvasWidth,
  setRefreshRate,
  setFilterWidgetVisibility,
  setShowGrid,
  setIsSplitLayout,
  setCanvasWidgets,
  setDashboardStates,
  setCanvasWidgetStatesById,
} from '@redux/dashboardEditor';
import { statesLocalToServer } from '@pages/DashboardEditorPage/DashboardEditorLayout.utils';
import {
  CanvasWidget,
  CanvasWidthOption,
  Coordinates,
  DashboardState,
  WidgetType,
} from '@redux/redux.interface.d';
import { widgetMap } from './widgetMap';
import {
  initialWidgetData,
  creationStageMap,
  localToServer,
} from '@pages/CreateWidgetPage/widgets.utils';
import { getDefaultNameCreateWidget } from '@pages/CreateWidgetPage/CreateWidgetPage.utils';
import { editorConfig } from './editorConfig';
import { CSI } from './CanvasService.interface';
import { isWidgetGroup } from './widget.utils';
import { httpService } from '@core/http/HttpService';
import { removeWindowSelection } from '@core/utils';
import { unselectWidgetById } from '@src/redux/dashboardEditor/dashboardEditor.actions';

const cellSize = editorConfig.gridCellSizePx;

class CanvasService {
  draggedItem$ = new Subject<CSI.DragInfo>(this.emptyDraggedItem());
  resizedItem$ = new Subject<CSI.ResizeInfo>(this.emptyResizedItem());
  /**
   * Represents the absolute x position (in px) of a red line that
   * is rendered to show the canvas end. Is shown when a changeCanvasWidth()
   * operation fails because there are widgets exceeding the new canvas width.
   */
  canvasEndIndicatorX$ = new Subject<number>(null);

  private outerElement: HTMLElement; // The HTML element responsible for canvas vertical-scroll.
  private innerElement: HTMLElement; // The HTML element that contains the widgets.
  private autoScrollInterval;

  // UC-6347 - doInfiniteScrolling is executing a function with old X and Y values. While on Y it has no effect, in X it causes the element to flicker, like it has a Parkinson.
  private pageX = 0;
  private pageY = 0;

  /**
   * Timeout handler to control the amount of requests sent to the api that updates
   * the widget state (x, y, w, h)
   */

  private timeoutHandler: NodeJS.Timeout = null;

  /**
   * Set the HTML element responsible for canvas vertical-scroll.
   */
  setElements(outer: HTMLElement, inner: HTMLElement) {
    this.outerElement = outer;
    this.innerElement = inner;
  }

  setMousePosition({ pageX, pageY }) {
    this.pageX = pageX;
    this.pageY = pageY;
  }

  /**
   * Cleans up the service. Should be called when the editor page is unloaded.
   */
  cleanup() {
    this.outerElement = undefined;
    this.innerElement = undefined;
    this.canvasEndIndicatorX$.next(null);
  }

  setCanvasWidgetPositions(widgets: Array<CanvasWidget>, currentLayout: string) {
    dispatch(setCanvasWidgets(widgets, currentLayout));
  }

  selectWidget(widget: CanvasWidget, options: { isMultiSelect?: boolean } = {}) {
    dispatch(selectWidgetById(widget.id, options));
  }

  unselectWidget(widget: CanvasWidget) {
    dispatch(unselectWidgetById(widget.id));
  }

  deleteWidget(widget: CanvasWidget) {
    const canvasWidgets = getState().dashboardEditor.canvasWidgets;
    const isBaseWidget = canvasWidgets.some((w) => w.id === widget.id);
    const isGroupChild = !isBaseWidget;
    if (isGroupChild) {
      return modalService.openAlert({ text: 'dashboardEditor.cantDeleteWidgetInGroup' });
    }
    return modalService
      .openConfirm(
        {
          text: 'dashboard-editor.delete-widget-msg',
          headerText: 'dashboard-editor.delete-widget-header',
          showCloseBtn: true,
        },
        { widgetName: widget.name }
      )
      .then((confirm) => {
        if (confirm) {
          httpService
            .api({ type: 'deleteWidget', urlParams: { widgetId: widget.id } })
            .then((res: { id: number }) => {
              dispatch(removeCanvasWidget(res.id));
            });
        }
      });
  }

  /**
   * Creates a group of widgets. Grouping is possible only if:
   * - The group (as a perfect rectangle) doesn't intersect with other widgets.
   * - Widgets must not be groups themselves.
   */
  createGroup() {
    const canvasWidgets = getState().dashboardEditor.canvasWidgets;
    const widgetsToGroup = canvasWidgets.filter((w) => w.isSelected);
    const containsGroups = widgetsToGroup.some((w) => isWidgetGroup(w));

    if (containsGroups) {
      return modalService.openAlert({ text: 'dashboardEditor.groupOfGroupsDisallowed' });
    }

    const groupDimensions = this.getGroupDimensions(widgetsToGroup);

    const groupIntersectsOtherWidgets = canvasWidgets.some((cw) => {
      const isGroupWidget = widgetsToGroup.some((w) => w.id === cw.id);
      return !isGroupWidget && this.isIntersecting(cw, groupDimensions);
    });

    if (groupIntersectsOtherWidgets) {
      return modalService.openAlert({ text: 'dashboardEditor.groupRequirements' });
    }

    this.applyGroupWidgets(widgetsToGroup, groupDimensions);
  }

  /**
   * Ungroups a group of widgets.
   */
  removeGroup(widget: CanvasWidget) {
    dispatch(removeCanvasWidget(widget.id));
    widget.children.forEach((child) => {
      // Select the children.
      child.isSelected = true;

      dispatch(addCanvasWidget(child));
    });
  }

  /**
   * When the canvas-outer resizes we need to resize the canvas-inner
   * element in some situations.
   */
  setCanvasHeight(newHeight?: number, layoutType?: string) {
    newHeight = newHeight || this.outerElement.getBoundingClientRect().height;
    const maxBottom = this.getMinCanvasHeight() / cellSize;
    const canvasWidgets = getState().dashboardEditor.canvasWidgets;
    const hasWidgetsExceedingScreen = canvasWidgets.some((w) => w.y + w.h > maxBottom);

    if (!hasWidgetsExceedingScreen) {
      const innerHeight = newHeight - this.getFilterWidgetHeight();
      const snappedHeight = innerHeight - (innerHeight % cellSize);
      dispatch(setCanvasHeight(snappedHeight));
    }

    // if (layoutType === 'TABLET' || layoutType === 'MOBILE') {
    dispatch(setCanvasHeight(newHeight));
    // }
  }

  onCanvasClick = ({
    pageX,
    pageY,
    ctrlKey = null,
    isRectangleSelection = false,
    setIsRectangleSelection = null,
  }) => {
    const { canvasWidgets, numSelected } = getState().dashboardEditor;
    if (numSelected === 0) {
      return;
    }
    const pos = this.getPositionOnCanvas(pageX, pageY);
    const x = pos[0] / cellSize;
    const y = pos[1] / cellSize;
    const clickedInWidget = canvasWidgets.some(
      (w) => x >= w.x && x <= w.x + w.w + 1 && y >= w.y && y <= w.y + w.h + 1
    );
    if (!clickedInWidget && !ctrlKey && !isRectangleSelection) {
      dispatch(clearWidgetSelection());
    }
    if (isRectangleSelection) {
      setIsRectangleSelection(false);
    }
  };

  /**
   * Starts a resize operation. Should be called on a mousedown event.
   */
  startResize(cfg: CSI.StartResizeCfg) {
    const { widget, direction, pageX, pageY, element } = cfg;
    document.body.style.cursor = this.getResizeCursor(direction);
    const rect = element.getBoundingClientRect();
    const mouseOffsetX = pageX - rect.left;
    const mouseOffsetY = pageY - rect.top;

    this.resizedItem$.next({
      ...this.emptyResizedItem(),
      isResizing: true,
      canvasWidget: widget,
      direction,
      mouseX: pageX,
      mouseY: pageY,
      mouseOffsetX,
      mouseOffsetY,
    });

    window.addEventListener('mousemove', this.onResize);
    window.addEventListener('mouseup', this.onResizeEnd);
  }

  /**
   * The user-filters widget is a special case. It is always docked
   * to the top and uses a different mechanism than the other widgets.
   */
  addUserFiltersWidget() {
    const filterWidgetVisible = getState().dashboardEditor.filterWidgetVisible;

    if (!filterWidgetVisible) {
      dispatch(setFilterWidgetVisibility(true));
      this.setCanvasHeight();
      this.setUserFiltersVisibilityServer(true);
    }
  }

  removeUserFiltersWidget() {
    const filterWidgetVisible = getState().dashboardEditor.filterWidgetVisible;

    if (filterWidgetVisible) {
      dispatch(setFilterWidgetVisibility(false));
      this.setCanvasHeight();
      this.setUserFiltersVisibilityServer(false);
    }
  }

  setUserFiltersVisibilityServer(filterWidgetVisible: boolean) {
    // Updates the server if the user filters widget should be displayed on the
    // dashboard editor and on the live dashboard
    const id = getState().dashboardEditor.id;
    httpService.api({
      type: 'setDashboard',
      urlParams: {
        id,
      },
      disableBI: true,
      data: { filterWidgetVisible },
    });
  }

  private readonly setMousePositionAndDrag = ({ pageX, pageY }) => {
    this.setMousePosition({ pageX, pageY });
    this.onDrag();
  };

  /**
   * Starts a drag operation. Should be called on a mousedown event.
   */
  startDragging(cfg: CSI.StartDraggingCfg) {
    const { element, widgetType, pageX, pageY, canvasWidget, widgetSettings, fromClipboard } = cfg;
    this.setMousePosition({ pageX, pageY });

    removeWindowSelection();
    document.body.style.cursor = canvasWidget && canvasWidget.id ? 'move' : 'grabbing';
    const rect = element.getBoundingClientRect();
    const mouseOffsetX = !fromClipboard ? pageX - rect.left : 0;
    const mouseOffsetY = !fromClipboard ? pageY - rect.top : 0;

    this.draggedItem$.next({
      ...this.emptyDraggedItem(),
      mouseX: pageX,
      mouseY: pageY,
      mouseOffsetX,
      mouseOffsetY,
      isDragging: true,
      draggedType: widgetType,
      canvasWidget,
      widgetSettings,
    });
    this.onDrag();

    window.addEventListener('mousemove', this.setMousePositionAndDrag);
    window.addEventListener('mouseup', this.onDragEnd);
  }

  // DO NOT DELETE!
  // getSelectionGridIndexes({ pageX, pageY }) {
  //   this.setMousePosition({ pageX: pageX, pageY: pageY });
  //   const { top, left } = this.getAbsolutePositionOnCanvas(pageX, pageY);
  //   return this.getGridIndexes(Math.round(top), Math.round(left));
  // }

  // DO NOT DELETE!
  // onRectangleSelection(cfg) {
  //   this.setMousePosition({ pageX: cfg.pageX, pageY: cfg.pageY });

  //   removeWindowSelection();

  //   const { selectionBoxOrigin, setSelectionBoxOrigin, selectionBoxTarget } = cfg;
  //   const spaceFromTop = 280;
  //   let pageY = this.pageY;
  //   let pageX = this.pageX;
  //   let newY = pageY;
  //   const { top, left } = this.getAbsolutePositionOnCanvas(pageX, pageY);
  //   const newGridIndexes = this.getGridIndexes(Math.round(top), Math.round(left));
  //   const isMoved = Math.abs(selectionBoxOrigin.y - selectionBoxTarget.y) > cellSize;
  //   const canvasHeight = getState().dashboardEditor.canvasHeight;

  //   if (pageY < spaceFromTop && isMoved && newGridIndexes.y > 8) {
  //     if (pageY !== 100) {
  //       this.factor = Math.round((spaceFromTop - pageY) / 5);
  //     }
  //     newY =
  //       this.outerElement.scrollTop > 0 ? selectionBoxOrigin.y + this.factor : selectionBoxOrigin.y;
  //     this.outerElement.scrollTop = this.outerElement.scrollTop - this.factor;

  //     setSelectionBoxOrigin({
  //       ...selectionBoxOrigin,
  //       y: newY >= 187 ? newY : 187,
  //     });
  //     return;
  //   }

  //   if (pageY >= this.outerElement.clientHeight + 150 && isMoved) {
  //     function isTooCloseToCanvasBottom() {
  //       const rawZoom = Math.floor((window.outerWidth / window.innerWidth) * 70);
  //       const zoom = rawZoom <= 100 ? Math.floor(rawZoom) : Math.floor(Math.floor(rawZoom) / 5) * 5;

  //       const ratio = zoom < 90 ? 1.08 : 1.1;

  //       return Number.parseFloat((canvasHeight / (newGridIndexes.y * cellSize)).toFixed(2)) < ratio;
  //     }

  //     if (this.outerElement.clientHeight + 100 !== pageY) {
  //       this.factor = Math.round(Math.abs(this.outerElement.clientHeight - pageY) / 10);
  //     }

  //     pageY = this.outerElement.clientHeight + 100;
  //     newY = !isTooCloseToCanvasBottom()
  //       ? selectionBoxOrigin.y - this.factor
  //       : selectionBoxOrigin.y;
  //     this.outerElement.scrollTop = this.outerElement.scrollTop + this.factor;

  //     setSelectionBoxOrigin({
  //       ...selectionBoxOrigin,
  //       y: newY >= 187 ? newY : 187,
  //     });
  //     return;
  //   }

  //   clearInterval(this.autoScrollInterval);
  //   this.autoScrollInterval = undefined;
  // }

  /**
   * Changes the canvas-width if no widgets exceed the new width.
   * Otherwise, will draw a line indicating where widgets should be moved
   * for the change to succeed.
   */
  changeCanvasWidth(width: CanvasWidthOption, dashboardStates: Array<DashboardState>) {
    const { canvasWidgets: widgets } = getState().dashboardEditor;
    const { sideBarWidth } = cssVarsService.vars;
    const numCols = width.value / cellSize;
    const hasExceedingWidgets = widgets.some(({ x, w }) => x + w > numCols);
    if (hasExceedingWidgets) {
      modalService.openAlert({ text: 'dashboard-editor.resize-failed-msg' });
      this.canvasEndIndicatorX$.next(width.value - sideBarWidth);
    } else {
      if (this.canvasEndIndicatorX$.getValue()) {
        this.canvasEndIndicatorX$.next(null);
      }
      cssVarsService.setVariable('canvasWidth', width.value);
      dispatch(setCanvasWidth(width));
      dispatch(setDashboardStates(dashboardStates));
    }
  }

  switchCanvasWidth(width: CanvasWidthOption) {
    cssVarsService.setVariable('canvasWidth', width.value);
    dispatch(setCanvasWidth(width));
  }

  changeRefreshRate(refreshRate: number) {
    dispatch(setRefreshRate(refreshRate));
  }

  changeShowGrid(showGrid: boolean) {
    dispatch(setShowGrid(showGrid));
  }

  changeLayoutMode(isSplitLayout: boolean) {
    dispatch(setIsSplitLayout(isSplitLayout));
  }

  private readonly onResizeEnd = () => {
    document.body.style.cursor = 'auto';
    this.resizedItem$.next(this.emptyResizedItem());
    window.removeEventListener('mousemove', this.onResize);
    window.removeEventListener('mouseup', this.onResizeEnd);
  };

  private readonly onResize = ({ pageX, pageY }) => {
    const resizedItem = this.resizedItem$.getValue();
    const { x, y, w, h, id } = resizedItem.canvasWidget;
    const newCoords = this.getNewResizeCoords(pageX, pageY, resizedItem.direction);
    const widthChanged = newCoords.w !== w;
    const heightChanged = newCoords.h !== h;
    const isMultiDirectionResize = resizedItem.direction.includes('-');

    if (!widthChanged && !heightChanged) {
      return;
    }

    if (!this.isIntersectingWidgets(id, newCoords)) {
      // We can apply the resize as-is.
      this.applyResize(pageX, pageY, newCoords);
    } else if (isMultiDirectionResize) {
      // We can try to apply only one resize-direction.
      const onlyXVariant = { x: newCoords.x, w: newCoords.w, y, h };
      const onlyYVariant = { x, w, y: newCoords.y, h: newCoords.h };

      if (widthChanged && !this.isIntersectingWidgets(id, onlyXVariant)) {
        this.applyResize(pageX, null, onlyXVariant);
      } else if (heightChanged && !this.isIntersectingWidgets(id, onlyYVariant)) {
        this.applyResize(null, pageY, onlyYVariant);
      }
    }

    const { bottom } = this.getAbsolutePositionOnCanvas(pageX, pageY, true);
    if (this.isTooCloseToCanvasBottom(bottom)) {
      this.addBufferToCanvasHeight();
    }
  };

  private applyResize(pageX: number, pageY: number, newCoords: Coordinates) {
    const resizedItem = this.resizedItem$.getValue();
    const id = resizedItem.canvasWidget.id;
    this.resizedItem$.next({
      ...resizedItem,
      mouseX: pageX ? pageX : resizedItem.mouseX,
      mouseY: pageY ? pageY : resizedItem.mouseY,
      canvasWidget: {
        ...resizedItem.canvasWidget,
        ...newCoords,
      },
    });

    this.updateWidgetState(id, newCoords);
  }

  updateWidgetState(id: number, newCoords: Coordinates) {
    const currentLayout = getState().dashboardEditor.currentLayout;
    if (id) {
      clearTimeout(this.timeoutHandler);
      this.timeoutHandler = setTimeout(() => {
        httpService.api({
          type: 'updateWidgetState',
          urlParams: { widgetId: id },
          data: { ...newCoords, layoutStateType: currentLayout },
          disableBI: true,
        });
      }, 500);

      dispatch(editCanvasWidgetById(id, newCoords, currentLayout));
    }
  }

  updateWidgetId(
    state: Coordinates,
    updatedId: number,
    name?: string,
    customization?,
    status?,
    hideWidgetName?
  ) {
    const currentLayout = getState().dashboardEditor.currentLayout;
    const widget = getState().dashboardEditor.canvasWidgets.find(
      (w) => w.x === state.x && w.y === state.y
    );

    if (!widget) return;

    const tempWidget = { ...widget };
    const prevId = tempWidget.id;
    tempWidget.id = updatedId;
    name && (tempWidget.name = name);
    customization && (tempWidget.customization = customization);
    status && (tempWidget.status = status);
    hideWidgetName && (tempWidget.hideWidgetName = hideWidgetName);

    dispatch(editCanvasWidgetById(prevId, tempWidget, currentLayout));

    return tempWidget;
  }

  private readonly onDragEnd = () => {
    document.body.style.cursor = 'auto';
    this.addDraggedWidgetToCanvas();
    this.draggedItem$.next(this.emptyDraggedItem());
    this.autoScrollInterval && clearInterval(this.autoScrollInterval);
    this.autoScrollInterval = undefined;

    window.removeEventListener('mousemove', this.setMousePositionAndDrag);
    window.removeEventListener('mouseup', this.onDragEnd);
  };

  //as long the cursor is in the bottom or top of the canvas there will be auto scroll
  private readonly doInfiniteScrolling = ({ pageX, pageY }) => {
    if (pageY < 280 || pageY >= this.outerElement.clientHeight) {
      this.autoScrollInterval = setInterval(() => {
        this.onDrag();
      }, 50);
    }
  };

  factor = 0; //scrolling distance (calc by the distance between the cursor and the bottm/top of the canvas)

  private readonly onDrag = () => {
    let pageY = this.pageY; // cursor position
    let pageX = this.pageX; // cursor position
    const { top, left, bottom } = this.getAbsolutePositionOnCanvas(pageX, pageY);
    const oldValue = this.draggedItem$.getValue();
    const newGridIndexes = this.getGridIndexes(Math.round(top), Math.round(left));
    const oldGridIndexes = oldValue.gridIndexes;
    const canvasWidget = oldValue.canvasWidget;
    const gridIndexesChanged = !isEqual(oldGridIndexes, newGridIndexes);
    const gridIndexes = gridIndexesChanged ? newGridIndexes : oldGridIndexes;
    const spaceFromTop = 280; //from this distance the scorll is start
    //start scroll to top
    if (pageY < spaceFromTop) {
      /**
       * calc the scroll space from top (only if the distance from top not equal to 100)
       * when user change cursor point the factor (scrolling speed) will recalculated
       */
      if (pageY !== 100) {
        this.factor = Math.round((spaceFromTop - pageY) / 5);
      }
      pageY = 100;
      //support for edge (instead of scrollBy).
      this.outerElement.scrollTop = this.outerElement.scrollTop - this.factor;
      !this.autoScrollInterval && this.doInfiniteScrolling({ pageX, pageY });
      //start scroll to bottom
    } else if (
      pageY >= this.outerElement.clientHeight &&
      (Math.abs(canvasWidget?.y - newGridIndexes.y) > 1 || !canvasWidget)
    ) {
      /**
       * calc the scroll space from bottom (only if the distance from bottom not equal to "this.outerElement.clientHeight + 100")
       * when user change cursor point the factor (scrolling speed) will recalculated
       */
      if (this.outerElement.clientHeight + 100 !== pageY) {
        this.factor = Math.round(Math.abs(this.outerElement.clientHeight - pageY) / 5);
      }
      pageY = this.outerElement.clientHeight + 100;
      //support for edge (instead of scrollBy).
      this.outerElement.scrollTop = this.outerElement.scrollTop + this.factor;
      !this.autoScrollInterval && this.doInfiniteScrolling({ pageX, pageY });
    } else {
      clearInterval(this.autoScrollInterval);
      this.autoScrollInterval = undefined;
    }

    if (this.isTooCloseToCanvasBottom(bottom)) {
      this.addBufferToCanvasHeight();
    }

    // We calculate isInvalidLocation only when the grid indexes change.
    const isIntersectingWidgets = gridIndexesChanged
      ? this.isIntersectingWidgets((canvasWidget || {}).id, gridIndexes)
      : !oldValue.isValidLocation;

    this.draggedItem$.next({
      ...oldValue,
      mouseX: pageX,
      mouseY: pageY,
      hasMoved: this.hasCanvasWidgetMoved(newGridIndexes.x, newGridIndexes.y),
      gridIndexes,
      isValidLocation: !isIntersectingWidgets,
    });
  };

  private hasCanvasWidgetMoved(x: number, y: number) {
    const { canvasWidget, hasMoved } = this.draggedItem$.getValue();
    if (hasMoved || !canvasWidget || !canvasWidget.id) {
      // Once you move, you can't return to false. If dragging a new
      // widget, we automatically say we moved.
      return true;
    } else {
      return Math.abs(canvasWidget.x - x) > 1 || Math.abs(canvasWidget.y - y) > 1;
    }
  }

  private isTooCloseToCanvasBottom(absoluteBottom: number) {
    const canvasHeight = getState().dashboardEditor.canvasHeight;
    return canvasHeight - absoluteBottom < 50;
  }

  private addBufferToCanvasHeight() {
    const { id, canvasHeight, currentLayout, states } = getState().dashboardEditor;
    //const heightAddition = 200 - (200 % cellSize);
    const newCanvasHeight = canvasHeight + 56;
    dispatch(setCanvasHeight(newCanvasHeight));
    dispatch(
      setDashboardStates(
        states.map((state) => {
          if (state.layoutStateType === currentLayout) {
            return { ...state, canvasHeight: newCanvasHeight };
          }
          return state;
        })
      )
    );
    httpService.api({
      type: 'updateDashboard',
      urlParams: {
        id,
      },
      disableBI: true,
      data: { canvasHeight: newCanvasHeight, layoutStateType: currentLayout },
    });
  }

  private getNewResizeCoords(pageX: number, pageY: number, resizeDirection: CSI.ResizeDirection) {
    const resizedItem = this.resizedItem$.getValue();
    const canvasWidth = cssVarsService.vars.canvasWidth - cssVarsService.vars.sideBarWidth;
    const { canvasHeight } = getState().dashboardEditor;
    const { x, y, w, h, type, hideWidgetName } = resizedItem.canvasWidget;
    const minH = hideWidgetName
      ? Math.ceil(this.getWidgetMinHeight(type) / cellSize) -
        Math.floor(cssVarsService.vars.widgetActionBarHeight / cellSize)
      : Math.ceil(this.getWidgetMinHeight(type) / cellSize);
    const minW = Math.ceil(this.getWidgetMinWidth(type) / cellSize);
    const [absoluteX, absoluteY] = this.getPositionOnCanvas(pageX, pageY);

    let newHeight = h;
    let newWidth = w;
    let newSnappedLeft = x;
    let newSnappedTop = y;

    if (resizeDirection.includes('bottom')) {
      const newBottom = Math.min(absoluteY, canvasHeight);
      const newSnappedBottom = (newBottom - (newBottom % cellSize)) / cellSize;
      newHeight = newSnappedBottom - y;
    }
    if (resizeDirection.includes('top')) {
      const newTop = Math.max(absoluteY, 0);
      newSnappedTop = (newTop - (newTop % cellSize)) / cellSize;
      newHeight = y + h - newSnappedTop;
    }
    if (resizeDirection.includes('left')) {
      const newLeft = Math.max(absoluteX, 0);
      newSnappedLeft = (newLeft - (newLeft % cellSize)) / cellSize;
      newWidth = x + w - newSnappedLeft;
    }
    if (resizeDirection.includes('right')) {
      const newRight = Math.min(absoluteX, canvasWidth);
      const newSnappedRight = (newRight - (newRight % cellSize)) / cellSize;
      newWidth = newSnappedRight - x;
    }

    return {
      x: newWidth >= minW ? newSnappedLeft : x,
      y: newHeight >= minH ? newSnappedTop : y,
      w: newWidth >= minW ? newWidth : w,
      h: newHeight >= minH ? newHeight : h,
    };
  }

  /**
   * Calculates the widget's position on the canvas from the mouse coordinates.
   */
  private getAbsolutePositionOnCanvas(pageX: number, pageY: number, isResize = false) {
    const { canvasWidth, sideBarWidth, canvasGutterSize } = cssVarsService.vars;
    const canvasHeight = getState().dashboardEditor.canvasHeight;
    const { draggedType, mouseOffsetX, mouseOffsetY, canvasWidget } = isResize
      ? this.resizedItem$.getValue()
      : this.draggedItem$.getValue();

    let [left, top] = this.getPositionOnCanvas(pageX - mouseOffsetX, pageY - mouseOffsetY);

    const width = canvasWidget ? canvasWidget.w * cellSize : this.getWidgetMinWidth(draggedType);
    const height = canvasWidget ? canvasWidget.h * cellSize : this.getWidgetMinHeight(draggedType);

    let bottom = top + height;
    let right = left + width;

    if (canvasWidget) {
      if (bottom < canvasWidget.h * canvasGutterSize) {
        bottom += canvasGutterSize - top;
        top = 0;
      }

      if (left != 0 && left < canvasGutterSize) {
        right += canvasGutterSize - left;
        left = canvasGutterSize;
      }

      if (bottom > canvasHeight) {
        top -= bottom - (canvasHeight - canvasGutterSize);
        bottom = canvasHeight - canvasGutterSize;
      }

      if (right > canvasWidth - sideBarWidth) {
        left -= right - (canvasWidth - sideBarWidth - canvasGutterSize);
        right = canvasWidth - sideBarWidth - canvasGutterSize;
      }
    } else {
      if (top < canvasGutterSize) {
        bottom += canvasGutterSize - top;
        top = canvasGutterSize;
      }

      if (left < canvasGutterSize) {
        right += canvasGutterSize - left;
        left = canvasGutterSize;
      }

      if (bottom > canvasHeight - canvasGutterSize) {
        top -= bottom - (canvasHeight - canvasGutterSize);
        bottom = canvasHeight - canvasGutterSize;
      }

      if (right > canvasWidth - sideBarWidth - canvasGutterSize) {
        left -= right - (canvasWidth - sideBarWidth - canvasGutterSize);
        right = canvasWidth - sideBarWidth - canvasGutterSize;
      }
    }

    return { top, left, bottom, right };
  }

  /**
   * Calculates the absolute position on the canvas (in pixels) from
   * The mouse coordinates (pageX, pageY).
   */
  private getPositionOnCanvas(pageX: number, pageY: number) {
    const canvasRect = this.innerElement.getBoundingClientRect();

    const offsetY = canvasRect.top - this.innerElement.scrollTop;
    const offsetX = canvasRect.left;

    return [pageX - offsetX, pageY - offsetY];
  }

  /**
   * Calculates the grid indexes a currently dragged item
   * with an absolute position on the grid.
   */
  private getGridIndexes(top: number, left: number) {
    const { draggedType, canvasWidget } = this.draggedItem$.getValue();

    const snappedTop = top - (top % cellSize);
    const snappedLeft = left - (left % cellSize);
    const width = canvasWidget ? canvasWidget.w * cellSize : this.getWidgetMinWidth(draggedType);
    const height = canvasWidget ? canvasWidget.h * cellSize : this.getWidgetMinHeight(draggedType);

    // We increase the height if the dimensions don't fit the grid.
    const heightFitsGrid = height % cellSize === 0;
    const heightOffset = heightFitsGrid ? 0 : cellSize - (height % cellSize);
    const snappedBottom = snappedTop + height + heightOffset;

    // We do the same for the width.
    const widthFitsGrid = width % cellSize === 0;
    const widthOffset = widthFitsGrid ? 0 : cellSize - (width % cellSize);
    const snappedRight = snappedLeft + width + widthOffset;

    return {
      x: snappedLeft / cellSize,
      y: snappedTop / cellSize,
      w: (snappedRight - snappedLeft) / cellSize,
      h: (snappedBottom - snappedTop) / cellSize,
    };
  }

  private getResizeCursor(direction: CSI.ResizeDirection): string | undefined {
    switch (direction) {
      case 'top':
      case 'bottom':
        return 'row-resize';
      case 'left':
      case 'right':
        return 'col-resize';
      case 'top-left':
        return 'nw-resize';
      case 'top-right':
        return 'ne-resize';
      case 'bottom-left':
        return 'sw-resize';
      case 'bottom-right':
        return 'se-resize';
    }
  }

  getWidgetIntersectionResultForHeaderReAdded(widgetId: number, coords: Coordinates) {
    const canvasWidgets = getState().dashboardEditor.canvasWidgets;
    let highestBottomBorderPosition = -1;

    let suggestedCords = {
      ...coords,
    };

    // When we show the header again, the element might be located in a such location where the header will be out of the canvas
    if (suggestedCords.y < 0) {
      suggestedCords.y = 0;

      // we moved the widget downwards so it will be located in a valid position. If in that posistion is intersects with
      // another widget, then return an intersection error
      if (
        canvasWidgets.some((cw) => {
          return cw.id !== widgetId && this.isIntersecting(cw, suggestedCords);
        })
      ) {
        return {
          isIntersecting: true,
          coords: suggestedCords,
        };
      }
    }

    canvasWidgets.forEach((cw) => {
      if (cw.id !== widgetId && this.isIntersecting(cw, suggestedCords)) {
        // Header was added, so top position of the widget is now intersecting with the bottom position of another widget
        // We need to find the intersecting widget with the most higher bottom position and, and move the widget downward
        // so it will not intersect with it.
        highestBottomBorderPosition = Math.max(highestBottomBorderPosition, cw.y + cw.h);
      }
    });

    if (highestBottomBorderPosition > 0) {
      suggestedCords.y = highestBottomBorderPosition;

      return {
        isIntersecting: canvasWidgets.some((cw) => {
          return cw.id !== widgetId && this.isIntersecting(cw, suggestedCords);
        }),
        coords: suggestedCords,
      };
    } else {
      return {
        isIntersecting: false,
        coords: suggestedCords,
      };
    }
  }

  private isIntersectingWidgets(widgetId: number, coords: Coordinates) {
    const canvasWidgets = getState().dashboardEditor.canvasWidgets;
    return canvasWidgets.some((cw) => {
      return cw.id !== widgetId && this.isIntersecting(cw, coords);
    });
  }

  isIntersectingWidgetArrangement(
    allWidgetsWithCalculatedCoords: Array<{
      id: number;
      coords: Coordinates;
    }>,
    widget: { id: number; coords: Coordinates }
  ) {
    return allWidgetsWithCalculatedCoords.some((w) => {
      return w.id !== widget.id && this.isIntersecting(w.coords, widget.coords);
    });
  }

  /**
   * Calculates whether 2 rectangular shapes are intersecting based
   * on their coordinates: top (y), left (x), width (w), height (h).
   */
  private isIntersecting(pos1: Coordinates, pos2: Coordinates) {
    return (
      pos2.x + pos2.w > pos1.x &&
      pos2.x < pos1.x + pos1.w &&
      pos2.y + pos2.h > pos1.y &&
      pos2.y < pos1.y + pos1.h
    );
  }

  /**
   * Use this function to save a widget from the canvas and not from the CreateWidgetPage
   */
  private saveWidgetToCanvas = async (
    widgetSettings,
    type,
    dashboardId,
    state,
    canvasWidget = {} as any
  ) => {
    const customization = canvasWidget?.customization && { ...canvasWidget?.customization };
    let status = 'DRAFT';
    if (type === 'image' && canvasWidget?.copyId) {
      status = canvasWidget.customization?.imageId ? 'PUBLISHED' : 'DRAFT';
    } else if (type === 'image_by_value' && canvasWidget?.copyId) {
      status = canvasWidget.status;
    }

    const { states } = getState().dashboardEditor;

    let _initialWidgetData = {
      ...initialWidgetData,
      languageKeys: [],
      type: type,
      ...canvasWidget,
      creationStage: creationStageMap[widgetSettings?.useSteps ? widgetSettings?.useSteps[0] : 1],
      state,
      status: status || 'DRAFT',
      customization: customization || null,
      dashboardId,
      states: [{ ...state, layoutStateType: getState().dashboardEditor.currentLayout }],
    };
    let widgetDefaultName =
      canvasWidget?.name || (await getDefaultNameCreateWidget(dashboardId, null, null, null, type));

    _initialWidgetData.name = widgetDefaultName;
    // creating the widget in the Server DB
    const widgetToServer = localToServer(_initialWidgetData);
    const res = await httpService.api<{ id: number }>({
      type: 'createWidget',
      data: widgetToServer,
    });

    let newDashboardStates = states.map((s) => {
      const widgetState = widgetToServer.states.find(
        (ws) => ws.layoutStateType === s.layoutStateType
      );

      return {
        ...s,
        canvasHeight: Math.max(s.canvasHeight, (widgetState.h + widgetState.y + 1) * cellSize),
      };
    });

    dispatch(setDashboardStates(newDashboardStates));

    newDashboardStates.forEach((state) => {
      httpService.api({
        type: 'updateDashboard',
        urlParams: {
          id: dashboardId,
        },
        disableBI: true,
        data: { canvasHeight: state.canvasHeight, layoutStateType: state.layoutStateType },
      });
    });

    // Updating the widget with location coords: {x, y, w, h} and name in the Redux,
    // so we will see the updated data before we need to refresh the page.
    const widgetData = this.updateWidgetId(
      _initialWidgetData.state,
      res.id,
      widgetDefaultName,
      customization,
      status,
      canvasWidget.hideWidgetName
    );

    dispatch(setCanvasWidgetStatesById(widgetData.id, widgetToServer.states));
  };

  private addDraggedWidgetToCanvas() {
    const dashboardId = getState().dashboardEditor.id;
    const currentLayout = getState().dashboardEditor.currentLayout;
    const {
      isValidLocation,
      canvasWidget,
      gridIndexes: { x, y, w, h },
      draggedType: type,
      widgetSettings,
    } = this.draggedItem$.getValue();

    if (!isValidLocation) {
      return;
    }

    if (canvasWidget && canvasWidget.id) {
      //if dragging existing widget inside the canvas
      const deltaX = x - canvasWidget.x;
      const deltaY = y - canvasWidget.y;

      // We must also update the children's position.
      const children = canvasWidget.children.map((child) => ({
        ...child,
        x: child.x + deltaX,
        y: child.y + deltaY,
      }));

      httpService.api({
        type: 'updateWidgetState',
        urlParams: { widgetId: canvasWidget.id },
        data: { x, y, w, h, layoutStateType: currentLayout },
        disableBI: true,
      });
      dispatch(editCanvasWidgetById(canvasWidget.id, { x, y, w, h, children }, currentLayout));
    } else {
      //dragging new widget from widget gallery or clipboard
      dispatch(addCanvasWidget({ x, y, w, h, type, isSelected: false }));

      // If true, set an empty draft widget on the canvas
      if (widgetSettings?.disableCreateWidgetFlow) {
        this.saveWidgetToCanvas(widgetSettings, type, dashboardId, { x, y, w, h }, canvasWidget);
      } else {
        history.push(`/main/edit-dashboard/${dashboardId}/create-widget/`, {
          coords: { x, y, w, h },
          type,
          copyId: canvasWidget && canvasWidget.copyId,
          name: canvasWidget && canvasWidget.name,
          canvasWidget,
        });
      }
    }
  }

  /**
   * Returns the resulting widget-group dimensions when grouping widgets together.
   */
  private getGroupDimensions(widgets: Array<CanvasWidget>): Coordinates {
    const x = Math.min(...widgets.map((w) => w.x));
    const y = Math.min(...widgets.map((w) => w.y));
    const w = Math.max(...widgets.map((w) => w.x + w.w)) - x;
    const h = Math.max(...widgets.map((w) => w.y + w.h)) - y;

    return { x, y, w, h };
  }

  /**
   * Applies a grouping operation on widgets.
   */
  private applyGroupWidgets(widgets: Array<CanvasWidget>, groupDimensions: Coordinates) {
    const widgetIds = widgets.map((w) => w.id);
    dispatch(removeCanvasWidget(widgetIds));

    widgets.forEach((w) => {
      // Remove the children's selection.
      w.isSelected = false;
    });

    dispatch(
      addCanvasWidget({
        ...groupDimensions,
        type: 'composite',
        isSelected: true,
        children: widgets,
      })
    );
  }

  /**
   * Calculates the height of the canvas that fills the entire screen
   * without requiring scroll.
   */
  private getMinCanvasHeight() {
    const outerRect = this.outerElement.getBoundingClientRect();
    const height = window.innerHeight - outerRect.top - this.getFilterWidgetHeight();

    // Canvas height must be a multiple of the grid cellSize.
    return height - (height % cellSize);
  }

  private emptyDraggedItem(): CSI.DragInfo {
    return {
      isDragging: false,
      hasMoved: false,
      draggedType: null,
      mouseX: null,
      mouseY: null,
      mouseOffsetX: null,
      mouseOffsetY: null,
      gridIndexes: null,
      isValidLocation: false,
    };
  }

  private emptyResizedItem(): CSI.ResizeInfo {
    return {
      isResizing: false,
      canvasWidget: null,
      direction: null,
      mouseX: null,
      mouseY: null,
      mouseOffsetX: null,
      mouseOffsetY: null,
    };
  }

  private getWidgetMinHeight(widgetType: WidgetType) {
    const actionBarHeight = cssVarsService.vars.widgetActionBarHeight;
    const gutterSize = cssVarsService.vars.canvasGutterSize;
    const minHeight = (widgetMap[widgetType] || {}).minHeight;

    return actionBarHeight + minHeight + 2 * gutterSize;
  }

  private getWidgetMinWidth(widgetType: WidgetType) {
    const gutterSize = cssVarsService.vars.canvasGutterSize;
    const minWidth = (widgetMap[widgetType] || {}).minWidth;

    return minWidth; // + 2 * gutterSize;
  }

  private getFilterWidgetHeight() {
    const { userFiltersWidgetContentHeight, userFiltersWidgetHeaderHeight, canvasGutterSize } =
      cssVarsService.vars;

    return getState().dashboardEditor.filterWidgetVisible
      ? userFiltersWidgetHeaderHeight + userFiltersWidgetContentHeight + canvasGutterSize * 2
      : 0;
  }
}

export const canvasService = new CanvasService();
