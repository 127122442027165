import SwitchWidget from '@components/widgets/SwitchWidget';
import { WidgetData } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { stepThreeDefaultValidation } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';

const stepFourSwitchValidation = (widgetData: WidgetData) => {
  if (widgetData.customization) {
    switch (widgetData.customization['buttonType']) {
      case 'TOGGLE_BUTTON':
        if (!widgetData.customization['requestSentText'] || !widgetData.customization['onText'])
          return false;
        break;
      case 'BUTTON':
        if (
          !widgetData.customization['requestSentText'] ||
          !widgetData.customization['onText'] ||
          !widgetData.customization['offText']
        )
          return false;
      default:
        break;
    }
  }
  return true;
};

export const switchWidget: WidgetMapItem = {
  id: 'switch',
  name: 'switch',
  component: SwitchWidget,
  minWidth: 166,
  minHeight: 64,
  fullSize: true,
  images: {
    canvas: '/assets/images/switch_empty_state_image.svg',
    thumbnail: 'switch',
  },
  editModalSize: {},
  settings: {
    enableSaveAsDraft: false,
    assetIsNotDisabled: ['booleanType'],
    acceptOnlyWritableTags: true,
    dataChangesToHandle: ['metrics'],
    // hideHideOptions: true,
    hidePreviewTable: false,
    useSteps: [1, 4],
    showDisclaimer: true,
    maxDataTags: 1,
    stepOneDisabled: ['AssetProperties'],
    stepOneHidden: ['Variables'],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
      4: (widgetData) => stepFourSwitchValidation(widgetData),
    },
    languageKeys: ['textualRemark', 'requestSentText', 'onText', 'offText'],
    /*   customizationServerChanges: (customization) => {
          delete customization.hideWidgetFrame;
          delete customization.hideWidgetName;
          return customization;
        }, */
    allowAssetTypeNavigation: (widgetData) => false,
    hideFilterNavigation: (widgetData) => true,
  },
};
