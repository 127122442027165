import React, { useRef, useMemo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

// Services | Interfaces
import { navigationDataBuilder } from '@pages/LiveDashboardPage/LiveDashboardPage.utils';
import { ValueWidgetProps } from './ConditionalValueWidget.interface';
import { convertBooleanToTranslatedString, numberFormatter } from '@core/utils';
import { conditionsService } from '@core/ConditionsService';
import { httpService } from '@core/http/HttpService';
import Image from '@components/Image';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: `flex`,
    overflow: 'auto',
    color: 'var(--widgetsFont)',
    flexDirection: ({ alignment }) => (alignment === 'CENTERED' ? 'column-reverse' : 'row'),
    alignItems: 'center',
    justifyContent: ({ alignment }) => (alignment === 'CENTERED' ? 'center' : 'space-between'),
    backgroundColor: ({ backgroundColor }) => (backgroundColor ? backgroundColor : 'unset'),
    padding: '5px 23px 15px 23px',
    '-ms-user-select': 'none',
    'user-select': 'none',
    '& :hover': {
      cursor: ({ navigateDashboard, widget }) =>
        navigateDashboard && widget?.navigationDashboard?.length && 'pointer',
    },
  },
  titleWrapper: {
    display: `flex`,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  symbol: {
    color: ({ customization }: any) => customization.symbolColor || 'var(--widgetsFont)',
    paddingRight: ({ customization }: any) => (customization.symbolToLeft ? 10 : 0),
    paddingLeft: ({ customization }: any) => (customization.symbolToLeft ? 0 : 10),
    fontWeight: 600,
    fontSize: ({ customization }: any) => `${customization.symbolFontSize || 20}px`,
    verticalAlign: 'baseline',
  },
  remark: {
    color: ({ remarkColor }) => remarkColor || 'var(--widgetsFont)',
    fontSize: ({ remarkFontSize }) => `${remarkFontSize || 12}px`,
  },
  value: {
    color: ({ valueColor }) => valueColor || 'var(--widgetsFont)',
    fontSize: ({ valueFontSize }) => `${valueFontSize || 35}px`,
    fontWeight: 800,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  emptyBox: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  emptyTitle: {
    border: `3px solid ${theme.app.noValueWidgetBorder}`,
    borderRadius: 10,
    color: theme.app.noValueWidgetTitle,
    fontSize: 14,
    fontWeight: 500,
    padding: '10px 16px',
    textAlign: 'center',
  },
}));

function ConditionalValueWidget(props: ValueWidgetProps) {
  const { widget, navigateDashboard, customization = {}, emptyImage, defaultDecimalDigits } = props;
  const {
    symbol,
    symbolToLeft,
    decimalDigits = defaultDecimalDigits,
    iconColor,
    conditions,
    iconSize,
  } = props.customization || {};
  const value = useMemo(() => {
    let val = props?.data?.results && props?.data?.results[0][props?.data?.columns[0]?.name];
    return val;
  }, [props]);

  const conditionData = useMemo(
    () =>
      (typeof value === 'boolean'
        ? conditions?.find((c) => c.boolValue === value)
        : conditions && conditionsService.findValue(conditions, value)) || {},
    [value, conditions]
  );
  const wrapperRef = useRef(null);
  const valueRef = useRef(null);
  const [icon, setIcon] = useState() as any;

  const getIcon = async () => {
    try {
      const res: any = await httpService.api({
        type: 'getConditioanlValueIcon',
        urlParams: { iconId: conditionData.iconId },
      });
      setIcon(res);
    } catch (e) {}
  };

  useEffect(() => {
    if (conditionData?.iconId && conditionData.iconId !== icon?.id) {
      getIcon();
    } else if (!conditionData?.iconId) {
      setIcon(undefined);
    }
  }, [conditionData]);

  const classes = useStyles({ ...props, ...conditionData, ...customization });

  const onNavigate = () => {
    if (navigateDashboard && widget.navigationDashboard) {
      navigationDataBuilder({
        selectedValue: value,
        selectedColumn: props.data && props.data.columns && props.data.columns[0],
        widget,
        columns: props.data.columns,
        rawValues: props.data.results[0],
        navigateDashboard,
      });
    }
  };

  return conditionData.id ? (
    <div className={classes.wrapper} ref={wrapperRef} onClick={onNavigate}>
      <div className={classes.titleWrapper}>
        {conditionData.remark && <div className={classes.remark}>{conditionData.remark}</div>}
        {symbolToLeft ? (
          <div
            ref={valueRef}
            className={classes.value}
            title={`${symbol && symbol !== '' ? symbol : ''} ${value} `}>
            {symbol && symbol !== '' && <span className={classes.symbol}>{symbol}</span>}
            {typeof value === 'boolean'
              ? convertBooleanToTranslatedString(value)
              : isNaN(Number(value))
              ? value
              : numberFormatter(+value, decimalDigits)}
          </div>
        ) : (
          <div
            ref={valueRef}
            className={classes.value}
            title={`${value}  ${symbol && symbol !== '' ? symbol : ''}`}>
            {typeof value === 'boolean'
              ? convertBooleanToTranslatedString(value)
              : isNaN(Number(value))
              ? value
              : numberFormatter(+value, decimalDigits)}
            {symbol && symbol !== '' && <span className={classes.symbol}>{symbol}</span>}
          </div>
        )}
      </div>
      {icon?.svg && (
        <div
          dangerouslySetInnerHTML={{
            __html: `${icon?.svg.slice(
              0,
              4
            )} style="width: ${iconSize}px; height: ${iconSize}px; fill: ${iconColor}; margin: 10px " ${icon?.svg.slice(
              4
            )}`,
          }}
        />
      )}
    </div>
  ) : (
    <Image
      mode="smaller"
      src={emptyImage}
      text={`widgets.${widget?.status === 'DRAFT' ? 'draft-' : ''}empty-state-text`}
    />
  );
}

export default ConditionalValueWidget;
