import React, { useState, useCallback, useMemo } from 'react';
import { MenuItem, Menu, makeStyles } from '@material-ui/core';

// Components
import Icon from '@components/Icon';

// Services | Interfaces
import { dashboardService } from '@core/canvas/DashboardService';
import { authService } from '@core/auth/AuthService';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';

// 3rd party
import classNames from 'classnames';
import { getState } from '@src/redux/store';

const useStyles = makeStyles((theme: any) => ({
  menuItem: {
    margin: '0 10px',
    padding: '5px 16px 5px 27px',
    borderBottom: `1px solid ${theme.app.multiSelectChip}`,
    display: 'flex',
    cursor: 'pointer',
  },
  name: {
    margin: '0 8px',
    color: 'var(--systemFont)',
    maxWidth: '250px',
  },
  mobileName: {
    margin: '0 5px 0 0',
    maxWidth: '115px',
  },
  icon: {
    display: 'inline-block',
  },
  link: {
    '&:link': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
    '&:visited': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
    '&:hover': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
    '&:active': {
      textDecoration: 'none',
      color: 'var(--systemFont)',
    },
  },
  noPadding: {
    padding: 0,
  },
}));

const menuLinks = {
  TERMS_AND_CONDITIONS: 'http://www.unitronics.cloud/terms-and-conditions',
  CONTACT_US: 'http://www.unitronics.cloud/unicloud-contact-us',
  HELP: 'http://www.unitronics.cloud/unicloud-help/',
};

function UserMenuSelect(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.login);
  const { type } = useSelector((state) => state.config.organizationDetails);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const menuItems = [
    {
      key: 'help_menu_item',
      onClick: () => onRedirect('HELP'),
      text: 'user-menu-select.help',
      isAllow: () => type === 'MACHINE_BUILDER',
    },
    {
      key: 'contactus_menu_item',
      onClick: () => onRedirect('CONTACT_US'),
      text: 'user-menu-select.contactus',
      isAllow: () => type === 'MACHINE_BUILDER',
    },
    {
      key: 'TAndC_menu_item',
      onClick: () => onRedirect('TERMS_AND_CONDITIONS'),
      text: 'user-menu-select.terms-and-conditions',
      isAllow: () => true,
    },
    {
      key: 'logout_menu_item',
      onClick: () => onLogoutSelected(),
      text: 'user-menu-select.logout',
      isAllow: () => true,
    },
  ];

  const classes = useStyles(props);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutSelected = useCallback(() => {
    handleClose();
    dashboardService.clearDashboards();
    authService.logout();
  }, []);

  const onRedirect = useCallback((type) => {
    handleClose();
    const clientType = getState().clientState?.clientType;
    switch (clientType) {
      case 'uniCloudApp':
        uniCloudApp?.openUrlInBrowser(menuLinks[type]);
        break;
      case 'iOSUniCloudApp':
        console.log('Before openUrlInBrowser');
        window['webkit']['messageHandlers']['openUrlInBrowser'].postMessage(menuLinks[type]);
        console.log('After openUrlInBrowser');
        break;
      default:
        window.open(menuLinks[type], '', 'top=100,left=100,width=400,height=600');
        break;
    }
  }, []);

  const menusColor = useMemo(() => 'var(--menusForeground)', []);

  return (
    <div>
      <MenuItem onClick={handleMenu} classes={isMobileView ? { root: classes.noPadding } : {}}>
        {!isMobileView && <Icon type="logout" className={classes.icon} color={menusColor} />}
        <div
          title={user.firstName}
          className={classNames(
            classes.name,
            isMobileView && classes.mobileName,
            'ellipsis-overflow'
          )}>
          {user.firstName}
        </div>
        <Icon type="dropDown" />
      </MenuItem>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}>
        <div style={{ maxHeight: 350, overflow: 'auto' }}>
          {menuItems
            .filter((item) => item.isAllow())
            .map((item) => (
              <MenuItem key={item.key} classes={{ root: classes.menuItem }} onClick={item.onClick}>
                <I18n>{item.text}</I18n>
              </MenuItem>
            ))}
        </div>
      </Menu>
    </div>
  );
}

export default UserMenuSelect;
