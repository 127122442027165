import React, { useState, useMemo } from 'react';
import { isEqual } from 'lodash';
import { InputBase } from '@material-ui/core';

//Components
import Select from '@components/Select';
import ColorInput from '@components/ColorInput';
import MaterialCheckbox from '@components/Checkbox';

// Services | Interfaces
import styles from './LineCustomizationRow.scss';
import {
  LineCustomizationAxes,
  LineCustomizationColumn,
  LineThresholdCustomization,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { LineCustomizationRowProps } from './LineCustomizationRow.interface';
import { getVar } from '@core/canvas/widget.utils';

// 3rd party
import classNames from 'classnames';
import { modalService } from '@core/modals/ModalService';
import I18n from '@components/I18n';
import { useEffect } from 'react';
import { handleNameBlur, handleNameChange, validateNameError } from './LineCustomizationRow.utils';
import { i18nService } from '@core/i18n/I18nService';

function LineCustomizationRow(props: LineCustomizationRowProps) {
  const {
    column,
    columnCfg,
    isFirst,
    isLast,
    colIndex,
    columnChanged,
    type,
    displayMoreThanOneYAxes,
    colorPaletteMode = 'AUTO',
  } = props;
  const [editedColumn, setEditedColumn] = useState<any>({
    ...column,
    displayName: column.displayName ? column.displayName : null,
  });
  const [displayNameHasError, setDisplayNameHasError] = useState(false);

  const typeOptions = useMemo(
    () => [
      { label: 'enum.BAR', value: 'BAR' },
      { label: 'enum.LINE', value: 'LINE' },
      { label: 'enum.AREA', value: 'AREA' },
    ],
    []
  );

  const showOptions = useMemo(
    () => [
      { label: 'enum.NO', value: 'NO' },
      { label: 'enum.LEFT', value: 'LEFT' },
      { label: 'enum.RIGHT', value: 'RIGHT' },
    ],
    []
  );

  const scaleOptions = useMemo(
    () => [
      { label: 'enum.AUTO', value: 'AUTO' },
      { label: 'enum.MANUAL', value: 'MANUAL' },
    ],
    []
  );

  const styleOptions = useMemo(
    () => [
      { label: 'enum.SOLID', value: 'SOLID' },
      { label: 'enum.DOTTED', value: 'DOTTED' },
      { label: 'enum.DASHED', value: 'DASHED' },
    ],
    []
  );

  useEffect(() => {
    validateNameError(editedColumn.displayName, setDisplayNameHasError);
  }, []);

  useEffect(() => {
    if (!isEqual(column, editedColumn)) setEditedColumn(column);
  }, [column]);

  const handleShowChange = (option) => {
    if (!displayMoreThanOneYAxes && option?.value === 'NO') {
      modalService.openModal('confirm', {
        headerText: 'create-widget-page.create-widget.step-four.columns.confirm-show-header',
        text: 'create-widget-page.create-widget.step-four.columns.confirm-show-text',
        showCloseBtn: true,
        confirmText: 'general.confirm',
      });
    } else {
      handleChange(option.value, 'show');
    }
  };

  const handleChange = (value, property, onlyLocalChange = false) => {
    let updatedColumn = { ...editedColumn, [property]: value };

    if (property === 'scale' && value === 'AUTO') {
      updatedColumn = { ...updatedColumn, minValue: null, maxValue: null };
    }

    if (
      value === '' &&
      (property === 'minValue' || property === 'maxValue' || property === 'value')
    ) {
      updatedColumn = { ...updatedColumn, [property]: null };
    }

    setEditedColumn(updatedColumn);
    !onlyLocalChange && columnChanged(updatedColumn);
  };

  const getColDefaultColor = () => {
    const varIndex = colIndex < 6 ? colIndex : colIndex % 6;
    return getVar(`widgetsGraphsColorsPalette${varIndex + 1}`);
  };

  const getColumnsTableFields = () => {
    const { displayName, color = getColDefaultColor() } = editedColumn as LineCustomizationColumn;

    return (
      <>
        <div style={columnCfg[1].style}></div>
        <div style={columnCfg[2].style}>
          <InputBase
            title={displayName}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              displayNameHasError && styles.containerInputError
            )}
            value={displayName}
            inputProps={{ maxLength: 20 }}
            onChange={(e) =>
              handleNameChange(
                e.target.value,
                editedColumn,
                setEditedColumn,
                setDisplayNameHasError
              )
            }
            onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
          />
        </div>
        <div style={columnCfg[3].style}></div>
        <div style={columnCfg[4].style}>
          {colorPaletteMode === 'MANUAL' ? (
            <ColorInput
              value={color}
              customeMarginButton={0}
              popOverPosition={{ left: 1, top: 21 }}
              isParentRelative={false}
              colorChange={(value) => handleChange(value, 'color')}
            />
          ) : (
            <div title={i18nService.translate('enum.AUTO')}>
              {i18nService.translate('enum.AUTO')}
            </div>
          )}
        </div>
      </>
    );
  };

  const getThresholdTableFields = () => {
    const {
      displayName,
      show,
      value,
      style,
      color = '#000000',
    } = editedColumn as LineThresholdCustomization;
    const thresholdStyle = styleOptions.find((opt) => opt.value === style);

    return (
      <>
        <div id="43b5b56c-9164-464d-8125-55398ee5051b" style={columnCfg[0].style}>
          <MaterialCheckbox
            color="primary"
            onChange={(e) => {
              handleChange(e.target.checked, 'show');
            }}
            checked={show}
          />
        </div>
        <div id="262a1fd8-6cf5-4809-9b73-6e628472afcc" style={columnCfg[1].style}>
          <InputBase
            disabled={!show}
            title={displayName}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              displayNameHasError && styles.containerInputError
            )}
            value={displayName}
            inputProps={{ maxLength: 20 }}
            onChange={(e) =>
              handleNameChange(
                e.target.value,
                editedColumn,
                setEditedColumn,
                setDisplayNameHasError
              )
            }
            onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
          />
        </div>
        <div id="e15209d8-a81c-430c-b65d-2df14d175c46" style={columnCfg[2].style}>
          <InputBase
            disabled={!show}
            title={value?.toString()}
            id={styles.inputStyle}
            className={styles.containerInput}
            value={value}
            type="number"
            onChange={(e) => handleChange(+e.target.value, 'value', true)}
            onBlur={(e) => handleChange(+e.target.value, 'value')}
          />
        </div>
        <div id="354af77e-6ef6-483a-8ea5-74e21df6c9a3" style={columnCfg[3].style}>
          <Select
            disabled={!show}
            options={styleOptions}
            value={thresholdStyle}
            onChange={(option) => handleChange(option.value, 'style')}
          />
        </div>
        <div id="ce202358-1c03-41b3-9cb9-6b76c90e09b6" style={columnCfg[4].style}>
          <ColorInput
            disabled={!show}
            value={color}
            customeMarginButton={0}
            popOverPosition={{ left: 1, top: 21 }}
            isParentRelative={false}
            colorChange={(value) => handleChange(value, 'color')}
          />
        </div>
      </>
    );
  };

  const getYAxesTableFields = () => {
    const {
      maxValue,
      minValue,
      displayName,
      scale = 'AUTO',
      decimalDigits,
      show = isFirst ? 'LEFT' : 'NO',
    } = editedColumn as LineCustomizationAxes;
    const columnShow = useMemo(() => showOptions.find((o) => o.value === show), [show]);
    const columnScale = useMemo(() => scaleOptions.find((o) => o.value === scale), [scale]);

    const showDecimalDigits = decimalDigits || decimalDigits === 0;

    return (
      <>
        <div id={'0b649b68-d340-48d8-b30b-2ea1079779cf'} style={columnCfg[1].style}>
          <Select options={showOptions} value={columnShow} onChange={handleShowChange} />
        </div>
        <div id={'12dd6fe2-0244-4572-9885-bb99fbec2be6'} style={columnCfg[2].style}>
          <InputBase
            disabled={show === 'NO'}
            title={displayName}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              displayNameHasError && styles.containerInputError
            )}
            value={displayName}
            inputProps={{ maxLength: 20 }}
            onChange={(e) =>
              handleNameChange(
                e.target.value,
                editedColumn,
                setEditedColumn,
                setDisplayNameHasError
              )
            }
            onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
          />
        </div>

        <div id={'8f93fdd6-4f9b-4242-abb7-d07b35a19633'} style={columnCfg[3].style}>
          <InputBase
            title={decimalDigits && decimalDigits.toString()}
            disabled={!showDecimalDigits}
            id={styles.inputStyle}
            className={styles.containerInput}
            value={decimalDigits}
            inputProps={{ maxLength: 1 }}
            onChange={(e) =>
              +e.target.value > -1 &&
              +e.target.value < 10 &&
              handleChange(e.target.value ? e.target.value : 0, 'decimalDigits', true)
            }
            onBlur={(e) =>
              +e.target.value > -1 &&
              +e.target.value < 10 &&
              handleChange(+e.target.value, 'decimalDigits')
            }
            type="number"
          />
        </div>
        <div id={'77764adb-9a29-4228-9a40-449c682e1c7d'} style={columnCfg[4].style}>
          <Select
            disabled={show === 'NO'}
            options={scaleOptions}
            value={columnScale}
            onChange={(option) => handleChange(option.value, 'scale')}
          />
        </div>

        <div id={'64bb2a0f-b825-407e-9869-d3f679a4f64a'} style={columnCfg[5].style}>
          <InputBase
            disabled={scale === 'AUTO'}
            title={minValue?.toString()}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              scale === 'MANUAL' && !minValue && minValue !== 0 && styles.containerInputError
            )}
            value={minValue}
            type="number"
            onChange={(e) => handleChange(e.target.value, 'minValue')}
            onBlur={(e) => handleChange(e.target.value, 'minValue')}
          />
        </div>

        <div id={'ff24260d-422e-4feb-b4fb-4f9e8415afaf'} style={columnCfg[6].style}>
          <InputBase
            disabled={scale === 'AUTO'}
            title={maxValue?.toString()}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              scale === 'MANUAL' && !maxValue && maxValue !== 0 && styles.containerInputError
            )}
            value={maxValue}
            type="number"
            onChange={(e) => handleChange(e.target.value, 'maxValue')}
            onBlur={(e) => handleChange(e.target.value, 'maxValue')}
          />
        </div>
      </>
    );
  };

  return (
    <div id={'ce9f6da9-da3f-47d1-86e3-1e0406e94ce0'} className={styles.wrapper}>
      {['columns', 'yAxes'].includes(type) && (
        <div
          title={editedColumn.name}
          style={columnCfg[0].style}
          className={classNames('ellipsis-overflow')}>
          <I18n>{editedColumn.name}</I18n>
        </div>
      )}
      {type === 'columns' && getColumnsTableFields()}
      {type === 'yAxes' && getYAxesTableFields()}
      {type === 'threshold' && getThresholdTableFields()}
    </div>
  );
}

export default LineCustomizationRow;
