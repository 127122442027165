import React from 'react';
import { ModalComponentProps } from '@core/modals/modals.interface';
import styles from './NotificationDetailsModal.scss';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import { getHeader } from './NotificationDetailsModal.utils';
import { Divider, makeStyles } from '@material-ui/core';
import { buildDateTime } from '@components/widgets/charts.utils';
import { i18nService } from '@core/i18n/I18nService';
import { downloadFileFromLink } from '@pages/MainPage/MainPage.utils';

const useStyles = makeStyles((theme: any) => ({
  divider: {
    '&.MuiDivider-middle': {
      margin: '8px 0 8px 0',
      opacity: 0.1,
      backgroundColor: 'var(--black)',
    },
  },
}));

function NotificationDetailsModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { notificationDetails, notificationDetailsRef },
  } = props;
  const { name, action, timestamp, title, body, links = {} } = notificationDetails;
  const { attachmentData = {}, embeddedData } = links;
  const classes = useStyles(props);

  function cancel() {
    dismiss(false);
  }

  function MessageComponent() {
    const attachmentKeys = attachmentData ? Object.keys(attachmentData) : [];
    return (
      <>
        {action === 'PUSH_NOTIFICATION' || action === 'EMAIL_NOTIFICATION' ? (
          <>
            <div className={styles.pushNotificationTitle}>{title}</div>
          </>
        ) : null}
        <div
          className={
            action === 'PUSH_NOTIFICATION' || action === 'EMAIL_NOTIFICATION'
              ? styles.pushNotificationBody
              : styles.htmlBody
          }>
          <div
            dangerouslySetInnerHTML={{
              __html: body,
            }}></div>
          <div className={styles.links}>
            {attachmentKeys?.length
              ? attachmentKeys.map((key, idx) => (
                  <div key={idx}>
                    <div
                      className={styles.link}
                      onClick={() => {
                        downloadFileFromLink(`${attachmentData[key].fileKey}`);
                      }}>
                      {`${i18nService.translate(`general.download`)} ${attachmentData[key].name}`}
                    </div>
                  </div>
                ))
              : null}
          </div>
          {embeddedData && (
            <div
              className={styles.embeddedTable}
              dangerouslySetInnerHTML={{
                __html: embeddedData,
              }}></div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className={styles.wrapper} ref={notificationDetailsRef}>
      <div className={styles.modalHeader}>
        <I18n templateName={'name'}>{getHeader(action)}</I18n>
        <Icon className={'pointer'} type="close" onClick={cancel} />
      </div>
      <div className={styles.modalForm}>
        <div className={styles.modalContent}>
          <div className={styles.nameSection}>
            <div className={classNames(styles.name, 'ellipsis-overflow')} title={name}>
              {name}
            </div>
            <div className={styles.timestamp}>
              {buildDateTime(timestamp, 'DATE_TIME_FORMAT_ONE', 'momentFormat')}
            </div>
          </div>
          <Divider variant="middle" className={classes.divider} />
          <MessageComponent />
        </div>
        <div className={styles.modalButtons}>
          <Button mode="bigFont" disabled={false} onClick={cancel}>
            <I18n>general.close</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NotificationDetailsModal;
