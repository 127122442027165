import React, { useMemo, useState } from 'react';
import { EditorState, Modifier } from 'draft-js';
import classNames from 'classnames';
import styles from './AddDataSource.scss';
import Select from '@components/Select';
import { i18nService } from '@core/i18n/I18nService';
import { sharedStyles } from '@core/utils';

export const enum origin {
  eventTemplate,
  widgetData,
}

function AddDataSource(props) {
  const [open, setOpen] = useState(false);
  const {
    editorState,
    onChange,
    dataSources,
    originatesFrom,
    tagsAllowed = true,
    addDataSourceWidth = null,
  } = props;
  const openDataSourcesDropdown = () => setOpen(!open);
  const trigger = useMemo(() => '#', []);
  const [selectedDataSource, setSelectedDataSource] = useState<any>();
  const addDataSource = (value, dataSourceId, dataType?) => {
    const stateWithEntity = editorState.getCurrentContent().createEntity('MENTION', 'IMMUTABLE', {
      text: `${trigger}${value}`,
      value: dataType ? `@data-type@${dataType}.${value}` : value,
      dataType,
      url: `datasource://${dataSourceId}`,
    });
    const entityKey = stateWithEntity.getLastCreatedEntityKey();
    const stateWithText = Modifier.replaceText(
      stateWithEntity,
      editorState.getSelection(),
      `${trigger}${value}`,
      null,
      entityKey
    );
    onChange(EditorState.push(editorState, stateWithText, 'insert-characters'));
  };

  const dataSourcesOptions = useMemo(
    () =>
      dataSources
        ?.map((d) => ({
          value: d.id,
          label: d.name,
          dataType: d.dataType,
        }))
        .sort((d) => d.label?.toLowerCase()),
    [dataSources]
  );

  const onDataSourceSelectionChanged = (dataSource) => {
    if (dataSource) {
      addDataSource(dataSource.label, dataSource.value, dataSource.dataType || null);
    }
  };

  return (
    <div
      id={'87a05c98-b0e9-48e6-9085-7d3ba3b03635'}
      title={i18nService.translate('events-template.create-message.add-tag')}
      onClick={openDataSourcesDropdown}
      className={classNames(
        'rdw-block-wrapper',
        (!dataSourcesOptions || !dataSourcesOptions.length) && styles.disabled
      )}
      aria-label="rdw-block-control">
      <Select
        styles={{
          control: { width: addDataSourceWidth ? addDataSourceWidth : 150 },
          placeholder: sharedStyles.placeholder,
        }}
        placeholder={
          originatesFrom === origin.eventTemplate
            ? 'events-template.create-message.add-data-source'
            : originatesFrom === 'conditionBuilder'
            ? 'events-template.create-message.add-tag'
            : originatesFrom === origin.widgetData
            ? tagsAllowed
              ? 'events-template.create-message.add-tag'
              : 'expression-builder.add-system-property'
            : ''
        }
        value={dataSourcesOptions.find((opt) => opt.value === selectedDataSource?.value) || ''}
        options={dataSourcesOptions}
        onChange={onDataSourceSelectionChanged}
        maxMenuHeight={180}></Select>
    </div>
  );
}

export default AddDataSource;
