import React, { useEffect, useState } from 'react';
import I18n from '@components/I18n';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import * as cloneDeep from 'lodash/cloneDeep';
import Icon from '@components/Icon';
import { conditionsService } from '@core/ConditionsService';
import { modalService } from '@core/modals/ModalService';
import RangeRow from './RangeRow';
import { httpService } from '@core/http/HttpService';

const useStyles = makeStyles((theme) => ({
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-1px',
    padding: '0px 12px 0px 28px',
    fontSize: 12,
    fontWeight: 500,
    borderTop: 'solid 1px #c2cfe0',
  },
  tableContainer: {
    border: 'solid 1px #c2cfe0',
    height: '100%',
    borderRadius: 5,
    backgroundColor: `var(--systemContentBackground)`,
  },
  tableHeader: {
    display: 'flex',
    padding: '10px 12px 10px 28px',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
    paddingRight: 15,
  },
  add: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 10,
    paddingBottom: 10,
    height: 43,
  },
}));

function Ranges(props) {
  const classes = useStyles(props);
  const {
    setWidgetData,
    isBoolean,
    columnCfg,
    ranges,
    insertToCustomization,
    errors = {},
    promptOnRangeDelete = false,
    canUseIcons = false,
    iconsApiType,
    optionalFields = {},
    maxRemarkLength,
  } = props;

  const [icons, setIcons] = useState([]);

  const getIcons = async () => {
    try {
      const res: any = await httpService.api({
        type: iconsApiType,
        query: { p: 1, ps: 1000 },
      });
      if (res) {
        setIcons(res);
      }
    } catch (e) {}
  };

  useEffect(() => {
    canUseIcons && getIcons();
  }, []);

  const updateRanges = (newRanges) => {
    setWidgetData((prevState) => ({
      ...prevState,
      customization: insertToCustomization(prevState.customization, newRanges),
    }));
  };

  const onColumnChanged = (range, idx) => {
    const _ranges = cloneDeep(ranges);
    _ranges[idx] = range;

    setWidgetData((prevState) => {
      const isValid = conditionsService.validate(_ranges, range);
      let errors = prevState.errors ? { ...prevState.errors } : {};

      if (!isBoolean) {
        if (!isValid && !errors[range.id]) {
          errors[range.id] = true;
        } else {
          errors[range.id] = false;
        }
      }

      return {
        ...prevState,
        customization: insertToCustomization(prevState.customization, _ranges, errors),
      };
    });
  };

  const deleteRange = (idx) => {
    function makeRemove() {
      const tempConditions = [...ranges];
      tempConditions.splice(idx, 1);
      updateRanges(tempConditions);
    }

    if (promptOnRangeDelete) {
      modalService
        .openModal('confirm', {
          text: 'create-widget-page.create-widget.step-four.image-by-value.remove-condition-message',
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          headerText:
            'create-widget-page.create-widget.step-four.image-by-value.remove-condition-header',
          showCloseBtn: true,
        })
        .then((confirm) => {
          if (confirm) {
            makeRemove();
          }
        });
      return;
    }
    makeRemove();
  };

  const addRange = () => {
    updateRanges([
      ...ranges,
      {
        id: Math.max(...ranges.map((range) => range.id)) + 1,
        fromValue: null,
        toValue: null,
        ...optionalFields,
      },
    ]);
  };

  return (
    <div className={classes.tableContainer}>
      {
        <div className={classes.tableHeader}>
          {columnCfg.map((header, idx) => (
            <div
              title={header.headerLabel && i18nService.translate(header.headerLabel)}
              key={idx}
              className={classes.header}
              style={header.style}>
              {header.headerLabel && (
                <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
              )}
            </div>
          ))}
        </div>
      }
      {ranges &&
        ranges.map((condition, idx) => (
          <div key={condition.id} className={classes.columnContainer}>
            <RangeRow
              columnCfg={columnCfg}
              column={condition}
              index={idx}
              columnChanged={(editedColumn, index) => onColumnChanged(editedColumn, index)}
              isBoolean={isBoolean}
              ranges={ranges}
              error={errors[condition.id]}
              deleteRange={() => deleteRange(idx)}
              icons={icons}
              maxRemarkLength={maxRemarkLength}
            />
          </div>
        ))}

      <div className={classNames(classes.columnContainer, classes.add)}>
        {!isBoolean &&
          ranges?.length < 100 &&
          !Object.values(errors).find((err) => err) &&
          (typeof ranges[ranges.length - 1].fromValue === 'number' ||
            typeof ranges[ranges.length - 1].toValue === 'number') && (
            <Icon className="pointer" type="addImageRow" onClick={addRange}></Icon>
          )}
      </div>
    </div>
  );
}

export default Ranges;
