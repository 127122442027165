import React, { useMemo, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import Button from '@components/Button';
import I18n from '@components/I18n';
import styles from '../AssetInfoGeneral.scss';
import FormikSelect from '@components/Formik/FormikSelect';
import FormikPlcInput from '@components/Formik/FormikPlcInput';
import FormikTextarea from '@components/Formik/FormikTextarea';
import { httpService } from '@core/http/HttpService';
import { OwnerOption, OwnerModel } from './assetInfoGeneralEdit.interface';
import GooglePlacesInput from '@components/GooglePlacesInput';
import { useSelector } from '@src/redux/useSelector';
import { getInitialValues, onSubmit } from './AssetInfoGeneralEdit.utils';
import { modalService } from '@core/modals/ModalService';
import { staticDictionary } from '@core/i18n/dictionary';
import { i18nService } from '@core/i18n/I18nService';
import Image from '@components/Image';
import Icon from '@components/Icon';
import { cssVarsService } from '@core/CssVarsService';
import { getImageData } from '@modals/EditAssetModal/EditAssetModal.utils';
import { makeStyles } from '@material-ui/core';

const validationSchema = Yup.object().shape({
  assetName: Yup.string().required().max(50, 'create-asset-modal.max50-chars'),
  assetSerial: Yup.string().max(15, 'create-asset-modal.max15-chars'),
  comments: Yup.string().max(500, 'create-asset-modal.max500-chars'),
  location: Yup.object().required(),
});

const useStyles = makeStyles({
  formikSelectWrapper: {
    maxHeight: '28px',
  },
});

function AssetInfoGeneralEdit(props) {
  const classes = useStyles(props);
  const selectedMBId = useSelector((state) => state.organizations.selectedMBId);
  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);
  const { setEditMode, data, imageUrl, setImageUrl } = props;
  const [disconnectedPlcByBtn, setDisconnectedPlcByBtn] = useState(false);
  const [owners, setOwners] = useState<OwnerOption[]>([]);
  const selectOptions = useMemo(() => owners, [owners]);
  const [newGalleryImageId, setNewGalleryImageId] = useState<number | null>(data?.galleryImageId);
  const initialValues = useMemo(() => {
    return getInitialValues(data);
  }, [data]);

  const canEditImage = userOrgType === 'MACHINE_BUILDER';

  useEffect(() => {
    if (disconnectedPlcByBtn) setEditMode(false); // execute Cancel when disconnect is clicked
  }, [disconnectedPlcByBtn]);

  useEffect(() => {
    httpService
      .api({ type: 'getOwners', urlParams: { organizationId: selectedMBId || undefined } })
      .then((types: OwnerModel[]) => {
        setOwners(
          types.map<OwnerOption>((t) => ({
            value: t.id,
            label: t.name,
          }))
        );
      });
  }, []);

  const checkIfOwnerWasChanged = (values) => {
    let ownerChanged = false;
    if (
      +values.owner.value !== +data.ownerId &&
      ['CONNECTED_SINGLE', 'CONNECTED_MULTIPLE'].includes(data.routerState)
    ) {
      ownerChanged = true;
      modalService.openModal('changeAssetOwner', { asset: data }).then((confirmChangeOwner) => {
        if (confirmChangeOwner) {
          onSubmit(
            values,
            setEditMode,
            data,
            disconnectedPlcByBtn,
            initialValues,
            data.assetTypePlcModel,
            values.plcSerial.id === null
          );
        }
      });
    }
    return ownerChanged;
  };

  const onSubmitForm = (values: any) => {
    const ownerChanged = checkIfOwnerWasChanged(values);
    !ownerChanged &&
      onSubmit(
        { ...values, galleryImageId: newGalleryImageId },
        setEditMode,
        data,
        disconnectedPlcByBtn,
        initialValues,
        data.assetTypePlcModel,
        values.plcSerial.id === null && values.plcSerial.text
      );
  };

  const getLocation = (data) => {
    if (data.location) {
      return data.location.description
        ? data.location.description
        : data.location.formatted === 'Unknown'
        ? i18nService.translate('edit-asset-modal.location.unknown')
        : data.location.formatted;
    }
    return '';
  };

  async function openGallery() {
    try {
      const res = await modalService.openModal('imageGallery', {
        selectedImageData: { id: data?.galleryImageId },
      });
      if (res && res.id) {
        setNewGalleryImageId(res.id);
        getImageData(res.id, setImageUrl);
      }
    } catch (e) {}
  }

  function onCancel() {
    setEditMode(false);
    setNewGalleryImageId(data?.galleryImageId);
    newGalleryImageId !== data?.galleryImageId && getImageData(data?.galleryImageId, setImageUrl);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmitForm}>
      {({ isValid, isSubmitting, values, setFieldValue }) => {
        return (
          <Form className={styles.modalForm}>
            <div className={styles.modalContent}>
              <div className={styles.tabContent}>
                <div className={styles.dataUnit}>
                  <div style={{ display: 'flex' }}>
                    <label className={styles.dataLabel}>
                      <I18n>asset-general.owner</I18n>:
                    </label>
                    <FormikSelect
                      className={classes.formikSelectWrapper}
                      name="owner"
                      options={selectOptions}
                      label="asset-general.owner"
                      hideLabel
                      defaultValue={values.owner}
                      selectStyles={{ control: { height: 25 } }}
                    />
                  </div>
                  <div className={styles.formikInput}>
                    <label className={styles.dataLabel}>
                      <I18n>asset-general.location</I18n>:
                    </label>
                    {data?.gpsSupport ? (
                      <span>{getLocation(values)}</span>
                    ) : (
                      <div className={classNames(styles.googleInput, classes.formikSelectWrapper)}>
                        <GooglePlacesInput
                          id="location"
                          defaultValue={values.location}
                          onSelectedCountryChange={(location) =>
                            setFieldValue('location', location)
                          }
                          isMulti={false}
                          coordinates={true}
                          styles={{ control: { height: 25 } }}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.deviceType}>
                    <label className={styles.dataLabel}>
                      <I18n>asset-general.type</I18n>:
                    </label>
                    <span>{data.deviceType}</span>
                  </div>
                  <div className={styles.dataRow}>
                    <label className={classNames(styles.dataLabel, styles.bold)}>
                      <I18n>{`asset-general.model`}</I18n>:
                    </label>
                    <span className={styles.dataValue}>
                      {data[data['model']] == 'Legacy'
                        ? staticDictionary['enum.LEGACY']
                        : data['model']}
                    </span>
                  </div>
                  {['MACHINE_BUILDER', 'UNITRONICS_MAIN'].includes(userOrgType) && !data.isDemo ? (
                    <div className={styles.formikInput}>
                      <label className={styles.dataLabel}>
                        <I18n>asset-general.plc-serial-number</I18n>:
                      </label>
                      <FormikPlcInput
                        name="plcSerial"
                        label="asset-general.plc-serial-number"
                        hideLabel
                        type="associateToAsset"
                        value={values.plcSerial}
                        values={values}
                        rowIndex={data.rowIndex}
                        assetId={data.assetId}
                        plcType={data.assetTypePlcModel === 'MODBUS' ? 'MODBUS' : 'UNITRONICS'}
                        setDisconnectPlcByBtn={setDisconnectedPlcByBtn}
                        isRequired={false}
                        assetPlcType={data.assetTypePlcModel}
                      />
                    </div>
                  ) : (
                    <div className={styles.dataRow}>
                      <label className={classNames(styles.dataLabel, styles.bold)}>
                        <I18n>asset-general.plc-serial-number</I18n>:
                      </label>
                      <span className={styles.dataValue}>{data.plcSerial}</span>
                    </div>
                  )}
                  <div className={styles.dataRow}>
                    <label className={classNames(styles.dataLabel, styles.bold)}>
                      <I18n>{`asset-general.plc-model`}</I18n>:
                    </label>
                    <span className={styles.dataValue}>
                      {data[data['plcModel']] == 'Legacy'
                        ? staticDictionary['enum.LEGACY']
                        : data['plcModel']}
                    </span>
                  </div>
                  <div className={styles.dataRow}>
                    <label className={classNames(styles.dataLabel, styles.bold)}>
                      <I18n>{`asset-general.plc-catalog-number`}</I18n>:
                    </label>
                    <span className={styles.dataValue}>
                      {data[data['plcCatalogNumber']] == 'Legacy'
                        ? staticDictionary['enum.LEGACY']
                        : data['plcCatalogNumber']}
                    </span>
                  </div>
                  <div className={styles.formikInput}>
                    <label className={styles.dataLabel}>
                      <I18n>asset-general.comments</I18n>:
                    </label>
                    <FormikTextarea
                      name="comments"
                      label="asset-general.comments"
                      hideLabel
                      className={classNames(styles.marginBottom, styles.paddingBottom)}
                      maxLength={500}
                      value={values.comments}
                      addLeftMargin
                    />
                  </div>
                </div>
                <div className={styles.imageViewEdit}>
                  <div className={styles.imageContainerEdit}>
                    <div
                      className={styles.imageWrapper}
                      style={
                        canEditImage
                          ? {
                              cursor: 'pointer',
                              border: '1px solid var(--systemPopupHeaderBackground)',
                            }
                          : undefined
                      }
                      onClick={canEditImage ? openGallery : null}>
                      {canEditImage && imageUrl && (
                        <Icon
                          type="closeCustomColorSquared"
                          tooltipText={'general.delete'}
                          color={cssVarsService.vars.systemPopupHeaderBackground}
                          className={styles.removeImageIcon}
                          onClick={
                            canEditImage
                              ? (e) => {
                                  e.stopPropagation();
                                  setImageUrl(null);
                                  setNewGalleryImageId(null);
                                }
                              : null
                          }
                        />
                      )}
                      <Image
                        mode="smaller"
                        src={imageUrl ? imageUrl : '/assets/images/empty_Image.svg'}
                        text={
                          imageUrl || !canEditImage
                            ? null
                            : 'edit-asset-modal.image-placeholder-text-edit'
                        }
                        maxTextWidth={'50%'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.modalButtons}>
              <Button styles={{ marginLeft: 13 }} mode={['cancel', 'bigFont']} onClick={onCancel}>
                <I18n>general.cancel</I18n>
              </Button>
              <Button
                styles={{ marginLeft: 13 }}
                mode="bigFont"
                type="submit"
                disabled={
                  (initialValues.galleryImageId === newGalleryImageId && !isValid) ||
                  (disconnectedPlcByBtn &&
                    !values['plcSerial'].id &&
                    data.assetTypePlcModel !== 'MODBUS')
                }>
                <I18n>general.save</I18n>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AssetInfoGeneralEdit;
