import React, { useState } from 'react';
import Panel from '@pages/MyAccountPage/RegionalSettings/Panel';
import { UsageDataInterface, UsageDataResponse } from './UsageTab.interface';
import UsageData from './UsageData/UsageData';
import moment from 'moment';
import I18n from '@components/I18n';
import styles from './UsageTab.scss';
import TimePicker from './TimePicker/TimePicker';
import { httpService } from '@core/http/HttpService';

function UsageTab(props) {
  const [data, setData] = useState(null);

  const getData = async (filter) => {
    try {
      const res: UsageDataResponse = await httpService.api({
        type: 'getSabscriptionsUsage',
        query: {
          ...filter,
        },
      });

      if (res) {
        setData(res.results);
      }
    } catch (e) {}
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.summery}>
          <div className={styles.countContainer}>
            <I18n className={styles.lable}>
              {'my-account-page.licensing-plan.usage.monthly-sms'}
            </I18n>
            <div className={styles.number}>{data?.currentMonthly}</div>
          </div>
          <div className={styles.separator}></div>
          <div className={styles.countContainer}>
            <I18n className={styles.lable}>{'my-account-page.licensing-plan.usage.sms-pool'}</I18n>
            <div className={styles.number}>{data?.currentMonthly + data?.currentPool}</div>
          </div>
        </div>

        <TimePicker setfilter={(date) => getData(date)} />
      </div>

      {data?.months.map((item: UsageDataInterface) => {
        const defaultExpanded =
          moment(item.date).format('MMMM YYYY') === moment().format('MMMM YYYY')
            ? { defaultExpanded: true }
            : {};
        return (
          <Panel
            label={moment(item.date).format('MMMM YYYY')}
            Component={UsageData}
            {...defaultExpanded}
            data={item}
          />
        );
      })}
    </div>
  );
}

export default UsageTab;
