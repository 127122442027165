import React, { useRef } from 'react';
import Image from '@components/Image';
import { widgetMap } from '@core/canvas/widgetMap';
import ReactApexChart from 'react-apexcharts';
import styles from '../HeatmapWidget.scss';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import { i18nService } from '@core/i18n/I18nService';
import ReactResizeDetector from 'react-resize-detector';

const useStyles = makeStyles((theme: any) => ({
  apexWrapper: {
    '& .apexcharts-menu-item.exportCSV': {
      display: 'none',
    },
  },
}));

function Chart(props) {
  const {
    showNavigationButtons,
    series,
    chartPage,
    next,
    heatmapOptions,
    isNextButtonEnabled,
    goBackOrForth,
  } = props;
  const classes = useStyles(props);
  const chartRef = useRef(null);

  const arrowSize = { height: '30px', width: '30px' };

  function onResize() {
    if (chartRef?.current?.chart?.updateOptions) {
      chartRef.current.chart.updateOptions(heatmapOptions, true, true, true);
    }
  }

  return (
    <div className={showNavigationButtons ? styles.wrapperWithNavigation : styles.wrapper}>
      <ReactResizeDetector handleHeight handleWidth onResize={onResize} />
      {showNavigationButtons && (
        <div className={styles.navigationButtons}>
          <div
            title={i18nService.translate('general.previous')}
            className={classNames(
              styles.buttonContainer,
              !chartPage || chartPage === 1 ? styles.disabled : undefined
            )}
            onClick={() => goBackOrForth('previous')}>
            <div
              className={classNames(
                styles.previous,
                !chartPage || chartPage === 1 ? styles.disabled : undefined
              )}>
              {/* {chartPage !== 1 && (
                <div className={styles.previousCounter}>{`${chartPage - 1} / ${
                  series?.length
                }`}</div>
              )} */}
              <I18n title={null}>general.previous</I18n>
            </div>
            <Icon type="leftArrow" {...arrowSize} className={styles.arrow} />
          </div>
          <div
            title={i18nService.translate('general.next')}
            className={classNames(
              styles.buttonContainer,
              !isNextButtonEnabled ? styles.disabled : undefined
            )}
            onClick={() => goBackOrForth('next')}>
            <Icon type="rightArrow" {...arrowSize} className={styles.arrow} />
            <div className={styles.next}>
              <I18n title={null}>general.next</I18n>
              {/* {chartPage !== series?.length && (
                <div className={styles.nextCounter}>{`${chartPage + 1} / ${series?.length}`}</div>
              )} */}
            </div>
          </div>
        </div>
      )}
      {series.length ? (
        <div
          style={{
            height: next || chartPage !== 1 ? 'calc(100% - 20px)' : '100%',
          }}>
          <div
            style={{ height: '100%', width: '100%' }}
            id={'a3a6786e-1048-48e2-b76f-ab7acadd2a22'}
            className={classes.apexWrapper}>
            <ReactApexChart
              ref={chartRef}
              options={heatmapOptions}
              series={series}
              height={'100%'}
              type="heatmap"
            />
          </div>
        </div>
      ) : (
        <div style={{ height: '100%' }}>
          <Image
            mode="smaller"
            src={widgetMap.heatmap.images.canvas}
            text="widget.widget-empty-label"
          />
        </div>
      )}
    </div>
  );
}

export default Chart;
