import React, { useCallback, useMemo, useState } from 'react';
import { Formik, Form } from 'formik';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import Button from '@components/Button';
import I18n from '@components/I18n';
import FormikPlcInput from '@components/Formik/FormikPlcInput';
import FormikPlcTypeSelect from '@components/Formik/FomikPlcTypeSelect';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { PLCModel } from './createPLCModal.interface';
import styles from './CreatePLCModal.scss';

const initialValues: PLCModel = {
  plcSerial: { id: null, text: null, message: null },
};

function CreatePLCModal(props: ModalComponentProps) {
  const [plcType, setPlcType] = useState('UNITRONICS');
  const { dismiss } = props;
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const onSubmit = useCallback(
    (values: PLCModel) => {
      if (plcType === 'UNITRONICS') {
        dismiss({
          apiType: 'createPLC',
          payload: {
            id: values.plcSerial.id,
          },
        });
      } else {
        dismiss({
          apiType: 'createNonUnitronicsPlc',
          payload: {
            plcType: plcType,
            plcSerial: values.plcSerial.text,
          },
        });
      }
    },
    [dismiss, plcType]
  );

  const plcTypeOptions = useMemo(
    () => [
      {
        value: 'UNITRONICS',
        label: 'plc-validation.plc-type.unitronics',
      },
      {
        value: 'MODBUS',
        label: 'plc-validation.plc-type.modbus',
      },
    ],
    []
  );

  const onPlcTypeChange = (option) => {
    setPlcType(option.value);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>create-plc-modal.title</I18n>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid, isSubmitting, errors }) => (
          <Form className={styles.modalForm}>
            <div className={styles.modalContent}>
              <FormikPlcTypeSelect
                name="plcType"
                value={plcType}
                onChange={onPlcTypeChange}
                options={plcTypeOptions}
                label="plc-validation.plc-type"
                allowRowsEllipsis
              />
              <FormikPlcInput
                name="plcSerial"
                label="plc-validation.plc-serial-number"
                type="addToOrganization"
                plcType={plcType}
                allowRowsEllipsis
              />
              {errors.plcSerial && errors.plcSerial.message && (
                <div className={styles.serverErrorMsg}>
                  <ErrorRoundedIcon className={styles.errorIcon} />
                  <span>{errors.plcSerial.message}</span>
                </div>
              )}
            </div>
            <div className={styles.modalButtons}>
              <Button
                styles={{ marginLeft: 13 }}
                mode={['cancel', 'bigFont']}
                disabled={isSubmitting}
                onClick={cancel}>
                <I18n>general.cancel</I18n>
              </Button>
              <Button
                styles={{ marginLeft: 13 }}
                mode="bigFont"
                type="submit"
                disabled={!isValid || isSubmitting}>
                <I18n>general.add</I18n>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreatePLCModal;
