import React from 'react';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { makeStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import { IconButtonWithTextProps, StyleProps } from './IconButtonWithText.interface';

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  wrapper: {
    display: 'flex',
    width: 'fit-content',
  },
  text: (theme) => ({
    margin: theme.textMargin ? theme.textMargin : '2px 10px',
    fontSize: theme.fontSize ? theme.fontSize : '14px',
    textAlign: 'center',
  }),
  disabledText: {
    cursor: 'default',
    pointerEvents: 'none',
    opacity: 0.4,
  },
}));

function IconButtonWithText(props: IconButtonWithTextProps) {
  const { iconType, name, showName, tooltip, onClick, isDisabled, styles } = props;
  const classes = useStyles(styles);

  return (
    <div
      className={classNames(classes.wrapper, 'ellipsis-overflow')}
      style={
        isDisabled
          ? { cursor: 'default', pointerEvents: 'none' }
          : { cursor: 'pointer', pointerEvents: 'auto' }
      }
      onClick={onClick}>
      <Icon
        type={iconType}
        color="var(--systemFont)"
        tooltipText={i18nService.translate(tooltip)}
        disabled={isDisabled}
      />
      {showName && (
        <I18n className={classNames(classes.text, isDisabled ? classes.disabledText : undefined)}>
          {name}
        </I18n>
      )}
    </div>
  );
}

export default IconButtonWithText;
