import { dispatch } from '@redux/store';
import { httpService } from '@core/http/HttpService';
import { AssetInfo, IntervalItem } from './EditAssetModal.interface';
import {
  setAll,
  clearEditAsset,
  updateEditAsset,
  setSelectedTab,
  setRemoteAccessMode,
} from '@redux/deviceManagment/editAsset';
import { AssetData, AssetStateEnum } from '@src/redux/redux.interface';

class EditAssetService {
  private _dismiss: Function;
  private _archive: boolean;
  private readonly _intervalHandlers: any = {};

  public INTERVAL_TIMER = 10000;

  setModalDismiss(dismiss: (val) => void) {
    this._dismiss = dismiss;
  }

  setModalArchive(archive) {
    this._archive = archive;
  }

  dismiss(val) {
    const res = { archive: this._archive, res: val };
    this._dismiss && this._dismiss(res);
  }

  getArchive() {
    return this._archive;
  }

  getAssetInfo(assetId: string, rowIdx: number) {
    httpService
      .api({
        type: 'getAssetInfo',
        urlParams: { assetId },
      })
      .then((assetInfo: AssetInfo) => {
        const generalData = Object.assign({}, assetInfo.general, {
          rowIndex: rowIdx,
          assetName: assetInfo.assetName,
          assetId,
          assetSerial: assetInfo.assetSerial,
          assetTypePlcModel: assetInfo.assetTypePlcModel,
          plcSerial: assetInfo.plcSerial,
          comments: assetInfo.comments,
          routerState: assetInfo.routerState,
          hasCertificate: assetInfo.hasCertificate,
          plc_id: assetInfo.plcId,
          location: assetInfo.general.location || {},
          isDemo: assetInfo.isDemo,
          subscription: assetInfo.subscription,
          gpsSupport: assetInfo.gpsSupport,
          networkType: assetInfo.networkType,
          signalStrength: assetInfo.signalStrength,
          galleryImageId: assetInfo.galleryImageId,
        });
        const assetInfoData = Object.assign({}, assetInfo, { general: generalData });
        this.setAssetInfo(assetInfoData);
      });
  }

  getAndUpdateAssetSubscription(editedAsset) {
    httpService
      .api({
        type: 'getAssetInfo',
        urlParams: { assetId: editedAsset.general.assetId },
      })
      .then((assetInfo: AssetInfo) => {
        editAssetService.updateEditAsset({
          subscription: assetInfo.subscription,
        });
      });
  }

  setSelectedTab(tab: string) {
    dispatch(setSelectedTab(tab));
  }

  setRemoteAccessMode(remoteAccessMode: boolean) {
    dispatch(setRemoteAccessMode(remoteAccessMode));
  }

  clearEditAsset() {
    dispatch(clearEditAsset());
  }

  updateEditAsset(values: any) {
    dispatch(updateEditAsset(values));
  }

  getStateBtnVal(state: AssetStateEnum, subscriptionStatus: string, isDemo: boolean) {
    let disconnect = true;

    if (subscriptionStatus === 'EXPIRED' || !subscriptionStatus) disconnect = false;

    switch (state) {
      case 'CONNECTED':
        return { disconnect, archive: true };
      case 'PENDING_ROUTER':
        return { disconnect: false, archive: true };
      case 'DISCONNECTED':
        return { disconnect: false, archive: true };
      case 'ARCHIVED':
        return { disconnect: false, archive: false };
      case 'COMMUNICATION_ERROR':
        return { disconnect: disconnect, archive: true };
    }
  }

  private setAssetInfo(assetInfo) {
    const staticData = {
      assetName: assetInfo.assetName,
      assetSerial: assetInfo.assetSerial,
      hasCertificate: assetInfo.hasCertificate,
      status: assetInfo.status,
      state: assetInfo.state,
      plcSerial: assetInfo.plcSerial,
      updatedAt: assetInfo.updatedAt,
      connectedAt: assetInfo.connectedAt,
      isDemo: assetInfo.isDemo,
      subscription: assetInfo.subscription,
      gpsSupport: assetInfo.gpsSupport,
      networkType: assetInfo.networkType,
      signalStrength: assetInfo.signalStrength,
      remoteAccessMode: assetInfo.remoteAccessMode,
      galleryImageId: assetInfo.galleryImageId,
    };
    const assetInfoData = {
      staticData,
      selectedTab: 'general',
      general: assetInfo.general,
      alarmsMeta: assetInfo.alarmsMeta,
      dataTablesMeta: assetInfo.dataTablesMeta,
      remoteAccessMode: assetInfo.remoteAccessMode,
    };
    dispatch(setAll(assetInfoData));
  }

  initDataInterval(assetId: string, cb: (assetId) => void, type: string, interval: number) {
    this._intervalHandlers[type] = {
      assetId,
      getData: cb,
      type,
      interval,
      intervalClearHandler: null,
    } as IntervalItem;

    const handler = this._intervalHandlers[type];

    handler.getData(handler.assetId, false);
    handler.intervalClearHandler = setInterval(
      () => handler.getData(handler.assetId),
      handler.interval
    );
  }

  clearDataInterval(type: string) {
    const handler = this._intervalHandlers[type];
    if (!handler) return;

    clearInterval(handler.intervalClearHandler);
    delete this._intervalHandlers[type];
  }
}

export const editAssetService = new EditAssetService();
