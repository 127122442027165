import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';
import I18n from '@components/I18n';
import Select from '@components/Select';
import DataViewer from '@components/DataViewer';
import styles from './CreateAssetModalSelect.scss';
import { FormikSelectProps } from './createAssetModalSelect.interface';
import { i18nService } from '@core/i18n/I18nService';
import { staticDictionary } from '@core/i18n/dictionary';

function CreateAssetModalSelect(props: FormikSelectProps) {
  const {
    formik,
    name,
    label,
    validate,
    disabled,
    options,
    defaultValue,
    onSelectedOptionChanged,
    className,
  } = props;
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    validate && formik.registerField(name, { props: { validate } });

    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));

    return () => {
      validate && formik.unregisterField(name);
    };
  }, [name]);

  const dataViewerData = useMemo(() => {
    const selectedOptionsDisplay = [];
    if (selectedOption) {
      selectedOptionsDisplay.push({
        label: 'create-asset-modal.plc.type',
        value:
          staticDictionary[`enum.${selectedOption.plcType}`] ||
          i18nService.translate(`enum.${selectedOption.plcType}`),
      });

      if (selectedOption.model) {
        selectedOptionsDisplay.unshift({
          label: 'create-asset-modal.model',
          value: selectedOption.model,
        });
      }
    }

    return selectedOptionsDisplay;
  }, [selectedOption]);

  const handleChange = useCallback(
    (selectedOpt) => {
      setSelectedOption(selectedOpt);
      formik.setFieldValue(name, selectedOpt);
      onSelectedOptionChanged();
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    formik.setFieldTouched(name, true);
  }, [formik]);

  return (
    <>
      <div className={classNames(styles.fieldWrapper, className)}>
        <label className={classNames(styles.fieldLabel, 'asterisk', 'ellipsis-overflow')}>
          <I18n>{label}</I18n>:
        </label>
        <div className={styles.fieldInput}>
          <Select
            defaultValue={defaultValue}
            isDisabled={disabled}
            options={options}
            onChange={handleChange}
            onBlur={handleBlur}
            maxMenuHeight={180}
          />
          <ErrorMessage name={name}>
            {(err) => (
              <I18n className={styles.error} element="div">
                {err}
              </I18n>
            )}
          </ErrorMessage>
        </div>
      </div>
      {selectedOption && (
        <DataViewer
          className={styles.formikSelectDataViewer}
          data={dataViewerData}
          opacity
          boldLabel
        />
      )}
    </>
  );
}

export default connect(CreateAssetModalSelect);
