import { compact, cloneDeep } from 'lodash';
import { i18nService } from '@core/i18n/I18nService';
import StepOneText from './StepTextData/StepOneText';
import StepTwoText from './StepTextData/StepTwoText';
import StepThreeText from './StepTextData/StepThreeText';
import StepFourText from './StepTextData/StepFourText';
import StepFiveText from './StepTextData/StepFiveText';

const getName = (name, languageId) => i18nService.translate(name, languageId);

export const stepsDisplayText = {
  1: (widgetData, languageId) => ({
    widgetType: widgetData.type,
    assetTypes: widgetData.assetTypes.map((assetType) => assetType.name),
    assetProperties: widgetData.assetProperties.map((prop) => getName(prop.name, languageId)),
    variables: widgetData.variables.map((variable) => variable.name),
    localTags: widgetData.localTags.map((localTag) => localTag.name),
    tags: widgetData.tags.map((tag) => tag.name),
    tagTypes: widgetData.tagTypes.map((tagType) => tagType.name),
    alarmInfos: widgetData.alarmInfos.map((alarmInfo) => getName(alarmInfo.name, languageId)),
    eventTemplateId: widgetData.eventTemplateId,
    allAssetTypes: widgetData.allAssetTypes,
  }),
  2: (widgetData) => ({
    scope: widgetData.scope,
    eventTemplateId: widgetData.eventTemplateId,
  }),
  3: (widgetData, languageId) => ({
    widgetData,
    groupBy: compact(
      widgetData.groupBy.map((group) => !group.hide && getName(group.name, languageId))
    ),
    metrics: compact(
      widgetData.metrics.map((metric) => !metric.hide && getName(metric.name, languageId))
    ),
    filters: widgetData.filters.map((filter) => getName(filter.name, languageId)),
    sorts: widgetData.sorts.map((sort) => getName(sort.name, languageId)),
    eventTemplateId: widgetData.eventTemplateId,
  }),
  4: (widgetData) => ({
    name: widgetData.name,
    type: widgetData.type,
    eventTemplateId: widgetData.eventTemplateId,
  }),
  5: (widgetData) => ({
    navigationDashboard: widgetData.navigationDashboard
      ? widgetData.navigationDashboard.label
      : null,
    navigateFilterBy: widgetData.navigateFilterBy.map((d) => d.label),
  }),
};

export const defaultSteps = [
  {
    stepIndex: 1,
    title: 'widget-side-bar.data',
    isDone: false,
    isDisable: false,
    component: StepOneText,
  },
  {
    stepIndex: 2,
    title: 'widget-side-bar.scope',
    isDone: false,
    isDisable: true,
    component: StepTwoText,
  },
  {
    stepIndex: 3,
    title: 'widget-side-bar.organize',
    isDone: false,
    isDisable: true,
    component: StepThreeText,
  },
  {
    stepIndex: 4,
    title: 'widget-side-bar.customize',
    isDone: false,
    isDisable: true,
    component: StepFourText,
  },
  {
    stepIndex: 5,
    title: 'widget-side-bar.navigate',
    isDone: false,
    isDisable: true,
    component: StepFiveText,
  },
];

export const getWidgetSteps = (useSteps, defaultSteps) =>
  useSteps ? defaultSteps.filter((step) => useSteps.includes(step.stepIndex)) : defaultSteps;

export const updatingStepsStatus = (data, currentStep, widgetStepValidationMap, widgetData) => {
  const currentStepIndex = data.findIndex((d) => d.stepIndex === currentStep);
  if (data && data.length && widgetData.status !== 'PUBLISHED')
    data[data.length - 1].isDone = false;

  data.forEach((step, index) => {
    if (index > currentStepIndex && widgetStepValidationMap[index](widgetData)) {
      // when previous step is valid
      step.isDisable = false;
    } else if (index < currentStepIndex) {
      // when previous step is done
      step.isDone = true;
      step.isDisable = false;
    } else if (!widgetStepValidationMap[index + 1](widgetData)) {
      // when current step is not valid or this is
      // one of the next steps and it's not valid
      step.isDisable = currentStepIndex !== index; // Remove disable from the current step
      step.isDone = false;
    } else if (
      widgetStepValidationMap[index + 1](widgetData) &&
      widgetData.status === 'PUBLISHED' &&
      index === data.length - 1
    ) {
      // last step, and it's valid, and the widget was published (A fix for UC-719, since we don't have another way of telling that the last step was completed)
      step.isDone = true;
    }
  });
};

// const widgetScopeServerToLocal = {
//   AGGREGATED_DATA: 'Aggregated data',
//   LAST_VALUE: 'Last value',
//   RAW_DATA: 'Raw data'
// };
