import React, { useState, useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import styles from './SignUp.scss';
import I18n from '@components/I18n';
import { getCompanyFieldsCfg, getFieldsCfgMainContact, save } from './signUp.utils';
import FormikField from '@components/FormikField';
import Button from '@material-ui/core/Button';
import { useSelector } from '@redux/useSelector';
import NewLogo from '@components/NewLogo';
import { cssVarsService } from '@core/CssVarsService';
import { useHistory } from 'react-router';
import { authService } from '@core/auth/AuthService';
import { CAPTCHA_SCRIPT_URL } from '@core/utils';

function SignUp() {
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const [userId, setUserId] = useState(null);
  const [emailErrors, setEmailErrors] = useState({
    name: '',
    email: '',
    message: '',
    isEmailMessage: null,
  });
  const [prevCompanyAndEmail, setPrevCompanyAndEmail] = useState({ email: '', company: '' });
  const history = useHistory();

  const [isValidEmail, setIsValidEmail] = useState(false);
  const [saving, setSaving] = useState(false);
  const isValidEmailRef = useRef(null);
  isValidEmailRef.current = isValidEmail;
  const emailValidationRef = useRef(null);
  emailValidationRef.current = emailErrors;
  const userIdRef = useRef(null);
  userIdRef.current = userId;

  const host = window.location.protocol + '//' + window.location.host;
  const apiKey = authService.getReCaptchaAPIKey(
    false,
    host.includes('localhost') ? process.env.BASE_URL : host
  );
  const [captchaToken, setCaptchaToken] = useState('');

  const data = useMemo(
    () => ({
      name: '',
      website: '',
      address: '',
      id: null,
      email: '',
      title: 'NONE',
      firstName: '',
      middleName: null,
      lastName: '',
      phone: '',
      mobile: null,
    }),
    []
  );

  useEffect(() => {
    const reCAPTCHAscript = document.createElement('script');
    reCAPTCHAscript.type = 'text/javascript';
    reCAPTCHAscript.src = CAPTCHA_SCRIPT_URL;
    (reCAPTCHAscript.defer = true), document.head.appendChild(reCAPTCHAscript);
  }, []);

  function handleRecaptcha(response) {
    setCaptchaToken(response);
  }

  function handleRecaptchaExpired() {
    setCaptchaToken('');
  }

  window['handleRecaptcha'] = handleRecaptcha;
  window['handleRecaptchaExpired'] = handleRecaptchaExpired;

  const onSubmit = async (values) => {
    setSaving(true);
    if (isValidEmailRef.current && emailValidationRef.current.isValid) {
      save(values, userIdRef, userId, captchaToken, setSaving, setIsValidEmail, history);
    } else if (isValidEmailRef.current && !emailValidationRef.current.isValid) {
      setIsValidEmail(false);
      setSaving(false);
    }
  };

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  const validate = (values) =>
    [...fieldsCfgCompany, ...fieldsCfgMainContact].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );

  const fieldsCfgCompany = getCompanyFieldsCfg(
    setUserId,
    emailErrors,
    setEmailErrors,
    prevCompanyAndEmail,
    setPrevCompanyAndEmail,
    setIsValidEmail
  );

  const fieldsCfgMainContact = getFieldsCfgMainContact(
    userId,
    setUserId,
    emailErrors,
    setEmailErrors,
    prevCompanyAndEmail,
    setPrevCompanyAndEmail,
    setIsValidEmail
  );

  return (
    <>
      <div className={classNames(styles.signUpPage, isMobileView && styles.signUpMobile)}>
        <Formik
          enableReinitialize
          initialValues={{ ...data }}
          validate={validate}
          validationSchema={validationSchema([...fieldsCfgCompany, ...fieldsCfgMainContact])}
          onSubmit={onSubmit}>
          {({ isValid, setFieldValue, values, errors }) => {
            return (
              <FormikForm
                className={classNames(styles.signUpForm, isMobileView && styles.mobileSignUpForm)}>
                <NewLogo style={{ logo: { height: '50px' }, logoWrapper: { height: '50px' } }} />
                <div className={classNames(styles.signUpHeader, isMobileView && styles.mobileMode)}>
                  <I18n noEllipsis>sign-up-page.title</I18n>
                </div>
                <div
                  className={classNames(
                    styles.signUpContent,
                    isMobileView && styles.mobileSignUpContent
                  )}>
                  <div>
                    <div className={classNames(styles.header, isMobileView && styles.mobileMode)}>
                      <I18n>sign-up-page.main-contact</I18n>
                    </div>
                    <div className={classNames(styles.box, isMobileView && styles.mobileMode)}>
                      <div className={styles.fields}>
                        {fieldsCfgMainContact.map((field, idx) => (
                          <FormikField
                            mode={`New${isMobileView ? 'Mobile' : 'Thin'}`}
                            smallPadding={isMobileView}
                            key={idx}
                            editMode={true}
                            {...field}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        ))}
                      </div>
                    </div>
                    <div className={classNames(styles.header, isMobileView && styles.mobileMode)}>
                      <I18n>sign-up-page.company</I18n>
                    </div>
                    <div className={classNames(styles.box, isMobileView && styles.mobileMode)}>
                      <div className={styles.fields}>
                        {fieldsCfgCompany.map((field, idx) => (
                          <FormikField
                            mode={`New${isMobileView ? 'Mobile' : 'Thin'}`}
                            smallPadding={isMobileView}
                            key={idx}
                            editMode={true}
                            {...field}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className="g-recaptcha"
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    data-sitekey={apiKey}
                    data-action={'REGISTRATION'}
                    data-callback={'handleRecaptcha'}
                    data-expired-callback={'handleRecaptchaExpired'}></div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                      style={
                        !isValid || saving || !captchaToken
                          ? { backgroundColor: `${cssVarsService.vars.colorDisabled}` }
                          : undefined
                      }
                      className={classNames(styles.submit, isMobileView && styles.submitMobile)}
                      type="submit"
                      variant="contained"
                      disabled={!isValid || saving || !captchaToken}>
                      <I18n>sign-up-page.create-org</I18n>
                    </Button>
                  </div>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </>
  );
}

export default SignUp;
