import React, { useEffect, useState } from 'react';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import Select from '@components/Select';
import { AssetFilterComponentProps } from './AssetFilterComponent.interface';
import { userFiltersService } from '@pages/DashboardEditorPage/UserFiltersService';
import { useSubject } from '@core/effects';
import { useSelector } from '@src/redux/useSelector';

const AssetFilterComponent = (props: AssetFilterComponentProps) => {
  const {
    assetTypeSelected,
    assetSelected,
    assetFilter,
    navigationAssetFilter,
    setNavigationAssetFilter,
    setAssetSelected,
    onChange,
    showClearButton,
    isAssetIllegalRange,
  } = props;
  const [assetOptions, setAssetOptions] = useState([]);
  const assetTypeOptions = useSubject(userFiltersService.assetTypeOptions$);

  const getAssetOptions = () => {
    const assetTypes = !assetTypeSelected?.length ? assetTypeOptions : assetTypeSelected;

    const requestAssetsByAssetTypes: any = httpService.api<{
      next: any;
      results: any;
      allCount: string;
      count: string;
    }>({
      type: 'getAssetListBaseDetails',
      query: {
        p: 1,
        ps: 1000,
        assetTypes: assetTypes
          .map((assetType) => {
            return assetType.id;
          })
          .reduce((prev, current) => (prev ? `${prev},${current}` : `${current}`), null),
      },
    });
    requestAssetsByAssetTypes.then((res) => {
      setAssetOptions(res.results);
    });
  };

  useEffect(() => {
    getAssetOptions();
  }, [assetTypeSelected, assetTypeOptions]);

  useEffect(() => {
    if (assetOptions?.length) {
      if (assetTypeSelected?.length) {
        if (
          !assetOptions.some((item) =>
            Number.isInteger(assetSelected[0])
              ? assetSelected[0] === item.id
              : assetSelected[0]?.id === item.id
          )
        ) {
          setAssetSelected([]);
        }
      }

      if (assetFilter) {
        if (assetFilter?.length && Number.isInteger(assetFilter[0])) {
          setAssetSelected(assetOptions.find((item) => item.id === assetFilter[0]));
        } else {
          setAssetSelected(assetOptions.find((item) => item.id === assetFilter.values[0]));
        }
      }
    }
  }, [assetOptions, assetTypeSelected]);

  useEffect(() => {
    if (navigationAssetFilter?.length && !assetSelected?.length) {
      setAssetSelected(navigationAssetFilter);
    }
  }, [navigationAssetFilter]);

  useEffect(() => {
    if (!assetSelected) {
      setAssetSelected([]);
    }
  }, [assetSelected]);

  return (
    <Select
      id="assetOptions"
      styles={{
        control: { height: 36 },
        placeholder: { color: 'var(--systemFont)' },
        menuPortal: { zIndex: 9999999999999, minWidth: '200px' },
      }}
      maxMenuHeight={180}
      menuPortalTarget={document.getElementById('mainContent') || document.body}
      value={assetSelected}
      options={assetOptions}
      getOptionLabel={(option) => (option.id ? `${option.name} - ${option.id}` : '')}
      getOptionValue={(option) => option.id}
      placeholder={
        isAssetIllegalRange
          ? i18nService.translate('general.illegal-range')
          : i18nService.translate('general.all')
      }
      showClearButton={showClearButton}
      onChange={onChange}
    />
  );
};

export default AssetFilterComponent;
