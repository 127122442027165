import React, { useEffect, useState } from 'react';
import { getHeatmapOptions, getSeriesWithNames } from '../HeatmapWidget.utils';
import Chart from '../Chart';
import { getData } from '../HeatmapWidgetGetData.utils';

function SingleGroupByHeatmapWidget(props) {
  const {
    data,
    setData,
    customization,
    widgetData,
    widget,
    classes,
    defaultDecimalDigits,
    dashboardFilters,
    widgetFilters,
    isPreview = false,
    navigateDashboard,
    availableSeries,
    pageSize,
    rowSize,
    fetchedData,
    setFetchedData,
  } = props;

  const { next, p } = data;
  const [heatmapOptions, setHeatmapOptions] = useState(
    getHeatmapOptions(customization, data, defaultDecimalDigits, navigateDashboard, widget)
  );

  const [chartPage, setChartPage] = useState(p);

  const [series, setSeries] = useState([]);
  const showNavigationButtons = !isPreview && (next || chartPage !== 1);

  useEffect(() => {
    !fetchedData[chartPage] && setFetchedData((prevState) => ({ ...prevState, [chartPage]: data }));
  }, []);

  useEffect(() => {
    setHeatmapOptions(
      getHeatmapOptions(customization, data, defaultDecimalDigits, navigateDashboard, widget)
    );
  }, [customization, data]);

  useEffect(() => {
    setSeries(
      data?.xaxis?.categories?.length
        ? getSeriesWithNames(widgetData, data, customization, data.results)
        : []
    );

    !fetchedData[data.p] && setFetchedData((prevState) => ({ ...prevState, [data.p]: data }));
  }, [data]);

  async function getMoreWidgetData(page) {
    if (fetchedData[page]) {
      setData(fetchedData[page]);
      setChartPage(page);
    } else {
      await getData(
        widget.id,
        dashboardFilters,
        widgetFilters,
        { p: page, ps: pageSize },
        setData,
        availableSeries
      );
      setChartPage(page);
    }
  }

  async function goBackOrForth(direction: string) {
    switch (direction) {
      case 'previous':
        await getMoreWidgetData(p - 1);
        break;
      case 'next':
        await getMoreWidgetData(p + 1);
        break;
      default:
        break;
    }
  }

  return (
    <Chart
      isPreview={isPreview}
      showNavigationButtons={showNavigationButtons}
      series={series}
      chartPage={chartPage}
      next={next}
      heatmapOptions={heatmapOptions}
      classes={classes}
      isNextButtonEnabled={
        next ||
        Object.keys(fetchedData).indexOf(chartPage.toString()) < Object.keys(fetchedData).length - 1
      }
      goBackOrForth={!isPreview ? goBackOrForth : null}
      navigateDashboard={navigateDashboard}
      {...props}
    />
  );
}

export default SingleGroupByHeatmapWidget;
