import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { omit } from 'lodash';
import Header from './Header/Header';
import Logo from './Panels/Logo';
import Panel from './Panel/Panel';
import DashboardSettings from './Panels/DashboardSettings';
import DashboardsColors from './Panels/DashboardsColors';
import SystemColors from './Panels/SystemColors';
import EmailFooter from './Panels/EmailFooter';
import RegionalSettings from './Panels/RegionalSettings/RegionalSettings';
import SubDomains from './Panels/SubDomains';
import { httpService } from '@core/http/HttpService';
import { useSelector } from '@src/redux/useSelector';
import { modalService } from '@core/modals/ModalService';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import StatusColors from './Panels/StatusColors';

const useStyles = makeStyles((theme: any) => ({}));

const panelsMap = [
  { id: 1, label: 'white-labeling.regional-settings.title', Component: RegionalSettings },
  { id: 2, label: 'white-labeling.logo.logo', Component: Logo },
  { id: 3, label: 'white-labeling.dashboards-settings.title', Component: DashboardSettings },
  { id: 4, label: 'white-labeling.dashboards-colors.title', Component: DashboardsColors },
  { id: 5, label: 'white-labeling.system-colors.title', Component: SystemColors, isPremium: true },
  { id: 6, label: 'white-labeling.status-colors.title', Component: StatusColors, isPremium: true },
  { id: 7, label: 'white-labeling.email-footer.title', Component: EmailFooter, isPremium: true },
  { id: 8, label: 'white-labeling.sub-domains.title', Component: SubDomains, isPremium: true },
];

function DisplaySettings(props) {
  const classes = useStyles(props);
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);
  const organizationType = useSelector((state) => state.config.organizationDetails.type);

  const colorsUpdateCondotions = {
    systemColors: {
      canUpdate: () => organizationTypeMap[userOrgType] === 1,
      colorsToUpdate: ['machineBuilderOrgArchivedColor'],
    },
  };
  // TODO: get data.
  const [displaySettingsData, setDisplaySettingsData] = useState({});
  const [refreshColorPickers, setRefreshColorPickers] = useState(false);

  useEffect(() => {
    const getDisplaySettings = async () => {
      try {
        const res: any = await httpService.api({
          type: 'getDisplaySettings',
        });
        if (res) {
          setDisplaySettingsData(res.whiteLabelDetails);
        }
      } catch {}
    };

    getDisplaySettings();
  }, []);

  const onChangeData = async (key, name, value, secondName) => {
    const moreUpdate = isPremium && secondName ? { [secondName]: value } : {};
    const body = {
      whiteLabelType: key,
      content: omit(
        {
          ...displaySettingsData[key].content,
          [name]: value,
          ...moreUpdate,
        },
        !colorsUpdateCondotions[key]?.canUpdate() ? colorsUpdateCondotions[key]?.colorsToUpdate : []
      ),
    };
    const data = {
      ...displaySettingsData,
      [key]: {
        ...displaySettingsData[key],
        content: {
          ...displaySettingsData[key].content,
          [name]: value,
          ...moreUpdate,
        },
      },
    };
    try {
      const res = httpService.api({ type: 'updateDisplaySettings', data: body });
      setDisplaySettingsData(data);
    } catch (e) {
      modalService.openAlert({ text: 'errors.global' });
    }
  };

  return (
    <div>
      <Header
        setDisplaySettingsData={setDisplaySettingsData}
        displaySettingsData={displaySettingsData}
        setRefreshColorPickers={setRefreshColorPickers}
      />
      {Object.keys(displaySettingsData).length
        ? panelsMap
            .filter((panel) => (organizationType === 'MACHINE_BUILDER' ? panel : panel.id !== 3))
            .map((panel) => (
              <Panel
                {...panel}
                key={panel.label}
                classes={classes}
                displaySettingsData={displaySettingsData}
                setDisplaySettingsData={setDisplaySettingsData}
                onChangeData={onChangeData}
                refreshColorPickers={refreshColorPickers}
              />
            ))
        : null}
    </div>
  );
}

export default DisplaySettings;
