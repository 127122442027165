import React, { useCallback, useEffect, useState } from 'react';
import { ModalComponentProps } from '@core/modals/modals.interface';
import I18n from '@components/I18n';
import Button from '@components/Button';
import Icon from '@components/Icon';
import styles from './EditGroupMembersModal.scss';
import { httpService } from '@core/http/HttpService';
import Select from '@components/Select';

interface Users {
  accountId: number;
  firstName: string;
  lastName: string;
}

function EditGroupMembersModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { notificationGroup, includeAdmins },
  } = props;

  const [currentGroup, setCurrentGroup] = useState<{
    label: string;
    value: number | string;
  } | null>(null);
  const [groupOptions, setGroupOptions] = useState([]);

  const [admins, setAdmins] = useState([]);
  const [allOrganizationUsers, setAllOrganizationUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showSelectIcons, setShowSelectIcon] = useState(true);

  const cancel = useCallback(() => dismiss(), [dismiss]);

  useEffect(() => {
    setGroupForRender();
  }, []);

  const getAdminUsers = async () => {
    try {
      const res: { results: Users[] } = await httpService.api({
        type: 'getAdminUsers',
        query: {
          p: 1,
          ps: 1000, // hoping there are less than 1000 users (before fix, we got only 10 users)
        },
      });
      if (res) {
        const users = res.results.map((user) => ({
          id: user.accountId,
          name: `${user.firstName} ${user.lastName}`,
        }));
        return users;
      }
    } catch (e) {}
  };

  const getOrganizationUsers = async () => {
    try {
      const res: { results: Users[] } = await httpService.api({
        type: 'getOrganizationUsers',
        query: {
          p: 1,
          ps: 1000, // hoping there are less than 1000 users (before fix, we got only 10 users)
        },
      });
      if (res) {
        const users = res.results.map((user) => ({
          id: user.accountId,
          name: `${user.firstName} ${user.lastName}`,
        }));

        setAllOrganizationUsers(users);
        return users;
      }
    } catch (e) {}
  };

  const getGroupMembers = async (id) => {
    try {
      const res: { name: string; id: number }[] = await httpService.api({
        type: 'getGroupMembers',
        urlParams: { notificationGroup: id },
      });

      if (res) {
        return res;
      }
    } catch (e) {}
  };

  const addGroupMember = async (acount, index) => {
    try {
      await httpService.api({
        type: 'addGroupMember',
        urlParams: { notificationGroup: currentGroup.value, accountId: acount.id },
      });

      const temp = [...selectedUsers];
      temp.push(acount);
      setSelectedUsers(temp);

      const available = [...availableUsers];
      available.splice(index, 1);
      setAvailableUsers(available);
    } catch (e) {}
  };

  const gremoveGroupMember = async (acount, index) => {
    try {
      await httpService.api({
        type: 'gremoveGroupMember',
        urlParams: { notificationGroup: currentGroup.value, accountId: acount.id },
      });
      const temp = [...selectedUsers];
      temp.splice(index, 1);
      setSelectedUsers(temp);

      const available = [...availableUsers];
      available.push(acount);
      setAvailableUsers(available);
    } catch (e) {}
  };

  const setGroupForRender = async () => {
    let group = [];
    if (includeAdmins) {
      group.push({ name: 'event-templates-tab.detail-popup.admins', id: 'ADMINS' });
    }
    const notifygroup = group.concat(
      notificationGroup.sort((a, b) => a.name.localeCompare(b.name))
    );

    const tempgroup: { label: string; value: number | string }[] = notifygroup.map(
      ({ name, id }) => ({ label: name, value: id })
    );

    if (tempgroup.length > 0) {
      onSelectGroup(tempgroup[0]);
    }
    return setGroupOptions(tempgroup);
  };

  const onSelectGroup = async (val: { label: string; value: string | number }) => {
    setShowSelectIcon(false);
    setCurrentGroup(val);
    if (val.value === 'ADMINS') {
      if (admins.length > 0) {
        setSelectedUsers(admins);
        setAvailableUsers([]);
      } else {
        const ans = await getAdminUsers();
        setAdmins(ans);
        setSelectedUsers(ans);
        setAvailableUsers([]);
      }
    } else {
      const members = await getGroupMembers(val.value);
      const tempAvailable = [];

      const users =
        allOrganizationUsers.length > 0 ? allOrganizationUsers : await getOrganizationUsers();

      users.forEach((item) => {
        const found = members.find((element) => element.id === item.id);
        if (!found) {
          tempAvailable.push(item);
        }
      });

      setAvailableUsers(tempAvailable);
      setSelectedUsers(members);
    }
    setShowSelectIcon(true);
  };

  const renderGroupOptions = () => {
    if (groupOptions?.length > 1) {
      return (
        <div className={styles.dropDown}>
          <Select
            value={currentGroup}
            defaultValue={currentGroup[0]}
            options={groupOptions}
            onChange={onSelectGroup}
          />
        </div>
      );
    }
    return <I18n>{currentGroup.label}</I18n>;
  };

  return (
    <div className={styles.wrapper}>
      <div style={{ display: 'flex' }}>
        <I18n
          className={styles.modalHeader}
          element="div"
          title={'event-templates-tab.table.create-date.table.edit-group-members'}>
          event-templates-tab.table.create-date.table.edit-group-members
        </I18n>
        <div style={{ display: 'flex' }} onClick={cancel}>
          <Icon className={styles.exitButton} type="close" />
        </div>
      </div>

      <div className={styles.modalContent}>
        <div className={styles.mainContainer}>
          <div className={styles.header}>
            <I18n className={styles.groupName}>event-templates-tab.detail-popup.group</I18n>
            {currentGroup && renderGroupOptions()}
          </div>

          <div className={styles.groupContainer}>
            <div className={styles.group} style={{ marginRight: 40 }}>
              <I18n className={styles.title}>event-templates-tab.detail-popup.available-users</I18n>
              <div className={styles.scroll}>
                {availableUsers.map((acount, index) => (
                  <div className={styles.nameRow} key={`${index * Math.random() * 999}`}>
                    <I18n className={styles.textBtn}>{acount.name}</I18n>
                    {showSelectIcons ? (
                      currentGroup.value === 'ADMINS' ? (
                        <div />
                      ) : (
                        <Icon
                          color="var(--systemButtonBackground)"
                          type="add_user"
                          style={{ cursor: 'pointer' }}
                          onClick={() => addGroupMember(acount, index)}></Icon>
                      )
                    ) : (
                      <div />
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className={styles.group}>
              <I18n className={styles.title}>event-templates-tab.detail-popup.selected-users</I18n>
              <div className={styles.scroll}>
                {selectedUsers.map((acount, index) => {
                  return (
                    <div className={styles.nameRow} key={`${index * Math.random() * 999}`}>
                      <I18n className={styles.textBtn}>{acount.name}</I18n>
                      {showSelectIcons ? (
                        currentGroup.value === 'ADMINS' ? (
                          <div />
                        ) : (
                          <Icon
                            color="var(--systemCancelButtonBackground)"
                            type="remove_user"
                            style={{ cursor: 'pointer' }}
                            onClick={() => gremoveGroupMember(acount, index)}></Icon>
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.modalButtons}>
          <Button mode="bigFont" disabled={false} onClick={cancel}>
            <I18n>general.close</I18n>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EditGroupMembersModal;
