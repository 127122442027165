import TextWidget from '@components/widgets/TextWidget';
import { WidgetMapItem } from '../widgetMap.interface';

export const text: WidgetMapItem = {
  id: 'text',
  name: 'text',
  component: TextWidget,
  minWidth: 120,
  minHeight: 50,
  fullSize: true,
  images: {
    canvas: '/assets/images/empty_text.svg',
    thumbnail: 'text',
  },
  editModalSize: {},
  settings: {
    enableSaveAsDraft: true,
    // hideHideOptions: true,
    hidePreviewTable: true,
    useSteps: [4, 5],
    languageKeys: ['text'],
    /*   customizationServerChanges: (customization) => {
        delete customization.hideWidgetFrame;
        delete customization.hideWidgetName;
        return customization;
      }, */
    allowAssetTypeNavigation: (widgetData) => false,
    hideFilterNavigation: (widgetData) => true,
    allowAssetTypeNavigationWithoutAssetProperties: true,
  },
};
