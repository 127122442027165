import * as cloneDeep from 'lodash/cloneDeep';
import { isEqual, compact } from 'lodash';

import history from '@core/history';
import { cssVarsService } from '@core/CssVarsService';
import { httpService } from '@http/HttpService';
import {
  GetAllDashboardsRes,
  GetDashboardRes,
  GetDefaultDashboardRes,
} from '@http/server.interface';
import {
  initEditor,
  resetEditor,
  setCurrentLayout,
  setDashboardName,
  setUserFilters,
} from '@redux/dashboardEditor';
import {
  initGallery,
  clearDashboards,
  addNewDashboard,
  setDefaultDashboard,
} from '@redux/dashboardGallery';
import { dispatch, getState } from '@redux/store';
import {
  DashboardEditorState,
  DashboardSummary,
  UserFilterType,
  UserFilterItem,
} from '@redux/redux.interface.d';
import { setDashboardRefId } from '@src/redux/config';

import { serverToLocal, localToServer } from './dashboard.utils';
import { modalService } from '@core/modals/ModalService';
import { resetLiveDashboardWidgetsData } from '@src/redux/liveDashboardWidgetsData';

const DASHBOARD_AUTOSAVE_INTERVAL_MS = 30000;

/**
 * Handles management of dashboards
 */
class DashboardService {
  private lastSaved: DashboardEditorState;
  private autoSaveInterval;
  public dashboardIdType;
  public firstDashboardId;
  private readonly apiMapType = {
    ref: {
      getDashboardId: 'getLiveDashboardData',
    },
    id: { getDashboardId: 'getDashboard' },
  };

  async getAllDashboards(): Promise<DashboardSummary[]> {
    try {
      const res = await httpService.api<GetAllDashboardsRes>({
        type: 'getAllDashboards',
        urlParams: { p: 1, ps: 99999 },
      });

      dispatch(initGallery(res.results, res.allCount, !!res.next));

      return res.results;
    } catch (e) {
      dispatch(initGallery([], 0, false));
      return [];
    }
  }

  async initDashboard(id: number | string) {
    id = +id;

    const currentId = getState().dashboardEditor.id;
    dispatch(resetLiveDashboardWidgetsData());

    const currentLayout = getState().dashboardEditor.currentLayout || 'DESKTOP';
    if (currentId !== id) {
      dispatch(resetEditor(currentLayout));
    }

    const dashboard = await this.getDashboardById(id, { disableBI: true }, undefined, {}, 'editor');

    dispatch(initEditor(dashboard));
    cssVarsService.setVariable(
      'canvasWidthValue',
      dashboard.states.find((state) => state.layoutStateType === 'DESKTOP').canvasWidthValue
    );
  }

  async getDashboardById(
    id: number,
    options: { disableBI?: boolean } = {},
    dashboardFilters?: any,
    query: any = {},
    isPreview?: string
  ) {
    const res = await httpService.api<GetDashboardRes>({
      type: this.apiMapType[this.dashboardIdType].getDashboardId,
      disableBI: options.disableBI,
      urlParams: { id },
      query,
      data: dashboardFilters ? { dashboardFilters } : null,
    });
    return serverToLocal(res, isPreview);
  }

  copyDashboard = (dashboard, func) => {
    modalService
      .openConfirm(
        {
          text: 'dashboard-gallery.duplicate-dashboard-confirm',
          showCloseBtn: true,
          headerText: 'dashboard-gallery.duplicate-dashboard-confirm-header',
        },
        { dashboardName: dashboard.name }
      )
      .then((confirm) => confirm && func());
  };

  async dashboardCopyEdit(id, action, notUseApi?) {
    let dashId = { id };
    let hasDraftVersion = false;

    if (action === 'edit') {
      //if editing publish dashborad that already have draft version navigate to draft version
      //get dashboards list
      let dashboards = getState().dashboardGallery?.dashboards;
      if (!dashboards) {
        dashboards = await this.getAllDashboards();
      }
      //find the dashboard in dashboards list
      const dashboard = dashboards && dashboards.find((dashboard) => dashboard.ref === id);
      //find the draft dashboard if exists
      const draftDashboard =
        dashboard &&
        dashboard.dash_instances &&
        dashboard.dash_instances.find((d) => d.status === 'DRAFT');

      dashId = { id: draftDashboard ? draftDashboard.id : id };
      hasDraftVersion = !!draftDashboard;
    }

    const res =
      notUseApi || hasDraftVersion
        ? dashId
        : await httpService.api<any>({
            type: `${action}Dashboard`,
            urlParams: { id },
          });

    if (res) {
      history.push(`/main/edit-dashboard/${res.id}`);
    }
  }

  async publishDashboard() {
    const { id, canvasWidgets, name } = getState().dashboardEditor;
    const draftWidgets = canvasWidgets.find((w) => w.status === 'DRAFT');

    const confirm = await modalService.openModal('confirm', {
      text: draftWidgets
        ? 'dashboard-editor-top-section.publishMessageDraft'
        : 'dashboard-editor-top-section.publishMessage',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      dashboardName: name,
      headerText: 'dashboard-editor-top-section.publishHeader',
    });

    if (confirm) {
      const res = await httpService.api({
        type: 'publishDashboard',
        urlParams: { id },
      });

      dispatch(setCurrentLayout('DESKTOP'));

      !getState().config?.organizationDetails?.dashboardRefId &&
        dispatch(setDashboardRefId({ dashboardRefId: id }));

      history.push(`/main/edit-dashboard/`);
    }
  }

  removeDraftDashboard(dashboard, needRefresh = false) {
    modalService
      .openConfirm(
        {
          headerText: 'dashboard-gallery.delete-dashboard.draft-header',
          text: 'dashboard-gallery.delete-dashboard-confirm',
          showCloseBtn: true,
        },
        { dashboardName: dashboard.name }
      )
      .then((confirm) => {
        confirm && this.removeDashboard(dashboard.id, needRefresh);
      });
  }

  removePublishedDashboard(dashboard, needRefresh = false) {
    modalService
      .openConfirm(
        {
          headerText: 'dashboard-gallery.delete-dashboard.published-header',
          text: 'dashboard-gallery.delete-dashboard-confirm',
          showCloseBtn: true,
        },
        { dashboardName: dashboard.name }
      )
      .then((confirm) => {
        confirm && this.verifyDashboard(dashboard, needRefresh);
      });
  }

  removeCombinedDashboards(dashboard, needRefresh = false) {
    modalService
      .openConfirmWithMultiButtons(
        {
          text: 'dashboard-editor-gallery.combined-delete-message',
          headerText: 'dashboard-gallery.delete-dashboard.header',
          showCloseBtn: true,
          multiButtons: compact([
            {
              text: 'general.draft',
              dismissModal: true,
              onClick: () => this.removeDashboard(dashboard.draft.id, needRefresh),
            },
            {
              text: 'general.dashboard',
              dismissModal: true,
              onClick: () => this.verifyDashboard(dashboard.published, needRefresh),
            },
          ]),
        },
        { dashboardName: dashboard.name }
      )
      .then((confirm) => {
        confirm && this.verifyDashboard(dashboard, needRefresh);
      });
  }

  copyCombinedDashboards(dashboard, needRefresh = false) {
    modalService
      .openConfirmWithMultiButtons(
        {
          text: 'dashboard-editor-gallery.combined-copy-message',
          headerText: 'dashboard-gallery.duplicate-dashboard.header',
          showCloseBtn: true,
          multiButtons: compact([
            {
              text: 'general.draft',
              dismissModal: true,
              onClick: () => this.dashboardCopyEdit(dashboard.draft.id, 'copy'),
            },
            {
              text: 'general.published',
              dismissModal: true,
              onClick: () => this.dashboardCopyEdit(dashboard.published.id, 'copy'),
            },
          ]),
        },
        { dashboardName: dashboard.name }
      )
      .then((confirm) => {
        confirm && this.verifyDashboard(dashboard, needRefresh);
      });
  }

  verifyDashboard(dashboard, needRefresh) {
    httpService
      .api({
        type: 'verifyRemoveDashboard',
        urlParams: {
          dashboardId: dashboard.id,
        },
        disableBI: true,
      })
      .then((res: any) => {
        res.isValid
          ? this.removeDashboard(dashboard.id, needRefresh)
          : this.openDashboardInUseModal(res, dashboard, needRefresh);
      });
  }

  verifyDashboardOLD(dashboard, needRefresh) {
    httpService
      .api({
        type: 'verifyRemoveDashboard',
        urlParams: {
          dashboardId: dashboard.id,
        },
        disableBI: true,
      })
      .then((res: any) => {
        switch (res.message) {
          case 'DEFAULT_FOR_ORGANIZATIONS':
            this.openDefaultDashboardListModal(res, dashboard.name);
            break;
          case 'PART_OF_NAVIGATION':
            this.openNavigationDashboardListModal(res, dashboard, needRefresh);
            break;
          default:
            this.removeDashboard(dashboard.id, needRefresh);
            break;
        }
      });
  }

  openDefaultDashboardListModal(res, dashboardName) {
    modalService.openModal('alert', {
      headerText: 'dashboard-gallery.delete-dashboard.published-header',
      text: `dashboard-gallery.delete-dashboard.${res.message}`,
      btnText: 'general.confirm',
      showCloseBtn: true,
      listText: res.messageItems,
      dashboardName,
    });
  }

  openDashboardInUseModal(res, dashboard, needRefresh) {
    modalService
      .openModal('dashboardInUseModal', {
        headerText: 'dashboard-gallery.delete-dashboard.published-header',
        usedAsDefaultDashboard: res.usedAsDefaultDashboard,
        defaultForAccounts: res.defaultForAccounts,
        usedInEvent: res.usedInEvent,
        navigateFrom: res.navigateFrom,
        dashboardName: dashboard.name,
      })
      .then((confirm) => {
        confirm && this.removeDashboard(dashboard.id, needRefresh);
      });
  }

  openNavigationDashboardListModal(res, dashboard, needRefresh) {
    modalService
      .openConfirm(
        {
          headerText: 'dashboard-gallery.delete-dashboard.published-header',
          text: `dashboard-gallery.delete-dashboard.${res.message}`,
          showCloseBtn: true,
          listText: res.messageItems,
        },
        { dashboardName: dashboard.name }
      )
      .then((confirm) => {
        confirm && this.removeDashboard(dashboard.id, needRefresh);
      });
  }

  removeDashboard(dashboardId, needRefresh) {
    httpService
      .api({
        type: 'removeDashboard',
        urlParams: { dashboardId },
      })
      .then(() => {
        needRefresh && this.getAllDashboards();
        history.push(`/main/edit-dashboard/`);
      });
  }

  openDashboardPreview(id, location) {
    history.push(`/main/edit-dashboard/${id}/preview`, { preview: location });
  }

  closeDashboardPreview() {
    const { state } = history.location;
    history.push(
      `/main/edit-dashboard${state.preview === 'gallery' ? '' : `/${this.firstDashboardId}`}`
    );
  }

  private nameChangeDebounce;
  changeDashboardName(name: string) {
    const dashboardId = getState().dashboardEditor.id;
    clearTimeout(this.nameChangeDebounce);
    dispatch(setDashboardName(name));
    this.nameChangeDebounce = setTimeout(() => {
      httpService.api({
        type: 'setDashboard',
        urlParams: {
          id: dashboardId,
        },
        disableBI: true,
        data: { name },
      });
    }, 300);
  }

  addNewDashboard(dashboard: DashboardSummary) {
    dispatch(addNewDashboard({ ref: dashboard.id, dash_instances: [dashboard] }));
  }

  setDefaultDashboard(dashboard: DashboardSummary) {
    httpService
      .api({
        type: 'setDefaultDashboard',
        urlParams: { dashboardId: dashboard.id },
      })
      .then((res) => {
        dispatch(setDefaultDashboard(dashboard));
      });
  }

  async getDefaultDashboard() {
    const res = await httpService.api<GetDefaultDashboardRes>({
      type: 'getDefaultDashboard',
    });

    return res.results[0];
  }

  clearDashboards() {
    dispatch(clearDashboards());
  }

  removeFilterFromFiltersWidget(filterType: UserFilterType) {
    const filters = getState().dashboardEditor.filters;

    const newFilters = filters.map((filt) => {
      if (filt.filterType === filterType && filt.visible) {
        return { ...filt, visible: false };
      } else {
        return filt;
      }
    });

    dispatch(setUserFilters(newFilters));
    this.updateFiltersWidgetInServer(newFilters);
  }

  addFiltersToFiltersWidget(filterTypes: UserFilterType[]) {
    const filters = getState().dashboardEditor.filters;

    const newFilters = filters.map((filt) => {
      if (filterTypes.includes(filt.filterType) && !filt.visible) {
        return { ...filt, visible: true };
      } else {
        return filt;
      }
    });

    dispatch(setUserFilters(newFilters));
    this.updateFiltersWidgetInServer(newFilters);
  }

  toggleEditableFilterFromFiltersWidget(filterType: UserFilterType) {
    const filters = getState().dashboardEditor.filters;

    const newFilters = filters.map((filt) => {
      if (filt.filterType === filterType) {
        return { ...filt, editable: !filt.editable };
      } else {
        return filt;
      }
    });

    dispatch(setUserFilters(newFilters));
    this.updateFiltersWidgetInServer(newFilters);
  }

  updateFiltersWidgetInServer(filters: UserFilterItem[]) {
    const id = getState().dashboardEditor.id;
    httpService.api({
      type: 'updateDashboardFilters',
      urlParams: {
        id,
      },
      disableBI: true,
      data: { filters },
    });
  }

  /**
   * Stops the auto-save mechanism.
   */
  stopAutoSave() {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
      this.autoSaveInterval = undefined;
    }
  }
}

export const dashboardService = new DashboardService();
