import React, { useMemo } from 'react';
import styles from './RouterPLCsMeta.scss';
import RouterPLCsTable from './RouterPLCsTable';
import ToolBar from '@components/ToolBar';
import { ToolbarCfg } from '@components/ToolBar/toolBar.interface';
import { useSelector } from '@redux/useSelector';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { compact } from 'lodash';
import { modalService } from '@core/modals/ModalService';
import { routersService } from '@pages/DeviceManagment/Routers/RoutersService';
import { RouterTabsProps } from '@modals/EditRouterModal/EditRouterModal.interface';

function RouterPLCsMeta(props: RouterTabsProps) {
  const userOrgType = useSelector(
    state => state.config.organizationDetails && state.config.organizationDetails.type
  );
  const hasPermission = useMemo(() => getPermissionStatus('DEVICE', 'EDIT'), []);

  const openConnectPLCToRouter = () => {
    modalService.openModal('connectPLCToRouter', { routerId: props.id }).then(res => {
      if (!res) return;
      // Refreshing the parent routers table
      routersService.refreshRoutersTableData();

      // Refreshing the parent routers table
      props.onDataChanged();
    });
  };

  const toolbarCfg: ToolbarCfg = {
    customStyles: { btns: { marginRight: 0, marginLeft: 'auto' } },
    buttons: compact([
      userOrgType !== 'MACHINE_BUILDER_CHANNEL' &&
        hasPermission && {
          type: 'create',
          onClick: openConnectPLCToRouter
        }
    ])
  };

  return (
    <>
      <div className={styles.content}>
        <ToolBar toolbarCfg={toolbarCfg} />
        <RouterPLCsTable {...props} />
      </div>
    </>
  );
}

export default RouterPLCsMeta;
