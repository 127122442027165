import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import Header from './Header';
import { httpService } from '@core/http/HttpService';
import Panel from './Panel';
import { modalService } from '@core/modals/ModalService';
import { dispatch } from '@src/redux/store';
import { updatetWhiteLabelDetails } from '@src/redux/config';
import DateTime from './Panels/DateTime';
import CsvDelimiter from './Panels/CsvDelimiter';
import NumberFormatting from '@src/pages/MyAccountPage/SharedComponents/NumberFormatting';

const panelsMap = [
  { label: 'white-labeling.date-time.title', Component: DateTime },
  { label: 'white-labeling.number-formatting.title', Component: NumberFormatting },
  { label: 'white-labeling.csv-delimiter-settings.title', Component: CsvDelimiter },
];

function RegionalSettings(props) {
  const [isError, setIsError] = useState(false);
  const [isPublishButtonDisabled, setIsPublishButtonDisabled] = useState(true);
  const [originalSettings, setOriginalSettings] = useState({}) as any;
  const [currentSettings, setCurrentSettings] = useState({}) as any;

  const getRegionalSettings = async (updateConfig = false) => {
    const res = (await httpService.api({ type: 'getRegionalSettingsWL' })) as any;
    if (res) {
      setOriginalSettings(res.whiteLabelDetails);
      setCurrentSettings(res.whiteLabelDetails);
      if (updateConfig) dispatch(updatetWhiteLabelDetails(res.whiteLabelDetails));
    }
  };

  useEffect(() => {
    getRegionalSettings();
  }, []);

  useEffect(() => {
    setIsPublishButtonDisabled(isEqual(originalSettings, currentSettings) || isError);
  }, [currentSettings, originalSettings, isError]);

  const onChangeData = async (key, name, value) => {
    const data = {
      ...currentSettings,
      [key]: {
        ...currentSettings[key],
        content: {
          ...currentSettings[key].content,
          [name]: value,
        },
      },
    };
    setCurrentSettings(data);
  };

  const onPublish = async () => {
    try {
      await httpService.api({
        type: 'setRegionalSettingsWL',
        data: {
          data: [
            {
              whiteLabelType: 'dateTime',
              content: {
                ...currentSettings.dateTime.content,
              },
            },
            {
              whiteLabelType: 'regionalSettings',
              content: {
                ...currentSettings.regionalSettings.content,
              },
            },
            {
              whiteLabelType: 'numberFormatting',
              content: {
                ...currentSettings.numberFormatting.content,
              },
            },
          ],
        },
      });

      setOriginalSettings(currentSettings);
      dispatch(updatetWhiteLabelDetails(currentSettings));
    } catch (e) {
      modalService.openAlert({ text: 'errors.global' });
    }
  };

  const onClear = async () => {
    try {
      const clearRes = await httpService.api({ type: 'clearRegionalSettingsWL' });
      getRegionalSettings(true);
    } catch (e) {
      modalService.openAlert({ text: 'errors.global' });
    }
  };

  return (
    <>
      <Header
        isPublishButtonDisabled={isPublishButtonDisabled}
        onPublish={onPublish}
        onClear={onClear}
      />
      {Object.keys(currentSettings).length
        ? panelsMap.map((panel, idx) => (
            <div key={idx} style={panelsMap.indexOf(panel) === 2 ? { marginBottom: '500px' } : null}>
              <Panel
                {...panel}
                key={panel.label}
                regionalSettingsData={currentSettings}
                onChangeData={onChangeData}
                setIsError={setIsError}
              />
            </div>
          ))
        : null}
    </>
  );
}

export default RegionalSettings;
