import React from 'react';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import I18n from '@components/I18n';
import styles from './OrganizationalSubscriptionsTab.scss';
import SabscriptionsTableTab from './SabscriptionsTableTab';
import UsageTab from './UsageTab';

const OrganizationalSubscriptionsTab = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.myAccountTabs}>
          <NavLink
            className={styles.tab}
            activeClassName={styles.activeTab}
            to={`${props.match.url}/sabscriptionsTableTab`}>
            <I18n>my-account-page.licensing-plan.organizational-subscriptions.subscriptions</I18n>
          </NavLink>
          <NavLink
            className={styles.tab}
            activeClassName={styles.activeTab}
            to={`${props.match.url}/usageTab`}>
            <I18n>my-account-page.licensing-plan.organizational-subscriptions.usage</I18n>
          </NavLink>
        </div>

        <div className={styles.routsSection}>
          <Switch>
            <Route
              path={`${props.match.url}/sabscriptionsTableTab`}
              component={SabscriptionsTableTab}
            />
            <Route path={`${props.match.url}/usageTab`} component={UsageTab} />

            <Redirect to={`${props.match.url}/sabscriptionsTableTab`} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default OrganizationalSubscriptionsTab;
