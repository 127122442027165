import React from 'react';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import I18n from '@components/I18n';

const useStyles = makeStyles((theme: any) => ({
  panel: {
    marginBottom: 13,
    boxShadow: theme.app.boxShadowGrey,
    borderRadius: 8,
    backgroundColor: 'var(--systemContentBackground)',
    '&:before': {
      height: 0,
    },
  },
  panelRounded: {
    height: 78,
  },
  panelDetails: {
    backgroundColor: theme.app.myAccountPanelsColor,
    width: '100%',
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
  },
}));

const Panel = (props) => {
  const {
    label,
    Component,
    displaySettingsData,
    onChangeData,
    setDisplaySettingsData,
    isPremium,
    refreshColorPickers,
  } = props;
  const classes = useStyles(props);

  return (
    <ExpansionPanel className={classes.panel}>
      <ExpansionPanelSummary className={classes.panelRounded} expandIcon={<ExpandMoreIcon />}>
        <I18n className={classes.label}>{label}</I18n>
        {isPremium && (
          <I18n className={classes.label}>
            &nbsp; (<I18n className={classes.label}>white-labeling.premium-only</I18n>)
          </I18n>
        )}
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={classes.panelDetails}>
          {Component && (
            <Component
              displaySettingsData={displaySettingsData}
              onChangeData={onChangeData}
              setDisplaySettingsData={setDisplaySettingsData}
              refreshColorPickers={refreshColorPickers}
            />
          )}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Panel;
