import React, { useEffect, useState } from 'react';

// services & Interfaces
import { ImageByValueCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import I18n from '@components/I18n';

// 3rd party
import * as cloneDeep from 'lodash/cloneDeep';
import { makeStyles } from '@material-ui/core';
import IBVCfgConditions from './IBVCfgConditions';

const useStyles = makeStyles((theme: any) => ({
  costumizeRow: {
    display: 'flex',
    height: '40px',
    alignItems: 'center',
  },
  rowWithHeader: {
    display: 'flex',
    alignItems: 'baseline',
  },
  subHeader: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 800,
  },
  subHeaderspace: {
    width: '120px',
  },
  text: {
    marginLeft: '10px',
    fontSize: '14px',
    minWidth: '65px',
  },
  widgetValue: {
    marginTop: '10px',
  },
  imageDimensions: {
    width: '100%',
    margin: '10px 0',
  },
  labelWrapper: {
    width: '100%',
  },
  error: {
    fontSize: 12,
    color: 'var(--formikErrorText)',
    fontWeight: 'bold',
    marginLeft: 10,
  },
}));

const Image = (props) => {
  const { widgetData, setWidgetData } = props;
  const { customization } = widgetData;

  const classes = useStyles(props);

  return (
    <div className={classes.widgetValue}>
      <div className={classes.rowWithHeader}>
        <I18n>create-widget-page.create-widget.step-four.image-by-value.conditions</I18n>
        {customization?.errors && Object.values(customization?.errors).find((err) => err) && (
          <I18n className={classes.error}>
            create-widget-page.create-widget.step-four.image-by-value.conditions-error
          </I18n>
        )}
      </div>
      <IBVCfgConditions widgetData={widgetData} setWidgetData={setWidgetData}></IBVCfgConditions>
    </div>
  );
};

export default Image;
