import { i18nService } from '@core/i18n/I18nService';
import { getByValue, tagFormatsMapWithFloatType } from '@core/mapsAndDefinitions';
import { modalService } from '@core/modals/ModalService';
import { assetsPropsMap, isFloatCalculation } from '@pages/CreateWidgetPage/widgets.utils';
import { getState } from '@src/redux/store';
import { omit, isEqual, cloneDeep } from 'lodash';

export function getTagDataSourceTarget(widgetData: any) {
  const tagData = widgetData.assetTypes?.length > 1 ? widgetData.tagTypes : widgetData.tags;
  return tagData.map((tag) => {
    return tag.format
      ? {
          source: { id: tag.id, name: tag.name, format: tag.format },
          replaceWith: { id: tag.id, name: tag.name, format: tag.format },
        }
      : {
          source: { id: tag.id, name: tag.name, type: tag.type },
          replaceWith: { id: tag.id, name: tag.name, type: tag.type },
        };
  });
}

export function prepareAssetOptions(options) {
  return options.map((option) => ({
    id: option.id,
    type: option.type,
    name: i18nService.translate(
      `create-widget-page.create-widget.asset-properties.${assetsPropsMap[option.id]}`
    ),
  }));
}

export function getNewCustomizationColumnValues(
  column: any,
  replacements: any,
  calculation: any,
  widgetData: any
) {
  const assetPropertyColumn = replacements.assetProperties.find(
    (item) => column.id === item.source.id
  );
  const tagDataColumn = replacements.tagData.find((item) => column.id === item.source.id);
  if (assetPropertyColumn) {
    return {
      id: assetPropertyColumn.replaceWith.id,
      valueType: column.valueType,
    };
  }

  if (tagDataColumn) {
    const format = tagDataColumn.replaceWith?.format
      ? tagDataColumn.replaceWith?.format
      : tagDataColumn.replaceWith?.type;
    return {
      id: tagDataColumn.replaceWith.id,
      valueType: replacements.assetTypes.replaceWith.length === 1 ? 'TAG' : 'TAG_TYPE',
      tagType: format,
      decimalDigits:
        format === 'FLOAT32' || isFloatCalculation(calculation, widgetData)
          ? column.decimalDigits
            ? column.decimalDigits
            : getState().config.whiteLabelDetails.numberFormatting.content.defaultDecimalDigits
          : null,
    };
  }
  return column;
}

export function replaceOrganizeFields(newWidgetData: any, fieldName: string, replacements: any) {
  const newData = newWidgetData[fieldName].map((newDataItem) => {
    if (newDataItem.valueType === 'VARIABLE' || newDataItem.valueType === 'CALCULATION') {
      return newDataItem;
    } else {
      const replacementData =
        newDataItem.valueType === 'ASSET_PROPERTY'
          ? replacements.assetProperties
          : replacements.tagData;
      const index = replacementData.indexOf(
        replacementData.find(
          (replacementDataItem) => replacementDataItem.source.id === newDataItem.valueId
        )
      );

      switch (newDataItem.valueType) {
        case 'ASSET_PROPERTY':
          return {
            ...newDataItem,
            valueId: replacements?.assetProperties[index]?.replaceWith?.id,
            name: replacements?.assetProperties[index]?.replaceWith?.name,
          };
        case 'ALARM_INFO':
          return {
            ...newDataItem,
            valueId: newDataItem.valueId,
            name: newDataItem.name,
          };
        default:
          return {
            ...newDataItem,
            valueId: replacements?.tagData[index]?.replaceWith?.id,
            name: replacements?.tagData[index]?.replaceWith?.name,
          };
      }
    }
  });
  return {
    ...newWidgetData,
    [fieldName]: newData,
  };
}

export function replaceInExpression(newWidgetData: any, replacements: any) {
  if (newWidgetData.type === 'update_asset') {
    return newWidgetData;
  }
  return {
    ...newWidgetData,
    calculations: newWidgetData.calculations.map((calc) => {
      let newExpression = calc.expression;
      replacements.tagData.map((tag) => {
        const previousGroupName =
          replacements.assetTypes.source?.length === 1 ? 'assetTags' : 'tagTypes';
        const newGroupName =
          replacements.assetTypes.replaceWith?.length === 1 ? 'assetTags' : 'tagTypes';

        newExpression = newExpression
          .replaceAll(tag.source.name, tag.replaceWith.name)
          .replaceAll(`${previousGroupName}.`, `${newGroupName}.`);

        return newExpression;
      });
      replacements.assetProperties.map(
        (assetProperty) =>
          (newExpression = newExpression.replaceAll(
            assetProperty.source.name,
            assetProperty.replaceWith.name
          ))
      );
      return { ...calc, expression: newExpression };
    }),
  };
}

export function removeNameFromCalculations(newWidgetData: any) {
  return {
    ...newWidgetData,
    calculations: newWidgetData.calculations.map((calc) => omit(calc, 'name')),
  };
}

export function replaceValueType(newWidgetData: any, fieldName: string) {
  return {
    ...newWidgetData,
    [fieldName]: newWidgetData[fieldName].map((item) => {
      if (
        item.valueType === 'ASSET_PROPERTY' ||
        item.valueType === 'ALARM_INFO' ||
        item.valueType === 'VARIABLE' ||
        item.valueType === 'CALCULATION'
      ) {
        return item;
      }
      if (newWidgetData.assetTypes.length === 1) {
        return { ...item, valueType: 'TAG' };
      }
      return { ...item, valueType: 'TAG_TYPE' };
    }),
  };
}

export function replaceTypeInMetrics(newWidgetData: any) {
  return {
    ...newWidgetData,
    metrics: newWidgetData.metrics.map((metric) => {
      if (
        metric.valueType === 'VARIABLE' ||
        metric.valueType === 'CALCULATION' ||
        metric.valueType === 'ALARM_INFO'
      ) {
        return metric;
      }
      return {
        ...metric,
        type:
          metric?.valueType === 'ASSET_PROPERTY'
            ? metric?.type
            : getByValue(tagFormatsMapWithFloatType, getDataIsFloat(newWidgetData, metric)),
      };
    }),
  };
}

function getDataIsFloat(widgetData: any, metric: any) {
  const valueId = metric.valueId;
  return widgetData?.tagTypes?.length
    ? widgetData?.tagTypes.find((tagType) => tagType.id === valueId).type
    : widgetData?.tags?.length && widgetData?.tags.find((tag) => tag.id === valueId)?.format;
}

export function setNamesAndLabels(newWidgetData: any, fieldName: string) {
  return {
    ...newWidgetData,
    [fieldName]: newWidgetData[fieldName].map((item) => {
      return {
        ...item,
        name:
          item.valueType === 'ASSET_PROPERTY'
            ? `create-widget-page.create-widget.asset-properties.${assetsPropsMap[item.valueId]}`
            : item.name,
        label:
          item.valueType === 'ASSET_PROPERTY'
            ? `create-widget-page.create-widget.asset-properties.${assetsPropsMap[item.valueId]}`
            : item.name,
      };
    }),
  };
}

export function openReplacementCompletedModal(replacements: any, widgetData: any) {
  let listText = [
    i18nService
      .translate('create-widget-page.create-widget.step-one.replace-tags.finished-part1')
      .concat('.'),
  ];
  if (
    !isEqual(replacements.assetTypes.replaceWith, replacements.assetTypes.source) &&
    widgetData.navigationDashboard?.length
  ) {
    listText = [
      i18nService
        .translate('create-widget-page.create-widget.step-one.replace-tags.finished-part1')
        .concat(' ')
        .concat(
          i18nService.translate(
            'create-widget-page.create-widget.step-one.replace-tags.finished-part2'
          )
        ),
    ];
  }
  if (
    widgetData.filters?.length &&
    widgetData.filters.some(
      (f) =>
        (f.valueType === 'TAG' || f.valueType === 'TAG_TYPE') &&
        replacements.tagData?.some(
          (tag) => tag.source.id === f.valueId && tag.replaceWith.id != tag.source.id
        )
    )
  ) {
    listText.push('create-widget-page.create-widget.step-one.replace-tags.finished-part3');
  }
  if (
    widgetData.filters?.length &&
    widgetData.filters.some((f) => f.valueType === 'ASSET_PROPERTY') &&
    replacements.assetProperties.some(
      (assetProperty) => !isEqual(assetProperty.replaceWith, assetProperty.source)
    )
  ) {
    listText.push('create-widget-page.create-widget.step-one.replace-tags.finished-part4');
  }

  function getCustomStylesForCompletedAlert() {
    switch (listText?.length) {
      case 2:
        return { customWrapperStyle: { width: '500px' }, customIconStyle: { marginBottom: 0 } };
      case 3:
        return {
          customWrapperStyle: { width: '500px', height: '380px' },
          customListStyle: { marginTop: '10px', maxHeight: '140px' },
          customContentStyle: { height: '259px' },
          customIconStyle: { marginBottom: '5px' },
        };
      default:
        return null;
    }
  }

  modalService.openModal('alert', {
    text: listText.length === 1 ? listText[0] : '',
    iconType: 'v_image',
    btnText: 'general.close',
    headerText: 'create-widget-page.create-widget.step-one.replace-tags.finished-header',
    showCloseBtn: true,
    listText: listText.length === 1 ? [] : listText,
    customStyles: getCustomStylesForCompletedAlert(),
    noBullets: true,
  });
}

export function updateCustomizationValuesPerWidgetType(
  widgetType: string,
  customization: any,
  newWidgetData: any
) {
  let newCustomization = cloneDeep(customization);
  switch (widgetType) {
    case 'columns':
      newCustomization = {
        ...newCustomization,
        xAxisInfo: newWidgetData.assetProperties.some((ap) => ap.id === 1) ? 1 : 2,
      };
      break;
  }
  return newCustomization;
}

export function updateMetricsPerWidgetType(
  widgetType: string,
  replacements: any,
  metrics: any,
  oldWidgetData: any
) {
  let newMetrics = cloneDeep(metrics);
  switch (widgetType) {
    case 'columns':
      if (
        replacements.assetProperties.some((ap) => ap.source.id === 1) &&
        oldWidgetData.metrics.some(
          (oldMetric) =>
            oldMetric.valueType === 'ASSET_PROPERTY' && oldMetric.valueId === 1 && oldMetric.hide
        )
      ) {
        const assetPropertyMetricToRemove = replacements.assetProperties.find(
          (a) => a.source.id === 1
        ).replaceWith;

        newMetrics = newMetrics.filter((nm) => nm.valueId !== assetPropertyMetricToRemove.id);
      } else if (
        replacements.assetProperties.some((ap) => ap.replaceWith.id === 1) &&
        !oldWidgetData.metrics.some(
          (oldMetric) =>
            oldMetric.valueType === 'ASSET_PROPERTY' && oldMetric.valueId === 1 && oldMetric.hide
        )
      ) {
        newMetrics = [
          ...newMetrics,
          {
            hide: true,
            order: 1,
            valueId: 1,
            operation: null,
            valueType: 'ASSET_PROPERTY',
          },
        ];
      }
      break;
  }
  return newMetrics;
}
