import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactResizeDetector from 'react-resize-detector';
import LiveWidgetWrapper from '@components/canvas/LiveWidgetWrapper';
import I18n from '@components/I18n';
import { getWidgetData } from '@pages/GetWidgetDataService';
import { usePrevious } from '@core/hooks/usePrevious';
import { useSubject } from '@core/effects';
import { dashboardService } from '@core/canvas/DashboardService';
import { layoutService } from '@core/canvas/LayoutService';
import styles from './LiveDashboardPage.scss';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import DashboardFilters from './DashboardFilters';
import { debounce } from 'lodash';
import {
  buildDashboardFilters,
  getJobsStatus,
  updateDownloadStatusState,
} from './LiveDashboardPage.utils';
import { isEqual, omit, compact } from 'lodash';
import { cssVarsService } from '@core/CssVarsService';
import { getPermissionStatus } from '@core/ffAndPermissions';
import PreviewActionBar from './PreviewActionBar';
import { useSelector } from '@src/redux/useSelector';
import classnames from 'classnames';
import { dispatch, getState } from '@src/redux/store';
import { resetLiveDashboardWidgetsData } from '@src/redux/liveDashboardWidgetsData';
import NewLogo from '@components/NewLogo';
import { setisPDFRender, setisPDFRenderReady } from '@src/redux/viewport';
import DateTime from '@pages/MainPage/DashboardReport/DateTime/DateTime';
import Filters from '@pages/MainPage/DashboardReport/Filters/Filters';
import DashboardReportFooter from '@pages/MainPage/DashboardReport/DashboardReportFooter/DashboardReportFooter';
import moment from 'moment';
import { resetUpdateAssetWidgetsData } from '@src/redux/updateAssetWidgetsData';

const downloadPollingInterval = 20000;
let timeoutHandler: NodeJS.Timeout;
//indication if user drilldown dashboard or go back and forward browser buttons
let isNavigateInside = false;
let canDebounce = false;

enum dateFilterOrder {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

type dateFilterOrderString = keyof typeof dateFilterOrder;

function LiveDashboardPage() {
  const history = useHistory();
  const widgets = useSubject(layoutService.widgets$);
  const pageRef = useRef(null);
  const languageId = useSelector((state) => state.config.languageId);
  const [noDashboardAvailable, setNoDashboardAvailable] = useState(false);
  const [defaultDashboard, setDefaultDashboard] = useState(null);
  const [dashboardFilters, setDashboardFilters] = useState(null);
  const dashboardFiltersRef = useRef(dashboardFilters);
  dashboardFiltersRef.current = dashboardFilters;
  const [widgetsDownloadStatus, setWidgetsDownloadStatus] = useState({});
  const widgetsDownloadStatusRef = useRef(widgetsDownloadStatus);
  widgetsDownloadStatusRef.current = widgetsDownloadStatus;
  const [userFilters, setUserFilters] = useState(null);
  const [widgetFilters, setWidgetFilters] = useState([]);
  const widgetFiltersRef = useRef(widgetFilters);
  widgetFiltersRef.current = widgetFilters;
  const [pageWidth, setPageWidth] = useState(0);
  const { dashboardId } = useParams<any>();
  const location = useLocation();
  const prevLocationState = usePrevious(location.state);
  const organizationDetails = useSelector((state) => state.config.organizationDetails);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const busyCounter = useSelector((state) => state.config.busyCounter);
  const [urlSearchParams, setUrlSearchParams] = useState(new URLSearchParams(location.search));
  const isPdfRender =
    (!!urlSearchParams.get('dashboardHeight') && !!urlSearchParams.get('dashboardWidth')) ||
    useSelector((state) => state.viewport.isPdfRender);
  const [dashboardVisibleFilters, setDashboardVisibleFilters] = useState(
    urlSearchParams.get('dashboardVisibleFilters')?.replace('[', '')?.replace(']', '').split(',')
  );

  const [timeZone, setTimeZone] = useState(urlSearchParams.get('timeZone'));
  const [applyButtonClicked, setApplyButtonClicked] = useState(false);

  const [dashboardRefreshRate, setDashboardRefreshRate] = useState(null);
  const defaultRefreshRate = useSelector((state) =>
    dashboardRefreshRate
      ? dashboardRefreshRate
      : state.config.whiteLabelDetails.MBConf.dashboardRefreshRate.refreshRate
  );

  const currentLayout = getState().dashboardEditor.currentLayout;

  useEffect(() => {
    dispatch(setisPDFRender({ isPdfRender }));
  }, [isPdfRender]);

  useEffect(() => {
    setApplyButtonClicked(false);
  }, [dashboardFiltersRef.current]);

  const isPreview = useMemo(() => (location.state ? location.state.preview : null), [location]);
  const firstDashboardId = useMemo(() => dashboardId, []);

  const isUserFiltersWidgetVisible = useMemo(
    () => defaultDashboard?.filterWidgetVisible && defaultDashboard?.filters.some((f) => f.visible),
    [defaultDashboard]
  );

  const routeUrl = useCallback(
    (dashboardId) =>
      isPreview ? `/main/edit-dashboard/${dashboardId}/preview` : `/main/dashboard/${dashboardId}/`,
    [isPreview, dashboardId]
  );

  const prevWidgets: any = usePrevious(widgets);

  useEffect(() => {
    isNavigateInside = false;
    dispatch(resetUpdateAssetWidgetsData());
    if (
      !isPreview ||
      (location.state?.firstDashboardId &&
        location.state?.firstDashboardId !== dashboardId.toString())
    ) {
      dashboardService.dashboardIdType = 'ref';

      if (!organizationDetails || !organizationDetails.dashboardRefId) {
        setNoDashboardAvailable(true);
        return;
      }
    } else {
      dashboardService.dashboardIdType = 'id';
    }

    return () => {
      clearTimeout(timeoutHandler);
      dashboardService.firstDashboardId = undefined;
    };
  }, []);

  useEffect(() => {
    if (isPreview) {
      dashboardService.firstDashboardId = firstDashboardId;
    }
  }, [firstDashboardId]);

  useEffect(() => {
    if (languageId) {
      navigateDashboard(dashboardId, undefined, undefined, true, true);
    }
  }, [languageId]);

  const onResize = useCallback((width) => {
    setPageWidth(width);
    layoutService.generateLayout(width);
  }, []);

  /**
    when dashboardId on url change we call navigateDashboardOnUrlChange
    only if there is state(this means click on back or forward)
    and state has been change
     */
  useEffect(() => {
    if (
      dashboardId &&
      location.state &&
      prevLocationState &&
      Object.keys(prevLocationState).includes('dashboardFilters') &&
      !isEqual(omit(location.state, ['userFilters']), omit(prevLocationState, ['userFilters'])) &&
      !isNavigateInside
    ) {
      const {
        navigationFilters,
        tempWidgetFilters,
        dashboardFilters = {},
        userFilters = {},
      } = location.state || {};
      const tempDashboardFilters = { ...dashboardFilters, ...userFilters };
      navigateDashboardOnUrlChange(
        +dashboardId,
        navigationFilters,
        tempWidgetFilters,
        Object.keys(tempDashboardFilters).length && tempDashboardFilters
      );
    } else {
      isNavigateInside = false;
    }
  }, [dashboardId, location.state]);

  const handleRefreshData = (dashboardId: number, prevDashboardId: number, refreshRate: number) => {
    clearTimeout(timeoutHandler);

    if (prevDashboardId && prevDashboardId !== dashboardId) pageRef.current.scrollTop = 0;
    timeoutHandler = setTimeout(
      () => navigateDashboard(dashboardId, undefined, undefined, undefined, true, true),
      refreshRate * 60_000
    );
  };

  /**
    replace url with all filters
     */
  const navigateDashboardOnUrlChange = useCallback(
    (dashboardId, navigationFilters?, tempWidgetFilters?, dashboardFilters?) => {
      //id should be use only from preview and first dashboard navigation.
      if (firstDashboardId === dashboardId.toString() && isPreview) {
        dashboardService.dashboardIdType = 'id';
      }
      const { newWidgetFilters, newDashboardFilters } = buildDashboardFilters(
        navigationFilters,
        dashboardFilters,
        tempWidgetFilters
      ) as any;

      if (navigationFilters?.length) {
        setWidgetFilters(
          [...newWidgetFilters].map((w, index) => ({
            ...w,
            order: index,
          }))
        );
      } else {
        setWidgetFilters([]);
      }

      getDashboardById(dashboardId, newDashboardFilters);
      history.replace(routeUrl(dashboardId), {
        navigationFilters,
        tempWidgetFilters,
        dashboardFilters,
        preview: isPreview,
        firstDashboardId,
      });
    },
    [timeoutHandler, history, widgetFilters, isPreview, firstDashboardId]
  );

  const navigateDashboard = useCallback(
    async (
      dashboardId,
      tempNavigationFilters?,
      wFilters?,
      firstInit?,
      isGetDataHandler?,
      isRefresh = false,
      clearAssetIdFilter = false
    ) => {
      !isGetDataHandler && dispatch(resetLiveDashboardWidgetsData());

      dispatch(resetUpdateAssetWidgetsData());

      history.replace(history.location.pathname, {
        ...history.location.state,
        userFilters: dashboardFiltersRef?.current,
      });

      isNavigateInside = true;
      //id should be use only from preview and first dashboard navigation.
      if (firstDashboardId !== dashboardId) {
        dashboardService.dashboardIdType = 'ref';
      }
      const navigationFilters =
        firstInit || isRefresh ? history.location.state?.navigationFilters : tempNavigationFilters;
      const tempWidgetFilters =
        firstInit || isRefresh ? history.location.state?.tempWidgetFilters : wFilters;

      const { newWidgetFilters, newDashboardFilters } = buildDashboardFilters(
        navigationFilters,
        firstInit || isRefresh
          ? history.location.state?.dashboardFilters
          : dashboardFiltersRef?.current,
        tempWidgetFilters
      ) as any;

      await getDashboardById(dashboardId, newDashboardFilters);

      if (navigationFilters?.length && widgetFiltersRef?.current) {
        const checkIsAssetIdExistInWidgetFiltersRef = () => {
          for (let filterRef of widgetFiltersRef.current) {
            return newWidgetFilters.some((filter) =>
              filter.values.some((assetId) => filterRef.values.some((fRef) => fRef === assetId))
            );
          }
        };

        let isAssetIdExist = !!checkIsAssetIdExistInWidgetFiltersRef();

        let widgetFiltersWithoutAssetId = isAssetIdExist
          ? [...widgetFiltersRef.current].map((w, index) => ({
              ...w,
              order: index,
            }))
          : [...widgetFiltersRef.current, ...newWidgetFilters].map((w, index) => ({
              ...w,
              order: index,
            }));

        if (clearAssetIdFilter) {
          widgetFiltersWithoutAssetId = [...widgetFiltersWithoutAssetId].filter(
            (f) => f.valueId !== 1 || f.valueType !== 'ASSET_PROPERTY'
          );
        }

        setWidgetFilters(widgetFiltersWithoutAssetId);
      } else if (!widgetFiltersRef?.current || !widgetFiltersRef?.current.length) {
        setWidgetFilters([]);
      } else if (clearAssetIdFilter) {
        let widgetFiltersWithoutAssetId = widgetFiltersRef.current.filter(
          (f) => f.valueId !== 1 || f.valueType !== 'ASSET_PROPERTY'
        );

        setWidgetFilters(widgetFiltersWithoutAssetId);
      }

      /**
     make replace if it's the first init to avoid going back twice
     */
      if (!isRefresh) {
        const url = routeUrl(dashboardId);
        history[firstInit ? 'replace' : 'push'](url, {
          navigationFilters,
          tempWidgetFilters,
          dashboardFilters:
            firstInit && history.location.state?.dashboardFilters
              ? history.location.state?.dashboardFilters
              : dashboardFiltersRef?.current,
          preview: isPreview,
          firstDashboardId: history.location.state?.firstDashboardId
            ? history.location.state?.firstDashboardId
            : firstDashboardId,
        });
      }
    },
    [dashboardFilters, widgetFilters, timeoutHandler, isNavigateInside, isPreview, firstDashboardId]
  );

  const mergeFilters = (dashboardFilter: Array<any>, userFilter: Array<any>) => {
    if (!userFilter.length) return dashboardFilter;
    if (!dashboardFilter.length) return userFilter;

    const mergedFilters = dashboardFilter.filter((value) =>
      userFilter.map((f) => f.id).includes(value.id)
    );
    if (mergedFilters.length) return mergedFilters;

    return null;
  };

  const mergeGeoFilters = (dashboardFilter: Array<any>, userFilter: Array<any>) => {
    if (!userFilter.length) return dashboardFilter;
    if (!dashboardFilter.length) return userFilter;

    const mergedFilters = dashboardFilter.filter((value) =>
      userFilter.some((f) => f.googleId === value.googleId)
    );
    if (mergedFilters.length) return mergedFilters;

    return null;
  };

  const getDateFilterAsCustom = (dateFilter: { dateFilter; dateFilterFrom; dateFilterTo }) => {
    switch (dateFilter.dateFilter) {
      case 'YEAR':
        return {
          dateFilter: 'CUSTOM',
          dateFilterFrom: moment().subtract(1, 'years'),
          dateFilterTo: moment(),
        };
      case 'MONTH':
        return {
          dateFilter: 'CUSTOM',
          dateFilterFrom: moment().subtract(1, 'months'),
          dateFilterTo: moment(),
        };
      case 'WEEK':
        return {
          dateFilter: 'CUSTOM',
          dateFilterFrom: moment().subtract(1, 'weeks'),
          dateFilterTo: moment(),
        };
      case 'DAY':
        return {
          dateFilter: 'CUSTOM',
          dateFilterFrom: moment().subtract(1, 'days'),
          dateFilterTo: moment(),
        };
      case 'CUSTOM':
        return {
          dateFilter: 'CUSTOM',
          dateFilterFrom: moment(dateFilter.dateFilterFrom, 'YYYY-MM-DD'),
          dateFilterTo: moment(dateFilter.dateFilterTo, 'YYYY-MM-DD'),
        };
    }
  };

  const mergeDateFilters = (
    dashboardFilter: { dateFilter; dateFilterFrom; dateFilterTo },
    userFilter: { dateFilter; dateFilterFrom; dateFilterTo }
  ) => {
    if (dashboardFilter.dateFilter === 'CUSTOM' || userFilter.dateFilter === 'CUSTOM') {
      let dateFilter1 = getDateFilterAsCustom(dashboardFilter);
      let dateFilter2 = getDateFilterAsCustom(userFilter);

      return {
        dateFilter: 'CUSTOM',
        dateFilterFrom: moment
          .max(dateFilter1.dateFilterFrom, dateFilter2.dateFilterFrom)
          .format('YYYY-MM-DD'),
        dateFilterTo: moment
          .min(dateFilter1.dateFilterTo, dateFilter2.dateFilterTo)
          .format('YYYY-MM-DD'),
      };
    } else {
      const minDateFilter = Math.min(
        dateFilterOrder[dashboardFilter.dateFilter as dateFilterOrderString],
        dateFilterOrder[userFilter.dateFilter as dateFilterOrderString]
      );

      const filter = dateFilterOrder[minDateFilter];

      return {
        dateFilter: filter,
        dateFilterFrom: null,
        dateFilterTo: null,
      };
    }
  };

  const getDashboardById = (dashboardId, newDashboardFilters) => {
    const prevDashboardId = defaultDashboard ? defaultDashboard.refId : null;
    dashboardService
      .getDashboardById(
        dashboardId,
        {},
        newDashboardFilters,
        {
          onlyPublishedWidgets: true,
          deleteOldJobs: isPdfRender ? 'False' : null,
        },
        isPreview
      )
      .then((dashboard) => {
        setDefaultDashboard(dashboard);
        setWidgetsDownloadStatus({});

        if (!urlSearchParams?.get('dashboardFilters'))
          setDashboardFilters({
            assetTypesFilter: dashboard.assetTypesFilter,
            assetFilter: dashboard.assetFilter,
            dateFilter: dashboard.dateFilter,
            dateFilterFrom: dashboard.dateFilterFrom,
            dateFilterTo: dashboard.dateFilterTo,
            geoFilter: dashboard.geoFilter,
            organizationsFilter: dashboard.organizationsFilter,
          });
        else {
          const pdfDashboardFilters = JSON.parse(urlSearchParams.get('dashboardFilters'));
          const mergedDateFilter = mergeDateFilters(
            {
              dateFilter: dashboard.dateFilter,
              dateFilterFrom: dashboard.dateFilterFrom,
              dateFilterTo: dashboard.dateFilterTo,
            },
            {
              dateFilter: pdfDashboardFilters.dateFilter,
              dateFilterFrom: pdfDashboardFilters.dateFilterFrom,
              dateFilterTo: pdfDashboardFilters.dateFilterTo,
            }
          );

          setDashboardFilters({
            assetTypesFilter: mergeFilters(
              dashboard.assetTypesFilter,
              (pdfDashboardFilters.assetTypesFilter || []).map((f) => ({ id: f }))
            ),
            assetFilter: mergeFilters(
              dashboard.assetFilter,
              (pdfDashboardFilters.assetFilter || []).map((f) => ({ id: f }))
            ),
            dateFilter: mergedDateFilter.dateFilter,
            dateFilterFrom: mergedDateFilter.dateFilterFrom,
            dateFilterTo: mergedDateFilter.dateFilterTo,
            geoFilter: mergeGeoFilters(dashboard.geoFilter, pdfDashboardFilters.geoFilter || []),
            organizationsFilter: mergeFilters(
              dashboard.organizationsFilter,
              (pdfDashboardFilters.organizationsFilter || []).map((f) => ({ id: f }))
            ),
          });
        }

        if (dashboard?.states.some((state) => state.refreshRate)) {
          setDashboardRefreshRate(
            dashboard.states.find((state) => state.layoutStateType === 'DESKTOP').refreshRate
          );
        }

        const refreshRate = dashboard.states.find(
          (state) => state.layoutStateType === 'DESKTOP'
        ).refreshRate;

        if (refreshRate === 0) {
          clearTimeout(timeoutHandler);
        }

        layoutService.importWidgetsFromDashboard(dashboard);
        !isPreview &&
          refreshRate !== 0 &&
          handleRefreshData(
            dashboardId,
            prevDashboardId,
            refreshRate ? refreshRate : defaultRefreshRate
          );
        // Tells widgets to call getWidgetData when dashboard navigates to itself
        if (!prevDashboardId || prevDashboardId === dashboardId) {
          setUserFilters(new Date());
        }
      });
  };

  // Genarate layout with all the widgets when navigating to a new dashboad
  useEffect(() => {
    if (prevWidgets && !isEqual(omit(prevWidgets, 'position'), omit(widgets, 'position'))) {
      layoutService.generateLayout(pageWidth);
    }
  }, [widgets, pageWidth]);

  const getWidgetDataFromServer = useCallback(
    (widgetId, paging, options, widgetType, customization = null) => {
      return getWidgetData({
        widgetId,
        paging,
        dashboardFilters: {
          organizationsFilter: dashboardFilters.organizationsFilter
            ? dashboardFilters.organizationsFilter.map((f) => f.id)
            : null,
          assetTypesFilter: dashboardFilters.assetTypesFilter
            ? dashboardFilters.assetTypesFilter.map((f) => f.id)
            : null,
          assetFilter: dashboardFilters.assetFilter
            ? dashboardFilters.assetFilter.map((f) => f.id)
            : null,
          geoFilter: dashboardFilters.geoFilter || null,
          dateFilter: dashboardFilters.dateFilter,
          dateFilterFrom: dashboardFilters.dateFilterFrom,
          dateFilterTo: dashboardFilters.dateFilterTo,
        },
        widgetFilters,
        options,
        widgetType,
        customization,
      });
    },
    [dashboardFilters, widgetFilters]
  );

  const canvasWidth = useMemo(
    () => ({
      maxWidth: defaultDashboard
        ? isPreview
          ? defaultDashboard.states.find((state) => state.layoutStateType === currentLayout)
              .canvasWidthValue - cssVarsService.vars.sideBarWidth
          : defaultDashboard.canvasWidth.value - cssVarsService.vars.sideBarWidth
        : undefined,
    }),
    [defaultDashboard]
  );

  const widgetsStyle = useMemo(() => {
    const maxBottom = widgets.reduce((maxBottom, curr) => {
      const bottom = curr.position ? curr.position.bottom : 0;
      return Math.max(maxBottom, bottom);
    }, 0);

    return Object.assign({}, canvasWidth, { height: maxBottom + cssVarsService.vars.headerHeight });
  }, [canvasWidth, widgets]);

  const footerStyle = useMemo(() => {
    const maxBottom = widgets.reduce((maxBottom, curr) => {
      const bottom = curr.position ? curr.position.bottom : 0;
      return Math.max(maxBottom, bottom);
    }, 0);

    return {
      position: 'absolute',
      width: '100%',
      top: maxBottom + 20,
      zIndex: 8,
    } as React.CSSProperties;
  }, [canvasWidth, widgets]);

  const prevwidgetsDownloadStatus = usePrevious(widgetsDownloadStatus);
  useEffect(() => {
    if (
      prevwidgetsDownloadStatus &&
      !isEqual(prevwidgetsDownloadStatus, widgetsDownloadStatus) &&
      !Object.keys(prevwidgetsDownloadStatus).length
    ) {
      startPolling();
    }
  }, [prevwidgetsDownloadStatus, widgetsDownloadStatus]);

  const startPolling = async () => {
    //[1, 2, 4, 6] - getting job ids for first fetch
    const jobs = compact(
      Object.keys(widgetsDownloadStatus).map((key) => widgetsDownloadStatus[key].jobId)
    );

    if (jobs.length) {
      //first fetch
      const res = await getJobsStatus(jobs, widgetsDownloadStatus, defaultDashboard.id);
      if (res) {
        updateDownloadStatusState(res, jobs, setWidgetsDownloadStatus);
      }

      const interval = setInterval(async () => {
        //[1, 2, 4, 6] - getting job ids

        const jobs = compact(
          //setIntervat not get the current state. only the state "status" that was in first declartion
          //for that use ref to the state.
          Object.keys(widgetsDownloadStatusRef.current).map(
            (key) => widgetsDownloadStatusRef.current[key].jobId
          )
        );

        if (jobs.length) {
          const res = await getJobsStatus(
            jobs,
            widgetsDownloadStatusRef.current,
            defaultDashboard.id
          );
          if (res) {
            updateDownloadStatusState(res, jobs, setWidgetsDownloadStatus);
          }
        } else {
          clearInterval(interval);
        }
      }, downloadPollingInterval);
    }
  };

  const debouncer = useCallback(
    debounce(() => {
      //console.log("Dashboard render finished");
      dispatch(setisPDFRenderReady({ isPdfRenderReady: true }));
    }, 5000),
    []
  );

  useEffect(() => {
    if (isPdfRender) {
      if (busyCounter === 0 && canDebounce) {
        //console.log("Debouncing " + busyCounter);
        debouncer();
      } else {
        //console.log("Canceling " + busyCounter);
        debouncer.cancel();
        canDebounce = true;
      }
    }
  }, [busyCounter, isPdfRender]);

  return (
    getPermissionStatus('DASHBOARD', 'VIEW') && (
      <div className={styles.wrapper} ref={pageRef} id="liveDashboard">
        {noDashboardAvailable && (
          <div
            className={classnames(styles.noDashboardContainer, isMobileView && styles.mobileMode)}>
            <I18n className={styles.noDashboardTitle}>general.no-available-dashboards-welcome</I18n>
            <NewLogo isFree />
            <I18n element="div" noEllipsis className={styles.noDashboardText}>
              general.no-available-dashboards
            </I18n>
          </div>
        )}
        {/* Should not render widgets before dashboard filters have been updated */}
        {defaultDashboard && dashboardFilters && (
          <>
            <div className={styles.title} style={canvasWidth}>
              <div
                className={
                  currentLayout === 'DESKTOP'
                    ? styles.topSectionDesktop
                    : styles.topSectionMobileTablet
                }
                style={isPdfRender ? { justifyContent: 'center', fontSize: '1.5em' } : {}}>
                {defaultDashboard.name}
                {isPreview && <PreviewActionBar route={isPreview} dashboard={defaultDashboard} />}
              </div>
              {isPdfRender && (
                <div>
                  <div className={styles.topSection} style={{ justifyContent: 'center' }}>
                    <DateTime timeZone={timeZone} />
                  </div>
                </div>
              )}
              {isPdfRender && dashboardVisibleFilters.length > 0 && (
                <div>
                  <Filters
                    dashboardFilters={JSON.parse(urlSearchParams.get('dashboardFilters'))}
                    dashboardVisibleFilters={dashboardVisibleFilters}
                  />
                </div>
              )}
            </div>
            {!isPdfRender && isUserFiltersWidgetVisible && (
              <div className={styles.dashboardFilters} style={canvasWidth}>
                <DashboardFilters
                  filtersCfg={defaultDashboard.filters}
                  filters={dashboardFilters}
                  widgetFilters={widgetFilters}
                  setFilters={setDashboardFilters}
                  applyFilters={setUserFilters}
                  dashboardId={defaultDashboard.id}
                  setApplyButtonClicked={setApplyButtonClicked}
                  isPreview={isPreview}
                />
              </div>
            )}
            <div
              className={classnames(styles.widgets, isMobileView && styles.widgetsMobile)}
              style={widgetsStyle}>
              <ReactResizeDetector handleWidth onResize={onResize} />
              {widgets.map((w) => (
                <LiveWidgetWrapper
                  key={w.id}
                  widget={w}
                  navigateDashboard={navigateDashboard}
                  getWidgetData={getWidgetDataFromServer}
                  widgetFilters={widgetFilters}
                  userFilters={userFilters}
                  dashboardFilters={dashboardFilters}
                  setWidgetsDownloadStatus={setWidgetsDownloadStatus}
                  widgetsDownloadStatus={widgetsDownloadStatus}
                  startPolling={startPolling}
                  applyButtonClicked={applyButtonClicked}
                  setApplyButtonClicked={setApplyButtonClicked}
                  timezone={timeZone}
                />
              ))}
              {isPdfRender && (
                <div style={footerStyle}>
                  <DashboardReportFooter />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    )
  );
}

export default React.memo(LiveDashboardPage);
