import React from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import styles from './DataViewer.scss';
import { DataViewerProps } from './dataViewer.interface';
import Icon from '@components/Icon';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  textField: {
    '&.MuiFormControl-marginDense': {
      margin: '0',
    },
  },
});

function DataViewer(props: DataViewerProps) {
  const classes = useStyles(props);
  const { data, className, boldLabel, opacity, smallLabel, marginBottom, labelStyle = {} } = props;

  return (
    <div className={classNames(styles.dataViewer, className, opacity && styles.opacity)}>
      <div className={classNames(styles.wrapper, styles.leftColumn, 'ellipsis-overflow')}>
        {data.map((dataLabel, idx) => (
          <div
            key={idx}
            className={classNames(
              styles.dataLabel,
              boldLabel && styles.bold,
              smallLabel && styles.smallWidth,
              marginBottom && styles.marginBottom,
              'ellipsis-overflow'
            )}
            style={labelStyle}>
            <I18n addColon={dataLabel.label && dataLabel.label !== '' ? true : false}>
              {dataLabel.label}
            </I18n>
          </div>
        ))}
      </div>
      <div className={classNames(styles.wrapper, 'ellipsis-overflow')}>
        {data.map((dataValue, idx) => (
          <div
            key={idx}
            className={classNames(
              styles.dataValue,
              marginBottom && styles.marginBottom,
              dataValue.editMode && styles.editMode
            )}>
            {dataValue.editMode ? (
              <TextField
                id={styles.textFieldInput}
                className={classes.textField}
                variant="outlined"
                value={dataValue.value}
                margin="dense"
                onChange={(e) => {
                  if ((e.target.value && e.target.value !== '') || dataValue.allowEmpty) {
                    if (dataValue.maxLength) {
                      dataValue.setValue(
                        e.target.value?.length > dataValue.maxLength
                          ? dataValue.value
                          : e.target.value
                      );
                      return;
                    }
                    dataValue.setValue(e.target.value);
                  }
                }}
                style={dataValue.inputStyle}
                inputProps={{ autoComplete: 'off' }}
              />
            ) : (
              <div
                id={styles.textFieldStatic}
                className={'ellipsis-overflow'}
                style={dataValue.valueStyle}
                title={dataValue.value?.toString()}>
                {dataValue.value}
              </div>
            )}
            {dataValue.allowEdit ? (
              dataValue.editMode ? (
                <div className={styles.buttonsWrapper}>
                  <Icon
                    type="save"
                    className={classNames('pointer')}
                    onClick={() => {
                      dataValue.setEditMode(false);
                      dataValue.onSave(dataValue.onSaveArgs, dataValue.value);
                    }}
                  />
                  <Icon
                    type="remove"
                    className={classNames('pointer')}
                    onClick={(e) => {
                      dataValue.setEditMode(false);
                      dataValue.setValue(dataValue.initialValue);
                    }}
                  />
                </div>
              ) : (
                <div className={styles.buttonsWrapper}>
                  <Icon
                    type="edit"
                    color="var(--systemFont)"
                    className={classNames('pointer')}
                    onClick={() => {
                      dataValue.setEditMode(true);
                      data.forEach((element) => {
                        if (element.id && element.id !== dataValue.id) {
                          element.setEditMode && element.setEditMode(false);
                          if (element.setValue && element.value !== element.initialValue) {
                            element.setValue(element.initialValue);
                          }
                        }
                      });
                    }}
                  />
                </div>
              )
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DataViewer;
