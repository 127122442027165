import React, { useState, useEffect } from 'react';
import { MuiThemeProvider,InputBase } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import classNames from 'classnames';
import Icon from '@components/Icon';

import styles from './LocalSearch.scss';


const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      root: {
        width: '100%'
      },
      input: {
        fontSize: 12
      }
    }
  }
});

function LocalSearch(props) {
  const [searchText, setSearchText] = useState('');
  const { searchFunc, disableClick } = props;

  const handleKeyUp = e => {
    const text = e.target.value;
    // In case of click on Enter or Backspace
    if (e.key === 'Enter' || !text.length) searchFunc(text);
    // In case of typing text
    else setSearchText(text);
  };

  return (
    <div className={styles.search}>
      <MuiThemeProvider theme={theme}>
        <InputBase
          className={styles.searchInput}
          onKeyUp={handleKeyUp}
          onChange={e => {
            e.target.value.length > 2 && searchFunc(e.target.value);
          }}
        />
      </MuiThemeProvider>
      <Icon
        type="search"
        className={classNames(styles.searchIcon, !disableClick && 'pointer')}
        onClick={disableClick ? null : () => searchFunc(searchText)}
      />
    </div>
  );
}
export default LocalSearch;
