import Details from './Details';
import Assets from './Assets';
import Users from './Users';
import PLC from './PLC';
import Routers from './Routers';
import { getFlagStatus } from '@core/ffAndPermissions';
import Events from './Events';
import Variables from './Variables';

export const showTabMap = {
  UNITRONICS_MAIN: {
    UNITRONICS_MAIN: ['details', 'users', 'events'],
    UNITRONICS_CHANNEL: ['details', 'users', 'events'],
    MACHINE_BUILDER_CHANNEL: ['details', 'users', 'assets', 'routers', 'events'],
    END_CUSTOMER: ['details', 'users', 'assets', 'routers', 'events'],
  },
  UNITRONICS_CHANNEL: {
    UNITRONICS_CHANNEL: ['details', 'users', 'events'],
    MACHINE_BUILDER: ['details', 'users', 'events'],
  },
  MACHINE_BUILDER: {
    MACHINE_BUILDER_CHANNEL: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
    END_CUSTOMER: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
  },
  MACHINE_BUILDER_CHANNEL: {
    MACHINE_BUILDER_CHANNEL: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
    END_CUSTOMER: ['details', 'users', 'assets', 'routers', 'events', 'variables'],
  },
  END_CUSTOMER: { END_CUSTOMER: ['details', 'users', 'events', 'variables'] },
};

export const redirectToMap = [
  { url: 'details', featureFlag: 'details-tab' },
  { url: 'users', featureFlag: 'users-tab' },
];

const isDisplay = (tabName, userOrgType, selectedOrgType) =>
  !showTabMap[userOrgType][selectedOrgType] ||
  showTabMap[userOrgType][selectedOrgType].includes(tabName);

export const getTabs = (
  showAssetsTab,
  showUsersTab,
  showPlcTab,
  showRoutersTab,
  userOrgType,
  selectedOrgType,
  showEventsTab,
  isVariablesTabVisible
) => ({
  details: {
    component: Details,
    label: 'organizations-page.details',
    url: 'details',
    condition: () => isDisplay('details', userOrgType, selectedOrgType),
  },
  users: {
    component: Users,
    label: 'organizations-page.users',
    url: 'users',
    condition: () => showUsersTab && isDisplay('users', userOrgType, selectedOrgType),
  },
  assets: {
    component: Assets,
    label: 'organizations-page.assets',
    url: 'assets',
    condition: () => showAssetsTab && isDisplay('assets', userOrgType, selectedOrgType),
  },
  plc: {
    component: PLC,
    label: 'organizations-page.plc',
    url: 'plc',
    condition: () => showPlcTab && isDisplay('plc', userOrgType, selectedOrgType),
  },
  events: {
    component: Events,
    label: 'organizations-page.events',
    url: 'events',
    condition: () => showEventsTab && isDisplay('events', userOrgType, selectedOrgType),
  },
  routers: {
    component: Routers,
    label: 'organizations-page.routers',
    url: 'routers',
    condition: () => showRoutersTab && isDisplay('routers', userOrgType, selectedOrgType),
  },
  variables: {
    component: Variables,
    label: 'variables.caption',
    url: 'variables',
    condition: () => isVariablesTabVisible && isDisplay('variables', userOrgType, selectedOrgType),
  },
});

export const getRedirectTab = (userOrgType) => {
  return userOrgType === 'END_CUSTOMER'
    ? redirectToMap[1]
    : redirectToMap.find((r) => getFlagStatus(`organizations-management.${r.featureFlag}`));
};
