import { combineReducers, Reducer } from 'redux';
import login from './login';
import config from './config';
import dashboardEditor from './dashboardEditor';
import dashboardGallery from './dashboardGallery';
import plc from './deviceManagment/plc';
import routers from './deviceManagment/routers';
import asset from './deviceManagment/asset';
import assetType from './deviceManagment/assetType';
import editAsset from './deviceManagment/editAsset';
import editAssetType from './deviceManagment/editAssetType';
import organizations from './organizations';
import liveDashboardWidgetsDataReducer from './liveDashboardWidgetsData';
import viewport from './viewport';
import widgetModals from './widgetModals';
import imageCache from './imageCache';
import * as RI from './redux.interface';
import updateAssetWidgetsDataReducer from './updateAssetWidgetsData';
import accountsReducer from './accounts';
import clientReducer from './client';

const appReducer = combineReducers({
  login: login as Reducer<RI.LoginState, any>,
  config: config as Reducer<RI.ConfigState, any>,
  dashboardGallery: dashboardGallery as Reducer<RI.DashboardGalleryState, any>,
  dashboardEditor: dashboardEditor as Reducer<RI.DashboardEditorState, any>,
  asset: asset as Reducer<RI.AssetState, any>,
  assetType: assetType as Reducer<RI.AssetTypeState, any>,
  plc: plc as Reducer<RI.PLCState, any>,
  routers: routers as Reducer<RI.RoutersState, any>,
  editAsset: editAsset as Reducer<RI.EditAssetState, any>,
  editAssetType: editAssetType as Reducer<RI.EditAssetTypeState, any>,
  organizations: organizations as Reducer<RI.OrganizationsState, any>,
  viewport: viewport as Reducer<RI.ViewportState, any>,
  widgetModals: widgetModals as Reducer<RI.ModalState, any>,
  liveDashboardWidgetsData: liveDashboardWidgetsDataReducer as Reducer<RI.ModalState, any>,
  imageCache: imageCache as Reducer<any, any>,
  updateAssetWidgetsData: updateAssetWidgetsDataReducer as Reducer<any, any>,
  accountState: accountsReducer as Reducer<RI.AccountState, any>,
  clientState: clientReducer as Reducer<RI.ClientState, any>,
});

export const rootReducer = (state, action) => {
  if (action.type === 'login/SET_LOGGED_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};
