import React, { useState, useCallback, useRef, useEffect, useMemo, Context } from 'react';
import { TableProps, ColumnLayout, TableFilters } from './table.interface';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { makeStyles } from '@material-ui/core';
import TableFilter from './TableFilter';
import TableEmptyState from './TableEmptyState';

const useStyles = makeStyles(() => ({
  backDrop: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
}));

//TODO refactor!
const getNextPage = (event, getData, orderedBy, disabledLoad, filters) => {
  const { scrollHeight, offsetHeight, scrollTop } = event.target;
  const distanceFromBottom = scrollHeight - (scrollTop + offsetHeight);
  if (distanceFromBottom < 1 && !disabledLoad) {
    getData(false, orderedBy, filters);
  }
};

function Table(props: TableProps) {
  const {
    data,
    getData,
    colunmsCfg,
    disabledLoad,
    onRowClicked,
    onCellClicked,
    defaultOrdering,
    hasPagination = true,
    isWrapperScroll,
    getNextPageHolder,
    customization,
    resetScrollOnDataSet,
    clearOrder = false,
    showEmptyState = false,
    emptyStateMessage,
    eventTemplateId = null,
    widgetType = null,
    style = undefined,
    getIsRowClickable = null,
  } = props;
  const [orderedBy, setOrderedBy] = useState(defaultOrdering || { label: '', orderSign: '' });
  const [filterData, setFilterData] = useState<TableFilters>({});
  const tableBodyRef = useRef<HTMLDivElement>(null);

  const classes = useStyles(props);

  useEffect(() => {
    // When there is 'isWrapperScroll' the infinite scroll - getData mechanism
    // will be passed to the table wrapper for use
    if (isWrapperScroll) {
      getNextPageHolder['handleGetNextPage'] = getNextPage;
    }
  }, []);

  useEffect(() => {
    clearOrder && setOrderedBy({ label: '', orderSign: '' });
  }, [clearOrder]);

  const orderByField = useCallback(
    (header) => {
      let orderSign;
      orderedBy.label !== header || (orderedBy.label === header && orderedBy.orderSign === '-')
        ? (orderSign = '')
        : (orderSign = '-');

      setOrderedBy({ label: header, orderSign });
      getData(true, { label: header, orderSign }, filterData.selectedOptionsByField);
      tableBodyRef.current.scrollTop = 0;
    },
    [orderedBy, getData, filterData]
  );

  const onFilter = useCallback(
    (field, filtersIds) => {
      const newFilters = filterData?.selectedOptionsByField
        ? { ...filterData?.selectedOptionsByField, [field]: filtersIds }
        : { [field]: filtersIds };
      setFilterData({ selectedOptionsByField: newFilters });
      getData(true, orderedBy, newFilters);
      tableBodyRef.current.scrollTop = 0;
    },
    [orderedBy, getData, filterData]
  );

  const columnLayout: ColumnLayout = useMemo(() => {
    return colunmsCfg.reduce(
      (p, current) => {
        return {
          ...p,
          [current.type || 'responsive']: [...p[current.type || 'responsive'], current],
          fixedWidth: current.type ? p.fixedWidth + current.style.width : p.fixedWidth,
        };
      },
      { staticStart: [], responsive: [], staticEnd: [], fixedWidth: 0 }
    );
  }, [colunmsCfg]);

  useEffect(() => {
    //when adding more items to the top of the list, scroll to the position the user was before
    if (tableBodyRef.current.scrollTop && resetScrollOnDataSet) {
      tableBodyRef.current.scrollTop = 0;
    }
  }, [data]);

  return (
    columnLayout && (
      <>
        <TableHeader
          customization={customization}
          columnLayout={columnLayout}
          orderedBy={orderedBy}
          setFilterData={setFilterData}
          filterData={filterData}
          onSort={orderByField}
          style={style}
        />
        {showEmptyState && <TableEmptyState emptyStateMessage={emptyStateMessage} />}
        <TableBody
          data={data}
          customization={customization}
          columnLayout={columnLayout}
          onTableScroll={(e) =>
            getNextPage(e, getData, orderedBy, disabledLoad, filterData.selectedOptionsByField)
          }
          onRowClicked={onRowClicked}
          onCellClicked={onCellClicked}
          tableBodyRef={tableBodyRef}
          hasPagination={hasPagination}
          eventTemplateId={eventTemplateId}
          widgetType={widgetType}
          getIsRowClickable={getIsRowClickable}
        />
        <TableFilter onFilter={onFilter} {...filterData} />
      </>
    )
  );
}

export default Table;
