import ColumnsWidget from '@components/widgets/ColumnsWidget';
import {
  stepThreeDefaultValidation,
  buildMetricByOperation,
  stepFourLineAndColumnsValidation,
} from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';
import { omit } from 'lodash';
import { setMetrics } from './column.utils';

const similarWidgets = (scope) => {
  const sm: any[] = [{ widgetType: 'table' }, { widgetType: 'line' }];

  if (scope !== 'LAST_VALUE') {
    sm.push({
      widgetType: 'pie',
      getSideEffectCfg: () => ({
        condition: (widgetData) =>
          widgetData.metrics &&
          buildMetricByOperation(
            Array.isArray(widgetData.metrics) ? widgetData.metrics : [widgetData.metrics]
          ).length > 1,
        changedKey: 'metrics',
        selectCfg: {
          isMulti: false,
          getOptionLabel: (option) => option.displayName,
          getOptionValue: (option) => option.displayName,
          options: (widgetData) => buildMetricByOperation(widgetData.metrics),
        },
        generateSelectedData: (newData) => {
          return omit(newData, ['displayName']);
        },
        title: 'change-widget-data-modal.pie-similar-widget-message',
      }),
    });
  } else {
    sm.push({
      widgetType: 'pie',
      getSideEffectCfg: () => ({
        goToStep: 3,
        condition: (widgetData) => true,
      }),
    });
  }

  return sm;
};

export const columns: WidgetMapItem = {
  id: 'columns',
  name: 'Columns',
  component: ColumnsWidget,
  minWidth: 300,
  minHeight: 128,
  images: {
    canvas: '/assets/images/columns_empty_state_image.svg',
    thumbnail: 'columns',
  },
  editModalSize: {},
  settings: {
    dataChangesToHandle: ['groupBy', 'metrics', 'filters', 'sorts', 'calculations', 'localTags'],
    stepOneHidden: [],
    stepValidation: {
      3: (widgetData) =>
        stepThreeDefaultValidation(widgetData)('metrics') ||
        widgetData.metrics?.filter((f) => !f.hide).length < 1,
      4: (widgetData) => stepFourLineAndColumnsValidation(widgetData),
    },
    setDefaultDataByStep: {
      2: (scope, setWidgetData, widgetData) =>
        setWidgetData((prevState) => ({
          ...prevState,
          exportFormats: ['CSV'],
        })),
      3: (scope, setWidgetData, widgetData) => {
        setMetrics(setWidgetData, widgetData);
      },
    },
    dragAndDropRules: {
      maxGrouping: (scope) => 2,
      groupingDataValidation: (scope, draggableItem) => {
        if (
          scope === 'AGGREGATED_LAST_VALUE' &&
          (draggableItem?.id === 7 || draggableItem?.valueId === 7) &&
          (draggableItem?.tagType === 'ASSET_PROPERTY' ||
            draggableItem?.valueType === 'ASSET_PROPERTY')
        ) {
          return 'noTimeStampOnAggregateByLastValue';
        }
        return null;
      },
      maxMetrics: null,
      metricsDataValidation: (scope, draggableItemType) => {
        if (
          scope === 'LAST_VALUE' &&
          draggableItemType !== 'numberType' &&
          draggableItemType !== 'floatType'
        ) {
          return 'nonNumerical';
        }
        return null;
      },
    },
    showPreviewData: ['metrics', 'groupBy'],
    enableExportCsv: () => true,
    disabledScope: ['RAW_DATA'],
    similarWidgets,
    languageKeys: ['columnsDict.*', 'yAxesDict.*', 'subtitle_text'],
    customizationServerChanges: (customization) => ({
      ...customization,
      columnsDict:
        customization.columns &&
        customization.columns.reduce(
          (result, column) =>
            column.operation
              ? {
                  ...result,
                  [`${column.valueType?.toLowerCase()}.${
                    column.id
                  }.${column.operation?.toLowerCase()}`]: column.displayName,
                }
              : {
                  ...result,
                  [`${column.valueType?.toLowerCase()}.${column.id}`]: column.displayName,
                },
          {}
        ),
      yAxesDict: customization.yAxes.reduce(
        (result, yAxis) =>
          yAxis.operation
            ? {
                ...result,
                [`${yAxis.valueType?.toLowerCase()}.${yAxis.id}.${yAxis.operation.toLowerCase()}`]:
                  yAxis.displayName,
              }
            : {
                ...result,
                [`${yAxis.valueType?.toLowerCase()}.${yAxis.id}`]: yAxis.displayName,
              },
        {}
      ),
    }),
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) =>
      widgetData.groupBy.find(
        (gb) => gb.valueType === 'ASSET_PROPERTY' && (gb.valueId === 1 || gb.valueId === 8)
      ) || widgetData.scope === 'LAST_VALUE', // id 1 is Asset ID, id 8 is Asset Type or scope is last value
  },
};
