import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Button from '@components/Button';
import { ModalComponentProps } from '@core/modals/modals.interface';
import I18n from '@components/I18n';
import styles from './EditUserModal.scss';
import { UserData } from '@pages/OrganizationsPage/Users/UsersTable/usersTable.interface';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import classNames from 'classnames';
import Icon from '@components/Icon';
import * as Yup from 'yup';
import { colorMap } from '@core/statusColorMap';
import {
  getFieldsCfg,
  getUser,
  onSubmit,
  onChangeStatus,
  resetPassword,
  deleteUser,
  resendUserInvitaion,
} from './EditUserModal.utils';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { useSelector } from '@src/redux/useSelector';
import { httpService } from '@core/http/HttpService';
import { i18nService } from '@core/i18n/I18nService';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import usersRolesMap from '@pages/OrganizationsPage/Users/UsersRolesMap';

const getToolbarsBtns = (user, hasPermission, setUser, dismiss, login) => {
  const isNotMe = login?.user.pk != user.user_id;
  const resetBtn = (
    <div
      className={classNames(
        user.status !== 'PRE_ACTIVATED' && hasPermission ? 'pointer' : styles.disabledStatusBtns,
        styles.btnGroup
      )}
      onClick={() => resetPassword(user)}>
      <Icon className={styles.iconBtn} type="resetPassword"></Icon>
      <I18n className={styles.textBtn}>edit-user-modal.reset-password</I18n>
    </div>
  );
  const supsendBtn = (
    <div
      className={classNames(
        user.status !== 'PRE_ACTIVATED' && hasPermission && isNotMe
          ? 'pointer'
          : styles.disabledStatusBtns,
        styles.btnGroup
      )}
      onClick={() => onChangeStatus('suspendBtn', user, setUser)}>
      <Icon
        className={styles.iconBtn}
        type={user.status === 'SUSPENDED' ? 'activateOrg' : 'suspendOrg'}></Icon>
      <I18n className={styles.textBtn}>{`enum.${
        user.status === 'SUSPENDED' ? 'ACTIVATE' : 'SUSPEND'
      }`}</I18n>
    </div>
  );
  const archiveBtn = (
    <div
      className={classNames(
        user.status !== 'PRE_ACTIVATED' && hasPermission && isNotMe
          ? 'pointer'
          : styles.disabledStatusBtns,
        styles.btnGroup
      )}
      onClick={() => onChangeStatus('archiveBtn', user, setUser)}>
      <Icon
        className={styles.iconBtn}
        type={user.status === 'ARCHIVED' ? 'activateOrg' : 'archive'}></Icon>
      <I18n className={styles.textBtn}>{`enum.${
        user.status === 'ARCHIVED' ? 'ACTIVATE' : 'ARCHIVE'
      }`}</I18n>
    </div>
  );

  const resendInvitationBtn = (
    <div
      className={classNames(
        hasPermission && user.status === 'PRE_ACTIVATED' ? 'pointer' : styles.disabledStatusBtns,
        styles.btnGroup
      )}
      onClick={() => resendUserInvitaion(user)}>
      <Icon className={styles.iconBtn} type="resendInvitation"></Icon>
      <I18n className={styles.textBtn}>edit-user-modal.resend-invitation</I18n>
    </div>
  );

  return (
    <div className={styles.toolbarsBtns}>
      {resendInvitationBtn}
      {resetBtn}
      {user.status !== 'LOCKED' && (
        <>
          {supsendBtn} {archiveBtn}
        </>
      )}
      <div
        className={classNames(
          hasPermission && !user.mainContact && isNotMe ? 'pointer' : styles.disabledStatusBtns,
          styles.btnGroup
        )}
        onClick={() => !user.mainContact && deleteUser(user, dismiss)}>
        <Icon className={styles.iconBtn} type="deleteUser" color="var(--systemFont)"></Icon>
        <I18n className={styles.textBtn}>edit-user-modal.delete-user</I18n>
      </div>
    </div>
  );
};

function EditUserModal(props: ModalComponentProps) {
  const { dismiss, args } = props;
  const selectedMBId = useSelector((state) => state.organizations.selectedMBId);
  const languageId = useSelector((state) => state.config.languageId);
  const roles = useSelector((state) => state.config.roles);
  const userInfo = args.rowData as UserData;
  const [editMode, setEditMode] = useState(false);
  const [dashboardOptions, setDashboardOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [adminRoleIds, setAdminRoleIds] = useState([]);
  const [emailValidation, setEmailValidation] = useState({
    email: '',
    message: '',
    isValid: true,
  });
  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);
  const isUserMB = useMemo(() => organizationTypeMap[userOrgType] === 3, [userOrgType]);

  const allowDefaultDashboard = useMemo(
    () =>
      roles.find(
        (role) =>
          role === 'Machine Builder Admin' || role === 'Machine Builder Organization Manager'
      ),
    [roles]
  );
  const [user, setUser] = useState(null);
  const close = useCallback(() => dismiss(user), [dismiss, user]);
  const login = useSelector((state) => state.login);

  const getAllPublishDashboard = async () => {
    const { results: publishedDashboards }: any = await httpService.api({
      type: 'getAllPublishDashboard',
      urlParams: { p: 1, ps: 99999, organizationId: selectedMBId || undefined },
    });
    setDashboardOptions(() => [
      { value: null, label: i18nService.translate('my-details.organizations-default') },
      ...publishedDashboards
        .map((option) => ({ value: option.refId, label: option.name }))
        .sort((a, b) => (a.label > b.label ? 1 : -1)),
    ]);
  };

  useEffect(() => {
    if (selectedMBId && allowDefaultDashboard && userInfo?.status !== 'PRE_ACTIVATED') {
      getAllPublishDashboard();
    }
  }, [selectedMBId]);

  useEffect(() => {
    getUser(setRolesOptions, setAdminRoleIds, userInfo, setUser, languageId, setEmailValidation);
  }, []);

  const save = (values: any) => {
    onSubmit(
      emailValidation,
      {
        ...values,
        dashboardRefId: values.dashboardRefId
          ? Number.isInteger(values.dashboardRefId)
            ? values.dashboardRefId
            : values.dashboardRefId?.value
            ? values.dashboardRefId?.value
            : null
          : null,
      },
      user,
      setEmailValidation,
      setUser,
      languageId,
      setEditMode
    );
  };

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  const validate = (values) =>
    [...fieldsCfg].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );

  const hasPermission = useMemo(() => getPermissionStatus('ORGANIZATION', 'EDIT'), []);

  const isRoleOptionDisabled = (option) =>
    userInfo.mainContact && adminRoleIds.includes(option.value);

  const fieldsCfg = getFieldsCfg(
    emailValidation,
    setEmailValidation,
    dashboardOptions,
    user,
    rolesOptions,
    isRoleOptionDisabled,
    allowDefaultDashboard,
    isUserMB
  );

  return (
    user && (
      <div className={styles.wrapper}>
        <div className={styles.modalHeader}>
          <span>
            <I18n>{user.title === 'NONE' ? '' : `enum.${user.title}`}</I18n>
            {`${user.title === 'NONE' ? '' : ' '} ${user.firstName} ${user.lastName}`}
          </span>
          <Icon type="close" onClick={close} className={'pointer'}></Icon>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.modalToolbar}>
            <div className={styles.statusWarpper}>
              <div className={styles.status}>
                <I18n>edit-user-modal.status</I18n>:
              </div>
              <I18n
                element="div"
                style={{
                  color: colorMap[user.status],
                  maxWidth: '160px',
                }}>{`user-status.${user.status}`}</I18n>
            </div>
            {getToolbarsBtns(user, hasPermission, setUser, dismiss, login)}
          </div>
          <div className={styles.toolBarLine}></div>
          <div className={styles.content}>
            <Formik
              enableReinitialize
              initialValues={{ ...user, enable: editMode }}
              validationSchema={validationSchema([...fieldsCfg])}
              validate={validate}
              onSubmit={save}>
              {({ isValid, isSubmitting, values, errors, setFieldValue }) => {
                return (
                  <FormikForm className={styles.form}>
                    {!editMode && user?.status && user?.status !== 'PRE_ACTIVATED' && (
                      <Icon
                        type="edit"
                        color="var(--systemFont)"
                        className={classNames('pointer', styles.editBtn)}
                        onClick={() => setEditMode(!editMode)}
                        disabled={!hasPermission}
                      />
                    )}
                    <div
                      className={classNames(
                        styles.details,
                        editMode || user?.status === 'PRE_ACTIVATED' ? styles.paddingTop : undefined
                      )}>
                      <div className={styles.fields}>
                        {fieldsCfg.map((field, idx) => {
                          if (field?.visible) {
                            return (
                              <div key={idx} className={styles.field}>
                                <FormikField
                                  editMode={editMode}
                                  {...field}
                                  setFieldValue={setFieldValue}
                                  nullSubstitutionValue={
                                    field.name === 'dashboardRefId' && !values.dashboardRefId
                                      ? i18nService.translate('my-details.organizations-default')
                                      : null
                                  }
                                  values={values}
                                  showVertificationStatus={field.name === 'mobile'}
                                  mobileVerified={user?.mobileVerified}></FormikField>
                                {field.name === 'email' && user.pendingEmail && (
                                  <div className={styles.pendingEmail}>
                                    <I18n>edit-user-modal.pending-email</I18n>
                                    <span className={styles.pendingEmailText}>
                                      {values.pendingEmail}
                                    </span>
                                  </div>
                                )}
                              </div>
                            );
                          }
                        })}
                      </div>
                    </div>

                    <div className={styles.footerButtons}>
                      {editMode ? (
                        <>
                          <Button
                            mode="cancel"
                            styles={{ width: 92, marginRight: 13 }}
                            onClick={() => setEditMode(false)}>
                            <I18n>general.cancel</I18n>
                          </Button>
                          <Button styles={{ width: 92 }} type="submit" disabled={!isValid}>
                            <I18n>general.save</I18n>
                          </Button>
                        </>
                      ) : (
                        <Button styles={{ width: 92 }} onClick={close}>
                          <I18n>general.close</I18n>
                        </Button>
                      )}
                    </div>
                  </FormikForm>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    )
  );
}

export default EditUserModal;
