import { AppRoute } from './MainPage.interface';
import CreateWidgetPage from '@pages/CreateWidgetPage';
import OrganizationsPage from '@pages/OrganizationsPage';
import DashboardGalleryPage from '@pages/DashboardGalleryPage';
import DeviceManagementPage from '@pages/DeviceManagment/DeviceManagementPage';
import DashboardEditorPage from '@pages/DashboardEditorPage';
import LiveDashboardPage from '@pages/LiveDashboardPage';
import MyAccountPage from '@pages/MyAccountPage';
import { dispatch } from '@src/redux/store';
import { httpService } from '@core/http/HttpService';
import {
  setLanguage,
  setConfig,
  setApexLangChange,
  setfileForDownload,
  setUnreadNotifications,
} from '@src/redux/config';
import { getFlagStatus } from '@core/ffAndPermissions';
import { i18nService } from '@core/i18n/I18nService';
import { modalService } from '@core/modals/ModalService';
import EventsManagementPage from '@pages/EventsManagementPage/EventsManagementPage';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { widgetMap } from '@core/canvas/widgetMap';
import { updateDontShowAgain } from '@core/utils';
import { saveAs } from 'file-saver';

export const routeMap: Array<AppRoute> = [
  {
    isExact: false,
    path: '/main/dashboard/:dashboardId/',
    component: LiveDashboardPage,
    isMobileAvailable: true,
    featureFlag: 'live-dashboard-icon',
    isUniAdminLiveDashboardAvailable: true,
    isAvailableForUnitronicsAdmin: false,
  },
  {
    isExact: true,
    path: '/main/edit-dashboard/:dashboardId/create-widget/',
    component: CreateWidgetPage,
    isMobileAvailable: false,
    featureFlag: 'dashboard-editor-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: false,
  },
  {
    isExact: true,
    path: '/main/edit-dashboard/:dashboardId/create-widget/:widgetId',
    component: CreateWidgetPage,
    isMobileAvailable: false,
    featureFlag: 'dashboard-editor-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    isExact: true,
    path: '/main/edit-dashboard/:dashboardId/edit-widget/:widgetId',
    component: CreateWidgetPage,
    isMobileAvailable: false,
    featureFlag: 'dashboard-editor-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    isExact: true,
    path: '/main/edit-dashboard',
    component: DashboardGalleryPage,
    isMobileAvailable: false,
    featureFlag: 'dashboard-editor-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: false,
  },
  {
    isExact: true,
    path: '/main/edit-dashboard/:id',
    component: DashboardEditorPage,
    isMobileAvailable: false,
    featureFlag: 'dashboard-editor-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    isExact: false,
    path: '/main/device-management',
    component: DeviceManagementPage,
    isMobileAvailable: false,
    featureFlag: 'device-management-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    isExact: false,
    path: '/main/events-management',
    component: EventsManagementPage,
    isMobileAvailable: false,
    featureFlag: 'events-management-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    isExact: false,
    path: '/main/edit-dashboard/:dashboardId/preview',
    component: LiveDashboardPage,
    isMobileAvailable: false,
    featureFlag: 'dashboard-editor-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    isExact: false,
    path: '/main/my-account',
    component: MyAccountPage,
    isMobileAvailable: false,
    featureFlag: 'my-account-management-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
  {
    isExact: false,
    path: '/main/organizations',
    component: OrganizationsPage,
    isMobileAvailable: false,
    featureFlag: 'organizations-management-icon',
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
  },
];

export const getRedirectToMap = (dashboardId) => [
  {
    path: `/main/dashboard/${dashboardId}/`,
    featureFlag: 'live-dashboard-icon',
    isMobileAvailable: true,
    isUniAdminLiveDashboardAvailable: true,
    isAvailableForUnitronicsAdmin: false,
    condition: () => dashboardId || dashboardId === 0,
  },
  {
    path: '/main/organizations',
    featureFlag: 'organizations-management-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
    condition: () => true,
  },
  {
    path: '/main/my-account',
    featureFlag: 'my-account-management-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
    condition: () => true,
  },
  {
    path: '/main/edit-dashboard',
    featureFlag: 'dashboard-editor-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: false,
    condition: () => true,
  },
  {
    path: '/main/device-management',
    featureFlag: 'device-management-icon',
    isMobileAvailable: false,
    isUniAdminLiveDashboardAvailable: false,
    isAvailableForUnitronicsAdmin: true,
    condition: () => true,
  },
];

export const getUserConfig = async (isLoggedIn, setIsLoading?, otherOrganizationId?, isIframe?) => {
  if (isLoggedIn) {
    const userConfig: any = await httpService.api({
      type: otherOrganizationId ? 'getUserConfigForUnitronicsAdmin' : 'getUserConfig',
      urlParams: { organizationId: otherOrganizationId },
    });

    const canShowSmsVertificationModal =
      userConfig?.isUnAssigned == undefined ? false : !userConfig?.isUnAssigned;

    userConfig && dispatch(setConfig({ ...userConfig, languageId: undefined }));
    if (userConfig.languageId) {
      try {
        dispatch(setApexLangChange(true));
        const dictionary = await httpService.api({
          type: otherOrganizationId ? 'getLanguageByIdForUnitronicsAdmin' : 'getLanguageById',
          urlParams: { languageId: userConfig.languageId, organizationId: otherOrganizationId },
        });

        dictionary && i18nService.changeApexLang(dictionary);
        dictionary &&
          dispatch(
            setLanguage({ dictionary, languageId: userConfig.languageId, apexLangChange: false })
          );
      } catch {}
    }
    setIsLoading && setIsLoading(false);

    const isUnitronicsAdminUser = organizationTypeMap[userConfig.organizationDetails?.type] === 1;
    const isUnitronicsChannel = organizationTypeMap[userConfig.organizationDetails?.type] === 2;
    if (!userConfig.mobileVerified && !isIframe && !isUnitronicsAdminUser && !isUnitronicsChannel) {
      canShowSmsVertificationModal &&
        userConfig.showVerification &&
        setUserSmsVertification(userConfig);
    }
  }
};

export const setUserSmsVertification = async (userConfig) => {
  const vertificationNumber = await modalService.openModal('smsVertificationModal', { userConfig });

  if (vertificationNumber) {
    await modalService.openModal('mobileNumberValidationModal', { vertificationNumber });
  }
};

/**
 * @param item is route/menuItem from the side menu
 */
export const isAllow = (
  item,
  isMobile,
  isTablet,
  isUnitronicsAdminLiveDashboardSession,
  isUnitronicsAdminUser
) => {
  const isAllowByFF = !item.featureFlag || getFlagStatus(`side-menu.${item.featureFlag}`);
  return (
    isAllowByFF &&
    (isMobile || isTablet ? item.isMobileAvailable : true) &&
    (isUnitronicsAdminLiveDashboardSession
      ? item.isUniAdminLiveDashboardAvailable
      : isUnitronicsAdminUser
      ? item.isAvailableForUnitronicsAdmin
      : true)
  );
};

export function getNoCacheEmptyImageUrl(widgetType: string) {
  return `${widgetMap[widgetType]?.images.canvas}?${new Date().getTime()}`;
}

export function openAppIsAvailableModal(isMobileAppInstalled: boolean) {
  modalService
    .openModal('confirm', {
      headerText: 'mobile-app.app-available-pop-up.header',
      text: isMobileAppInstalled
        ? 'mobile-app.app-available-pop-up.text.app-installed'
        : 'mobile-app.app-available-pop-up.text.app-not-installed',
      confirmText: isMobileAppInstalled
        ? 'mobile-app.app-available-pop-up.open-in-unicloud-app'
        : 'mobile-app.app-available-pop-up.go-to-google-play',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      allowDontShowAgain: true,
      updateDontShowAgainOnCancel: true,
      mode: 'smallFont',
    })
    .then((confirm) => {
      if (confirm?.dontShowAgain) {
        updateDontShowAgain('PROFILE', 'SUGGEST_MOBILE_APP');
      }
      if (confirm?.res) {
        if (isMobileAppInstalled) {
          window.open('app://com.unitronics.unicloud');
        } else {
          window.open('https://play.google.com/store/apps/details?id=com.unitronics.unicloud');
        }
      }
    });
}

export async function getNotifications(
  unreadOnly = false,
  setNotifications,
  page = 1,
  notifications = { allCount: 0, count: 0, next: null, previous: '', results: [] }
) {
  const res = await httpService.api({
    type: 'getNotifications',
    query: { unreadOnly, p: page, ps: 100 },
  });

  if (page === 1) {
    setNotifications(res);
  } else {
    const updatedNotifications = [];
    updatedNotifications.push(...notifications.results);
    updatedNotifications.push(...res['results']);
    setNotifications({ ...notifications, results: updatedNotifications });
  }
}

export async function downloadFileFromLink(fileForDownload, retries = 3) {
  try {
    const res: { url: string; fileIsReady?: boolean } = await httpService.api({
      type: 'getFileForDownload',
      urlParams: { fileId: fileForDownload },
    });
    if (res) {
      if (res.fileIsReady) {
        console.log('URL: ', res.url);
        const extension = geFileExtensionFromUrl(res.url);
        var xhr = new XMLHttpRequest();
        xhr.open('GET', res.url);
        xhr.responseType = 'blob';
        xhr.onload = function () {
          saveAs(xhr.response, extension ? `event.${extension}` : 'event');
        };
        xhr.onerror = function () {
          console.error('could not download file');
        };
        xhr.send();
        dispatch(setfileForDownload(null));
      } else {
        retries--;
        if (retries >= 0) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          downloadFileFromLink(retries);
        } else throw 'Unable to download file';
      }
    }
  } catch (e) {
    showAlertFileNotFound();
  }
}

function geFileExtensionFromUrl(url) {
  return url.split(/[#?]/)[0].split('.').pop().trim();
}

function showAlertFileNotFound() {
  modalService.openModal('alert', {
    iconType: 'attention_image',
    btnText: 'general.confirm',
    showCloseBtn: true,
    headerText: 'fownload-file-alert-header',
  });
}

export function updateUnreadNotifications(accountId: number, unreadNotifications: any) {
  accountId == unreadNotifications?.data?.accountId &&
    dispatch(setUnreadNotifications(unreadNotifications.data.unreadMessages));
}
