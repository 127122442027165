import React, { useState, useEffect, useCallback } from 'react';
import { TableColunm } from '@components/Table/table.interface';
import Table from '@components/Table';
import { useSelector } from '@redux/useSelector';
import AlarmsTime from './AlarmsTime';
import AlarmsName from './AlarmsName';
import AlarmsSeverity from './AlarmsSeverity';
import AlarmsDescription from './AlarmsDescription';
import AlarmsSuggestedResolution from './AlarmsSuggestedResolution';
import styles from './AlarmsTable.scss';
import { editAssetService } from '@modals/EditAssetModal/EditAssetService';
import { httpService } from '@core/http/HttpService';
import { Alarm, AlarmData } from '@modals/EditAssetModal/EditAssetModal.interface';
import { Sort } from '@material-ui/icons';
import { dynamicSort } from '@core/utils';

const colunmsCfg: Array<TableColunm> = [
  {
    Component: AlarmsTime,
    label: 'asset-alarms.time',
    style: { width: '20%', minWidth: '20%', maxWidth: '20%' },
    field: 'createdAt',
    isSortable: true,
  },
  {
    Component: AlarmsName,
    label: 'asset-alarms.name',
    style: { width: '15%', minWidth: '15%', maxWidth: '15%' },
    field: 'name',
    isSortable: true,
  },
  {
    Component: AlarmsSeverity,
    label: 'asset-alarms.severity',
    style: { width: '15%', minWidth: '15%', maxWidth: '15%' },
    field: 'severity',
    isSortable: true,
  },
  {
    Component: AlarmsDescription,
    label: 'asset-alarms.description',
    style: { width: '25%', minWidth: '25%', maxWidth: '25%' },
  },
  {
    Component: AlarmsSuggestedResolution,
    label: 'asset-alarms.suggested-resolution',
    style: { width: '20%', minWidth: '20%', maxWidth: '20%' },
  },
];

function AlarmsTable() {
  const [alarms, setAlarms] = useState([]);
  const assetId = useSelector((state) => state.editAsset.general.assetId);
  const status = useSelector((state) => state.editAsset.staticData.status);
  const [_order, setOrder] = useState(null);

  const orderData = (firstInit: boolean, order?: any): Alarm[] => {
    let converter = null;

    if (order.label === 'severity') converter = severityToNumberConverter;

    return alarms.sort(dynamicSort(order.label, order.orderSign, converter));
  };

  const severityToNumberConverter = (severity: string): number => {
    const alarmSeverity = ['OK', 'WARN', 'MINOR', 'MAJOR', 'CRITICAL'];
    let res = alarmSeverity.indexOf(severity);
    if (res === -1) console.log('Unknown alarm severity: ' + severity);

    return res;
  };

  const orderedData = _order ? orderData(false, _order) : alarms;

  useEffect(() => {
    if (status === 'DISCONNECTED' || status === 'ARCHIVED') {
      editAssetService.clearDataInterval('alarms');
      getAlarms(assetId, false);
      return;
    }

    editAssetService.clearDataInterval('alarms');
    editAssetService.initDataInterval(
      assetId,
      getAlarms,
      'alarms',
      editAssetService.INTERVAL_TIMER
    );

    return () => editAssetService.clearDataInterval('alarms');
  }, [status]);

  const getAlarms = useCallback((assetId: string, disableBI = true) => {
    httpService
      .api({
        type: 'getAlarms',
        urlParams: { assetId },
        disableBI,
      })
      .then((res: AlarmData) => {
        setAlarms(res.alarms);
      });
  }, []);

  return (
    <div className={styles.tableWrapper}>
      <Table
        hasPagination={false}
        data={orderedData}
        getData={(firstInit, order) => {
          order && setOrder(order);
          orderData(firstInit, order);
        }}
        colunmsCfg={colunmsCfg}
        className={styles.tableBody}
      />
    </div>
  );
}

export default AlarmsTable;
