import { compact } from 'lodash';
import { i18nService } from '@core/i18n/I18nService';
import { tagFormatMap, variableFormatMap } from '@core/mapsAndDefinitions';

export const buildOptions = (option, type, scope) => {
  const tagFormat =
    type === 'VARIABLE'
      ? variableFormatMap[option.valueType]
      : type === 'TAG'
      ? tagFormatMap[option.format]
      : tagFormatMap[option.type];
  return {
    id: option.id,
    tagType: type,
    type: tagFormat,
    name: option.name,
    isDisabled:
      type == 'ALARM_INFO'
        ? [8, 6].includes(option.id)
        : (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') &&
          type !== 'VARIABLE' &&
          (tagFormat === 'numberType' || tagFormat === 'floatType'),
  };
};

export const getAvailableData = (scope, data, type) => {
  const metricsOperations = [];
  let tempData = data;
  // only if scope is aggregate - display mertics by operation
  if (scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE') {
    tempData = data.map((d) => {
      const isNumber = ['numberType', 'floatType'].includes(d.type);
      (isNumber
        ? type === 'alarms' || scope === 'AGGREGATED_LAST_VALUE'
          ? ['AVERAGE', 'COUNT', 'MAX', 'MIN', 'SUM']
          : ['AVERAGE', 'COUNT', 'DELTA', 'FIRST', 'LAST', 'MAX', 'MIN', 'SUM']
        : ['COUNT']
      ).forEach((operation) => {
        metricsOperations.push({
          id: d.id,
          tagType: d.tagType,
          operation,
          name: d.name,
          isDisabled: false,
          type: d.type !== 'numberType' && d.type !== 'floatType' ? 'numberType' : d.type,
        });
      });
      return !d.isDisabled && d;
    });
  } else {
    tempData = data.map((d) => !d.isDisabled && d);
  }
  return compact(tempData.concat(metricsOperations));
};

const filteringAvailableDataSwitch = (
  source,
  newIdx,
  _filters,
  prevIdx,
  _availableData,
  setAvailableData,
  draggableId,
  setWidgetData
) => {
  switch (source.droppableId) {
    case 'filteringAvailableData':
      if (newIdx === prevIdx) {
        return; // No need to reorder.
      }
      // Reorder.
      const tagToMove = _availableData[prevIdx];
      _availableData.splice(prevIdx, 1);
      _availableData.splice(newIdx, 0, tagToMove);
      return setAvailableData([..._availableData]);
    case 'filteringFilters':
      // remove from filters
      return setWidgetData((prevState) => ({
        ...prevState,
        filters: _filters.filter((t) => `${t.order}filters` !== draggableId),
      }));
  }
};

const filteringFilters = (
  source,
  newIdx,
  _availableData,
  prevIdx,
  _filters,
  setWidgetData,
  widgetData
) => {
  switch (source.droppableId) {
    case 'filteringAvailableData':
      // Add item to filters
      const sourceData = _availableData[prevIdx];
      const newFilter = {
        order: newIdx,
        valueType: sourceData.tagType,
        valueId: sourceData.id,
        condition:
          sourceData.type === 'dateTimeType' &&
          widgetData.type === 'line' &&
          widgetData.scope === 'RAW_DATA'
            ? { value: 'AFTER', label: 'enum.AFTER' }
            : sourceData.type === 'dateTimeType' ||
              sourceData.type === 'booleanType' ||
              (sourceData.tagType === 'ALARM_INFO' && sourceData.id === 5)
            ? { value: 'IS', label: 'enum.IS' }
            : { value: 'INCLUDES', label: 'enum.INCLUDES' },
        values:
          sourceData.type === 'dateTimeType'
            ? [
                {
                  type:
                    sourceData.tagType == 'ALARM_INFO'
                      ? 'LAST_YEAR'
                      : widgetData.type === 'line' && widgetData.scope === 'RAW_DATA'
                      ? 'MONTH'
                      : 'YEAR',
                  date: null,
                },
              ]
            : sourceData.type === 'booleanType'
            ? [
                {
                  value: 'True',
                  label: i18nService.translate('create-widget-page.create-widget.step-three.true'),
                },
              ]
            : [],
        autocompleteOptions: [],
        operation: sourceData.operation || null,
        type: sourceData.type,
        name: sourceData.name,
      };
      _filters.splice(newIdx, 0, newFilter);
      _filters.forEach((item, idx) => (item.order = idx));

      return setWidgetData((prevState) => ({ ...prevState, filters: _filters }));
    case 'filteringFilters':
      if (newIdx === prevIdx) {
        return; // No need to reorder.
      }
      // Reorder.
      const tagToMove = _filters[prevIdx];
      _filters.splice(prevIdx, 1);
      _filters.splice(newIdx, 0, tagToMove);
      _filters.forEach((item, idx) => (item.order = idx));

      return setWidgetData((prevState) => ({ ...prevState, filters: _filters }));
  }
};

export const onDragAndDropFilters = (
  result,
  availableData,
  filters,
  setWidgetData,
  setAvailableData,
  widgetData
) => {
  const { source, destination } = result;
  const draggableId = result.draggableId;

  if (!destination) {
    return;
  }
  const newIdx = destination.index;
  const prevIdx = source.index;

  const _availableData = availableData;
  const _filters = [...filters];

  switch (destination.droppableId) {
    case 'filteringAvailableData':
      filteringAvailableDataSwitch(
        source,
        newIdx,
        _filters,
        prevIdx,
        _availableData.filter((ad) => ad.id != 11),
        setAvailableData,
        draggableId,
        setWidgetData
      );
      break;
    case 'filteringFilters':
      filteringFilters(
        source,
        newIdx,
        _availableData.filter((ad) => ad.id != 11),
        prevIdx,
        _filters,
        setWidgetData,
        widgetData
      );
      break;
  }
};
