import { createMuiTheme } from '@material-ui/core';
import { ExtendedThemeOptions, ExtendedTheme, Stylesheet } from './cssVars.interface';

/**
 * Handles the interface between JavaScript and CSS. Manages the Material-UI theme,
 * and makes sure its variables are also accessible in CSS.
 */
class CssVarsService {
  /**
   * All the App's CSS variables.
   */
  vars = {
    thinSideMenuWidth: undefined,
    editorTopSectionHeight: undefined,
    editorTopSectionDivider: undefined,
    editorFilterSectionHeight: undefined,
    editorButtonsHeight: undefined,
    widgetGalleryHeight: undefined,
    canvasColor: undefined,
    canvasTopSectionColor: undefined,
    canvasTopSectionBoxShadow: undefined,
    canvasGridColor: undefined,
    canvasWidth: undefined,
    sideBarWidth: undefined,
    canvasGutterSize: undefined,
    canvasWidgetBorderRadius: undefined,
    dashboardThumbnailText: undefined,
    widgetActionBarHeight: undefined,
    asterisk: undefined,
    boxShadow_1: undefined,
    boxShadowGrey: undefined,
    black: undefined,
    white1: undefined,
    white2: undefined,
    green1: undefined,
    btnTextColor: undefined,
    btnBoxShadow: undefined,
    headerHeight: undefined,
    grey: undefined,
    grey1: undefined,
    lightGrey: undefined,
    darkerGrey: undefined,
    disabledGrey: undefined,
    bowShadow: undefined,
    boxShadow1: undefined,
    textColorPrimary: undefined,
    colorPrimary: undefined,
    colorDisabled: undefined,
    textDisabled: undefined,
    colorPrimaryDark: undefined,
    colorSecondary: undefined,
    colorSecondaryDark: undefined,
    defaultFont: undefined,
    formikErrorText: undefined,
    formikErrorBorder: undefined,
    formikInlineBorder: undefined,
    sideMenuText: undefined,
    formikErrorBackground: undefined,
    formikPLCCheckIcon: undefined,
    multilineAutocompleteBowShadow: undefined,
    multilineAutocompleteBorder: undefined,
    multilineAutocompleteChip: undefined,
    multiSelectChip: undefined,
    multiSelectChipText: undefined,
    multiSelectOptionSelected: undefined,
    multiSelectOptionFocused: undefined,
    multiSelectRemoveIcon: undefined,
    multiSelectRemoveHover: undefined,
    selectRemoveIcon: undefined,
    selectRemoveHover: undefined,
    datePickerApplyButtonColor: undefined,
    datePickerDropDownActiveColor: undefined,
    datePickerDropDownActiveBackgroundColor: undefined,
    datePickerRangeColor: undefined,
    datePickerActiveDateColor: undefined,
    datePickerActiveDateBackgroundColor: undefined,
    datePickerHoverDateColor: undefined,
    datePickerButtonsBackgroundColor: undefined,
    datePickerRangesBackgroundColor: undefined,
    datePickerFontheaderColor: undefined,
    datePickerCancelButtonColor: undefined,
    datePickerRangesColor: undefined,
    datePickerTableBackgroundColor: undefined,
    datePickerRangeBackgroundColor: undefined,
    datePickerDateOffColor: undefined,
    datePickerRangesHoverBackgroundColor: undefined,
    datePickerAvailableFontColor: undefined,
    userFiltersWidgetHeaderHeight: undefined,
    userFiltersWidgetContentHeight: undefined,
    datePickerMonthHeaderColor: undefined,
    tableRowBorderBottom: undefined,
    tableRowBorderBottomHover: undefined,
    tableSpinnerBackground: undefined,
    alertBackground: undefined,
    scrollbarBackground: undefined,
    createDashboardClearAll: undefined,
    modalToolBar: undefined,
    telemetryText: undefined,
    similarWidgetsText: undefined,
    createWidgetAvailableData: undefined,
    createWidgetAvailableData1: undefined,
    createWidgetAvailableData2: undefined,
    createWidgetAvailableData3: undefined,
    createWidgetFiltersBoxShadow: undefined,
    filtersWidgetDisable: undefined,
    filtersWidgetDisable1: undefined,
    filtersWidgetDisable2: undefined,
    widgetGalleryArrowBoxShadow: undefined,
    statusCriticalAlarm: undefined,
    statusMajorAlarm: undefined,
    statusCommunicationError: undefined,
    statusConnectedWarning: undefined,
    statusMinorAlarm: undefined,
    statusDisconnected: undefined,
    statusAvailable: undefined,
    statusConnectedOk: undefined,
    statusPendingRouter: undefined,
    statusArchived: undefined,
    statusMissingPLC: undefined,
    dashboardBorder: undefined,
    dashboardBackground: undefined,
    widgetsBorderHeader: undefined,
    widgetsBackground: undefined,
    widgetsHeaderFont: undefined,
    widgetsFont: undefined,
    widgetsGraphsColorsPalette1: undefined,
    widgetsGraphsColorsPalette2: undefined,
    widgetsGraphsColorsPalette3: undefined,
    widgetsGraphsColorsPalette4: undefined,
    widgetsGraphsColorsPalette5: undefined,
    widgetsGraphsColorsPalette6: undefined,
    altLineBackgroundColor: undefined,
    menusBackground: undefined,
    menusForeground: undefined,
    menusSelected: undefined,
    liveMenusBackground: undefined,
    liveMenusForeground: undefined,
    liveMenusSelected: undefined,
    systemHeaderBackground: undefined,
    systemScreenBackground: undefined,
    systemContentBackground: undefined,
    systemCreateWidgetSideBarBackground: undefined,
    systemSelectedWidgetBorder: undefined,
    systemSelectionRectangleColor: undefined,
    systemFontSelected: undefined,
    systemFont: undefined,
    systemButtonBackground: undefined,
    systemCancelButtonBackground: undefined,
    systemPopupHeaderBackground: undefined,
    systemPopupSubHeaderBackground: undefined,
    systemPublishedBackground: undefined,
    systemDraftBackground: undefined,
    systemEnableEditorIcons: undefined,
    systemDisableEditorIcons: undefined,
    systemDisabledFont: undefined,
    systemDisabledBackground: undefined,
    highlightedTableRow: undefined,
    systemMachineBuilderOrgBackgroundColor: undefined,
    systemMachineBuilderOrgTextColor: undefined,
    systemMachineBuilderOrgArchivedColor: undefined,
    systemMachineBuilderChannelOrgBackgroundColor: undefined,
    systemMachineBuilderChannelOrgTextColor: undefined,
    systemMachineBuilderChannelOrgArchivedColor: undefined,
    systemCustomerOrgBackgroundColor: undefined,
    systemCustomerOrgTextColor: undefined,
    systemCustomerOrgArchivedColor: undefined,
    statusEventStatusPublishedColor: undefined,
    statusEventStatusSuspendedColor: undefined,
    statusEventStatusDraftColor: undefined,
    statusEventStatusExpiredColor: undefined,
    statusEventStatusArchivedColor: undefined,
    statusEventStatusActiveColor: undefined,
    statusEventStatusRecipientsMissingColor: undefined,
    conditionBuilderBackground: undefined,
    conditionBuilderBackgroundOutline: undefined,
    conditionBuilderRuleBackground: undefined,
    conditionBuilderRuleBackgroundOutline: undefined,
    conditionBuilderTree: undefined,
    conditionBuilderText: undefined,
    conditionBuilderInputBackground: undefined,
    conditionBuilderButtonText: undefined,
    conditionBuilderButtonTextHover: undefined,
    conditionBuilderButtonBackground: undefined,
    conditionBuilderAndOrButtonTextSelected: undefined,
    conditionBuilderAndOrButtonBackgroundSelected: undefined,
    conditionBuilderNotButtonTextSelected: undefined,
    conditionBuilderNotButtonBackgroundSelected: undefined,
    conditionBuilderDeleteButtonText: undefined,
    conditionBuilderDeleteButtonBackground: undefined,
    defaultDisabledColor: undefined,
  };

  private readonly defaultStylesheet: Stylesheet = {
    dashboardColors: {
      licensingType: 'FREE',
      content: {
        border: '#c2cfe0',
        background: '#f9fafe',
      },
    },
    widgetsColors: {
      licensingType: 'FREE',
      content: {
        borderHeader: '#79eac8',
        headerFont: '#fff',
        background: '#fff',
        font: '#3b406e',
        graphsColorsPalette: ['#008ffb', '#00e396', '#feb019', '#ff4560', '#775dd0', '#a5978b'],
      },
    },
    menusColors: {
      licensingType: 'FREE',
      content: {
        background: '#fff',
        foreground: '#3b406e',
        selected: '#79eac8',
        liveBackground: '#fff',
        liveForeground: '#3b406e',
        liveSelected: '#79eac8',
      },
    },
    systemColors: {
      licensingType: 'PREMIUM',
      content: {
        headerBackground: '#eaeff8',
        screenBackground: '#f9fafe',
        contentBackground: '#fefefe',
        createWidgetSideBarBackground: '#fefefe',
        selectedWidgetBorder: '#000',
        selectionRectangleColor: '#3788cf',
        fontSelected: '#3788cf',
        font: '#3b406e',
        buttonBackground: '#3788cf',
        cancelButtonBackground: '#c2cfe0',
        popupHeaderBackground: '#3788cf',
        popupSubHeaderBackground: 'rgba(224, 231, 243, 0.6)',
        publishedBackground: '#79eac8',
        draftBackground: '#d6dfef',
        enableEditorIcons: '#3b406e',
        disableEditorIcons: '#3b406e',
        disabledFont: '#99a5b3',
        disabledBackground: '#c2cfdf',
        highlightedTableRow: '#b0d3ee',
        machineBuilderOrgBackgroundColor: '#8699ab',
        machineBuilderOrgTextColor: '#ffffff',
        machineBuilderOrgArchivedColor: '#8699abcc',
        machineBuilderChannelOrgBackgroundColor: '#8699ab',
        machineBuilderChannelOrgTextColor: '#ffffff',
        machineBuilderChannelOrgArchivedColor: '#8699abcc',
        customerOrgBackgroundColor: '#8699ab',
        customerOrgTextColor: '#ffffff',
        customerOrgArchivedColor: '#8699abcc',
        errorMessagesTextColor: '#ff0000',
        conditionBuilderBackground: '#3788cf',
        conditionBuilderBackgroundOutline: '#004d86',
        conditionBuilderRuleBackground: '#9ac6f0',
        conditionBuilderRuleBackgroundOutline: '#004d86',
        conditionBuilderTree: '#ffffff',
        conditionBuilderText: '#000000',
        conditionBuilderInputBackground: '#ffffff',
        conditionBuilderButtonText: '#000000',
        conditionBuilderButtonTextHover: '#4aacfb',
        conditionBuilderButtonBackground: '#ffffff',
        conditionBuilderAndOrButtonTextSelected: '#ffffff',
        conditionBuilderAndOrButtonBackgroundSelected: '#4aacfb',
        conditionBuilderNotButtonTextSelected: '#ffffff',
        conditionBuilderNotButtonBackgroundSelected: '#ff0000',
        conditionBuilderDeleteButtonText: '#ffffff',
        conditionBuilderDeleteButtonBackground: '#ff0000',
      },
    },
    statusColors: {
      content: {
        eventStatusColors: {
          published: '#0BBA26',
          suspended: '#2B3346',
          draft: '#2B3346',
          expired: '#FF0000',
          archived: '#C2CFE0',
          active: '#0BBA26',
          recipientsMissing: '#FF0000',
        },
      },
    },
  };

  /**
   * The current Material-UI theme.
   */
  theme: ExtendedTheme;

  /**
   * Initializes the service. Fetches the Material-UI theme, and
   * sets all the CSS variables.
   */
  async init(stylesheet?: Stylesheet) {
    const genericVars = this.applyStyles(stylesheet || this.defaultStylesheet);

    await this.generateTheme(genericVars);

    // App-specific vars are mapped 1-to-1 from the theme.
    Object.assign(this.vars, this.theme.app);

    // Vars from Material-UI have a custom mapping.
    this.vars.headerHeight =
      this.theme.overrides.MuiToolbar.regular['height'] +
      this.theme.overrides.MuiToolbar.regular['borderBottomWidth'] +
      this.theme.overrides.MuiToolbar.regular['borderTopWidth'];
    this.vars.boxShadow1 = this.theme.shadows[1];
    this.vars.textColorPrimary = this.theme.palette.text.primary;
    this.vars.colorPrimary = this.theme.palette.primary.main;
    this.vars.btnTextColor = this.theme.app.btnTextColor;
    this.vars.colorPrimaryDark = this.theme.palette.primary.dark;
    this.vars.colorSecondary = this.theme.palette.secondary.main;
    this.vars.colorSecondaryDark = this.theme.palette.secondary.dark;
    this.vars.defaultFont = this.theme.typography.fontFamily;

    for (const key in this.vars) {
      this.setVariable(key, this.vars[key]);
    }
  }

  private hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}`
      : null;
  }

  private assignStylesheet(stylesheet: Stylesheet) {
    return {
      dashboardBorder: stylesheet.dashboardColors.content.border, // Dashboards border color
      dashboardBackground: stylesheet.dashboardColors.content.background, // Dashboards background color
      widgetsBorderHeader: stylesheet.widgetsColors.content.borderHeader, // widgets border and header color
      widgetsBackground: stylesheet.widgetsColors.content.background, // widgets background color
      widgetsHeaderFont: stylesheet.widgetsColors.content.headerFont, // widgets header font color
      widgetsFont: stylesheet.widgetsColors.content.font, // widgets
      widgetsGraphsColorsPalette1: stylesheet.widgetsColors.content.graphsColorsPalette[0], // widgets graphs colors palette
      widgetsGraphsColorsPalette2: stylesheet.widgetsColors.content.graphsColorsPalette[1], // widgets graphs colors palette
      widgetsGraphsColorsPalette3: stylesheet.widgetsColors.content.graphsColorsPalette[2], // widgets graphs colors palette
      widgetsGraphsColorsPalette4: stylesheet.widgetsColors.content.graphsColorsPalette[3], // widgets graphs colors palette
      widgetsGraphsColorsPalette5: stylesheet.widgetsColors.content.graphsColorsPalette[4], // widgets graphs colors palette
      widgetsGraphsColorsPalette6: stylesheet.widgetsColors.content.graphsColorsPalette[5], // widgets graphs colors palette
      menusBackground: stylesheet.menusColors.content.background, // menus
      menusForeground: stylesheet.menusColors.content.foreground, // menus
      menusSelected: stylesheet.menusColors.content.selected, // menus
      liveMenusBackground: stylesheet.menusColors.content.liveBackground, // menus
      liveMenusForeground: stylesheet.menusColors.content.liveForeground, // menus
      liveMenusSelected: stylesheet.menusColors.content.liveSelected, // menus
      systemHeaderBackground: stylesheet.systemColors.content.headerBackground, // system
      systemScreenBackground: stylesheet.systemColors.content.screenBackground, // system
      systemContentBackground: stylesheet.systemColors.content.contentBackground, // system
      systemCreateWidgetSideBarBackground:
        stylesheet.systemColors.content.createWidgetSideBarBackground, // system
      systemSelectedWidgetBorder: stylesheet.systemColors.content.selectedWidgetBorder, // system
      systemSelectionRectangleColor: stylesheet.systemColors.content.selectionRectangleColor, // system
      systemFontSelected: stylesheet.systemColors.content.fontSelected, // system
      systemFont: stylesheet.systemColors.content.font, // system
      systemButtonBackground: stylesheet.systemColors.content.buttonBackground, // system
      systemCancelButtonBackground: stylesheet.systemColors.content.cancelButtonBackground, // system
      systemPopupHeaderBackground: stylesheet.systemColors.content.popupHeaderBackground, // system
      systemPopupSubHeaderBackground: stylesheet.systemColors.content.popupSubHeaderBackground, // system
      systemPublishedBackground: stylesheet.systemColors.content.publishedBackground, // system
      systemDraftBackground: stylesheet.systemColors.content.draftBackground, // system
      systemEnableEditorIcons: stylesheet.systemColors.content.enableEditorIcons, // system
      systemDisableEditorIcons: stylesheet.systemColors.content.disableEditorIcons, // system
      systemDisabledFont: stylesheet.systemColors.content.disabledFont, // system
      systemDisabledBackground: stylesheet.systemColors.content.disabledBackground, // system
      highlightedTableRow: stylesheet.systemColors.content.highlightedTableRow, // system
      systemMachineBuilderOrgBackgroundColor:
        stylesheet.systemColors.content.machineBuilderOrgBackgroundColor, // system
      systemMachineBuilderOrgTextColor: stylesheet.systemColors.content.machineBuilderOrgTextColor, // system
      systemMachineBuilderOrgArchivedColor:
        stylesheet.systemColors.content.machineBuilderOrgArchivedColor, // system
      systemMachineBuilderChannelOrgBackgroundColor:
        stylesheet.systemColors.content.machineBuilderChannelOrgBackgroundColor, // system
      systemMachineBuilderChannelOrgTextColor:
        stylesheet.systemColors.content.machineBuilderChannelOrgTextColor, // system
      systemMachineBuilderChannelOrgArchivedColor:
        stylesheet.systemColors.content.machineBuilderChannelOrgArchivedColor, // system
      systemCustomerOrgBackgroundColor: stylesheet.systemColors.content.customerOrgBackgroundColor, // system
      systemCustomerOrgTextColor: stylesheet.systemColors.content.customerOrgTextColor, // system
      systemCustomerOrgArchivedColor: stylesheet.systemColors.content.customerOrgArchivedColor, // system
      statusEventStatusPublishedColor: stylesheet.statusColors.content.eventStatusColors.published,
      statusEventStatusSuspendedColor: stylesheet.statusColors.content.eventStatusColors.suspended,
      statusEventStatusDraftColor: stylesheet.statusColors.content.eventStatusColors.draft,
      statusEventStatusExpiredColor: stylesheet.statusColors.content.eventStatusColors.expired,
      statusEventStatusArchivedColor: stylesheet.statusColors.content.eventStatusColors.archived,
      statusEventStatusActiveColor: stylesheet.statusColors.content.eventStatusColors.active,
      statusEventStatusRecipientsMissingColor:
        stylesheet.statusColors.content.eventStatusColors.recipientsMissing,
      formikErrorText: stylesheet.systemColors.content.errorMessagesTextColor,
      conditionBuilderBackground: this.hexToRgb(
        stylesheet.systemColors.content.conditionBuilderBackground
      ),
      conditionBuilderBackgroundOutline:
        stylesheet.systemColors.content.conditionBuilderBackgroundOutline,
      conditionBuilderRuleBackground:
        stylesheet.systemColors.content.conditionBuilderRuleBackground,
      conditionBuilderRuleBackgroundOutline:
        stylesheet.systemColors.content.conditionBuilderRuleBackgroundOutline,
      conditionBuilderTree: stylesheet.systemColors.content.conditionBuilderTree,
      conditionBuilderText: stylesheet.systemColors.content.conditionBuilderText,
      conditionBuilderInputBackground:
        stylesheet.systemColors.content.conditionBuilderInputBackground,
      conditionBuilderButtonText: stylesheet.systemColors.content.conditionBuilderButtonText,
      conditionBuilderButtonTextHover:
        stylesheet.systemColors.content.conditionBuilderButtonTextHover,
      conditionBuilderButtonBackground:
        stylesheet.systemColors.content.conditionBuilderButtonBackground,
      conditionBuilderAndOrButtonTextSelected:
        stylesheet.systemColors.content.conditionBuilderAndOrButtonTextSelected,
      conditionBuilderAndOrButtonBackgroundSelected:
        stylesheet.systemColors.content.conditionBuilderAndOrButtonBackgroundSelected,
      conditionBuilderNotButtonTextSelected:
        stylesheet.systemColors.content.conditionBuilderNotButtonTextSelected,
      conditionBuilderNotButtonBackgroundSelected:
        stylesheet.systemColors.content.conditionBuilderNotButtonBackgroundSelected,
      conditionBuilderDeleteButtonText:
        stylesheet.systemColors.content.conditionBuilderDeleteButtonText,
      conditionBuilderDeleteButtonBackground:
        stylesheet.systemColors.content.conditionBuilderDeleteButtonBackground,
    };
  }

  applyStyles(stylesheet: Stylesheet) {
    const configurationStyles = this.assignStylesheet(stylesheet);
    Object.assign(this.vars, configurationStyles);

    for (const key in configurationStyles) {
      this.setVariable(key, configurationStyles[key]);
    }

    return configurationStyles;
  }

  /**
   * Sets a CSS variable from the JavaScript so that the value
   * is accessible in CSS.
   */
  setVariable(key: string, value: string | number) {
    this.vars[key] = value;
    const valToSet = typeof value === 'number' ? `${value}px` : value;
    document.documentElement.style.setProperty(`--${key}`, valToSet);
  }

  /**
   * Generates a Material-UI theme.
   */
  private generateTheme(genericVars) {
    return this.getThemeCfg(genericVars).then((themeCfg) => {
      this.theme = createMuiTheme(themeCfg) as ExtendedTheme;
      window['theme'] = this.theme;
    });
  }

  /**
   * This will be an API to the server to get the theme according
   * to the white-label. Currently, the theme is hardcoded.
   */
  private getThemeCfg(genericVars): Promise<ExtendedThemeOptions> {
    const options: ExtendedThemeOptions = {
      app: {
        ...genericVars,
        organizationType_UNITRONICS_MAIN: '#3b406e',
        organizationType_UNITRONICS_CHANNEL: 'rgba(59, 64, 110, 0.77)',
        organizationType_MACHINE_BUILDER: '#8699ab',
        organizationType_MACHINE_BUILDER_CHANNEL: '#879bb7',
        organizationType_END_CUSTOMER: '#aebed7',
        thinSideMenuWidth: 51,
        editorTopSectionHeight: 50,
        editorTopSectionDivider: 27,
        editorFilterSectionHeight: 38,
        widgetGalleryHeight: 60,
        editorButtonsHeight: 25,
        myAccountPanelsColor: '#f9fafe',
        canvasColor: '#f9fafe',
        canvasTopSectionColor: '#e0e7f3',
        canvasTopSectionBoxShadow: '0 4px 10px 0 rgba(45, 77, 141, 0.25)',
        canvasGridColor: '#dedede',
        canvasWidth: 1280, // The canvas has hard-coded width and is not responsive. Width must be a multiple of 8px.
        sideBarWidth: 52,
        canvasGutterSize: 8,
        dashboardThumbnailText: '#1c285a',
        canvasWidgetBorderRadius: 5,
        widgetActionBarHeight: 45,
        sideMenuText: '#2d455b',
        boxShadow_1: '0 0 6px 0 rgba(158, 175, 200, 0.4)',
        boxShadowGrey: '0 0 6px 0 rgba(194, 207, 224, 0.4)',
        bowShadow: 'rgba(0, 0, 0, 0.25)',
        asterisk: '#ef6262',
        black: '#000',
        white1: '#ffffff',
        white2: '#fefefe',
        btnTextColor: '#15193c',
        btnBoxShadow: 'rgba(0, 0, 0, 0.05)',
        green1: '#79eac8',
        grey: '#c2cfe0',
        grey1: '#dde4f2',
        lightGrey: '#d6dfef',
        darkerGrey: '#b3b3b3',
        disabledGrey: '#879bb7',
        colorDisabled: '#c2cfe0',
        textDisabled: 'rgba(0, 0, 0, 0.26)',
        //formikErrorText: '#ef1818',
        formikErrorBorder: '#eb5757',
        formikInlineBorder: 'rgba(0, 0, 0, 0.2)',
        formikErrorBackground: 'rgba(235, 87, 87, 0.11)',
        formikPLCCheckIcon: '#0bba26',
        multilineAutocompleteBowShadow: 'rgba(135, 155, 183, 0.7)',
        multilineAutocompleteBorder: '#58bee6',
        multilineAutocompleteChip: '#eaeff8',
        multiSelectChip: '#e3edf2',
        multiSelectChipText: 'rgba(0, 0, 0, 0.87)',
        multiSelectOptionSelected: '#f3f3f3',
        multiSelectOptionFocused: '#f1f1f1',
        multiSelectRemoveIcon: '#879bb7',
        multiSelectRemoveHover: 'rgba(0, 0, 0, 0.6)',
        selectRemoveIcon: '#b5c5dc',
        selectRemoveIconHover: '#879bb7',
        datePickerApplyButtonColor: '#79eac8',
        datePickerCancelButtonColor: '#c2cfe0',
        datePickerDropDownActiveColor: '#2d455b',
        datePickerDropDownActiveBackgroundColor: '#ecf1f8',
        datePickerRangesColor: '#2b3346',
        datePickerRangesBackgroundColor: '#fff',
        datePickerRangesHoverBackgroundColor: '#c2cfe0',
        datePickerDateRangeColor: '#2d455b',
        datePickerDateRangeBackgroundColor: '#ecf1f8',
        datePickerActiveDateColor: '#fff',
        datePickerActiveDateBackgroundColor: '#79eac8',
        datePickerHoverDateColor: '#eee',
        datePickerButtonsBackgroundColor: '#fff',
        datePickerMonthHeaderColor: '#2b3346',
        datePickerFontheaderColor: '#879bb7',
        datePickerTableBackgroundColor: '#fff',
        datePickerDateOffColor: '#d6dfef',
        datePickerAvailableFontColor: '#2d455b',
        noValueWidgetBorder: '#d6dfef',
        noValueWidgetTitle: '#a3b3c9',
        sortItemBorder: '#d6dfef',
        userFiltersWidgetHeaderHeight: 48,
        userFiltersWidgetContentHeight: 108,
        mapLoctionStatusUndefined: '#3b406e',
        tableRowBorderBottom: 'rgba(194, 207, 224, 0.5)',
        tableRowBorderBottomHover: 'rgba(121, 234, 200, 0.1)',
        tableSpinnerBackground: 'rgba(0, 0, 0, 0.1)',
        alertBackground: '#f9fafe',
        scrollbarBackground: '#c4c4c4',
        createDashboardClearAll: '#43c19b',
        modalToolBar: 'rgba(224, 231, 243, 0.6)',
        telemetryText: '#454c5d',
        similarWidgetsText: '#13b988',
        createWidgetAvailableData: '#eaeff8',
        createWidgetAvailableData1: '#98a2b3',
        createWidgetAvailableData2: '#9098a2',
        createWidgetAvailableData3: '#999999',
        createWidgetFiltersBoxShadow: '0 0 20px 0 rgba(136, 145, 157, 0.3)',
        filtersWidgetDisable: '#cccccc',
        filtersWidgetDisable1: '#f2f2f2',
        filtersWidgetDisable2: '#808080',
        widgetGalleryArrowBoxShadow: '0 2px 4px 0 rgba(13, 19, 74, 0.2)',
        statusCriticalAlarm: '#e52828',
        statusMajorAlarm: '#e52828',
        statusCommunicationError: '#e52828',
        statusConnectedWarning: '#ff991f',
        statusMinorAlarm: '#ff991f',
        statusDisconnected: '#97a0af',
        statusAvailable: '#0bba26',
        statusConnectedOk: '#0bba26',
        statusPendingRouter: '#97a0af',
        statusArchived: '#97a0af',
        statusMissingPLC: '#97a0af',
        statusAboutToExpaired: '#ff991f',
        statusExpairedGrace: '#ff991f',
        statusExpaired: '#e52828',
        disabledFont: '#99a5b3',
        disabledBackground: '#c2cfdf',
        highlightedTableRowColor: '#b0d3ee',
        defaultDisabledColor: '#f4f4f4',
      },
      palette: {
        primary: {
          main: '#76eac7',
          dark: '#13e5a6',
        },
        secondary: {
          main: '#3b406e',
          dark: '#30355e',
        },
        text: {
          primary: genericVars.systemFont,
        },
      },
      props: {
        MuiButton: {
          disableRipple: true,
        },
        MuiCheckbox: {
          disableRipple: true,
        },
      },
      typography: {
        fontFamily: "'Lato', sans-serif",
      },
      overrides: {
        MuiAppBar: {
          root: {
            boxShadow: 'none',
          },
        },
        MuiToolbar: {
          regular: {
            minHeight: 'unset !important',
            height: 47,
            background: 'white',
            borderBottomWidth: 1,
            borderTopWidth: 1,
            borderBottomColor: '#e3edf2',
            borderBottomStyle: 'solid',
          },
        },
        MuiPaper: {
          root: {
            color: 'none',
          },
        },
        MuiButton: {
          root: {
            textTransform: 'none',
            borderRadius: 25,
            fontWeight: 'bold',
            padding: '2px 13px',
            minWidth: 92,
            '&:focus': {
              outline: 'none',
            },
          },
          contained: {
            boxShadow: 'none',
            padding: '2px 13px',
            '&:hover': {
              opacity: 0.7,
              boxShadow: 'none',
            },
          },
          containedPrimary: {
            padding: '2px 13px',
            color: genericVars.systemFont,
            // backgroundImage: `linear-gradient(to top, #13e5a6, #76eac7)`
          },
        },
        MuiCheckbox: {
          root: {
            padding: 0,
          },
        },
        MuiOutlinedInput: {
          notchedOutline: {
            borderColor: '#c2cfe0 !important',
          },
        },
        MuiTextField: {
          root: {
            backgroundColor: '#fff',
          },
        },
        MuiSwitch: {
          root: {
            '& .MuiSwitch-track': {
              backgroundColor: '#8aa69e',
            },
            '& .Mui-checked + .MuiSwitch-track': {
              backgroundColor: '#8aa69e',
            },
          },
        },
        // MuiSvgIcon: {
        //   root: {
        //     color: '#0f3b65 !important'
        //   }
        // }
      },
    };

    return Promise.resolve(options);
  }
}

export const cssVarsService = new CssVarsService();
