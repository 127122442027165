const outlinedMode = {
  backgroundColor: 'transparent',
  border: '2px solid var(--systemButtonBackground)',
  color: 'var(--systemFont)',
  height: 24,
  '&:hover': {
    backgroundColor: 'var(--systemButtonBackground)',
  },
};

const defaultMode = {
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'inline-block',
  cursor: 'pointer',
  padding: '0 15px',
  minWidth: 96,
  maxWidth: 300,
  boxSizing: 'border-box',
  height: 28,
  borderRadius: 25,
  backgroundColor: 'var(--systemButtonBackground)',
  border: 'none',
  textAlign: 'center' as 'center',
  fontSize: 12,
  fontWeight: 600,
  color: 'var(--systemFont)',
  transition: 'all 0.2s linear',
  '&:focus': {
    outline: 'none',
  },
};

const cancelButton = {
  backgroundColor: 'var(--systemCancelButtonBackground)',
};

const bigFontMode = {
  fontSize: 14,
};

const smallFontMode = {
  fontSize: 12,
};

const bigButtonMode = {
  fontSize: 14,
  height: 36,
};

const disabledMode = {
  backgroundColor: 'var(--colorDisabled)',
  color: 'var(--textDisabled)',
  border: 'none',
  cursor: 'unset',
  '&:hover': {
    backgroundColor: 'var(--colorDisabled)',
    cursor: 'auto',
  },
};

export const buttonModes = [
  { mode: 'default', styles: defaultMode },
  { mode: 'outlined', styles: outlinedMode },
  { mode: 'disabled', styles: disabledMode },
  { mode: 'cancel', styles: cancelButton },
  { mode: 'bigFont', styles: bigFontMode },
  { mode: 'smallFont', styles: smallFontMode },
  { mode: 'big', styles: bigButtonMode },
];
