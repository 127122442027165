import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { modalService } from './ModalService';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  paperRounded: { borderRadius: (props: any) => (props && props.rounded ? 8 : 4) }
}));

/**
 * A wrapper for the modal. Each modal will receive the following props:
 * 1) args: the input arguments for the modal.
 * 2) dismiss: a function to dismiss the modal with a value.
 */
export default function ModalWrapper({ id, args, props, Component }) {
  const [state, setState] = useState({ isOpen: true, closeValue: undefined });
  const classes = useStyles(props);
  return (
    <Dialog
      {...props}
      open={state.isOpen}
      classes={{ paper: classes.paperRounded }}
      disableBackdropClick={props.disableBackdropClick || false}
      onClose={() => setState({ isOpen: false, closeValue: undefined })}
      onExited={() => modalService.closeModal(id, state.closeValue)}>
      <Component args={args} dismiss={closeValue => setState({ isOpen: false, closeValue })} />
    </Dialog>
  );
}
