import { omit } from 'lodash';
import ConditionalValueWidget from '@components/widgets/ConditionalValueWidget';
import { stepThreeDefaultValidation } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';
import { ConditionalValueCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';

export const conditionalValue: WidgetMapItem = {
  id: 'conditional_value',
  name: 'conditional_value',
  component: ConditionalValueWidget,
  minWidth: 136,
  minHeight: 50,
  fullSize: true,
  images: {
    canvas: '/assets/images/empty_value.svg',
    thumbnail: 'conditionalValue',
  },
  editModalSize: {},
  settings: {
    extraMetricsText: {
      value: (scope) =>
        `create-widget-page.create-widget.step-three.conditional-value-empty-metrics-${
          scope === 'LAST_VALUE' ? 'last-value' : 'aggregated'
        }`,
      condition: () => true,
    },
    dataChangesToHandle: ['metrics', 'filters', 'calculations', 'localTags'],
    stepOneHidden: [],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
      4: (widgetData) => {
        const lastCondiotion =
          widgetData?.customization?.conditions &&
          widgetData?.customization?.conditions[widgetData?.customization?.conditions.length - 1];
        const noErrors =
          !widgetData?.customization?.errors ||
          !Object.values(widgetData?.customization?.errors).find((err) => err);

        return (
          widgetData?.customization?.conditions[0].boolValue ||
          (noErrors &&
            (lastCondiotion?.fromValue ||
              lastCondiotion?.fromValue === 0 ||
              lastCondiotion?.toValue ||
              lastCondiotion?.toValue === 0))
        );
      },
    },
    expansionsPanelDisable: ['sort'],
    dragAndDropRules: {
      maxGrouping: (scope) => 0,
      maxMetrics: 1,
      metricsDataValidation: (scope, draggableItemType) => {
        if (
          scope === 'LAST_VALUE' &&
          !['numberType', 'floatType', 'booleanType'].includes(draggableItemType)
        ) {
          return 'nonNumericalAndBoolean';
        }
        return null;
      },
    },
    disableGrouping: {
      text: ({ eventTemplateId }) =>
        'create-widget-page.create-widget.step-three.conditional-value-grouping',
    },
    showPreviewData: ['metrics'],
    disabledScope: ['RAW_DATA'],
    customizationServerChanges: (customization: ConditionalValueCustomization) => ({
      ...omit(customization, 'errors'),
      columnsDict:
        customization.conditions &&
        customization.conditions.reduce(
          (result, condition) => ({
            ...result,
            [condition.id]: condition.remark,
          }),
          {}
        ),
    }),
    languageKeys: ['columnsDict.*', 'symbol'],
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => widgetData.scope === 'LAST_VALUE',
  },
};
