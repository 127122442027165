import React, { useEffect, useState } from 'react';
import { getHeatmapOptions } from '../HeatmapWidget.utils';
import { cloneDeep, isEqual } from 'lodash';
import Chart from '../Chart';
import { getData } from '../HeatmapWidgetGetData.utils';

function TwoGroupByHeatmapWidget(props) {
  const {
    data,
    setData,
    customization,
    widget,
    classes,
    defaultDecimalDigits,
    dashboardFilters,
    widgetFilters,
    isPreview = false,
    navigateDashboard,
    availableSeries,
    pageSize,
    rowSize,
    fetchedData,
    setFetchedData,
  } = props;

  const { next, p } = data;
  const [heatmapOptions, setHeatmapOptions] = useState(null);

  const [chartPage, setChartPage] = useState(null);

  const [series, setSeries] = useState([]);

  const showNavigationButtons =
    !isPreview && (next || (chartPage && chartPage !== 1) || Object.keys(fetchedData)?.length > 1);

  useEffect(() => {
    if (isPreview) {
      setSeries(data.results);
    } else {
      let updatedFetchedData = cloneDeep(fetchedData);

      const lastPage = updatedFetchedData[Object.keys(updatedFetchedData).length];

      const shouldMergeAppend =
        lastPage?.xaxis.categories[lastPage.xaxis.categories.length - 1] ===
        data?.xaxis.categories[0];

      if (shouldMergeAppend) {
        getMergeAppend(lastPage);
      }

      let index = 0;
      while (index < data.xaxis.categories.length) {
        if (lastPage?.p && !lastPage.isCompleted) {
          let fillSize = rowSize - lastPage.xaxis.categories.length;
          fillSize = Math.min(fillSize, data.xaxis.categories.length);

          lastPage.xaxis.categories = buildPageCategories(lastPage, index, fillSize);
          lastPage.results = buildPageDataResults(lastPage, index, fillSize);

          index += fillSize;

          if (index < data.xaxis.categories.length || !next) {
            lastPage.isCompleted = true;
          }
        } else {
          const newPageKey = Object.keys(updatedFetchedData).length + 1;

          let newPage: any = {
            ...data,
            p: newPageKey,
            isCompleted: false,
            results: data.results.map((res) => ({ ...res, data: [] })),
            xaxis: { ...data.xaxis, categories: [] },
          };

          let fillSize = rowSize;
          fillSize = Math.min(fillSize, data.xaxis.categories.length - index);

          newPage.xaxis.categories = buildPageCategories(newPage, index, fillSize);
          newPage.results = buildPageDataResults(newPage, index, fillSize);

          index += fillSize;

          if (index < data.xaxis.categories.length || !next) {
            newPage.isCompleted = true;
          }

          updatedFetchedData = {
            ...updatedFetchedData,
            [newPageKey]: newPage,
          };
        }
      }

      let currentChartPage = chartPage || 1;

      setFetchedData(updatedFetchedData);
      setSeries(updatedFetchedData[currentChartPage]?.results || []);
      setHeatmapOptions(
        getHeatmapOptions(
          customization,
          updatedFetchedData[currentChartPage],
          defaultDecimalDigits,
          navigateDashboard,
          widget
        )
      );
    }
  }, [data]);

  useEffect(() => {
    if (chartPage) {
      if (!isPreview && !fetchedData[chartPage]?.isCompleted) {
        getMoreWidgetData(p + 1);
      } else {
        setSeries(fetchedData[chartPage].results);
        setHeatmapOptions(
          getHeatmapOptions(
            customization,
            fetchedData[chartPage],
            defaultDecimalDigits,
            navigateDashboard,
            widget
          )
        );
      }
    }
  }, [chartPage]);

  useEffect(() => {
    isPreview &&
      setHeatmapOptions(
        getHeatmapOptions(customization, data, defaultDecimalDigits, navigateDashboard, widget)
      );
  }, [customization, data]);

  async function getMoreWidgetData(page) {
    await getData(
      widget.id,
      dashboardFilters,
      widgetFilters,
      { p: page, ps: pageSize },
      setData,
      availableSeries
    );
  }

  function buildPageCategories(page, index, fillSize) {
    const categories = [
      ...page.xaxis.categories,
      ...data.xaxis.categories.slice(index, index + fillSize),
    ];
    return categories;
  }

  function buildPageDataResults(page, index, fillSize) {
    const results = page.results.map((res) => {
      const selectedData = data.results.find((lpr) => lpr.name === res.name);
      let newData = [];
      if (selectedData?.data?.length) {
        newData = selectedData.data?.slice(index, index + fillSize);
      }

      return {
        ...res,
        data: [...res.data, ...newData],
      };
    });
    return results;
  }

  async function goBackOrForth(direction: string) {
    switch (direction) {
      case 'previous':
        setChartPage(chartPage ? chartPage - 1 : 1);
        break;
      case 'next':
        setChartPage(chartPage ? chartPage + 1 : 2);
        break;
      default:
        break;
    }
  }

  function getMergeAppend(lastPage) {
    if (lastPage?.results) {
      data.results.forEach((serverDataItem) => {
        let lastPageData = lastPage.results.find((r) => r.name === serverDataItem.name);
        const newValue = serverDataItem.data.shift();
        if (newValue) {
          lastPageData.data.splice(lastPageData.data.length - 1, 1, newValue);
        }
      });

      data.xaxis.categories.shift();
    }
  }

  return (
    <Chart
      isPreview={isPreview}
      showNavigationButtons={showNavigationButtons}
      series={series}
      chartPage={chartPage}
      next={next}
      heatmapOptions={heatmapOptions}
      classes={classes}
      isNextButtonEnabled={
        next ||
        Object.keys(fetchedData).indexOf(chartPage?.toString()) <
          Object.keys(fetchedData).length - 1
      }
      goBackOrForth={goBackOrForth}
      navigateDashboard={navigateDashboard}
      {...props}
    />
  );
}

export default TwoGroupByHeatmapWidget;
