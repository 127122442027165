import { cloneDeep } from 'lodash';
import { openConfirmInitializeWidget } from '../GroupingAndMeasures.utils';
import { useCalculations } from './Calculations/useCalculations';
import { useState } from 'react';
import { getAddLocalTagFieldCfg } from './Metrics.utils';
import { getSharedIdForLocalTagsAndCustMetrics } from '@pages/CreateWidgetPage/widgets.utils';

export function useAddLocalTag({ widgetData, setWidgetData, groupBy, metrics }) {
  const { onCalculationChanged } = useCalculations({ widgetData, setWidgetData });
  const id = getSharedIdForLocalTagsAndCustMetrics(widgetData);

  const [nameValidation, setNameValidation] = useState({
    name: '',
    message: '',
    isValid: true,
  });

  const fieldsCfg = getAddLocalTagFieldCfg(widgetData, nameValidation);

  const addLocalTagArgs = {
    header: 'create-widget-page.create-widget.step-three.add-local-tag',
    onSubmit,
    fieldsCfg,
  };

  async function onSubmit(localTag) {
    const order = getOrder(metrics);
    const calculation = {
      valueType: 'CALCULATION',
      valueId: id,
      expression: '0',
      operation: null,
      order: order,
    };

    const newMetric = {
      valueId: id,
      name: localTag.name,
      valueType: 'CALCULATION',
      type: 'numberType',
      order: order,
      operation: null,
      calculation,
      selectType: null,
    };
    const newMetrics = cloneDeep(metrics);
    newMetrics.splice(order, 0, newMetric);
    newMetrics.map((item, idx) => (item.order = idx));

    const canAdd = await openConfirmInitializeWidget(widgetData, setWidgetData, groupBy, [
      ...newMetrics,
    ]);

    if (canAdd) {
      onCalculationChanged(calculation);

      setWidgetData((prevState) => {
        let newCalculations = cloneDeep(widgetData.calculations);
        let newLocalTags = cloneDeep(widgetData.localTags);

        newCalculations.push({
          valueType: 'CALCULATION',
          valueId: id,
          expression: '0',
          operation: null,
          order: order,
          metrics: [],
        });

        newLocalTags.push({ ...localTag, id });
        return {
          ...prevState,
          calculations: newCalculations,
          localTags: newLocalTags,
          metrics: newMetrics,
        };
      });
    }
  }

  function getOrder(metrics) {
    return (
      Math.max(
        metrics.map((metric) => {
          return metric.order;
        })
      ) || 0
    );
  }

  return { addLocalTagArgs };
}
