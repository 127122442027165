import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { connect, getIn, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { InputBase } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import I18n from '@components/I18n';
import { FormikPlcInputProps, PlcSerial } from './FormikPlcInput.interface';
import styles from './FormikPlcInput.scss';
import { i18nService } from '@core/i18n/I18nService';
import Icon from '@components/Icon';
import {
  handleBlurPlcInput,
  validatePlcSerial,
  disconnectPlcSerial,
  PlcSerialStatus,
} from './FormikPlcInput.utils';
import { httpService } from '@core/http/HttpService';
import PlcSerialButton from './PlcSerialButton/PlcSerialButton';

const validate = (
  value: PlcSerial,
  isRequired: boolean,
  associateToRouter?,
  plcType?: string,
  plcSerialGenerated?: boolean
) => {
  const isUnitronicsPLC = !plcType || plcType === 'UNITRONICS';

  if ((!value.text || value.text.length <= 0) && !isRequired) {
    return undefined;
  } else if (!value.text && isRequired) {
    return i18nService.translate(
      associateToRouter ? 'validations.router-missing-serial-number' : 'validations.mandatory'
    );
  } else if (value.text.length > 20 || !value.text.match(/^[a-z0-9]+$/i)) {
    return i18nService.translate('validations.plc-format');
  } else if (!value.id && (isUnitronicsPLC || !plcSerialGenerated)) {
    return i18nService.translate('validations.plc-validation');
  } else if (value.message) {
    return value.message;
  } else {
    return undefined;
  }
};

function FormikPlcInput(props: FormikPlcInputProps) {
  const {
    formik,
    name,
    label,
    hideLabel = false,
    disabled,
    type,
    className,
    value,
    values,
    rowIndex,
    assetId,
    setDisconnectPlcByBtn,
    isRequired = true,
    routerId,
    onSucceedValidation,
    allowRowsEllipsis,
    removeLabelEllipsis,
    plcType = 'UNITRONICS',
    setCatalogNumberOnGenerate = false,
    assetPlcType,
  } = props;
  const [catalogNumber, setCatalogNumber] = useState(null);
  const [plcSerial, setPlcSerial] = useState(value);
  const btnClicked = useRef(false);
  const error = getIn(formik.errors, name);
  const touched = getIn(formik.touched, name);
  const initialValuesPlc = getIn(formik.initialValues, name);
  const [prevPlcType, setPrevPlcType] = useState(null);
  const [plcSerialStatus, setPlcSerialStatus] = useState(PlcSerialStatus.none);

  useEffect(() => {
    formik.registerField(name, {
      props: {
        validate: (value) =>
          validate(
            value,
            isRequired,
            associateToRouter,
            plcType,
            plcSerialStatus === PlcSerialStatus.generated
          ),
      },
    });

    return () => {
      formik.unregisterField(name);
    };
  }, [name, plcType, plcSerial, plcSerialStatus]);

  useEffect(() => {
    // This will trigger form validation on mount.
    formik.setFieldValue(name, getIn(formik.values, name));
  }, [name]);

  const dataViewerData = useMemo(
    () => [{ label: 'create-asset-modal.cat-num', value: catalogNumber }],
    [catalogNumber]
  );

  const handleChange = useCallback(
    (e) => {
      const fieldVal = e.target.value;
      const plcVal = { id: null, text: fieldVal, message: null, type: null };
      setPlcSerial(plcVal);
      formik.setFieldValue(name, plcVal);
      setCatalogNumber(null);
      setPlcSerialStatus(PlcSerialStatus.none);
      if (formik.values.routerSerial) {
        formik.setFieldValue('routerSerial', { id: null, text: null, message: null });
      }
    },
    [formik]
  );

  const handleBlur = useCallback(() => {
    handleBlurPlcInput(btnClicked, formik, name);
  }, [formik, btnClicked]);

  const validatePlc = useCallback(() => {
    validatePlcSerial(
      btnClicked,
      type,
      plcSerial,
      formik,
      initialValuesPlc,
      setCatalogNumber,
      name,
      routerId,
      onSucceedValidation,
      setPlcSerial,
      assetPlcType,
      setPlcSerialStatus
    );
  }, [formik, plcSerial, assetPlcType]);

  const generateModbusSerial = useCallback(async () => {
    try {
      const res: any = await httpService.api({
        type: 'generatePlcSerial',
        data: { plcType: plcType },
      });
      if (res) {
        setPlcSerialStatus(PlcSerialStatus.generated);
        const plcVal = { id: null, text: res.plcSerial, message: null, type: null };
        setPlcSerial(plcVal);
        formik.setFieldValue(name, plcVal);
        setCatalogNumberOnGenerate && setCatalogNumber('Modbus');
      }
    } catch {}
  }, [plcType]);

  const disconnectPlc = () => {
    if (
      disconnectPlcSerial(
        assetId,
        setDisconnectPlcByBtn,
        setPlcSerial,
        setCatalogNumber,
        rowIndex,
        formik,
        name
      )
    )
      setPlcSerialStatus(PlcSerialStatus.none);
  };

  const clearPlcSerial = () => {
    const plcVal = { id: null, text: '', message: null, type: null };
    setPlcSerial(plcVal);
    formik.setFieldValue(name, plcVal);
    setPlcSerialStatus(PlcSerialStatus.none);
    setCatalogNumber(null);
  };

  const associateToRouter = useMemo(() => type === 'associateToRouter', [type]);
  const addToOrganization = useMemo(() => type === 'addToOrganization', [type]);
  const associateToAsset = useMemo(() => type === 'associateToAsset', [type]);

  useEffect(() => {
    if (prevPlcType && prevPlcType != plcType) {
      const plcVal = { id: null, text: '', message: null, type: null };
      setPlcSerial(plcVal);
      formik.setFieldValue(name, plcVal);
      formik.setFieldTouched(name, false);
      setPlcSerialStatus(PlcSerialStatus.none);
    }

    setPrevPlcType(plcType);
  }, [plcType]);

  const plcSerialButton = useMemo(() => {
    let plcSerialButtonType: 'None' | 'Disconnect' | 'Generate' | 'Validate' = 'None';
    let plcSerialButtonDisabled: boolean;

    if (plcType === 'UNITRONICS') {
      if (error) {
        plcSerialButtonType = 'Validate';
        plcSerialButtonDisabled = !plcSerial || !plcSerial.text;
      } else if (
        plcSerial &&
        plcSerial.id &&
        plcSerial.text !== '' &&
        plcSerial.text &&
        plcSerialStatus !== PlcSerialStatus.validated
      ) {
        plcSerialButtonType = 'Disconnect';
        plcSerialButtonDisabled = false;
      }
    } else {
      if (!plcSerial?.text) {
        plcSerialButtonType = 'Generate';
        plcSerialButtonDisabled = false;
      } else {
        if (associateToAsset && plcSerialStatus !== PlcSerialStatus.generated && error) {
          plcSerialButtonType = 'Validate';
          plcSerialButtonDisabled = !plcSerial || !plcSerial.text;
        } else if (
          plcSerial &&
          plcSerial.id &&
          plcSerial.text !== '' &&
          plcSerial.text &&
          plcSerialStatus !== PlcSerialStatus.validated &&
          !error &&
          !addToOrganization
        ) {
          plcSerialButtonType = 'Disconnect';
          plcSerialButtonDisabled = false;
        }
      }
    }

    return {
      plcSerialButtonType,
      plcSerialButtonDisabled,
    };
  }, [plcType, error, plcSerial, plcSerialStatus, addToOrganization, associateToAsset]);

  return (
    <>
      <div
        className={classNames(
          styles.fieldWrapper,
          associateToRouter && styles.associateToRouter,
          className
        )}>
        {!hideLabel && (
          <label
            className={classNames(
              styles.fieldLabel,
              !associateToRouter && isRequired && 'asterisk',
              !removeLabelEllipsis && 'ellipsis-overflow'
            )}>
            <I18n noEllipsis={removeLabelEllipsis}>{label}</I18n>:
          </label>
        )}
        <div className={styles.fieldInput}>
          <div className={styles.formikPlcInputWrapper}>
            <InputBase
              id={error && touched ? styles.formikPlcErrorInput : styles.formikPlcInput}
              value={plcSerial ? plcSerial.text : null}
              disabled={disabled}
              readOnly={plcType !== 'UNITRONICS' && addToOrganization}
              autoComplete="off"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {plcSerial &&
              !plcSerial.id &&
              plcSerial.text !== '' &&
              plcSerial.text &&
              !associateToRouter &&
              plcSerialStatus !== PlcSerialStatus.generated && (
                <Icon
                  type="remove"
                  className={classNames(styles.clearIcon, 'pointer')}
                  onClick={clearPlcSerial}
                />
              )}
            {plcSerial?.text &&
              plcSerial?.text !== '' &&
              !plcSerial?.message &&
              (plcSerial?.id || plcSerialStatus === PlcSerialStatus.generated) && (
                <CheckIcon id={styles.formikPlcCheckIcon} />
              )}
            <PlcSerialButton
              disconnectPlc={disconnectPlc}
              validatePlc={validatePlc}
              generatePlcSerial={generateModbusSerial}
              associateToRouter={associateToRouter}
              disabled={plcSerialButton.plcSerialButtonDisabled}
              assetName={values?.assetName}
              type={plcSerialButton.plcSerialButtonType}
            />
          </div>
          {(plcType === 'UNITRONICS' || associateToAsset) &&
          plcSerialStatus !== PlcSerialStatus.generated ? (
            <ErrorMessage name={name}>
              {(err) =>
                typeof err === 'string' ? (
                  <I18n
                    className={classNames(
                      styles.error,
                      allowRowsEllipsis && styles.rowsEllipsis,
                      associateToRouter && styles.fullWidth
                    )}
                    element="div"
                    noEllipsis={allowRowsEllipsis}>
                    {err}
                  </I18n>
                ) : null
              }
            </ErrorMessage>
          ) : null}
        </div>
      </div>
      {/* {catalogNumber && (
        <DataViewer
          className={styles.formikPlcCatalogNumber}
          data={dataViewerData}
          opacity
          boldLabel
        />
      )} */}
    </>
  );
}

export default connect(FormikPlcInput);
