import ValueWidget from '@components/widgets/ValueWidget';
import { stepThreeDefaultValidation, getVar } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';

export const value: WidgetMapItem = {
  id: 'value',
  name: 'Value',
  component: ValueWidget,
  minWidth: 120,
  minHeight: 50,
  fullSize: true,
  images: {
    canvas: '/assets/images/empty_value.svg',
    thumbnail: 'value',
  },
  editModalSize: {},
  settings: {
    extraMetricsText: {
      value: (scope, eventTemplateId) =>
        !eventTemplateId
          ? 'create-widget-page.create-widget.step-three.value-metrics'
          : 'create-event-data-source-page.event-data-source-widget.step-three.value-metrics',
      condition: () => true,
    },
    dataChangesToHandle: ['groupBy', 'metrics', 'filters', 'sorts', 'calculations', 'localTags'],
    stepValidation: {
      3: (widgetData) => stepThreeDefaultValidation(widgetData)('metrics'),
    },
    expansionsPanelDisable: ['sort'],
    dragAndDropRules: {
      maxGrouping: (scope) => 0,
      maxMetrics: 1,
      excludeNonNumericVariables: (scope) =>
        scope === 'AGGREGATED_DATA' || scope === 'AGGREGATED_LAST_VALUE',
    },
    disableGrouping: {
      text: ({ eventTemplateId }) =>
        !eventTemplateId
          ? 'create-widget-page.create-widget.step-three.value-grouping'
          : 'create-event-data-source-page.create-event-data-source.step-three.value-grouping',
    },
    showPreviewData: ['metrics'],
    disabledScope: ['RAW_DATA'],
    similarWidgets: () => [
      {
        widgetType: 'radial_gauge',
        getSideEffectCfg: () => ({
          goToStep: 1,
          condition: (widgetData) => true,
          getNewData: (widgetData) => ({
            assetProperties: [],
            tags: [],
            tagTypes: [],
            localTags: [],
          }),
        }),
      },
      {
        widgetType: 'linear_gauge',
        getSideEffectCfg: () => ({
          goToStep: 1,
          condition: (widgetData) => true,
          getNewData: (widgetData) => ({
            assetProperties: [],
            tags: [],
            tagTypes: [],
            localTags: [],
          }),
        }),
      },
    ],
    languageKeys: ['description', 'symbol'],
    customizationServerChanges: (customization) => ({
      ...customization,
      decimalDigits:
        customization.decimalDigits ||
        customization.decimalDigits === '' ||
        customization.decimalDigits === 0
          ? +customization.decimalDigits
          : 0,
      valueFontSize: +customization.valueFontSize,
      symbolFontSize: +customization.symbolFontSize,
      descriptionFontSize: +customization.descriptionFontSize,
      valueColor: customization.valueColor || getVar('widgetsFont'),
      symbolColor: customization.symbolColor || getVar('widgetsFont'),
      remarkColor: customization.remarkColor || getVar('widgetsFont'),
    }),
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) => widgetData.scope === 'LAST_VALUE',
  },
};
