import { i18nService } from '@core/i18n/I18nService';
import { httpService } from '@core/http/HttpService';
import React, { useEffect, useState } from 'react';
import Select from '@components/Select';
import DatePicker from '@components/DatePicker';
import MultiSelect from '@components/MultiSelect';
import GooglePlacesInput from '@components/GooglePlacesInput';
import TimeFieldMore24 from '@components/TimeFieldMore24';
import { assetsPropsMap, alarmInfosMap } from '@pages/CreateWidgetPage/widgets.utils';
import { useSelector } from '@src/redux/useSelector';
import { staticDictionary } from '@core/i18n/dictionary';
import { usePrevious } from '@core/hooks/usePrevious';
import { isEqual } from 'lodash';

const ValuesComponent = (props) => {
  const { updateFilter, assetTypes, widgetData, operatorsEnableDatePickerRanges } = props;
  const languageId = useSelector((state) => state.config.languageId);
  const [loadAutoComplete, setLoadAutoComplete] = useState(false);
  const prevFilters: any = usePrevious(props.filter);

  const assetPropertiesMultiSelectElemennt = [2, 1, 4, 12, 13, 14, 15, 16, 17, 18, 19]; //['Asset name', 'Asset ID', 'Organization', 'PLC Model', 'PLC Catalog Number', 'PLC Type', 'Router Model', 'Router Serial Number', 'Signal strength', 'Network type', 'PLC Serial Number'];
  const alarmInfosMultiSelectElemennt = [1, 7]; //['Alarm Index, Alarm name'];
  const specialAlarmInfos = [1, 7, 3, 5]; //['Alarm Index, Alarm name', 'SEVERITY', 'DURATION];
  const specialAssetProperties = [1, 2, 4, 3, 5, 8, 12, 13, 14, 15, 17, 18]; //['Asset ID', 'Asset name', 'Organization', 'Geography', 'Status','Asset Type', 'PLC Model', 'PLC Catalog Number', 'PLC Type', 'Router Model', 'Signal strength', 'Network type'];
  const maxSelectionsByOperator = ['IS', 'IS_NOT', 'SMALLER_THAN', 'LARGER_THAN'];

  const assetPropertiesStatus = [
    'AVAILABLE',
    'CONNECTED_OK',
    'CONNECTED_CRITICAL_ALARM',
    'CONNECTED_MAJOR_ALARM',
    'CONNECTED_MINOR_ALARM',
    'CONNECTED_WARNING',
    'DISCONNECTED',
    'COMMUNICATION_ERROR',
    // 'ARCHIVED',
  ];

  const alarmInfosSeverity = ['OK', 'WARN', 'MINOR', 'MAJOR', 'CRITICAL'];

  const booleanTypeLabels = {
    true: 'True',
    false: 'False',
    ALARM_INFO_true: i18nService.translate(
      'create-widget-page.create-widget.step-three.alarms-filters-on'
    ),
    ALARM_INFO_false: i18nService.translate(
      'create-widget-page.create-widget.step-three.alarms-filters-off'
    ),
  };

  const booleanTypeOptions = [
    {
      value: 'true',
      label: i18nService.translate(
        'create-widget-page.create-widget.step-three.alarms-filters-true'
      ),
    },
    {
      value: 'false',
      label: i18nService.translate(
        'create-widget-page.create-widget.step-three.alarms-filters-false'
      ),
    },
  ];

  const booleanAlarmTypeOptions = [
    {
      value: 'true',
      label: i18nService.translate('create-widget-page.create-widget.step-three.alarms-filters-on'),
    },
    {
      value: 'false',
      label: i18nService.translate(
        'create-widget-page.create-widget.step-three.alarms-filters-off'
      ),
    },
  ];

  const { filter, idx } = props;

  const onUpdateValues = (val, idx) => {
    updateFilter('values', idx, val);
  };

  const onUpdateSingleValues = (val, idx) => {
    updateFilter('values', idx, [val.value]);
  };

  const onUpdateGeoValues = (val, idx) => {
    const values = Array.isArray(val) ? val : [val];
    updateFilter('values', idx, values);
  };

  const dateSelectedChange = (value, idx) => {
    updateFilter('values', idx, [{ type: value.dateFilter, date: value.dateFilterFrom }]);
  };

  const getFilterAutocomplete = (filter: any, idx: number) => {
    if (![3, 5, 6, 7, 8, 16, 19].includes(filter.valueId)) {
      let queryParams = {
        p: 1,
        ps: 1000,
        property:
          filter.valueType === 'ALARM_INFO'
            ? alarmInfosMap[filter.valueId]
            : assetsPropsMap[filter.valueId],
      };

      // Will return string on assetTypes 3,4,5,6 || 3
      queryParams['assetTypes'] = assetTypes.reduce(
        (acc, asset) => (acc ? acc + ',' + asset.id : asset.id + ''),
        null
      );
      httpService.api({ type: 'getFilterAutocomplete', query: queryParams }).then((res: any) => {
        updateFilter('autocompleteOptions', idx, res.results);
      });
    }
  };

  const onUpdateFreeValues = (value, idx) => {
    updateFilter('values', idx, value);
  };

  const onUpdateDuration = (value, idx) => {
    updateFilter('values', idx, value);
  };

  const validationNewVal = (value, type) => {
    let reg = null;
    switch (type) {
      case 'ipv4Type':
        reg = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
        break;
      case 'numberType':
        reg = /^\-?\d+(\d+)?$/;
        break;
      case 'floatType':
        reg = /^\-?\d+((\.)\d+)?$/;
        break;
    }
    if (reg) return reg.test(value);
    return true;
  };

  const getValueSelected = (filter, options) => {
    if (filter.values.length) {
      const selected = options.find((at) => at.id == filter.values[0]);
      if (selected) return { value: selected.id, label: selected.name };
      return null;
    }
    return null;
  };

  // get type, filter name,data - return the data according the name (the server return different response)
  const getModifiedData = (context, id, data) => {
    switch (context) {
      case 'optionLabel':
        if (id === 4) return data.name;
        else if (id === 14) return staticDictionary[`enum.${data}`];
        return data;
      case 'optionValue':
        if (id === 4) return data.id;
        return data;
      case 'onChange':
        if (id === 4) return data.map((x) => x.id.toString());
        return data;
      case 'multiValue':
        if (id === 4) {
          return data.autocompleteOptions.filter((ao) => data.values.find((v) => v == ao.id));
        }
        return data.values;
      case 'optionsSelect':
        if (id === 4) return { value: data.id.toString(), label: data.name };
        else if (id === 14) return { value: data, label: staticDictionary[`enum.${data}`] };
        return { value: data, label: data };
      case 'singleValue':
        if (id === 4) return getValueSelected(data, data.autocompleteOptions);
        else if (id === 14)
          return { value: data.values[0], label: staticDictionary[`enum.${data.values[0]}`] };
        return data.values.length ? { value: data.values[0], label: data.values[0] } : null;
    }
  };

  useEffect(() => {
    if (assetTypes?.length && loadAutoComplete && !isEqual(prevFilters, props.filter)) {
      getFilterAutocomplete(filter, idx);
    }
  }, [props.filter]);

  const multi = !maxSelectionsByOperator.includes(filter.condition && filter.condition.value);
  if (
    ((filter.valueType === 'ALARM_INFO' && specialAlarmInfos.includes(filter.valueId)) ||
      (filter.valueType === 'ASSET_PROPERTY' && specialAssetProperties.includes(filter.valueId))) &&
    !filter.operation
  ) {
    if (
      assetPropertiesMultiSelectElemennt.includes(filter.valueId) ||
      alarmInfosMultiSelectElemennt.includes(filter.valueId)
    ) {
      !loadAutoComplete && setLoadAutoComplete(true);

      return multi ? (
        <MultiSelect
          id={`${idx}assetPropertyAuto`}
          options={filter.autocompleteOptions}
          getOptionLabel={(option) => getModifiedData('optionLabel', filter.valueId, option)}
          getOptionValue={(option) => getModifiedData('optionValue', filter.valueId, option)}
          values={getModifiedData('multiValue', filter.valueId, filter)}
          onChange={(e) => onUpdateValues(getModifiedData('onChange', filter.valueId, e), idx)}
          placeholder={'general.none'}
          optionType="checkbox"
          mode="thin"
        />
      ) : (
        <Select
          value={getModifiedData('singleValue', filter.valueId, filter)}
          options={filter.autocompleteOptions.map((x) => {
            return getModifiedData('optionsSelect', filter.valueId, x);
          })}
          placeholder={'general.none'}
          menuPortalTarget={document.body}
          onChange={(e) => onUpdateSingleValues(e, idx)}
        />
      );
    }
    if (filter.valueId == 3 && filter.valueType === 'ASSET_PROPERTY') {
      return (
        <GooglePlacesInput
          id="widgetFiltersGeo"
          mode="thin"
          defaultValue={multi ? filter.values : filter.values[0]}
          placeholder={'general.none'}
          onSelectedCountryChange={(e) => onUpdateGeoValues(e, idx)}
          isMulti={multi}
          allowSelectingUnknownAddress
        />
      );
    }
    if (
      (filter.valueId == 3 && filter.valueType === 'ALARM_INFO') ||
      (filter.valueId == 5 && filter.valueType === 'ASSET_PROPERTY')
    ) {
      const options =
        filter.valueType === 'ASSET_PROPERTY' ? assetPropertiesStatus : alarmInfosSeverity;
      return multi ? (
        <MultiSelect
          id={`${idx}status`}
          options={options}
          getOptionLabel={(option) => i18nService.translate(`enum.${option}`, languageId)}
          getOptionValue={(option) => i18nService.translate(`enum.${option}`, languageId)}
          values={filter.values}
          onChange={(e) => onUpdateValues(e, idx)}
          placeholder={'general.none'}
          optionType="checkbox"
          mode="thin"
        />
      ) : (
        <Select
          value={
            filter.values.length
              ? {
                  value: filter.values[0],
                  label: i18nService.translate(`enum.${filter.values[0]}`, languageId),
                }
              : null
          }
          options={options.map((x) => {
            return {
              value: x,
              label: i18nService.translate(`enum.${x}`, languageId),
            };
          })}
          placeholder={'general.none'}
          menuPortalTarget={document.body}
          onChange={(e) => onUpdateSingleValues(e, idx)}
        />
      );
    }
    if (filter.valueId == 8 && filter.valueType === 'ASSET_PROPERTY') {
      return multi ? (
        <MultiSelect
          id={`${idx}AssetType`}
          options={assetTypes}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          values={assetTypes.filter((at) => filter.values.find((v) => v == at.id))}
          onChange={(e) =>
            onUpdateValues(
              e.map((v) => v.id.toString()),
              idx
            )
          }
          placeholder={'general.none'}
          optionType="checkbox"
          mode="thin"
        />
      ) : (
        <Select
          value={getValueSelected(filter, assetTypes)}
          options={assetTypes.map((x) => {
            return { value: x.id.toString(), label: x.name };
          })}
          placeholder={'general.none'}
          menuPortalTarget={document.body}
          onChange={(e) => onUpdateSingleValues(e, idx)}
        />
      );
    }
    if (filter.valueId == 5 && filter.valueType === 'ALARM_INFO') {
      return <TimeFieldMore24 onUpdateDuration={onUpdateDuration} idx={idx} filter={filter} />;
    }
  } else {
    switch (filter.type) {
      case 'dateTimeType':
        const mode =
          widgetData.type === 'line' && widgetData.scope === 'RAW_DATA'
            ? 'line'
            : filter.valueType === 'ALARM_INFO' && 'alarms';

        return (
          <DatePicker
            height={28}
            singleDatePicker={
              mode !== 'line' && !operatorsEnableDatePickerRanges.includes(filter.condition.value)
            }
            disableCustomLabel={
              mode === 'line' || operatorsEnableDatePickerRanges.includes(filter.condition.value)
            }
            data={{
              dateFilter: filter.values[0].type,
              dateFilterFrom: filter.values[0].date,
              dateFilterTo: null,
            }}
            displayRanges={
              mode === 'line' ||
              operatorsEnableDatePickerRanges.includes(filter.condition && filter.condition.value)
            }
            mode={mode || undefined}
            selectedChanged={(e) => dateSelectedChange(e, idx)}></DatePicker>
        );
      case 'booleanType':
        return (
          <Select
            value={
              filter.values.length
                ? {
                    value: filter.values[0],
                    label:
                      filter.values[0].label || filter.valueType === 'ALARM_INFO'
                        ? booleanTypeLabels[`ALARM_INFO_${filter.values[0]}`]
                        : booleanTypeLabels[filter.values[0]],
                  }
                : null
            }
            options={
              filter.valueType === 'ALARM_INFO' ? booleanAlarmTypeOptions : booleanTypeOptions
            }
            menuPortalTarget={document.body}
            onChange={(e) => onUpdateSingleValues(e, idx)}
          />
        );
      default:
        return multi ? (
          <MultiSelect
            id={`${idx}freeText`}
            values={filter.values}
            onChange={(e) => onUpdateFreeValues(e, idx)}
            placeholder={'general.none'}
            isCreatable
            mode="thin"
            creatableValidation={(e) => validationNewVal(e, filter.type)}
          />
        ) : (
          <Select
            value={
              filter.values.length ? { value: filter.values[0], label: filter.values[0] } : null
            }
            isCreatable
            creatableValidation={(e) => validationNewVal(e, filter.type)}
            placeholder={'general.none'}
            menuPortalTarget={document.body}
            onChange={(e) => onUpdateSingleValues(e, idx)}
          />
        );
    }
  }
};

export default ValuesComponent;
