import React, { useState } from 'react';
import Icon from '@components/Icon';
import styles from './ThumbnailWidgetGroup.scss';
import { MenuItem, Menu, makeStyles } from '@material-ui/core';
import ThumbnailWidgetWrapper from '../ThumbnailWidgetWrapper';
import { i18nService } from '@core/i18n/I18nService';
import I18n from '@components/I18n';
import { useSelector } from '@src/redux/useSelector';

const useStyles = makeStyles(() => ({
  menuItems: {
    height: 'fit-content',
    width: '194px',
    '& .MuiListItem-gutters': {
      paddingLeft: '4px',
      paddingRight: '3px',
    },
    '& .MuiButtonBase-root': {
      cursor: 'auto',
    },
  },
  name: {
    color: 'var(--systemFont)',
  },
  widgetGroupLabel: {
    fontSize: '14px',
    fontWeight: 800,
    color: 'var(--systemFont)',
  },
  transparent: {
    '& .MuiListItem-button': {
      paddingBottom: 0,
      backgroundColor: 'transparent',
    },
    '& .MuiList-padding': {
      padding: 0,
    },
    '& .MuiPaper-elevation8': {
      boxShadow: 'none',
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
      borderRadius: 0,
    },
    '& .MuiMenuItem-root': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

function ThumbnailWidgetGroup(props) {
  const { widgetGroup, theme, isRegularWidget } = props;
  const classes = useStyles(props);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectionBox = useSelector((state) => state.dashboardEditor.selectionBox);

  const handleMenu = (event) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <div
      style={
        anchorEl
          ? {
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }
          : selectionBox
          ? { pointerEvents: 'none' }
          : undefined
      }
      className={theme ? styles[`thumbnailWidget${theme}`] : styles.thumbnailWidgetGroup}
      onClick={handleMenu}
      title={i18nService.translate(widgetGroup.label)}>
      <div className={classes.transparent}>
        <MenuItem>
          <Icon type={widgetGroup && widgetGroup.thumbnail} className={styles.icon} />
          <I18n element="div" className={classes.widgetGroupLabel}>
            {widgetGroup.label}
          </I18n>
        </MenuItem>
      </div>

      <Menu
        className={classes.transparent}
        id="widget-group"
        open={!!anchorEl}
        anchorEl={anchorEl}
        anchorReference={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        MenuListProps={{ onMouseLeave: handleCloseMenu }}
        onClose={handleCloseMenu}>
        <div className={classes.menuItems}>
          {widgetGroup.widgets.map((widget, idx) => (
            <MenuItem className={classes.transparent} key={idx}>
              <div className={classes.name}>
                <ThumbnailWidgetWrapper
                  index={idx}
                  key={widget.name}
                  widgetType={widget.id}
                  isRegularWidget={isRegularWidget}
                />
              </div>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  );
}

export default ThumbnailWidgetGroup;
