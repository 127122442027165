import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import I18n from '@components/I18n';
import RichTextBox from '@components/RichTextBox/RichTextBox';
import { useSelector } from '@src/redux/useSelector';
import MaterialCheckbox from '@components/Checkbox';
import LogoIcon from '@components/LogoIcon';
import { debounce } from 'lodash';
import { httpService } from '@core/http/HttpService';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    padding: '26px 40px',
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    flexDirection: 'column',
  },
  wrapperEditor: {
    display: 'flex',
  },
  sidesWrapper: {
    width: '50%',
    paddingRight: 70,
    boxSizing: 'border-box',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 7,
  },
  preview: {
    border: `1px dashed ${theme.app.grey}`,
    padding: 16,
    borderRadius: 5,
    height: 213,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    background: theme.app.white1,
    maxWidth: `100%`,
    '& p': {
      margin: 0,
    },
  },
  displayLogo: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  checkboxText: {
    marginLeft: 5,
  },
}));

function EmailFooter(props) {
  const { displaySettingsData, setDisplaySettingsData } = props;
  const { freeLogo, emailFooter } = displaySettingsData;
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const displayLogo = isPremium ? freeLogo : whiteLabelDetails.premiumLogo;
  const classes = useStyles(props);

  const updateEmailFooter = async (key, value, disableBI?) => {
    const content = {
      ...emailFooter?.content,
      [key]: value,
    };
    setDisplaySettingsData((prevState) => ({
      ...prevState,
      emailFooter: { ...prevState.emailFooter, content },
    }));
    try {
      const res: any = await httpService.api({
        type: 'updateDisplaySettings',
        data: { whiteLabelType: 'emailFooter', content },
        disableBI,
      });
    } catch {}
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.displayLogo}>
        <MaterialCheckbox
          disabled={!isPremium}
          checked={emailFooter?.content?.displayLogo}
          color="primary"
          onChange={(e) => updateEmailFooter('displayLogo', e.target.checked)}
        />
        <I18n className={classes.checkboxText}>white-labeling.email-footer.displayLogo</I18n>
      </div>
      <div className={classes.wrapperEditor}>
        <div className={classes.sidesWrapper}>
          <I18n element="div" className={classes.title}>
            white-labeling.email-footer.edit
          </I18n>
          <RichTextBox
            disabled={!isPremium}
            maxLength={500}
            value={emailFooter?.content?.email}
            onChange={debounce((val) => updateEmailFooter('email', val, true), 500)}
          />
        </div>
        <div className={classes.sidesWrapper}>
          <I18n element="div" className={classes.title}>
            white-labeling.email-footer.preview
          </I18n>
          <div className={classes.preview}>
            {displayLogo?.content?.logoUrl && emailFooter?.content?.displayLogo && (
              <LogoIcon logo={displayLogo?.content?.logoUrl} width={125} height={25} />
            )}
            <div dangerouslySetInnerHTML={{ __html: emailFooter?.content?.email }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailFooter;
