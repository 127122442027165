import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { compact } from 'lodash';
import styles from './NewLanguageModal.scss';
import Icon from '@components/Icon';
import * as Yup from 'yup';
import { i18nService } from '@core/i18n/I18nService';
import Button from '@components/Button';
import { Formik, Form as FormikForm } from 'formik';
import FormikField from '@components/FormikField';
import I18n from '@components/I18n';
import { ModalComponentProps } from '@core/modals/modals.interface';
import { httpService } from '@core/http/HttpService';
import { buildErrorObj } from '@core/utils';
import { IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';

function NewLanguageModal(props: ModalComponentProps) {
  const {
    dismiss,
    args: { languages, languageData },
  } = props;
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const [flags, setFlags] = useState([]);
  const [nameValidation, setNameValidation] = useState({
    name: languageData?.name || '',
    message: '',
    isValid: true,
  });
  const [saving, setSaving] = useState(false);
  const [isValidName, setIsValidName] = useState(false);
  const nameValidationRef = useRef(null);
  nameValidationRef.current = nameValidation;
  const isValidNameRef = useRef(null);
  isValidNameRef.current = isValidName;

  const initialValues = useMemo(() => {
    const language = languageData || languages.find((lang) => lang.isDefault);
    const flag = language && flags.find((f) => f.url.split('?')[0] === language.flag.split('?')[0]);
    return {
      name: languageData?.name || '',
      flag: { label: flag?.name, value: flag?.id, extraName: flag?.url },
      source: !languageData?.id && { value: language.id, label: language.name },
    };
  }, [flags, languages, languageData]);

  useEffect(() => {
    const getFlags = async () => {
      const res: any = await httpService.api({ type: 'getFlags' });
      res && setFlags(res);
    };

    getFlags();
  }, []);

  const fieldsCfg = compact([
    {
      label: 'languages.language-name',
      name: 'name',
      type: 'input',
      isRequired: true,
      validate: (values) => {
        if (nameValidation.name === values.name && nameValidation.message) {
          return nameValidation.message;
        }

        return '';
      },
      onLostFocus: (values, setFieldValue, setInputValue, setErrors) => {
        if (
          nameValidation.name.toLowerCase() !== values.name.toLowerCase() &&
          languageData?.name.toLowerCase() !== values.name.toLowerCase()
        ) {
          httpService
            .api({
              type: 'validateLanguageName',
              data: { name: values['name'] },
              disableBI: true,
            })
            .then((res: any) => {
              setNameValidation({ name: '', message: '', isValid: true });
              setIsValidName(true);
            })
            .catch((res: any) => {
              const message =
                res.data.message &&
                i18nService.translate(
                  `errors.${res.data.code}`,
                  undefined,
                  buildErrorObj(res.data.keys)
                );
              setIsValidName(true);

              setNameValidation({ name: values['name'], message, isValid: false });
              setErrors({ name: message });
            });
        } else if (nameValidation.name !== values.name && languageData?.name !== values.name) {
          setNameValidation({ name: '', message: '', isValid: true });
          setIsValidName(true);
        }
      },
      validation: Yup.string()
        .required('languages.mandatory')
        .max(30, 'languages.name-message')
        .min(2, 'languages.name-message')
        .matches(/^[^<>*%:&\\'\]\["^@#$!\(\)\/;\{\}+`,\.~=\?]*$/, 'languages.name-message'),
    },
    {
      label: 'languages.flag',
      isRequired: true,
      validation: Yup.object().required('languages.mandatory'),
      name: 'flag',
      type: 'select',
      selectType: 'icons',
      maxMenuHeight: 108,
      options: flags
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((flag) => ({ value: flag.id, label: flag.name, extraName: flag.url })),
    },
    (!languageData || !languageData.id) && {
      label: 'languages.language-source',
      isRequired: true,
      validation: Yup.object().required('languages.mandatory'),
      name: 'source',
      type: 'select',
      maxMenuHeight: 108,
      options: languages.map((lang) => ({ value: lang.id, label: lang.name })),
    },
  ]);

  const onSubmit = async (values: any) => {
    setSaving(true);

    const myInterval = setInterval(() => {
      if (
        (isValidNameRef.current &&
          nameValidationRef.current &&
          nameValidationRef.current.isValid) ||
        (languageData?.id && languageData?.name === values.name)
      ) {
        save(values);
        clearInterval(myInterval);
      } else if (isValidNameRef.current && !nameValidationRef.current.isValid) {
        clearInterval(myInterval);
        setSaving(false);
        setIsValidName(false);
      }
    }, 100);
  };

  const save = async (values) => {
    const data = await httpService.api({
      type: languageData?.id ? 'updateLanguage' : 'createLanguage',
      urlParams: languageData?.id && { languageId: languageData?.id },
      data: languageData?.id
        ? { name: values.name, flagId: values.flag.value }
        : { name: values.name, flagId: values.flag.value, sourceId: values.source.value },
    });

    const flag = flags.find((f) => f.id === values.flag.value);

    dismiss(languageData?.id ? { ...languageData, flag: flag?.url, name: values.name } : data);
  };

  const validationSchema = (fieldsCfg) => {
    return Yup.object().shape(
      fieldsCfg.reduce(
        (res, item) => (item.validation ? { ...res, [item.name]: item.validation } : res),
        {}
      )
    );
  };

  const validate = (values) =>
    [...fieldsCfg].reduce(
      (res, item: any) =>
        item.validate && item.validate(values)
          ? { ...res, [item.name]: item.validate(values) }
          : res,
      {}
    );

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>{languageData?.id ? 'languages.edit-language' : 'languages.add-new-language'}</I18n>
        <div>
          <IconButton className={'pointer'} onClick={cancel}>
            <Close />
          </IconButton>
        </div>
        {/* <Icon type="close" onClick={cancel} className={'pointer'}></Icon> */}
      </div>
      <div className={styles.modalContent}>
        <Formik
          enableReinitialize
          initialValues={{ ...initialValues }}
          validationSchema={validationSchema([...fieldsCfg])}
          validate={validate}
          onSubmit={onSubmit}>
          {({ isValid, values, setFieldValue }) => {
            return (
              <FormikForm className={styles.form}>
                <div className={styles.content}>
                  {fieldsCfg.map((field, idx) => (
                    <FormikField
                      key={idx}
                      editMode={true}
                      {...field}
                      values={values}
                      setFieldValue={setFieldValue}></FormikField>
                  ))}
                </div>
                <div className={styles.footerButtons}>
                  <Button styles={{ width: 92, marginRight: 13 }} onClick={cancel} mode="cancel">
                    {i18nService.translate('general.cancel')}
                  </Button>
                  <Button styles={{ width: 92 }} type="submit" disabled={!isValid || saving}>
                    {i18nService.translate(`general.${languageData?.id ? 'confirm' : 'save'}`)}
                  </Button>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default NewLanguageModal;
