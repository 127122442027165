import { pullAllWith, isEqual, omit, compact } from 'lodash';
import { httpService } from '@core/http/HttpService';
import qs from 'qs';
import { getWidgetData } from '@pages/GetWidgetDataService';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { saveAs } from 'file-saver';
import { useCallback } from 'react';
import { getState } from '@src/redux/store';

const GET_DATA_INTERVAL = 300000;
const downloadPollingInterval = 20000;

const filterOptions = [
  {
    valueType: 'ASSET_PROPERTY',
    valueId: 3, //GEO
  },
  {
    valueType: 'ASSET_PROPERTY',
    valueId: 4, // ORGANIZATION
  },
  {
    valueType: 'ASSET_PROPERTY',
    valueId: 7, // DATE
  },
  {
    valueType: 'ASSET_PROPERTY',
    valueId: 8, //ASSET_TYPE
  },
];

function download(url, name) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    saveAs(xhr.response, name);
  };
  xhr.onerror = function () {
    console.error('could not download file');
  };
  xhr.send();
}

const downloadFile = (fileName) => {
  httpService
    .api({
      type: 'downloadFile',
      query: { file: fileName },
    })
    .then((res: string) => {
      download(res as string, fileName);
    });
};

export const updateDownloadStatusState = (res, jobs, setWidgetsDownloadStatus) => {
  setWidgetsDownloadStatus((state) => {
    const data = Object.keys(state).reduce((data, stat) => {
      const newStatus = !jobs.includes(state[stat].jobId) ? { [stat]: state[stat] } : {};
      return {
        ...data,
        ...newStatus,
      };
    }, {});
    return { ...res, ...data };
  });
};

export const getJobsStatus = async (jobs, downloadStatus, dashboardId) => {
  const res: any = await httpService.api({
    type: 'getJobs',
    urlParams: { dashboardId },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    query: { jobs },
    disableBI: true,
  });
  //get all jobs data and return
  return res && res.length
    ? res.reduce((data, job) => {
        //TO HANDLE IF OTHER TYPES WILL COME
        if (job.type !== 'EXPORT_WIDGET') {
          return data;
        }
        switch (job.status) {
          case 'IN_PROGRESS':
            return {
              ...data,
              [job.widgetId]: job,
            };
          case 'COMPLETED':
            downloadFile(job.fileName);
            return omit(data, [job.widgetId]);
          case 'COMPLETED_WITH_ERRORS':
            return omit(data, [job.widgetId]);
        }
      }, downloadStatus)
    : {};
};

// DO NOT DELETE
// const defaultDashboardFilters = {
//   assetTypesFilter: [],
//   dateFilter: 'MONTH',
//   dateFilterFrom: null,
//   dateFilterTo: null,
//   geoFilter: [],
//   organizationsFilter: []
// };

const isAssetsEqual = (a, b) => a.valueId === b.valueId && a.valueType === b.valueType;

export const buildDashboardFilters = (navigationFilters, dashboardFilters, widgetFilters) => {
  const newWidgetFilters = pullAllWith(widgetFilters, filterOptions, isAssetsEqual);
  const tempDashboardFilters = {
    assetTypesFilter: dashboardFilters
      ? dashboardFilters.assetTypesFilter
        ? dashboardFilters.assetTypesFilter.map((at) => at.id)
        : null
      : [],
    assetFilter: dashboardFilters
      ? dashboardFilters.assetFilter
        ? dashboardFilters.assetFilter.map((asset) => asset.id)
        : null
      : [],
    dateFilter: dashboardFilters ? dashboardFilters.dateFilter : 'MONTH',
    dateFilterFrom: dashboardFilters ? dashboardFilters.dateFilterFrom : null,
    dateFilterTo: dashboardFilters ? dashboardFilters.dateFilterTo : null,
    geoFilter: dashboardFilters
      ? dashboardFilters.geoFilter
        ? dashboardFilters.geoFilter
        : null
      : [],
    organizationsFilter: dashboardFilters
      ? dashboardFilters.organizationsFilter
        ? dashboardFilters.organizationsFilter.map((at) => at.id)
        : null
      : [],
  };

  // DO NOT DELETE
  //   pullAllWith(navigationFilters, filterOptions, isAssetsEqual).forEach(filter => {
  //     const filterData = widgetFilters.find(f => isAssetsEqual(f, filter)) || {};
  //     if (filter.valueId === 8) {
  //       tempDashboardFilters.assetTypesFilter =
  //         filterData.values || dashboardFilters.assetTypesFilter || [];
  //     } else if (filter.valueId === 4) {
  //       tempDashboardFilters.organizationsFilter =
  //         filterData.values || dashboardFilters.organizationsFilter || [];
  //     } else if (filter.valueId === 2) {
  //       tempDashboardFilters.geoFilter = filterData.values || dashboardFilters.geoFilter || [];
  //     } else if (filter.valueId === 1) {
  //       tempDashboardFilters.dateFilter = filterData.values
  //         ? 'CUSTOM'
  //         : dashboardFilters.dateFilter
  //         ? dashboardFilters.dateFilter
  //         : 'MONTH';
  //       tempDashboardFilters.dateFilterTo =
  //         filterData.values[0] || dashboardFilters.dateFilterTo || null;
  //       tempDashboardFilters.dateFilterFrom =
  //         filterData.values[0] || dashboardFilters.dateFilterFrom || null;
  //     }
  //   });

  return {
    newWidgetFilters,
    newDashboardFilters: dashboardFilters ? tempDashboardFilters : null,
  };
};

/**
 * get widget filters from widget and build it for navigation.
 * @selectedValue - the value the user press on.
 * @selectedColumn - the column that selectedValue is on it.
 * @widget - widget info that come from widget parent.
 * @columns - all data columns.
 * @rawValues - values object of the row that has been clicked
 * @navigateDashboard - function that come from widget parent.
 */
export const navigationDataBuilder = async ({
  selectedValue,
  selectedColumn,
  widget,
  columns,
  rawValues,
  navigateDashboard,
}: any) => {
  const assetId =
    rawValues[
      columns?.find((col) => col.valueType === 'ASSET_PROPERTY' && col.valueId === 1)?.name
    ] || rawValues?.asset_id;
  const navigationDashboard = widget.navigationDashboard;
  const isUrlNavigation = navigationDashboard.some((item) => item.type === 'URL');
  let dashboardToGo = null;
  let urlToGo = null;

  if (navigationDashboard.length) {
    //if navigate by asset type
    if (
      navigationDashboard[0].assetTypeId &&
      (!rawValues.assetId || !Array.isArray(rawValues.assetId))
    ) {
      // get assetTypeId (from BE || from data)
      const res: any = rawValues.asset_type_id
        ? { assetTypeId: rawValues.asset_type_id }
        : await httpService.api({
            type: 'getAssetTypeIdByAssetId',
            urlParams: { asset_id: assetId },
          });

      //get dashboard or URL by asset type id
      const dashboardOrUrl =
        res.assetTypeId && navigationDashboard.find((item) => item.assetTypeId === res.assetTypeId);

      isUrlNavigation
        ? (urlToGo = dashboardOrUrl && dashboardOrUrl.url)
        : (dashboardToGo = dashboardOrUrl && dashboardOrUrl.id);
    } else {
      // if not, navigate according to the main navigation selection
      isUrlNavigation
        ? (urlToGo = navigationDashboard[0].url)
        : (dashboardToGo = navigationDashboard[0].id);
    }
  } else {
    // fallback for deprecated navigation settings
    dashboardToGo = !Array.isArray(navigationDashboard) && navigationDashboard;
  }

  if (navigateDashboard && urlToGo) {
    const clientType = getState().clientState?.clientType;
    switch (clientType) {
      case 'uniCloudApp':
        uniCloudApp?.openUrlInBrowser(urlToGo);
        break;
      case 'iOSUniCloudApp':
        // console.log('Before openUrlInBrowser');
        window['webkit']['messageHandlers']['openUrlInBrowser'].postMessage(urlToGo);
        // console.log('After openUrlInBrowser');
        break;
      default:
        window.open(urlToGo);
        break;
    }
  }

  if (navigateDashboard && dashboardToGo) {
    switch (widget.type) {
      case 'map':
        mapNavigation({ widget, dashboardToGo, navigateDashboard, rawValues });
        break;
      case 'line':
        lineNavigation({
          selectedValue,
          selectedColumn,
          widget,
          navigateDashboard,
          dashboardToGo,
        });
        break;
      case 'text':
      case 'image':
        rawValues?.length
          ? textOrImageNavigation({
              widget,
              dashboardToGo,
              navigateDashboard,
              assetFilter: rawValues,
            })
          : defaultNavigation({
              selectedValue,
              selectedColumn,
              widget,
              columns,
              rawValues,
              navigateDashboard,
              dashboardToGo,
            });
        break;
      case 'asset':
        comboWidgetNavigation({
          widget,
          dashboardToGo,
          navigateDashboard,
          assetIdColumn: columns.find(
            (col) => col.valueType === 'ASSET_PROPERTY' && col.valueId === 1
          ),
          assetFilter: rawValues,
        });
        break;
      case 'heatmap':
        heatmapNavigation({
          widget,
          dashboardToGo,
          navigateDashboard,
          assetId: rawValues.asset_id,
        });
        break;
      default:
        defaultNavigation({
          selectedValue,
          selectedColumn,
          widget,
          columns,
          rawValues,
          navigateDashboard,
          dashboardToGo,
        });
    }
  }
};

const lineNavigation = ({
  selectedValue,
  selectedColumn,
  widget,
  navigateDashboard,
  dashboardToGo,
}) => {
  const isClickable =
    selectedColumn &&
    widget.navigationFilters &&
    widget.navigationFilters.find((n) =>
      isAssetsEqual(n, { valueId: selectedColumn.fieldId, valueType: selectedColumn.fieldType })
    );
  const widgetFilters = [];
  if (isClickable && !selectedColumn.fieldOperation) {
    widgetFilters.push({
      valueType: selectedColumn.fieldType,
      valueId: selectedColumn.fieldId,
      condition: Array.isArray(selectedValue) ? 'INCLUDES' : 'IS',
      values: Array.isArray(selectedValue) ? selectedValue : [selectedValue],
    });
  }

  if (widget.navigationFilters.find((n) => n.valueId === 1 && n.valueType === 'ASSET_PROPERTY')) {
    if (selectedColumn.fieldId !== 1 || selectedColumn.fieldType !== 'ASSET_PROPERTY') {
      widgetFilters.push({
        valueType: 'ASSET_PROPERTY',
        valueId: 1,
        condition: 'IS',
        values: Array.isArray(selectedColumn.assetId)
          ? selectedColumn.assetId
          : [selectedColumn.assetId],
      });
    }
  }

  navigateDashboard(dashboardToGo, widget.navigationFilters, widgetFilters);
};

const mapNavigation = ({ widget, dashboardToGo, navigateDashboard, rawValues }) => {
  const assetIdFilter = {
    order: 1,
    valueType: 'ASSET_PROPERTY',
    valueId: 1,
    condition: 'IS',
    values: Array.isArray(rawValues.asset_id) ? [+rawValues.asset_id[0]] : [+rawValues.asset_id],
  };
  navigateDashboard(
    dashboardToGo,
    widget.navigationFilters,
    widget.widgetFilters ? [...widget.widgetFilters, assetIdFilter] : [assetIdFilter]
  );
};

const textOrImageNavigation = ({ widget, dashboardToGo, navigateDashboard, assetFilter }) => {
  const assetIdFilter = {
    order: 1,
    valueType: 'ASSET_PROPERTY',
    valueId: 1,
    condition: 'IS',
    values: assetFilter,
  };
  navigateDashboard(
    dashboardToGo,
    widget.navigationFilters,
    widget.widgetFilters ? [...widget.widgetFilters, assetIdFilter] : [assetIdFilter]
  );
};

const comboWidgetNavigation = ({
  widget,
  dashboardToGo,
  navigateDashboard,
  assetIdColumn,
  assetFilter,
}) => {
  const assetIdFilter = {
    order: 1,
    valueType: 'ASSET_PROPERTY',
    valueId: 1,
    condition: 'IS',
    values: [assetFilter[assetIdColumn['name']]],
  };
  navigateDashboard(dashboardToGo, widget.navigationFilters, [assetIdFilter]);
};

const heatmapNavigation = ({ widget, dashboardToGo, navigateDashboard, assetId }) => {
  const assetIdFilter = {
    order: 1,
    valueType: 'ASSET_PROPERTY',
    valueId: 1,
    condition: 'IS',
    values: [assetId],
  };
  navigateDashboard(dashboardToGo, widget.navigationFilters, [assetIdFilter]);
};

const defaultNavigation = ({
  selectedValue,
  selectedColumn,
  widget,
  columns,
  rawValues,
  navigateDashboard,
  dashboardToGo,
}) => {
  const isClickable =
    selectedColumn &&
    widget.navigationFilters &&
    widget.navigationFilters.find((n) => isAssetsEqual(n, selectedColumn));

  const isNavigateByAssetId = widget.navigationFilters.find(
    (n) => n.valueId === 1 && n.valueType === 'ASSET_PROPERTY'
  );

  const widgetFilters = [];
  if (isClickable && !selectedColumn.operation) {
    widgetFilters.push({
      valueType: selectedColumn.valueType,
      valueId: selectedColumn.valueId,
      condition: Array.isArray(selectedValue) ? 'INCLUDES' : 'IS',
      values: Array.isArray(selectedValue) ? selectedValue : [selectedValue],
    });
  }

  const allowAssetTypeNavigationWithoutAssetProperties = getConfigValueFromWidgetSettings(
    widget.type,
    'allowAssetTypeNavigationWithoutAssetProperties'
  );

  if (rawValues && isNavigateByAssetId && !allowAssetTypeNavigationWithoutAssetProperties) {
    const assetIdColumn = columns.find(
      (column) => column.valueId === 1 && column.valueType === 'ASSET_PROPERTY' && !column.operation
    );

    if (
      assetIdColumn &&
      !isEqual(assetIdColumn, omit(selectedColumn, ['isClickable', 'onClickCell', 'title'])) &&
      !isAssetsEqual(assetIdColumn, widgetFilters[0] || {})
    ) {
      widgetFilters.push({
        valueType: assetIdColumn.valueType,
        valueId: assetIdColumn.valueId,
        condition: 'IS',
        values: [rawValues[assetIdColumn.name]],
      });
    }
  }

  if (!isNavigateByAssetId) {
    // Navigate by Asset ID is unchecked, and this is Text / Image element
    // Clears the filters in navigation
    navigateDashboard(
      dashboardToGo,
      widget.navigationFilters,
      widgetFilters,
      undefined,
      false,
      false,
      true
    );
  } else {
    navigateDashboard(dashboardToGo, widget.navigationFilters, widgetFilters);
  }
};

export const navigateByDashboardIdType = (
  dashboardService,
  isNavigateInside,
  isPreview,
  setNoDashboardAvailable,
  timeoutHandler,
  organizationDetails,
  navigateDashboard,
  dashboardId
) => {
  // isNavigateInside = false;
  if (!isPreview) {
    dashboardService.dashboardIdType = 'ref';

    if (organizationDetails && organizationDetails.dashboardRefId) {
      navigateDashboard(organizationDetails.dashboardRefId, undefined, undefined, true);
    } else {
      setNoDashboardAvailable(true);
      return;
    }
  } else {
    dashboardService.dashboardIdType = 'id';
    navigateDashboard(dashboardId, undefined, undefined, true);
  }

  return () => {
    clearTimeout(timeoutHandler);
    dashboardService.firstDashboardId = undefined;
  };
};

const getDashboardById = (
  layoutService,
  dashboardService,
  dashboardId,
  newDashboardFilters,
  defaultDashboard,
  setUserFilters,
  setDefaultDashboard,
  setWidgetsDownloadStatus,
  setDashboardFilters,
  timeoutHandler,
  pageRef,
  navigateDashboard
) => {
  const prevDashboardId = defaultDashboard ? defaultDashboard.id : null;
  dashboardService
    .getDashboardById(dashboardId, {}, newDashboardFilters, { onlyPublishedWidgets: true })
    .then((dashboard) => {
      setDefaultDashboard(dashboard);
      setWidgetsDownloadStatus({});
      setDashboardFilters({
        assetTypesFilter: dashboard.assetTypesFilter,
        assetFilter: dashboard.assetFilter,
        dateFilter: dashboard.dateFilter,
        dateFilterFrom: dashboard.dateFilterFrom,
        dateFilterTo: dashboard.dateFilterTo,
        geoFilter: dashboard.geoFilter,
        organizationsFilter: dashboard.organizationsFilter,
      });
      layoutService.importWidgetsFromDashboard(dashboard);
      handleRefreshData(dashboardId, prevDashboardId, timeoutHandler, pageRef, navigateDashboard);
      // Tells widgets to call getWidgetData when dashboard navigates to itself
      if (!prevDashboardId || prevDashboardId === dashboardId) {
        setUserFilters(new Date());
      }
    });
};

const handleRefreshData = (
  dashboardId: number,
  prevDashboardId: number,
  timeoutHandler,
  pageRef,
  navigateDashboard
) => {
  clearTimeout(timeoutHandler);

  if (prevDashboardId && prevDashboardId !== dashboardId) pageRef.current.scrollTop = 0;
  timeoutHandler = setTimeout(() => navigateDashboard(dashboardId), GET_DATA_INTERVAL);
};

const routeUrl = (dashboardId, isPreview) =>
  isPreview ? `/main/edit-dashboard/${dashboardId}/preview` : `/main/dashboard/${dashboardId}/`;

export const onClickNavigateDashboard = (
  layoutService,
  dashboardService,
  firstInit,
  isPreview,
  defaultDashboard,
  setUserFilters,
  setDefaultDashboard,
  setWidgetsDownloadStatus,
  setDashboardFilters,
  widgetFilters,
  setWidgetFilters,
  tempWidgetFilters,
  navigationFilters,
  history,
  firstDashboardId,
  dashboardId,
  dashboardFilters,
  isNavigateInside,
  timeoutHandler,
  pageRef,
  navigateDashboard
) => {
  history.replace(history.location.pathname, {
    ...history.location.state,
    userFilters: dashboardFilters,
  });
  // isNavigateInside = true;
  //id should be use only from preview and first dashboard navigation.
  if (firstDashboardId !== dashboardId.toString()) {
    dashboardService.dashboardIdType = 'ref';
  }
  const { newWidgetFilters, newDashboardFilters } = buildDashboardFilters(
    navigationFilters,
    dashboardFilters,
    tempWidgetFilters
  ) as any;
  if (navigationFilters) {
    setWidgetFilters(
      [...widgetFilters, ...newWidgetFilters].map((w, index) => ({ ...w, order: index }))
    );
  }
  getDashboardById(
    layoutService,
    dashboardService,
    dashboardId,
    newDashboardFilters,
    defaultDashboard,
    setUserFilters,
    setDefaultDashboard,
    setWidgetsDownloadStatus,
    setDashboardFilters,
    timeoutHandler,
    pageRef,
    navigateDashboard
  );

  /**
 make replace if it's the first init to avoid going back twice
 */
  const url = routeUrl(dashboardId, isPreview);
  history[firstInit ? 'replace' : 'push'](url, {
    navigationFilters,
    tempWidgetFilters,
    dashboardFilters,
    preview: isPreview,
  });
};

export const replaceUrlWithAllFilters = (
  layoutService,
  dashboardService,
  history,
  defaultDashboard,
  setUserFilters,
  setDefaultDashboard,
  setWidgetsDownloadStatus,
  setDashboardFilters,
  timeoutHandler,
  pageRef,
  navigateDashboard,
  setWidgetFilters,
  firstDashboardId,
  dashboardId,
  isPreview,
  navigationFilters,
  dashboardFilters,
  tempWidgetFilters
) => {
  //id should be use only from preview and first dashboard navigation.
  if (firstDashboardId === dashboardId.toString() && isPreview) {
    dashboardService.dashboardIdType = 'id';
  }
  const { newWidgetFilters, newDashboardFilters } = buildDashboardFilters(
    navigationFilters,
    dashboardFilters,
    tempWidgetFilters
  ) as any;

  if (navigationFilters) {
    setWidgetFilters(newWidgetFilters.map((w, index) => ({ ...w, order: index })));
  } else {
    setWidgetFilters([]);
  }
  getDashboardById(
    layoutService,
    dashboardService,
    dashboardId,
    newDashboardFilters,
    defaultDashboard,
    setUserFilters,
    setDefaultDashboard,
    setWidgetsDownloadStatus,
    setDashboardFilters,
    timeoutHandler,
    pageRef,
    navigateDashboard
  );
  history.replace(routeUrl(dashboardId, isPreview), {
    navigationFilters,
    tempWidgetFilters,
    dashboardFilters,
    preview: isPreview,
  });
};

export const onGetDataFromServer = (widgetId, paging, dashboardFilters, options, widgetFilters) => {
  return getWidgetData({
    widgetId,
    paging,
    dashboardFilters: {
      organizationsFilter: dashboardFilters.organizationsFilter
        ? dashboardFilters.organizationsFilter.map((f) => f.id)
        : null,
      assetTypesFilter: dashboardFilters.assetTypesFilter
        ? dashboardFilters.assetTypesFilter.map((f) => f.id)
        : null,
      assetFilter: dashboardFilters.assetFilter?.id
        ? [parseInt(dashboardFilters.assetFilter.id)]
        : [],
      geoFilter: dashboardFilters.geoFilter || null,
      dateFilter: dashboardFilters.dateFilter,
      dateFilterFrom: dashboardFilters.dateFilterFrom,
      dateFilterTo: dashboardFilters.dateFilterTo,
    },
    widgetFilters,
    options,
  });
};

export const startPolling = async (
  setWidgetsDownloadStatus,
  widgetsDownloadStatus,
  defaultDashboard,
  widgetsDownloadStatusRef
) => {
  //[1, 2, 4, 6] - getting job ids for first fetch
  const jobs = compact(
    Object.keys(widgetsDownloadStatus).map((key) => widgetsDownloadStatus[key].jobId)
  );

  if (jobs.length) {
    //first fetch
    const res = await getJobsStatus(jobs, widgetsDownloadStatus, defaultDashboard.id);
    res && setWidgetsDownloadStatus(res);

    const interval = setInterval(async () => {
      //[1, 2, 4, 6] - getting job ids

      const jobs = compact(
        //setIntervat not get the current state. only the state "status" that was in first declartion
        //for that use ref to the state.
        Object.keys(widgetsDownloadStatusRef.current).map(
          (key) => widgetsDownloadStatusRef.current[key].jobId
        )
      );

      if (jobs.length) {
        const res = await getJobsStatus(
          jobs,
          widgetsDownloadStatusRef.current,
          defaultDashboard.id
        );
        res && setWidgetsDownloadStatus(res);
      } else {
        clearInterval(interval);
      }
    }, downloadPollingInterval);
  }
};

export const onDifferentWidgetsDownloadStatus = (
  prevwidgetsDownloadStatus,
  widgetsDownloadStatus,
  defaultDashboard,
  widgetsDownloadStatusRef,
  setWidgetsDownloadStatus
) => {
  if (
    prevwidgetsDownloadStatus &&
    !isEqual(prevwidgetsDownloadStatus, widgetsDownloadStatus) &&
    !Object.keys(prevwidgetsDownloadStatus).length
  ) {
    startPolling(
      setWidgetsDownloadStatus,
      widgetsDownloadStatus,
      defaultDashboard,
      widgetsDownloadStatusRef
    );
  }
};

export const onStateLocationChange = (
  navigateDashboardOnUrlChange,
  dashboardId,
  location,
  isNavigateInside,
  prevLocationState
) => {
  if (
    dashboardId &&
    location.state &&
    !isEqual(omit(location.state, ['userFilters']), omit(prevLocationState, ['userFilters'])) &&
    !isNavigateInside
  ) {
    const {
      navigationFilters,
      tempWidgetFilters,
      dashboardFilters = {},
      userFilters = {},
    } = location.state || {};
    const tempDashboardFilters = { ...dashboardFilters, ...userFilters };
    navigateDashboardOnUrlChange(
      +dashboardId,
      navigationFilters,
      tempWidgetFilters,
      Object.keys(tempDashboardFilters).length && tempDashboardFilters
    );
  } else {
    isNavigateInside = false;
  }
};

export const genarateLayoutOnNavigatingNewDashboad = (
  layoutService,
  prevWidgets,
  pageWidth,
  widgets
) => {
  if (prevWidgets && !isEqual(omit(prevWidgets, 'position'), omit(widgets, 'position'))) {
    layoutService.generateLayout(pageWidth);
  }
};
