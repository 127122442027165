import React, { useCallback } from 'react';

// Components
import Button from '@components/Button';
import I18n from '@components/I18n';
import FormikRouterInput from '@components/Formik/FormikRouterInput';
import Icon from '@components/Icon';

// Services | Interfaces
import { ModalComponentProps } from '@core/modals/modals.interface';
import { RouterModel } from './CreateRouterModal.interface';
import styles from './CreateRouterModal.scss';

// 3rd party
import { Formik, Form } from 'formik';

const initialValues: RouterModel = {
  routerSerial: { id: null, text: '', message: '' },
};

function CreateRouterModal(props: ModalComponentProps) {
  const { dismiss } = props;
  const cancel = useCallback(() => dismiss(false), [dismiss]);
  const onSubmit = useCallback(
    (values: RouterModel) => {
      dismiss({
        id: values.routerSerial.id,
      });
    },
    [dismiss]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.modalHeader}>
        <I18n>create-router-modal.title</I18n>
        <Icon type="close" onClick={cancel} className={'pointer'}></Icon>
      </div>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isValid, isSubmitting, errors }) => (
          <Form className={styles.modalForm}>
            <div className={styles.modalContent}>
              <FormikRouterInput
                name="routerSerial"
                label="router-validation.router-serial-number"
                type="addToOrganization"
                isRequired={true}
                allowRowsEllipsis
              />
            </div>
            <div className={styles.modalButtons}>
              <Button
                styles={{ marginLeft: 13 }}
                mode={['cancel', 'bigFont']}
                disabled={isSubmitting}
                onClick={cancel}>
                <I18n>general.cancel</I18n>
              </Button>
              <Button
                styles={{ marginLeft: 13 }}
                mode="bigFont"
                type="submit"
                disabled={!isValid || isSubmitting}>
                <I18n>general.add</I18n>
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default CreateRouterModal;
