import React, { useCallback, useRef, useMemo, useEffect } from 'react';
import classNames from 'classnames';
import { omit, compact } from 'lodash';
import { canvasService } from '@core/canvas/CanvasService';
import { useSelector } from '@redux/useSelector';
import ThumbnailWidget from '../ThumbnailWidget';
import styles from './ThumbnailWidgetWrapper.scss';
import { ThumbnailWidgetWrapperProps } from './ThumbnailWidgetWrapper.interface';
import { getPermissionStatus } from '@core/ffAndPermissions';
import { i18nService } from '@core/i18n/I18nService';
import { widgetMap } from '@core/canvas/widgetMap';
import { httpService } from '@core/http/HttpService';
import { modalService } from '@core/modals/ModalService';
import { localToServer } from '@pages/CreateWidgetPage/widgets.utils';
import { dispatch } from '@src/redux/store';
import { resetClipboardWidget } from '@src/redux/dashboardGallery';
import { editorConfig } from '@core/canvas/editorConfig';
import { clearWidgetSelection } from '@src/redux/dashboardEditor';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';

function ThumbnailWidgetWrapper(props: ThumbnailWidgetWrapperProps) {
  const { widgetType, theme, copy, isRegularWidget, index } = props;
  const filterWidgetVisible = useSelector((state) => state.dashboardEditor.filterWidgetVisible);
  const dashboardId = useSelector((state) => state.dashboardEditor.id);
  const canvasWidth = useSelector((state) => state.dashboardEditor.canvasWidth);
  const clipboardWidget = useSelector((store) => store.dashboardGallery.clipboardWidget);
  const canvasWidgets = useSelector((store) => store.dashboardEditor.canvasWidgets);
  const currentLayout = useSelector((store) => store.dashboardEditor.currentLayout);
  const ref = useRef<HTMLDivElement>(null);
  const maxWidgetsOnCanvas = getConfigValueFromWidgetSettings(widgetType, 'maxWidgetsOnCanvas');
  const isDisabled = !!(
    widgetType === 'clipboard' ||
    (maxWidgetsOnCanvas &&
      canvasWidgets.filter((widget) => widget.type === widgetType).length === maxWidgetsOnCanvas) ||
    (widgetType === 'vpn' && currentLayout !== 'DESKTOP')
  );
  const isUserFiltersWidget = widgetType === 'userFilters';
  const cellSize = editorConfig.gridCellSizePx;

  const trimCopiedWidgetName = (name) => {
    if (name.length <= 100) return name;

    return name.substring(name.length - 100, name.length);
  };

  useEffect(() => {
    //work around to remove widgets that saved with imageUrl
    if (clipboardWidget?.type === 'image' && clipboardWidget?.customization?.imageUrl) {
      dispatch(resetClipboardWidget());
    }
  }, []);

  const setCannotCopyModal = (code) => {
    modalService.openModal('confirm', {
      iconType: 'attention_image',
      text: `errors.${code}`,
      confirmText: 'general.confirm',
      headerText: `errors.${code}-header`,
      showCloseBtn: true,
    });
    dispatch(resetClipboardWidget());
  };

  const onMouseDown = useCallback(
    async (event) => {
      event.stopPropagation();
      if (event.button === 0 && !isUserFiltersWidget && !isDisabled) {
        let canPaste = true;
        if (copy) {
          const { w, h, x, y } = clipboardWidget;
          const data = omit(
            localToServer({
              ...clipboardWidget,
              dashboardId,
              state: { w, h, x, y },
            }),
            ['w', 'h', 'x', 'y', 'copyId']
          );
          if (
            ['image', 'image_by_value'].includes(clipboardWidget.type) &&
            clipboardWidget.customization
          ) {
            const { customization } = clipboardWidget;
            const imageIds = compact(
              clipboardWidget.type === 'image'
                ? [customization.imageId]
                : customization.conditions.map((c) => c.imageId)
            );
            const r: { allExists: boolean } = await httpService.api({
              type: 'isGalleryImagesExistValidation',
              data: { imageIds },
            });
            if (!r.allExists) {
              canPaste = false;
              setCannotCopyModal(100);
            }
          }

          if (canPaste) {
            const res = (await httpService.api({
              type: 'copyWidgetValidation',
              disableBI: true,
              data,
            })) as any;

            canPaste = res.canPaste;
            if (res.message?.code) {
              setCannotCopyModal(res.message?.code);
            }
          }
        }
        canPaste &&
          widgetType != 'empty' &&
          canvasService.startDragging({
            canvasWidget: copy &&
              clipboardWidget && {
                ...clipboardWidget,
                w: Math.min((canvasWidth.value - 56) / cellSize, clipboardWidget.w), // dashboard has a size of 52 pixels less than the configured size. Then we align the number down to 8
                name: trimCopiedWidgetName(
                  clipboardWidget.name &&
                    `${i18nService.translate('edit-dashboard.copy-widget')} ${clipboardWidget.name}`
                ),
              },
            element: ref.current,
            widgetType,
            widgetSettings: widgetMap[widgetType].settings,
            pageX: event.pageX,
            pageY: event.pageY,
            fromClipboard: true,
          });
      }
    },
    [widgetType, clipboardWidget, copy, isUserFiltersWidget, isDisabled, dashboardId]
  );

  const onClick = useCallback(() => {
    if (isUserFiltersWidget) {
      dispatch(clearWidgetSelection());
      canvasService.addUserFiltersWidget();
    }
  }, [widgetType]);

  const hasPermission = useMemo(() => {
    return getPermissionStatus('DASHBOARD', 'EDIT');
  }, []);

  return (
    <div
      className={classNames(
        styles.wrapper,
        hasPermission && !isDisabled
          ? (isUserFiltersWidget ? styles.userFilters : styles.draggable,
            isUserFiltersWidget && filterWidgetVisible && styles.disabled)
          : styles.disabled
      )}
      onMouseDown={onMouseDown}
      onClick={onClick}
      ref={ref}>
      <ThumbnailWidget
        index={index}
        theme={theme}
        widgetType={widgetType}
        isDisabled={widgetType === 'userFilters' ? filterWidgetVisible : isDisabled}
        isRegularWidget={isRegularWidget}
      />
    </div>
  );
}

export default ThumbnailWidgetWrapper;
