import React from 'react';
import { AlarmsMeta } from '@redux/redux.interface.d';
import moment from 'moment';

function AlarmsTime(props: AlarmsMeta) {
  const { createdAt } = props;
  const createdDate = moment(createdAt).format('DD/MM/YYYY HH:mm');
  return <div title={createdDate}>{createdDate}</div>;
}

export default AlarmsTime;
