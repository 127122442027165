import { ConfigState, StoreAction } from './redux.interface.d';
import { cssVarsService } from '@core/CssVarsService';
import { updateOrAddToArray } from '@core/utils';

export const setLanguage = (payload): StoreAction => ({
  type: 'config/SET_LANGUAGE',
  payload,
});

export const addLanguage = (payload): StoreAction => ({
  type: 'config/ADD_LANGUAGE',
  payload,
});

export const removeLanguage = (payload): StoreAction => ({
  type: 'config/REMOVE_LANGUAGE',
  payload,
});

export const setDashboardRefId = (payload): StoreAction => ({
  type: 'config/SET_DASHBOARD_REF_ID',
  payload,
});

export const incrementBusyCounter = (): StoreAction => ({
  type: 'config/INCREMENT_BUSY_COUNTER',
  payload: null,
});

export const decrementBusyCounter = (): StoreAction => ({
  type: 'config/DECREMENT_BUSY_COUNTER',
  payload: null,
});

export const setConfig = (payload): StoreAction => ({
  type: 'config/SET_CONFIG',
  payload,
});

export const setWhiteLabelDetails = (payload): StoreAction => ({
  type: 'config/SET_WHITE_LABEL_DETAILS',
  payload,
});

export const updatetWhiteLabelDetails = (payload): StoreAction => ({
  type: 'config/UPDATE_WHITE_LABEL_DETAILS',
  payload,
});

export const setApexLangChange = (payload): StoreAction => ({
  type: 'config/SET_APEX_LANG_CHANGE',
  payload,
});

export const setShowDisclaimerAgain = (payload): StoreAction => ({
  type: 'config/SET_SHOW_DISCLAIMER_AGAIN',
  payload,
});

export const setfileForDownload = (payload): StoreAction => ({
  type: 'config/SET_FILE_FOR_DOWNLOAD',
  payload,
});

export const setOrganizationDetails = (payload): StoreAction => ({
  type: 'config/SET_ORGANIZATION_DETAILS',
  payload,
});

export const setDashboardGalleryViewType = (payload): StoreAction => ({
  type: 'config/SET_DASHBOARD_GALLERY_VIEW_TYPE',
  payload,
});

export const setShowSplitDashboardConfirmation = (payload): StoreAction => ({
  type: 'config/SET_SHOW_SPLIT_DASHBOARD_CONFIRMATION',
  payload,
});

export const setDontShowAgain = (payload): StoreAction => ({
  type: 'config/SET_DONT_SHOW_AGAIN',
  payload,
});

export const setEmptyStateImageUrls = (payload: any): StoreAction => ({
  type: 'config/SET_EMPTY_STATE_IMAGE_URLS',
  payload,
});

export const setUnreadNotifications = (payload: any): StoreAction => ({
  type: 'config/SET_UNREAD_NOTIFICATIONS',
  payload,
});

const initialState: ConfigState = {
  busyCounter: 0,
  languageId: null,
  languages: [],
  permissions: {},
  featureFlags: {},
  whiteLabelDetails: {},
  apexLangChange: false,
  showDisclaimerAgain: true,
  acceptDisclaimerDate: null,
  fileForDownload: null,
  dashboardRefId: null,
  dashboardGalleryViewType: null,
  showSplitDashboardConfirmation: true,
  dontShowAgain: [],
  emptyStateImageUrls: {},
  isUnAssigned: true,
};

export default function configReducer(state: ConfigState = initialState, action: StoreAction) {
  switch (action.type) {
    case 'config/SET_CONFIG':
      const permissions = {};
      action.payload.permissions?.map((p) => {
        permissions[p.type] = p.access;
      });
      action.payload.whiteLabelDetails
        ? cssVarsService.init(action.payload.whiteLabelDetails)
        : cssVarsService.init();
      return { ...state, ...action.payload, permissions };
    case 'config/SET_LANGUAGE':
      return { ...state, ...action.payload };
    case 'config/INCREMENT_BUSY_COUNTER':
      return { ...state, busyCounter: state.busyCounter + 1 };
    case 'config/DECREMENT_BUSY_COUNTER':
      if (state.busyCounter === 0) {
        console.error('[GlobalBusyIndicator]', 'minus value of busyCounter');
      }
      const busyCounter = Math.max(state.busyCounter - 1, 0);
      return { ...state, busyCounter };
    case 'config/ADD_LANGUAGE':
      const languages = updateOrAddToArray(state.languages, action.payload, 'id');
      return {
        ...state,
        languages,
      };
    case 'config/REMOVE_LANGUAGE':
      return {
        ...state,
        languages: state.languages.filter((lang) => lang.id !== action.payload.id),
      };
    case 'config/SET_WHITE_LABEL_DETAILS':
      cssVarsService.init(action.payload);
      return { ...state, whiteLabelDetails: { ...action.payload } };
    case 'config/UPDATE_WHITE_LABEL_DETAILS':
      return {
        ...state,
        whiteLabelDetails: {
          ...state.whiteLabelDetails,
          ...action.payload,
        },
      };
    case 'config/SET_DASHBOARD_REF_ID':
      return { ...state, organizationDetails: { ...state.organizationDetails, ...action.payload } };
    case 'config/SET_APEX_LANG_CHANGE':
      return { ...state, apexLangChange: action.payload };
    case 'config/SET_SHOW_DISCLAIMER_AGAIN':
      return {
        ...state,
        showDisclaimerAgain: action.payload,
        acceptDisclaimerDate: new Date().toString(),
      };
    case 'config/SET_FILE_FOR_DOWNLOAD':
      return { ...state, fileForDownload: action.payload };
    case 'config/SET_ORGANIZATION_DETAILS':
      return { ...state, organizationDetails: action.payload };
    case 'config/SET_DASHBOARD_GALLERY_VIEW_TYPE':
      return { ...state, dashboardGalleryViewType: action.payload };
    case 'config/SET_SHOW_SPLIT_DASHBOARD_CONFIRMATION':
      return { ...state, showSplitDashboardConfirmation: action.payload };
    case 'config/SET_DONT_SHOW_AGAIN':
      return { ...state, downShowAgain: state.dontShowAgain.push(action.payload) };
    case 'config/SET_EMPTY_STATE_IMAGE_URLS':
      return { ...state, emptyStateImageUrls: action.payload };
    case 'config/SET_UNREAD_NOTIFICATIONS':
      return { ...state, unreadNotifications: action.payload };
    default:
      return state;
  }
}
