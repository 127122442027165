import React, { useState, useEffect } from 'react';
import { useSelector } from '@src/redux/useSelector';

function LogoIcon(props) {
  const { logo, onChange, height, width, maxWidth, style = {} } = props;
  const [dimensions, setDimensions] = useState<any>({});
  const [imageDimensionsFromUrl, setImageDimensionsFromUrl] = useState<any>({});
  const isMobileView = useSelector((state) => state.viewport.isMobileView);

  const img = new Image();
  img.src = logo;

  useEffect(() => {
    img.onload = () => {
      setImageDimensionsFromUrl({ height: img.height, width: img.width });
    };
  }, [img]);

  const onImageLoad = async ({ target: img }) => {
    const aspectRatio = imageDimensionsFromUrl.width
      ? imageDimensionsFromUrl.width / imageDimensionsFromUrl.height
      : img.offsetWidth / img.offsetHeight;
    const newHeight = (aspectRatio > 1 && height * aspectRatio) <= maxWidth ? height : 17;
    const newWidth = aspectRatio === 1 ? height : newHeight * aspectRatio;
    setDimensions({ height: newHeight, width: newWidth });
    onChange && onChange({ height: newHeight, width: newWidth });
  };

  useEffect(() => {
    setDimensions({
      height: undefined,
      width: undefined,
    });
  }, [logo]);

  useEffect(() => {
    isMobileView &&
      setDimensions({
        height: 17,
        width: 100,
      });
  }, [isMobileView]);

  return (
    <img
      src={logo}
      // crossOrigin="anonymous"
      onLoad={onImageLoad}
      style={{
        width: dimensions.width,
        height: dimensions.height,
        visibility: dimensions.width ? 'visible' : 'hidden',
        ...style,
      }}
    />
  );
}

export default LogoIcon;
