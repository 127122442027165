import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import I18n from '@components/I18n';
import { useSelector } from '@redux/useSelector';
import styles from './EditAssetTabs.scss';
import TabContent from './TabContent/TabContent';
import AssetInfoGeneral from './AssetInfoGeneral';
import AssetInfoTagsMeta from './AssetInfoTagsMeta';
import AssetInfoAlarms from './AssetInfoAlarms/AssetInfoAlarms';
import AssetInfoSubscriptionMeta from './AssetInfoSubscriptionMeta';
import { editAssetService } from '../EditAssetService';
import { getFlagStatus } from '@core/ffAndPermissions';
import { makeStyles, Switch, withStyles } from '@material-ui/core';
import AssetTypeInfoVariablesMeta from '@modals/EditAssetTypeModal/EditAssetTypeTabs/AssetTypeInfoVariablesMeta';
import DataBackup from './DataBackup';

function getTabs(organizationType, plcSerial) {
  return {
    general: {
      component: AssetInfoGeneral,
      label: 'edit-asset-modal.general',
      featureFlag: 'assets.edit-buttons',
      id: '7838797c-65ab-41ca-bb6c-39ac733505ac',
    },
    tagsMeta: {
      component: AssetInfoTagsMeta,
      label: 'edit-asset-modal.tags',
      featureFlag: 'assets.edit-buttons',
      id: 'd2742982-5b2a-46e4-8f82-0c921f083792',
    },
    alarmsMeta: {
      component: AssetInfoAlarms,
      label: 'edit-asset-modal.alarms',
      featureFlag: 'assets.edit-buttons',
      id: '79e12a89-3351-46c0-8cf5-7bb8dca197c1',
    },
    variablesMeta: {
      component: AssetTypeInfoVariablesMeta,
      label: 'variables.caption',
      id: '1374f3c9-03a4-47a8-8151-f377e17b0df9',
      isHidden: organizationType !== 'MACHINE_BUILDER',
    },
    subscriptionMeta: {
      component: AssetInfoSubscriptionMeta,
      label: 'edit-asset-modal.subscription',
      featureFlag: 'assets.subscription-tab',
      id: 'fd16b01e-78ba-4120-9693-9c5e64245056',
    },
    dataBackup: {
      component: DataBackup,
      label: 'edit-asset-modal.data-backup.data-backup',
      featureFlag: 'assets.data-backup-tab',
      id: '4cf1160c-9e15-4db2-8293-d1ca96494f51',
      isHidden: organizationType !== 'MACHINE_BUILDER' || !plcSerial,
    },
  };
}

const useStyles = makeStyles((theme: any) => ({
  gpsLocations: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    textAlign: 'right',
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
  },
}));

function EditAssetTabs(props) {
  const organizationType = useSelector((state) => state.config.organizationDetails.type);
  const organizationId = useSelector((state) => state.config.organizationDetails.id);
  const assetInfo = useSelector((state) => state.editAsset);
  const { selectedTab, dataTablesMeta } = assetInfo;
  const { hasPermission, handleSupportGpsChanged, remoteAccessMode } = props;
  const classes = useStyles(props);
  const tabs = getTabs(organizationType, assetInfo?.staticData?.plcSerial);

  const [categoryList, setCategoryList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const dataBackupProperties =
    selectedTab === 'dataBackup'
      ? {
          categoryList,
          setCategoryList,
          dateList,
          setDateList,
          fileList,
          setFileList,
          selectedCategory,
          setSelectedCategory,
          selectedDate,
          setSelectedDate,
          selectedFile,
          setSelectedFile,
        }
      : {};

  const CustomSwitch = withStyles({
    switchBase: {
      color: assetInfo.staticData?.isDemo ? '#A4A4A4' : 'var(--systemButtonBackground)', // button unchecked
      '&$checked': {
        color: assetInfo.staticData?.isDemo ? '#A4A4A4' : 'var(--systemButtonBackground)', // button checked
      },
      '&$checked + $track': {
        backgroundColor: `#8CBBE9!important`, // track checked,
      },
    },
    checked: {},
    track: {
      backgroundColor: `#9F9F9F!important`, // track unchecked
    },
  })(Switch);

  const handleInputChange = (tabName) => {
    if (selectedTab === tabName) return;
    editAssetService.setSelectedTab(tabName);
  };

  useEffect(() => {
    //TODO change to correct feature flags.
    !getFlagStatus('assets.edit-buttons') && handleInputChange('subscriptionMeta');
  }, []);

  return selectedTab ? (
    <>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          {Object.keys(tabs).map((key) => {
            if (key === 'dataTablesMeta' && !dataTablesMeta.length) return null;
            if (tabs[key].featureFlag && !getFlagStatus(tabs[key].featureFlag)) return null;
            if (tabs[key].isHidden) return null;
            return remoteAccessMode && key === 'tagsMeta' ? (
              <div key={key} className={classNames('auto', styles.tab)} style={{ opacity: 0.4 }}>
                <I18n>{tabs[key].label}</I18n>
              </div>
            ) : (
              <div
                key={key}
                className={
                  key === selectedTab
                    ? classNames('auto', styles.tab, styles.activeTab)
                    : classNames('pointer', styles.tab)
                }
                onClick={() => handleInputChange(key)}>
                <I18n>{tabs[key].label}</I18n>
              </div>
            );
          })}
        </div>
        {!remoteAccessMode && (
          <div className={classes.gpsLocations}>
            <CustomSwitch
              disabled={assetInfo.staticData?.isDemo || remoteAccessMode || !hasPermission}
              checked={props.supportGpsLocations && !remoteAccessMode}
              onChange={handleSupportGpsChanged}
              size="medium"
            />
            <I18n className={classes.text}>edit-asset-modal.support-gps-locations</I18n>
          </div>
        )}
      </div>
      <div className={styles.tabsContent}>
        <TabContent
          Component={tabs[selectedTab].component}
          {...{
            ...props,
            assetId: Number.parseInt(assetInfo.general.assetId),
            organizationId: organizationId,
            ...dataBackupProperties,
          }}
        />
      </div>
    </>
  ) : null;
}

export default EditAssetTabs;
