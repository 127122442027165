/* Based on react-rectangle-selection by remigallego */
import React, { useState } from 'react';
import { useSelector } from '@src/redux/useSelector';
import { editorConfig } from '@core/canvas/editorConfig';
import { canvasService } from '@core/canvas/CanvasService';
import { dispatch } from '@src/redux/store';
import { clearWidgetSelection, setSelectionBox } from '@src/redux/dashboardEditor';

const cellSize = editorConfig.gridCellSizePx;

function RectangleSelection({
  setIsRectangleSelection,
  children,
  canvasWidgets,
  selectedWidgets,
  setSelectedWidgets,
  isArrangementPopoverOpen,
  isFiltersPopoverOpen,
  isSettingsPopoverOpen,
}) {
  const selectionRectangleColor = useSelector(
    (state) => state.config.whiteLabelDetails.systemColors.content.selectionRectangleColor
  );
  const selectionBox = useSelector((state) => state.dashboardEditor.selectionBox);
  const [selectionOrigin, setSelectionOrigin] = useState({ x: 0, y: 0 });
  const [selectionBoxOrigin, setSelectionBoxOrigin] = useState({ x: 0, y: 0 });
  const [selectionBoxTarget, setSelectionBoxTarget] = useState({ x: 0, y: 0 });

  function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `${r}, ${g}, ${b}`;
  }

  function handleTransformBox() {
    if (selectionBoxOrigin.y > selectionBoxTarget.y && selectionBoxOrigin.x > selectionBoxTarget.x)
      return 'scaleY(-1) scaleX(-1)';

    if (selectionBoxOrigin.y > selectionBoxTarget.y) {
      return 'scaleY(-1)';
    }
    if (selectionBoxOrigin.x > selectionBoxTarget.x) {
      return 'scaleX(-1)';
    }
    return null;
  }

  function closeSelectionBox() {
    dispatch(setSelectionBox(false));
  }

  function handleMouseDown(e) {
    if (!e.ctrlKey) {
      setSelectedWidgets([]);
      dispatch(clearWidgetSelection());
    }
    dispatch(setSelectionBox(false));

    if (e.target.id === 'rectangleSelection') {
      dispatch(setSelectionBox(false));
    }

    setSelectionOrigin({ x: e.nativeEvent.pageX, y: e.nativeEvent.pageY });
    setSelectionBoxOrigin({ x: e.nativeEvent.pageX, y: e.nativeEvent.pageY });
    setSelectionBoxTarget({ x: e.nativeEvent.pageX, y: e.nativeEvent.pageY });

    if (e.buttons === 1 && !selectionBox) {
      dispatch(setSelectionBox(true));
    }
  }

  const baseStyle = {
    position: 'fixed',
    zIndex: 10000000,
    left: selectionBoxOrigin.x,
    top: selectionBoxOrigin.y,
    width: Math.abs(selectionBoxTarget.x - selectionBoxOrigin.x - 1),
    height: Math.abs(selectionBoxTarget.y - selectionBoxOrigin.y - 1),
    transformOrigin: 'top left',
    transform: handleTransformBox(),
    backgroundColor: `rgb(${hexToRgb(selectionRectangleColor)}, 0.5)`,
    border: '1px solid var(--systemSelectionRectangleColor)',
  };

  function onRectangleSelect(coords) {
    const origin = {
      x: coords.origin.x,
      y: coords.origin.y,
    };
    const target = {
      x: coords.target.x,
      y: coords.target.y,
    };
    canvasWidgets.map((widget) => {
      const widgetWrapperRect = widget.ref.current.getBoundingClientRect();

      const widgetLeft = widgetWrapperRect.x + cellSize;
      const widgetTop = widgetWrapperRect.y + cellSize;
      const widgetRight = widgetWrapperRect.right - cellSize;
      const widgetBottom = widgetWrapperRect.bottom - cellSize;

      const isOverlappingOnLeftAndRight =
        (widgetLeft >= origin.x && widgetRight <= target.x) ||
        (widgetRight <= origin.x && widgetRight >= target.x && widgetLeft >= target.x);

      const isOverlappingOnTopAndBottom =
        (widgetTop >= origin.y && widgetBottom <= target.y) ||
        (widgetBottom <= origin.y && widgetBottom >= target.y && widgetTop >= target.y);

      if (isOverlappingOnLeftAndRight && isOverlappingOnTopAndBottom) {
        canvasService.selectWidget(widget, { isMultiSelect: true });
        let newSelectedWidgets = selectedWidgets;
        !newSelectedWidgets.some((sw) => sw.id === widget.id) && newSelectedWidgets.push(widget);
        setSelectedWidgets(newSelectedWidgets);
      }
    });
  }

  return (
    <div
      style={{ height: '100%', width: 'inherit', overflow: 'hidden' }}
      onMouseDown={(e) => {
        e.button === 0 &&
          !selectionBox &&
          !isArrangementPopoverOpen &&
          !isFiltersPopoverOpen &&
          !isSettingsPopoverOpen &&
          e.nativeEvent.pageY >= 187 &&
          e.target['id'] !== 'filtersWidget' &&
          e.target['id'] !== 'filtersWidgetExpandIcon' &&
          e.target['id'] !== 'filtersWidgetExpansionPanel' &&
          e.target['id'] !== 'filtersWidgetExpansionSummary' &&
          e.target['id'] !== 'filtersWidgetExpansionPanelDetails' &&
          handleMouseDown(e);
      }}
      onMouseUp={() => {
        if (selectionBox) {
          onRectangleSelect({
            origin: selectionOrigin,
            target: selectionBoxTarget,
          });
          closeSelectionBox();
          setIsRectangleSelection(true);
        }
      }}
      onMouseMove={(e) => {
        if (selectionBox && e.buttons === 1) {
          setSelectionBoxTarget({
            x: e.nativeEvent.pageX,
            y: e.nativeEvent.pageY >= 187 ? e.nativeEvent.pageY : 187,
          });
        }
      }}>
      {selectionBox && <div id={'rectangleSelection'} style={Object.assign(baseStyle)} />}
      {children}
    </div>
  );
}

export default RectangleSelection;
