import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import { operatorOptionByFormat } from '@pages/CreateWidgetPage/SharedMaps';
import I18n from '@components/I18n';
import Tooltip from '@components/Tooltip';
import Icon from '@components/Icon';
import Select from '@components/Select';
import styles from './Filters.scss';

// 3rd party
import moment from 'moment';
import * as cloneDeep from 'lodash/cloneDeep';
import * as isEqual from 'lodash/isEqual';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from '@src/redux/useSelector';
import ValuesComponent from './ValuesComponent/ValuesComponent';
import { httpService } from '@core/http/HttpService';

const And = (props) => {
  return props.idx ? (
    <I18n className={classNames(styles.boxText, styles.and)}>
      create-widget-page.create-widget.step-three.and
    </I18n>
  ) : null;
};

function Filters(props) {
  const { setWidgetData, filters, availableData, disabled, widgetData } = props;
  const [assetTypes, setAssetTypes] = useState(props.assetTypes);

  const languageId = useSelector((state) => state.config.languageId);
  const operatorsEnableDatePickerRanges = ['IS'];

  useEffect(() => {
    !props.assetTypes?.length &&
      httpService
        .api({
          type: 'getAssetTypes',
          query: { p: 1, ps: 1000 },
        })
        .then((res: any) => setAssetTypes(res.results));
  }, []);

  useEffect(() => {
    const res = filters.filter((item1) =>
      availableData.some((item2) => {
        if (item1.operation) {
          return (
            item2.id === item1.valueId &&
            item2.tagType === item1.valueType &&
            item2.operation === item1.operation
          );
        } else return item2.id === item1.valueId && item2.tagType === item1.valueType;
      })
    );
    updateWidgetData(res);
  }, [availableData]);

  const updateWidgetData = (data) => {
    setWidgetData((prevState) => ({
      ...prevState,
      filters: data,
    }));
  };

  const updateWidgetDataSingleFilter = (filter, idx) => {
    setWidgetData((prevState) => {
      const clonedFilters = cloneDeep(prevState.filters);
      clonedFilters[idx] = filter;
      return {
        ...prevState,
        filters: clonedFilters,
      };
    });
  };

  const onUpdateCondition = (val, idx) => {
    val && updateFilter('condition', idx, val);
  };

  const updateFilter = (updateField, idx, value) => {
    if (isEqual(filters[idx][updateField], value)) return;

    const updatedFiltersSelected = cloneDeep(filters);
    if (updateField === 'condition' && updatedFiltersSelected[idx].type !== 'booleanType') {
      if (updatedFiltersSelected[idx].type === 'dateTimeType') {
        if (
          operatorsEnableDatePickerRanges.includes(
            updatedFiltersSelected[idx][updateField].value
          ) &&
          !operatorsEnableDatePickerRanges.includes(value.value)
        ) {
          const today = new Date();
          const date = moment(today).format('YYYY-MM-DD');
          updatedFiltersSelected[idx]['values'] = [{ type: 'CUSTOM', date }];
        } else if (
          !operatorsEnableDatePickerRanges.includes(
            updatedFiltersSelected[idx][updateField].value
          ) &&
          operatorsEnableDatePickerRanges.includes(value.value)
        ) {
          updatedFiltersSelected[idx]['values'] = [{ type: 'YEAR', date: null }];
        }
      } else {
        updatedFiltersSelected[idx]['values'] = [];
      }
    }
    updatedFiltersSelected[idx][updateField] = value;
    updateWidgetDataSingleFilter(updatedFiltersSelected[idx], idx);
  };

  return (
    <div className={styles.filters}>
      <div className={styles.infoGroup}>
        <I18n className={styles.subTitle}>create-widget-page.create-widget.step-three.filters</I18n>
        <Tooltip
          text={'create-widget-page.create-widget.step-three.filters-info'}
          placement="right"
          children={
            <div>
              <Icon className={styles.iconInfo} type="info" />
            </div>
          }></Tooltip>
      </div>
      <Droppable droppableId="filteringFilters" isDropDisabled={disabled}>
        {(provided) => (
          <div
            className={classNames('pointer', styles.filtersBox)}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {filters.length === 0 ? (
              <I18n noEllipsis className={styles.boxText}>
                create-widget-page.create-widget.step-three.filters-box
              </I18n>
            ) : (
              filters.map((filter, idx) => (
                <Draggable
                  key={`${filter.order}filters`}
                  draggableId={`${filter.order}filters`}
                  index={idx}>
                  {(provided) => (
                    <div
                      className={classNames(
                        styles.filterContainer,
                        filter.disabled && styles.filterDisabled
                      )}
                      ref={provided.innerRef}
                      {...provided.draggableProps}>
                      <And idx={idx} />
                      <div className={styles.filterContent}>
                        <div className={styles.dragIndicator} {...provided.dragHandleProps}>
                          <Icon type="drag" />
                        </div>
                        <div className={styles.filter}>
                          <div className={styles.tag}>
                            <Icon
                              className={styles.iconType}
                              type={
                                filter.valueType === 'ASSET_PROPERTY' && filter.valueId === 3
                                  ? 'geoType'
                                  : filter.type
                              }
                            />
                            <I18n className={classNames(styles.tagName, 'ellipsis-overflow')}>
                              {filter.operation
                                ? `${i18nService.translate(
                                    filter.name,
                                    languageId
                                  )} - ${i18nService.translate(
                                    `enum.${filter.operation.toUpperCase()}`,
                                    languageId
                                  )}`
                                : filter.name}
                            </I18n>
                          </div>
                          <div className={styles.operator}>
                            <Select
                              value={filter.condition}
                              options={operatorOptionByFormat(
                                filter.type,
                                widgetData,
                                filter.valueId,
                                filter.valueType
                              )}
                              onChange={(e) => onUpdateCondition(e, idx)}
                              menuPortalTarget={document.body}
                            />
                          </div>
                          <div className={styles.values}>
                            <ValuesComponent
                              filter={filter}
                              idx={idx}
                              assetTypes={assetTypes}
                              updateFilter={updateFilter}
                              widgetData={widgetData}
                              operatorsEnableDatePickerRanges={operatorsEnableDatePickerRanges}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Draggable>
              ))
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}

export default Filters;
