import { isEqual, omit, get, pick, flatten, pull } from 'lodash';
import { getWidgetData } from '@pages/GetWidgetDataService';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { httpService } from '@core/http/HttpService';
import { dashboardService } from '@core/canvas/DashboardService';
import { WidgetData, WidgetDataWithPosition } from './CreateWidgetPage.interface';
import { serverToLocal, creationStageMap, localToServer } from './widgets.utils';
import { modalService } from '@core/modals/ModalService';
import { canvasService } from '@core/canvas/CanvasService';
import { dispatch, getState } from '@src/redux/store';
import { resetEditor, setDashboardStates, setDashboardWidgets } from '@src/redux/dashboardEditor';
import { widgetMap } from '@core/canvas/widgetMap';
import { editorConfig } from '@core/canvas/editorConfig';
import { omitProperties, updateDontShowAgain } from '@core/utils';
import { cloneDeep } from 'lodash';
import { i18nService } from '@core/i18n/I18nService';

const validationForGetWidgetData = (key, prevWidgetData, widgetData) => {
  if (key === 'metrics') {
    return widgetData[key].some((m) => m.selectType && !m.operation);
  }
  if (
    key === 'scope' &&
    !(
      ['LAST_VALUE', 'RAW_DATA'].includes(prevWidgetData.scope) &&
      ['LAST_VALUE', 'RAW_DATA'].includes(widgetData.scope)
    )
  ) {
    return true;
  }
  return false;
};

export const getData = async (widgetData, setPreviewData) => {
  try {
    const res = await getWidgetData({ widgetData });
    setPreviewData(res);
  } catch (e) {
    setPreviewData({});
  }
};

const shouldGetDataOnChange = (key, prevWidgetData, widgetData, setPreviewData) => {
  // getting data from server when widgetData get changes according to DataChangesToHandleMap
  if (prevWidgetData && !isEqual(get(prevWidgetData, key), get(widgetData, key))) {
    if (validationForGetWidgetData(key, prevWidgetData, widgetData)) {
      if (key === 'metrics' && widgetData[key].some((m) => m.selectType && m.operation)) {
        return false;
      }
      setPreviewData(null);
      return false;
    }

    if (
      widgetData.type != 'line' &&
      key === 'calculations' &&
      widgetData.calculations?.length !== prevWidgetData.calculations?.length
    ) {
      return false;
    }

    return true;
  }
};

const getWidgetDataOnChange = (prevWidgetData, widgetData, setPreviewData) => {
  const widgetDataPickedProps = pick(widgetData, [
    'alarmInfos',
    'assetProperties',
    'assetTypes',
    'calculations',
    'variables',
    'tags',
    'tagTypes',
    'localTags',
    'type',
    'scope',
    'groupBy',
    'metrics',
    'filters',
    'sorts',
    'customizationMetrics',
  ]);

  const prevWidgetDataPickedProps = pick(prevWidgetData, [
    'alarmInfos',
    'assetProperties',
    'assetTypes',
    'calculations',
    'variables',
    'tags',
    'tagTypes',
    'localTags',
    'type',
    'scope',
    'groupBy',
    'metrics',
    'filters',
    'sorts',
    'customizationMetrics',
  ]);

  if (
    (widgetData['metrics']?.length || widgetData['groupBy']?.length) &&
    !isEqual(widgetDataPickedProps, prevWidgetDataPickedProps)
  )
    getData(widgetData, setPreviewData);
};

export const onChangeWidgetData = (widgetData, prevWidgetData, setPreviewData) => {
  const shouldGetData =
    !getConfigValueFromWidgetSettings(widgetData.type, 'showPreviewData') ||
    getConfigValueFromWidgetSettings(widgetData.type, 'showPreviewData').find((key) =>
      Array.isArray(get(widgetData, key)) ? get(widgetData, key).length : get(widgetData, key)
    );

  if (widgetData.type && shouldGetData) {
    let shouldGetData = false;
    const changesMap = getConfigValueFromWidgetSettings(widgetData.type, 'dataChangesToHandle');
    changesMap &&
      changesMap.forEach(
        (changeKey) =>
          (shouldGetData =
            shouldGetData ||
            shouldGetDataOnChange(changeKey, prevWidgetData, widgetData, setPreviewData))
      );

    (shouldGetData || widgetData.eventTemplateId) &&
      getWidgetDataOnChange(prevWidgetData, widgetData, setPreviewData);
  }
};
export const onChangeHeatmapWidgetData = (widgetData, prevWidgetData, setPreviewData) => {
  const shouldGetData =
    !getConfigValueFromWidgetSettings(widgetData.type, 'showPreviewData') ||
    getConfigValueFromWidgetSettings(widgetData.type, 'showPreviewData').find((key) =>
      Array.isArray(get(widgetData, key)) ? get(widgetData, key).length : get(widgetData, key)
    );

  if (widgetData.type && shouldGetData) {
    let shouldGetData = false;
    const changesMap = getConfigValueFromWidgetSettings(widgetData.type, 'dataChangesToHandle');
    changesMap &&
      changesMap.forEach(
        (changeKey) =>
          (shouldGetData =
            shouldGetData ||
            shouldGetDataOnChange(changeKey, prevWidgetData, widgetData, setPreviewData))
      );

    (shouldGetData || widgetData.eventTemplateId) &&
      getWidgetDataOnChange(prevWidgetData, widgetData, setPreviewData);
  }
};

const getWidgetOnEditMode = (widgetId, setWidgetData, setCurrentStep, setInitialData) => {
  httpService.api({ type: 'getWidget', urlParams: { widgetId } }).then((widget: WidgetData) => {
    const canvasWidget = getState().dashboardEditor.canvasWidgets?.find((w) => w.id === +widgetId);
    let data = serverToLocal(
      addMissingTerms(addMissingCustomization(widget))
    ) as WidgetDataWithPosition;
    if (canvasWidget) {
      const state = {
        x: canvasWidget.x,
        y: canvasWidget.y,
        w: canvasWidget.w,
        h: canvasWidget.h,
      };
      data = {
        ...data,
        state: state,
        originalState: {
          ...state,
        },
      };
    }
    setWidgetData(data);
    setInitialData(data);
    setCurrentStep(
      +Object.keys(creationStageMap).find((key) => creationStageMap[key] === widget.creationStage)
    );
  });
};

export const getDefaultNameCreateWidget = (
  dashboardId,
  location,
  setWidgetData,
  setInitialData,
  type?
) => {
  return httpService
    .api({
      type: 'getWidgetDefaultName',
      urlParams: { dashboardId },
      query: { type: (type || (location.state.type as string)).toUpperCase() },
    })
    .then((res: { name: string }) => {
      // When coming with a type, it means that the widget is being created not in the CreateWidgetPage,
      // for example: CanvasService - when dropping the widget on the canvas and not opennig the create widget flow.
      // setWidgetData exist only in CreateWidgetPage
      if (type) return res.name;

      setWidgetData((prevState) => ({
        ...prevState,
        dashboardId: +dashboardId,
        name: res.name,
        type: location.state.type,
        state: location.state.coords,
      }));
      //UC-5866 - Set initial data the same as widget data when a new widget is created
      setInitialData((prevState) => ({
        ...prevState,
        dashboardId: +dashboardId,
        name: res.name,
        type: location.state.type,
        state: location.state.coords,
      }));
    });
};

export const getWidget = async (
  widgetId,
  dashboardEditorId,
  dashboardId,
  location,
  setWidgetData,
  setCurrentStep,
  setInitialData,
  canvasWidget
) => {
  if (dashboardId && !dashboardEditorId) {
    dashboardService.dashboardIdType = 'id';
    await dashboardService.initDashboard(dashboardId);
  }

  if (widgetId) {
    // EDIT mode
    getWidgetOnEditMode(widgetId, setWidgetData, setCurrentStep, setInitialData);
  } else if (location.state.copyId) {
    const omitData = [
      'children',
      'created_at',
      'h',
      'isSelected',
      'is_deleted',
      'updated_at',
      'w',
      'x',
      'y',
      'coords',
    ];
    // COPY mode
    setWidgetData(
      omit(
        {
          ...addMissingTerms(addMissingCustomization(location.state?.canvasWidget)),
          status: 'DRAFT',
          dashboardId: +dashboardId,
          state: location.state.coords,
        },
        omitData
      )
    );
  } else {
    // Get default name for newly created widget
    getDefaultNameCreateWidget(dashboardId, location, setWidgetData, setInitialData);
  }
};

export const cancel = (
  history,
  widgetData,
  dashboardId,
  onSaveWidget,
  enableSaveAsDraft,
  initialData
) => {
  const currentLayout = getState().dashboardEditor.currentLayout || 'DESKTOP';
  if (
    isEqual(
      { ...initialData, creationStage: undefined },
      { ...widgetData, creationStage: undefined }
    )
  ) {
    dispatch(resetEditor(currentLayout));
    history.push(`/main/edit-dashboard/${dashboardId}`, { overrideGuard: true });
    return;
  }
  modalService
    .openConfirm({
      headerText: 'general.discard-changes',
      text: 'create-widget-page.cancel-confirm',
      iconType: 'attention_image',
      cancelText: 'general.cancel',
      confirmText: 'general.confirm',
      showCloseBtn: true,
    })
    .then((confirm) => {
      if (confirm) {
        dispatch(resetEditor(currentLayout));
        history.push(`/main/edit-dashboard/${dashboardId}`, { overrideGuard: true });
      }
    });
};

export const silentCancel = (history, dashboardId) => {
  const currentLayout = getState().dashboardEditor.currentLayout || 'DESKTOP';
  dispatch(resetEditor(currentLayout));
  history.push(`/main/edit-dashboard/${dashboardId}`, { overrideGuard: true });
};

export const setCustomizationTermsForBE = (widget) => {
  const languageKeys = widgetMap[widget.type?.toLowerCase()].settings.languageKeys.map(
    (languageKey) => {
      if (languageKey.includes('*')) {
        return languageKey.split('.')[0];
      }
      return languageKey;
    }
  );

  if (languageKeys.length && widget?.customization) {
    const customizationAsArray = Object.entries(widget.customization);

    const customizationByCategoryAsObject = flatten(
      languageKeys.map((languageKey) =>
        customizationAsArray.filter(
          (item) => item[0] === languageKey || item[0] === languageKey.split('.')[0]
        )
      )
    );

    const customizationByCategoryAsObjectWithSpreadNestedObjects =
      customizationByCategoryAsObject.map((item) => {
        let newObj = {};
        const keys = item[1] && Object.keys(item[1]);
        Array.isArray(keys) &&
          keys.map((key) => {
            newObj = {
              ...newObj,
              [key]: item[1][key]?.displayName ? item[1][key]?.displayName : item[1][key],
            };
          });
        return [item[0], newObj];
      });

    const customizationByCategoryAsArray = ['ASSET'].includes(widget.type)
      ? customizationByCategoryAsObjectWithSpreadNestedObjects.map((term) =>
          flatten([
            term[0],
            !term[1] || term[1] === ''
              ? null
              : typeof term[1] === 'string'
              ? term[1]
              : Object.entries(term[1]),
          ])
        )
      : customizationByCategoryAsObject.map((term) =>
          flatten([
            term[0],
            !term[1] || term[1] === ''
              ? null
              : typeof term[1] === 'string'
              ? term[1]
              : Object.entries(term[1]),
          ])
        );

    const customizationAsFlatKeyValue = flatten(
      customizationByCategoryAsArray.map((term) =>
        pull(
          term.map((item, index) => {
            if (index > 0) {
              if (Array.isArray(item)) {
                return { [`${term[0]}.${item[0]}`]: item[1] };
              } else {
                return { [`${term[0]}`]: item };
              }
            }
          }),
          undefined,
          null
        )
      )
    );

    const termsWithPossibleDuplicates = customizationAsFlatKeyValue.filter(
      (term) =>
        languageKeys.includes(`${Object.keys(term)}`) ||
        languageKeys.includes(`${Object.keys(term)}`.split('.')[0])
    );

    return Object.assign(
      {},
      ...[...new Set(termsWithPossibleDuplicates.map((a) => JSON.stringify(a)))].map((a) =>
        JSON.parse(`${a}`)
      )
    );
  } else {
    return {};
  }
};

export const createWidget = async (widgetData, setWidgetData, publish: boolean = false) => {
  const newWidget = localToServer(widgetData);
  const dashboardEditor = getState().dashboardEditor;
  const dashboardStates = dashboardEditor.states;
  const { id, currentLayout, canvasWidgets } = dashboardEditor;
  const cellSize = editorConfig.gridCellSizePx;

  if (publish) {
    newWidget.status = 'PUBLISHED';
    setWidgetData((prevState) => ({ ...prevState, status: 'PUBLISHED' }));
  } else {
    newWidget.status = 'DRAFT';
    setWidgetData((prevState) => ({ ...prevState, status: 'DRAFT' }));
  }

  if (widgetData.id) {
    const { state, originalState, ...newWidgetWithoutPosition } = newWidget;

    await httpService.api<number>({
      type: 'updateWidget',
      urlParams: { widgetId: widgetData.id },
      data: {
        ...newWidgetWithoutPosition,
        customization: newWidgetWithoutPosition.customization
          ? removePropertiesAndFields(
              newWidgetWithoutPosition.customization,
              ['columnsDict', 'symbolDict', 'yAxesDict', 'thresholdDict', 'errors'],
              ['columns', 'yAxes', 'threshold', 'remoteAccessButtons'],
              newWidget.type === 'COLUMNS'
                ? ['tagType', 'name', 'displayName', 'draggableId']
                : ['tagType', 'name', 'displayName', 'type', 'draggableId']
            )
          : null,
        terms:
          widgetData.type === 'map' &&
          !widgetData.tags?.length &&
          !widgetData.tagTypes?.length &&
          !widgetData.variables?.length &&
          widgetData.assetProperties?.length < 4
            ? {}
            : setCustomizationTermsForBE(newWidgetWithoutPosition),
      },
    });

    if (originalState?.h !== state.h) {
      await httpService.api({
        type: 'updateWidgetState',
        urlParams: { widgetId: widgetData.id },
        data: { ...state, layoutStateType: currentLayout },
        disableBI: true,
      });
    }
  } else {
    const res = await httpService.api<{ id: number }>({
      type: 'createWidget',
      data: {
        ...newWidget,
        customization: newWidget.customization
          ? removePropertiesAndFields(
              newWidget.customization,
              ['columnsDict', 'symbolDict', 'yAxesDict', 'thresholdDict'],
              ['columns', 'yAxes', 'threshold', 'remoteAccessButtons'],
              newWidget.type === 'COLUMNS'
                ? ['tagType', 'name', 'displayName', 'draggableId']
                : ['tagType', 'name', 'displayName', 'type', 'draggableId']
            )
          : null,
        terms:
          widgetData.type === 'map' &&
          !widgetData.tags?.length &&
          !widgetData.tagTypes?.length &&
          !widgetData.variables?.length &&
          widgetData.assetProperties?.length < 4
            ? {}
            : setCustomizationTermsForBE(newWidget),
        states:
          newWidget.states?.length === 1
            ? newWidget.states
            : newWidget.states.map((widgetState) => {
                const dashboardState = dashboardStates.find(
                  (dashboardState) => dashboardState.layoutStateType === widgetState.layoutStateType
                );
                if (
                  widgetState.w <=
                  (dashboardState.canvasWidthValue - widgetState.x - cellSize * 2) / cellSize
                ) {
                  return widgetState;
                } else {
                  return {
                    ...widgetState,
                    w:
                      widgetState.x > 0
                        ? Math.floor(
                            (dashboardState.canvasWidthValue - widgetState.x - cellSize * 2) /
                              cellSize -
                              6
                          )
                        : Math.floor(
                            (dashboardState.canvasWidthValue - widgetState.x - cellSize * 2) /
                              cellSize -
                              14
                          ),
                    x: widgetState.x > 0 ? widgetState.x : 1,
                  };
                }
              }),
      },
    });

    setWidgetData((prevState) => ({ ...prevState, id: res.id }));
    canvasService.updateWidgetId(widgetData.state, res.id);
  }

  dispatch(
    setDashboardStates(
      dashboardStates.map((state) => {
        if (state.layoutStateType === currentLayout) {
          return state;
        }
        return {
          ...state,
          canvasHeight: state.canvasHeight + newWidget.states[0].h * cellSize + 50,
        };
      })
    )
  );

  dashboardStates.map((state) => {
    if (state.layoutStateType === currentLayout) {
      return;
    }
    httpService.api({
      type: 'updateDashboard',
      urlParams: {
        id,
      },
      disableBI: true,
      data: {
        canvasHeight: state.canvasHeight + newWidget.states[0].h * cellSize + 50,
        layoutStateType: state.layoutStateType,
      },
    });
  });

  dispatch(setDashboardWidgets(canvasWidgets));
};

export const onNavigateFromPage = (
  location,
  history,
  widgetData,
  setWidgetData,
  widgetId,
  initialData,
  enableSaveAsDraft
) => {
  if (
    (location.state && location.state.overrideGuard) ||
    isEqual(
      { ...initialData, creationStage: undefined },
      { ...widgetData, creationStage: undefined }
    )
  ) {
    return true; // Allow navigation.
  }
  const navigate = () => history.push(location.pathname, { overrideGuard: true });
  modalService
    .openConfirm({
      headerText: 'general.discard-changes',
      text: 'create-widget-page.leave-page-warning',
      iconType: 'attention_image',
      cancelText: 'general.cancel',
      confirmText: 'general.confirm',
      showCloseBtn: true,
    })
    .then((confirm) => {
      if (confirm) {
        navigate();
      }
    });
  return false; // Block navigation.
};

export function openReplaceTagsModal(
  widgetData: any,
  dashboardId: number | string,
  setWidgetData: any
) {
  modalService.openModal(
    'replaceTagsModal',
    {
      headerText: 'create-widget-page.create-widget.step-one.replace-tags',
      widgetData: widgetData,
      dashboardId: dashboardId,
      setWidgetData: setWidgetData,
    },
    { disableBackdropClick: true }
  );
}

export function promptOpenReplaceTagsModal(
  widgetData: any,
  dashboardId: number | string,
  setWidgetData: any
) {
  modalService
    .openModal('confirm', {
      text: 'create-widget-page.create-widget.open-replace-tags-utility.message',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      headerText: 'create-widget-page.create-widget.open-replace-tags-utility.header',
      showCloseBtn: true,
      allowDontShowAgain: true,
    })
    .then((confirm) => {
      if (confirm?.res) {
        openReplaceTagsModal(widgetData, dashboardId, setWidgetData);
        if (confirm?.dontShowAgain) {
          updateDontShowAgain('PROFILE', 'SWITCH_TAGS_UTILITY');
        }
      }
    });
}

function removePropertiesAndFields(
  customization,
  objectsToRemove: Array<string>,
  fieldsToReduce: Array<string>,
  subfieldsToRemove: Array<string>
) {
  let newCustomization = cloneDeep(customization);

  fieldsToReduce.forEach((field) => {
    if (newCustomization[field]) {
      if (Array.isArray(newCustomization[field])) {
        newCustomization = {
          ...newCustomization,
          [field]: newCustomization[field].map((p) => omit(p, subfieldsToRemove)),
        };
        return;
      }
      newCustomization = {
        ...newCustomization,
        [field]: omitProperties(newCustomization[field], subfieldsToRemove),
      };
    }
  });

  return omit(newCustomization, objectsToRemove);
}

function addMissingCustomization(widget) {
  switch (widget.type?.toLowerCase()) {
    case 'line':
      if (widget.customization && !widget.customization?.threshold?.length) {
        return {
          ...widget,
          customization: {
            ...widget.customization,
            threshold: [
              {
                id: 1,
                value: 0,
                show: false,
                style: 'SOLID',
                color: '#000000',
                displayName: i18nService.translate(
                  'create-widget-page.create-widget.step-four.columns.max-value'
                ),
              },
              {
                id: 2,
                value: 0,
                show: false,
                style: 'SOLID',
                color: '#000000',
                displayName: i18nService.translate(
                  'create-widget-page.create-widget.step-four.columns.min-value'
                ),
              },
            ],
          },
        };
      }
      return widget;
    default:
      return widget;
  }
}

function addMissingTerms(widget) {
  switch (widget.type?.toLowerCase()) {
    case 'line':
      if (widget.customization && !widget.customization.thresholdDict) {
        return {
          ...widget,
          customization: {
            ...widget.customization,
            thresholdDict:
              widget.customization.threshold?.length &&
              widget.customization.threshold.reduce(
                (result, t) => ({
                  ...result,
                  [`threshold.${t.id}`]: t.displayName,
                }),
                {}
              ),
          },
          terms: {
            ...widget.terms,
            ['thresholdDict.threshold.1']: 'Max',
            ['thresholdDict.threshold.2']: 'Min',
          },
        };
      }
      return widget;
    default:
      return widget;
  }
}
