import { makeStyles } from '@material-ui/core';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { useSelector } from '@src/redux/useSelector';
import React from 'react';
import EventStatusColors from './EventStatusColors';
import Panel from '../../Panel/Panel';

const useStyles = makeStyles((theme: any) => ({}));

const panelsMap = [
  {
    label: 'white-labeling.event-status-colors.title',
    Component: EventStatusColors,
    isPremium: false,
  },
];

function StatusColors(props) {
  const classes = useStyles(props);
  const isPremium = useSelector((state) => state.config.licensingType === 'PREMIUM');
  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);
  const { displaySettingsData, setDisplaySettingsData, onChangeData, refreshColorPickers } = props;
  const statusColors = displaySettingsData['statusColors']?.content;

  const setStatusColorsData = (value) => {
    setDisplaySettingsData(value);
  };

  const onStatusColorsChanged = (key, value) => {
    onChangeData('statusColors', key, value);
  };

  return (
    <div>
      {Object.keys(displaySettingsData).length
        ? panelsMap.map((panel) => (
            <Panel
              {...panel}
              key={panel.label}
              classes={classes}
              displaySettingsData={statusColors}
              setDisplaySettingsData={setStatusColorsData}
              onChangeData={onStatusColorsChanged}
              refreshColorPickers={refreshColorPickers}
            />
          ))
        : null}
    </div>
  );
}

export default StatusColors;
