import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Route, Switch, Redirect, NavLink } from 'react-router-dom';
import I18n from '@components/I18n';
import styles from './OrganizationsPage.scss';
import OrganizationsTree from './OrganizationsTree';
import { useSelector } from '@src/redux/useSelector';
import { getFlagStatus, getPermissionStatus } from '@core/ffAndPermissions';
import { getTabs, getRedirectTab } from './OrganizationsPage.utils';
import { httpService } from '@core/http/HttpService';

function OrganizationsPage(props) {
  const selectedOrgType = useSelector((state) => state.organizations.type);
  const userOrgType = useSelector(
    (state) => state.config.organizationDetails && state.config.organizationDetails.type
  );
  const selectedOrganizationsId = useSelector(
    (state) => state.organizations.selectedOrganizationsId
  );

  const showTree = useMemo(() => getFlagStatus('organizations-management.tree'), []);
  const showUsersTab = useMemo(() => getFlagStatus('organizations-management.users-tab'), []);
  const showAssetsTab = useMemo(() => getFlagStatus('organizations-management.assets-tab'), []);
  const showPlcTab = useMemo(() => getFlagStatus('organizations-management.plc-tab'), []);
  const showRoutersTab = useMemo(() => getFlagStatus('organizations-management.router-tab'), []);
  const showEventsTab = useMemo(() => getFlagStatus('organizations-management.events-tab'), []);
  const showVariablesTab = useMemo(
    () => getFlagStatus('organizations-management.variables-tab'),
    []
  );
  const [isVariablesTabVisible, setIsVariablesTabVisible] = useState(false);

  const hasPermission = useMemo(() => getPermissionStatus('ORGANIZATION', 'VIEW'), []);

  const redirectTo = useMemo(() => getRedirectTab(userOrgType), [userOrgType]);

  useEffect(() => {
    getShowVariablesTab();
  }, [selectedOrganizationsId]);

  function getShowVariablesTab() {
    if (selectedOrganizationsId) {
      if (userOrgType === 'MACHINE_BUILDER') {
        setIsVariablesTabVisible(showVariablesTab && showAssetsTab);
        return;
      }
      showVariablesTab &&
        httpService
          .api({
            type: 'getAssetVariablesByOrg',
            urlParams: { organizationId: selectedOrganizationsId },
          })
          .then(({ results }: any) => {
            setIsVariablesTabVisible(
              showVariablesTab &&
                results.some((variable) => variable.managementLevel === 'LOCAL_ORGANIZATION')
            );
          });
    }
  }

  const tabs = getTabs(
    showAssetsTab,
    showUsersTab,
    showPlcTab,
    showRoutersTab,
    userOrgType,
    selectedOrgType,
    showEventsTab,
    isVariablesTabVisible
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.pageTitle}>
          <I18n>organizations-page.organizations</I18n>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        {showTree && hasPermission && <OrganizationsTree />}
        {selectedOrgType && (
          <div className={classNames(styles.content, !showTree && styles.paddingLeft)}>
            <div className={styles.organizationsTabs}>
              {Object.keys(tabs).map((key, index) => {
                if (tabs[key].condition && tabs[key].condition()) {
                  return (
                    <NavLink
                      key={index}
                      className={styles.tab}
                      activeClassName={styles.activeTab}
                      to={`${props.match.url}/${tabs[key].url}`}>
                      <I18n>{tabs[key].label}</I18n>
                    </NavLink>
                  );
                }
                return null;
              })}
            </div>
            <div className={styles.routsSection}>
              <Switch>
                {Object.keys(tabs).map((key, index) => {
                  if (tabs[key].condition && tabs[key].condition()) {
                    return (
                      <Route
                        key={index}
                        path={`${props.match.url}/${tabs[key].url}`}
                        component={tabs[key].component}
                      />
                    );
                  }
                })}
                {redirectTo && <Redirect to={`${props.match.url}/${redirectTo.url}`} />}
              </Switch>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrganizationsPage;
