import * as RI from '../../redux.interface.d';

export const setAll = (editAssetData: RI.EditAssetState): RI.StoreAction => ({
  type: 'deviceManagment/editAsset/SET_ALL',
  payload: editAssetData
});

export const clearEditAsset = (): RI.StoreAction => ({
  type: 'deviceManagment/editAsset/CLEAR_EDIT_ASSET',
  payload: ''
});

export const updateEditAsset = (values: any): RI.StoreAction => ({
  type: 'deviceManagment/editAsset/UPDATE_EDIT_ASSET',
  payload: values
});

export const setSelectedTab = (tab: string): RI.StoreAction => ({
  type: 'deviceManagment/editAsset/SET_SELECTED_TAB',
  payload: tab
});

export const setRemoteAccessMode = (remoteAccessMode: boolean): RI.StoreAction => ({
  type: 'deviceManagment/editAsset/SET_REMOTE_ACCESS_MODE',
  payload: remoteAccessMode
});
