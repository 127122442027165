import React, { useState, useEffect, useMemo } from 'react';
import SortableTree, { changeNodeAtPath } from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import NodeContent from './NodeContent/NodeContent';
import classNames from 'classnames';
import SelectedHelpers from './SelectedHelpers';
import styles from './OrganizationsTree.scss';
import Icon from '@components/Icon';
import {
  makeStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';
import {
  getNodeKey,
  getChildren,
  getCurrentMB,
  getOrganizationDetailsById,
  findNodeAndParentById,
  removeNode,
  cleanOrganizationData,
  updateTreeChanges,
  onNodeClicked,
  updateMBMap,
  onAddingOrg,
  deleteSelected,
  setFirstNode,
  deleteOrganization,
} from './organizationsTree.utils';
import { useHistory } from 'react-router-dom';
import TreeLegend from './TreeLegend';
import I18n from '@components/I18n';

const useStyles = makeStyles((theme: any) => ({
  tree: {
    '& .rst__lineChildren::after': {
      backgroundColor: 'var(--systemFont)',
      height: 5,
    },
    '& .rst__lineHalfHorizontalRight::before': {
      backgroundColor: 'var(--systemFont)',
    },
    '& .rst__lineFullVertical::after': {
      backgroundColor: 'var(--systemFont)',
    },
    '& .rst__lineHalfVerticalTop::after': {
      backgroundColor: 'var(--systemFont)',
    },
    '& .rst__lineHalfVerticalBottom::after': {
      backgroundColor: 'var(--systemFont)',
    },
    transitionDuration: '0.35s',
  },
  legendPanel: {
    '& .MuiExpansionPanel::before': {
      display: 'none',
    },
  },
  legend: {
    marginBottom: 10,
    boxShadow: theme.app.boxShadowGrey,
    backgroundColor: 'var(--systemScreenBackground)',
    '& .MuiSvgIcon-root': {
      transform: 'rotate(180deg)',
    },
  },
  legendCaption: {
    height: '50px',
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
  },
}));

function OrganizationsTree(props) {
  const history = useHistory();
  const organizationDetails = useSelector((state: any) => state.config.organizationDetails);
  const selectedOrganizationDetails = useSelector((state: any) => state.organizations);
  const userOrganizationId = useSelector((state: any) => state.login.userOrganizationId);
  const [treeData, setTreeData] = useState([]);

  const [selected, setSelected] = useState({});
  const [openTree, setOpenTree] = useState(true);
  const machinBuilderMap = useMemo(() => ({}), []);
  const classes = useStyles(props);

  const [legendExpanded, setLegendExpanded] = useState(true);

  //set the first node of the tree
  useEffect(() => {
    setFirstNode(
      organizationDetails,
      setTreeData,
      setSelected,
      machinBuilderMap,
      userOrganizationId,
      history
    );
  }, [organizationDetails]);

  useEffect(() => {
    if (selectedOrganizationDetails.status === 'DELETE') {
      deleteOrganization(
        treeData,
        setTreeData,
        selected,
        setSelected,
        organizationDetails,
        machinBuilderMap,
        userOrganizationId,
        history
      );
    } else {
      //update tree according to changes that happen in org details.
      updateTreeChanges(
        treeData,
        setTreeData,
        selected,
        setSelected,
        selectedOrganizationDetails,
        organizationDetails
      );
    }
  }, [selectedOrganizationDetails]);

  const expandSelected = () => {
    collapseSelected(true);
  };

  const collapseSelected = (expanded) => {
    const { current } = findNodeAndParentById(treeData, selected);

    const data = changeNodeAtPath({
      treeData,
      path: current.path,
      newNode: {
        ...current.node,
        expanded: expanded === true,
      },
      getNodeKey,
    });
    setTreeData(data);
  };

  const handleLegendChanged =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setLegendExpanded(isExpanded ? !!panel : false);
    };

  return (
    <div
      className={classNames(
        styles.wrapper,
        styles.enableAnimation,
        openTree ? styles.expanded : styles.narrow
      )}
      style={{ marginRight: openTree ? 0 : 37 }}>
      <Icon
        color="var(--systemScreenBackground)"
        onClick={() => setOpenTree(!openTree)}
        style={{
          position: 'absolute',
          right: -7,
          top: '50%',
          cursor: 'pointer',
          transform: 'translateY(-32px)',
        }}
        type={openTree ? 'organizatinSideMenuOpen' : 'organizatinSideMenuClose'}
      />
      <div className={styles.treeWrapper}>
        {selected && (
          <SelectedHelpers
            expandSelected={expandSelected}
            collapseSelected={collapseSelected}
            deleteSelected={() => deleteSelected(selected, treeData, setTreeData, setSelected)}
          />
        )}
        {treeData.length && treeData[0].children && (
          <SortableTree
            rowHeight={40}
            canDrag={false}
            treeData={treeData}
            getNodeKey={getNodeKey}
            className={classNames(classes.tree, styles.test)}
            style={{ height: legendExpanded ? 'calc(100% - 310px)' : 'calc(100% - 110px)' }}
            innerStyle={{ padding: '20px 0px' }}
            generateNodeProps={(rowData) => ({
              onNodeClicked: () =>
                onNodeClicked(
                  rowData,
                  organizationDetails,
                  machinBuilderMap,
                  userOrganizationId,
                  history,
                  setSelected
                ),
              selected: selected && selected['id'] === rowData.node.id,
              cannotExpand:
                rowData.node.type === 'MACHINE_BUILDER' &&
                organizationDetails.type === 'UNITRONICS_CHANNEL',
              onAddClicked: () =>
                onAddingOrg(rowData, selectedOrganizationDetails, setTreeData, treeData, selected),
              updateMBMap: () => updateMBMap(rowData.node, machinBuilderMap),
            })}
            // onVisibilityToggle={n => getChildren(n, setTreeData)}
            onChange={(treeData, props) => {
              setTreeData(treeData);
            }}
            nodeContentRenderer={NodeContent}
          />
        )}
        {openTree && (
          <div className={styles.legendPanel}>
            <ExpansionPanel
              square={true}
              className={classes.legend}
              onChange={handleLegendChanged('legend')}
              expanded={legendExpanded}>
              <ExpansionPanelSummary
                className={classes.legendCaption}
                expandIcon={<ExpandMoreIcon />}>
                <I18n className={classes.label}>{'organizations-page.legend'}</I18n>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <TreeLegend />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrganizationsTree;
