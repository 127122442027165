import React, { useMemo } from 'react';

// Comonents
import I18n from '@components/I18n';
import Button from '@components/Button';

// Interfaces | Services
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import { useSelector } from '@src/redux/useSelector';

const AssignButton = (props) => {
  const {
    styles = { minWidth: 62, width: 62, padding: '0 12px' },
    isDemo,
    assetName,
    assetId,
    requiredPlan,
  } = props;
  const userOrgType = useSelector((state) => state.config.organizationDetails?.type);
  const hasPermission = useMemo(
    () => organizationTypeMap[userOrgType] === 3 || organizationTypeMap[userOrgType] === 1,
    [userOrgType]
  );

  const onClick = async () => {
    try {
      const subscriptions = await httpService.api({
        type: 'getAssetAvaliableSubscriptions',
        urlParams: { assetId },
      });

      modalService.openModal('addSubscriptionToAssetModal', {
        assetName,
        assetId,
        requiredPlan,
        subscriptions,
      });
    } catch (e) {}
  };

  const disabledMode = {
    backgroundColor: 'var(--colorDisabled)',
    color: 'var(--textDisabled)',
    border: 'none',
    cursor: 'unset',
    '&:hover': {
      backgroundColor: 'var(--colorDisabled)',
    },
  };

  return hasPermission ? (
    <Button
      onClick={isDemo ? null : onClick}
      styles={
        isDemo
          ? {
              backgroundColor: 'var(--colorDisabled)',
              color: 'var(--textDisabled)',
              border: 'none',
              '&:hover': {
                cursor: 'auto',
              },
              ...styles,
            }
          : { ...styles }
      }>
      <I18n>{`assets-tab.assign`}</I18n>
    </Button>
  ) : null;
};

export default AssignButton;
