import { i18nService } from '@core/i18n/I18nService';
import { getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import { WidgetData, WidgetDataWithPosition } from './CreateWidgetPage.interface';
import { dateTimeChoice, operatorOptionByFormat } from './SharedMaps';
import {
  allowedOperationsMap,
  assetProperiesFormatsMap,
  expressionBuilderGroupNamesVsValueType,
  getByValue,
  propertyNameByValueTypeMap,
  singleValueWidgets,
  tagFormatMap,
  variableFormatMap,
} from '@core/mapsAndDefinitions';
import { parseCustomizationTerms } from '@src/core/canvas/widget.utils';
import { compact, omit } from 'lodash';
import { getState } from '@src/redux/store';
import {
  getGroupName,
  getNextIndex,
} from '@modals/EditFormulaExpressionModal/EditFormulaExpressionModal.utils';
import { getDataSourcesWithDataType } from './DataStepComponent/StepThree/GroupingAndMeasures/Metrics/Calculations/Calculations.utils';
import { getNextNumberInArray } from '@core/utils';

export const filterOption = {
  valueType: 'ASSET_PROPERTY',
  label: 'create-widget-page.create-widget.step-five.filter-by-asset-id',
  valueId: 1, // ASSET_ID
};

export const filterOptions = [
  {
    valueType: 'ASSET_PROPERTY',
    label: 'create-widget-page.create-widget.step-five.asset-id',
    valueId: 1, // ASSET_ID
  },
];

export const alarmInfosMap = {
  1: 'ALARM_INDEX',
  2: 'ACTIVE_ALARM',
  3: 'SEVERITY',
  4: 'TIMESTAMP',
  5: 'DURATION',
  6: 'DESCRIPTION',
  7: 'NAME',
  8: 'RESOLUTION',
};

export const assetsPropsMap = {
  1: 'ASSET_ID',
  2: 'ASSET_NAME',
  3: 'GEO',
  4: 'ORGANIZATION',
  5: 'STATUS',
  6: 'CREATED_AT',
  7: 'DATE',
  8: 'ASSET_TYPE',
  10: 'ASSET_SERIAL_NUMBER',
  11: 'COMMENTS',
  12: 'PLC_MODEL',
  13: 'PLC_CATALOG_NUMBER',
  14: 'PLC_TYPE',
  15: 'ROUTER_MODEL',
  16: 'ROUTER_SERIAL_NUMBER',
  17: 'SIGNAL_STRENGTH',
  18: 'NETWORK_TYPE',
  19: 'PLC_SERIAL_NUMBER',
};

export const creationStageMap = {
  1: 'FIRST_STAGE',
  2: 'SECOND_STAGE',
  3: 'THIRD_STAGE',
  4: 'FOURTH_STAGE',
  5: 'FIFTH_STAGE',
};

export const creationStepIndexesByStageName = new Map([
  ['FIRST_STAGE', 1],
  ['SECOND_STAGE', 2],
  ['THIRD_STAGE', 3],
  ['FOURTH_STAGE', 4],
  ['FIFTH_STAGE', 5],
]);

export const assetPopertiesGroupByMap = [1, 2, 3, 4, 5, 6, 7, 8, 12, 13, 14, 15, 17, 18];

export const initialWidgetData: WidgetData = {
  id: null,
  dashboardId: null,
  eventTemplateId: null,
  name: '',
  hideWidgetName: false,
  type: null,
  status: 'DRAFT',
  creationStage: '',
  assetTypes: [],
  assetProperties: [],
  alarmInfos: [],
  variables: [],
  localTags: [],
  tags: [],
  tagTypes: [],
  scope: null,
  groupBy: [],
  metrics: [],
  calculations: [],
  filters: [],
  sorts: [],
  customization: null,
  customizationMetrics: [],
  exportFormats: [],
  navigationDashboard: [],
  navigateFilterBy: [],
  terms: {},
  allAssetTypes: false,
  states: [],
};

/**
 * Converts the server representation of the widget to the local
 * representation.
 **/

const serverToLocalAssetProps = (res) => {
  return res.assetProperties.map((prop) => ({
    ...prop,
    name: `create-widget-page.create-widget.asset-properties.${assetsPropsMap[prop.id]}`,
  }));
};

const serverToLocalAlarmInfos = (res) => {
  return res.alarmInfos.map((prop) => ({
    ...prop,
    name: `create-widget-page.create-widget.alarm-infos.${alarmInfosMap[prop.id]}`,
  }));
};

const getTagDetails = (item, res) => {
  switch (item.valueType) {
    case 'ASSET_PROPERTY':
      return serverToLocalAssetProps(res).find((x) => x.id === item.valueId);
    case 'TAG':
      return res.tags.find((x) => x.id === item.valueId);
    case 'TAG_TYPE':
      return res.tagTypes.find((x) => x.id === item.valueId);
    case 'VARIABLE':
      return res.variables.find((x) => x.id === item.valueId);
    case 'CALCULATION':
      return res.localTags.find((x) => x.id === item.valueId);
    case 'ALARM_INFO':
      return serverToLocalAlarmInfos(res).find((x) => x.id === item.valueId);
  }
  return null;
};

const serverToGroupBy = (res) => {
  return res.groupBy.map((item) => {
    const tagDetails = getTagDetails(item, res);
    const dropDownValue = item.operation && dateTimeChoice.find((x) => x.value === item.operation);
    return {
      order: item.order,
      hide: item.hide,
      valueType: item.valueType,
      valueId: item.valueId,
      operation: item.operation,
      isDropDown: !!item.operation,
      name: tagDetails?.name,
      type: tagFormatMap[tagDetails?.type],
      isDropDownValue: dropDownValue && {
        value: dropDownValue.value,
        label: dropDownValue.label,
      },
      isDragDisabled:
        ['HEATMAP'].includes(res.type) && item.valueType === 'ASSET_PROPERTY' && item.valueId === 7
          ? true
          : false,
    };
  });
};

const getSelectType = (tagType, widgetType, scope) => {
  // if scope is last_value there is no select (because there is no oparations)
  if (
    (tagType === 'numberType' || tagType === 'floatType') &&
    ['AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'].includes(scope)
  ) {
    if (
      getConfigValueFromWidgetSettings(widgetType.toLowerCase(), 'dragAndDropRules').maxMetrics ===
      1
    ) {
      return 'SingleSelect';
    } else return 'MultiSelect';
  } else return null;
};

const serverToMetrics = (res) => {
  return res.metrics.map((item) => {
    const tagDetails = getTagDetails(item, res);
    return {
      order: item.order,
      hide: item.hide,
      valueType: item.valueType,
      valueId: item.valueId,
      name: tagDetails.name,
      type:
        item.valueType === 'VARIABLE'
          ? variableFormatMap[tagDetails.valueType]
          : item.valueType === 'CALCULATION'
          ? 'numberType'
          : tagFormatMap[tagDetails.type],
      selectType: getSelectType(tagFormatMap[tagDetails.type], res.type, res.scope),
      operation: item.operation,
    };
  });
};

const miliToDuration = (val) => {
  let miliLeft = 0;
  let hours = Math.floor(val / 1000 / 60 / 60);
  miliLeft = val - hours * 1000 * 60 * 60;
  let minutes = Math.floor(miliLeft / 1000 / 60);
  miliLeft = miliLeft - minutes * 1000 * 60;
  let seconds = Math.floor(miliLeft / 1000);

  let timeString = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
    seconds < 10 ? '0' + seconds : seconds
  }`;
  return timeString;
};

const durationToMili = (val) => {
  return (val && !Array.isArray(val) ? val : '00:00:00').split(':').reduce((acc, item, idx) => {
    let mili = 0;
    if (idx == 0) mili = Number(item) * 1000 * 60 * 60;
    if (idx == 1) mili = Number(item) * 1000 * 60;
    if (idx == 2) mili = Number(item) * 1000;
    return acc + mili;
  }, 0);
};

const getIsActiveAlarmFilter = (item) =>
  item?.valueType === 'ALARM_INFO' &&
  item?.valueId === 2 &&
  item?.condition === 'IS' &&
  item?.values[0];

const serverToFilters = (res) => {
  return res.filters.map((item) => {
    const tagDetails = getTagDetails(item, res);
    const type = item.operation
      ? 'numberType'
      : item.valueType === 'VARIABLE'
      ? variableFormatMap[tagDetails.valueType]
      : tagFormatMap[tagDetails.type];

    return {
      disabled:
        res?.type === 'ALARMS' && res?.scope === 'LAST_VALUE' && getIsActiveAlarmFilter(item),
      order: item.order,
      valueType: item.valueType,
      valueId: item.valueId,
      name: tagDetails.name,
      type,
      autocompleteOptions: [],
      values:
        item.valueId === 5 && item.valueType === 'ALARM_INFO'
          ? miliToDuration(item.values)
          : item.values,
      operation: item.operation,
      condition: operatorOptionByFormat(type, null).find((x) => x.value === item.condition),
    };
  });
};

const serverToSorts = (res) => {
  return res.sorts.map((item) => {
    const tagDetails = getTagDetails(item, res);
    const operation = item.operation
      ? i18nService.translate(`enum.${(item.operation as string).toUpperCase()}`)
      : '';

    return {
      order: item.order,
      valueType: item.valueType,
      valueId: item.valueId,
      name: tagDetails.name,
      type: tagFormatMap[tagDetails.type],
      sortBy: item.sortBy,
      label: `${tagDetails.name} ${operation}`,
      operation: item.operation && item.operation.toUpperCase(),
    };
  });
};

const singleUpdateAssetServerCalculationToLocal = (calculation, widgetData: WidgetData) => {
  const { expression, metrics, order, valueType, valueId, operation } = calculation;
  let action = '';
  let actionValue = 0;
  const expressionRegex = /^\{\{ METRIC_(\d+) \}\}\s*([\*|\/])\s*(\d+(\.\d+)?)$/;

  const match = expression?.match(expressionRegex);
  if (metrics && metrics.length && match) {
    actionValue = +match[3];
    action = match[2] === '*' ? 'MULTIPLY' : 'DIVIDE';

    return {
      order,
      valueType,
      valueId,
      operation,
      action,
      actionValue,
    };
  }

  return false;
};

const singleServerCalculationToLocal = (calculation, widgetData: WidgetData) => {
  const { expression, metrics } = calculation;
  const tags = getDataSourcesWithDataType(widgetData.tags, 'assetTags');
  const tagTypes = getDataSourcesWithDataType(widgetData.tagTypes, 'tagTypes');
  const variables = getDataSourcesWithDataType(widgetData.variables, 'systemProperties');
  const localTags = getDataSourcesWithDataType(widgetData.localTags, 'localTags');

  let dataSources = getDataSources(
    widgetData.assetTypes?.length > 1 ? tagTypes || [] : tags || [],
    widgetData,
    widgetData.assetTypes?.length > 1 ? 'TAG_TYPE' : 'TAG'
  );

  widgetData?.variables && dataSources.push(...getDataSources(variables, widgetData, 'VARIABLE'));
  widgetData?.localTags &&
    dataSources.push(...getDataSources(localTags, widgetData, 'CALCULATION'));

  const metricRegex = /\{\{ METRIC_(\d+) \}\}/;

  let newExpression = '';

  if (expression) {
    newExpression = expression;
    while (metricRegex.test(newExpression) && dataSources.length) {
      const metricMatch = newExpression.match(metricRegex);

      const matchingTag = metrics.find((t) => t.order === +metricMatch[1]);

      if (matchingTag) {
        const { operation, valueId } = matchingTag;
        const matchingDataSource = dataSources.find(
          (d) => d.valueId === valueId && d.operation === operation
        );
        if (matchingDataSource)
          newExpression = newExpression.replace(
            metricRegex,
            `[${matchingDataSource.dataType}.${matchingDataSource.name}]`
          );
        else break;
      } else newExpression = '';
    }
  }

  return {
    ...omit(calculation, 'metrics'),
    expression: newExpression,
  };
};

export const serverToCalculations = (calculations, widgetData) => {
  if (widgetData.type?.toLocaleLowerCase() === 'update_asset') {
    const calculationsData = calculations.map((item) =>
      singleUpdateAssetServerCalculationToLocal(item, widgetData)
    );
    return compact(calculationsData);
  } else {
    const calculationsData = calculations.map((item) =>
      singleServerCalculationToLocal(item, widgetData)
    );
    return calculationsData;
  }
};

export function serverToLocal(res: WidgetData) {
  return {
    id: res.id,
    name: res.name,
    hideWidgetName: res.hideWidgetName,
    dashboardId: res.dashboardId,
    eventTemplateId: res.eventTemplateId,
    type: res.type.toLowerCase(),
    status: res.status,
    creationStage: res.creationStage,
    assetTypes: res.assetTypes,
    allAssetTypes: res.allAssetTypes,
    variables: res.variables.map((variable) => ({
      id: variable.id,
      name: variable.name,
      valueType: variable.valueType,
      value: variable.value,
    })),
    localTags: res.localTags.map((localTag) => ({
      id: localTag.id,
      name: localTag.name,
      format: 'FLOAT32',
    })),
    assetProperties: serverToLocalAssetProps(res),
    alarmInfos: serverToLocalAlarmInfos(res),
    tags: res.tags.map((tag) => ({ id: tag.id, name: tag.name, format: tag.type })),
    tagTypes: res.tagTypes,
    scope: res.scope,
    groupBy: serverToGroupBy(res),
    metrics: serverToMetrics(res),
    filters: serverToFilters(res),
    sorts: serverToSorts(res),
    calculations: serverToCalculations(res.calculations, res),
    customization:
      res.terms && Object.entries(res.terms).length
        ? parseCustomizationTerms(res.terms, res.type.toLowerCase(), res.customization)
        : res.customization,
    customizationMetrics: res.customizationMetrics,
    exportFormats: res.exportFormats,
    navigationDashboard: res.navigationDashboard,
    navigateFilterBy: res.navigateFilterBy.map((f) => ({
      valueType: f.valueType,
      valueId: f.valueId,
      label: filterOptions.find((fo) => fo.valueType === f.valueType && fo.valueId === f.valueId)
        .label,
    })),
    terms: res.terms,
  };
}

/**
 * Converts the local representation of the widget to the server
 * representation.
 **/

const groupByToServer = (groupBy) => {
  return groupBy.map((item) => {
    return {
      order: item.order,
      valueType: item.valueType,
      valueId: item.valueId,
      operation: item.operation,
      hide: item.hide,
    };
  });
};

const metricsToServer = (metrics) => {
  return metrics.map((item) => {
    return {
      order: item.order,
      valueType: item.valueType,
      valueId: item.valueId,
      operation: item.operation ? item.operation?.toUpperCase() : null,
      hide: item.hide,
    };
  });
};

const getFiltersValue = (item) => {
  if (item.name === 'Status') return item.values.map((x) => x.replace(/\s+/g, '_').toUpperCase());
  if (item.valueId === 5 && item.valueType === 'ALARM_INFO' && item.operation !== 'COUNT')
    return [durationToMili(item.values)];
  switch (item.type) {
    case 'floatType':
    case 'numberType':
      return Array.isArray(item.values) ? item.values.map(Number) : item.values;
    case 'booleanType':
      if (item.valueType === 'ALARM_INFO')
        return [
          typeof item?.values[0] === 'boolean' ? item?.values[0] : item?.values[0] === 'true',
        ];
      return item.values.length
        ? [
            item.values.value === 'true' ||
              item.values[0] === 'true' ||
              (item.values[0] === 'false' ? false : item.values[0]),
          ]
        : item.values;
  }

  return item.values;
};

const filtersToServer = (filters) => {
  return filters.map((item) => {
    return {
      order: item.order,
      valueType: item.valueType,
      valueId: item.valueId,
      condition: item.condition.value,
      values: getFiltersValue(item),
      operation: item.operation ? item.operation.toUpperCase() : null,
    };
  });
};

const isNumericFormat = (format) => {
  switch (format) {
    case 'INT8':
    case 'UINT8':
    case 'INT16':
    case 'UINT16':
    case 'INT32':
    case 'UINT32':
    case 'FLOAT32':
    case 'NUMERIC':
      return true;
  }

  return false;
};

const getDataSources = (data, widgetData, valueType) => {
  if (widgetData.scope === 'AGGREGATED_DATA' || widgetData.scope === 'AGGREGATED_LAST_VALUE') {
    const dataSources = [];
    let index = 0;

    const operations =
      valueType === 'VARIABLES'
        ? allowedOperationsMap.get(valueType)
        : allowedOperationsMap.get(widgetData.scope);

    const assetPropertiesData =
      widgetData.assetProperties && widgetData.assetProperties.every((ap) => ap.name)
        ? widgetData.assetProperties
        : widgetData.assetProperties
        ? serverToLocalAssetProps(widgetData)
        : [];

    assetPropertiesData &&
      assetPropertiesData
        ?.sort((a, b) =>
          a.name.toLowerCase() < b.name.toLowerCase()
            ? -1
            : a.name?.toLowerCase() > b.name?.toLowerCase()
            ? 1
            : 0
        )
        .forEach((assetProperty) => {
          operations.forEach((operation) => {
            if (
              !isNumericFormat(assetProperty.format || assetProperty.type) &&
              operation.toUpperCase() !== 'COUNT'
            )
              return; // Acts as a continue

            dataSources.push({
              order: index,
              valueType: 'ASSET_PROPERTY',
              name: `${i18nService.translate(assetProperty.name)} ${i18nService.translate(
                `enum.${operation.toUpperCase()}`
              )}`,
              valueId: assetProperty.id,
              operation,
              dataType: 'assetProperties',
            });
            index++;
          });
        });

    data
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      )
      .forEach((tag) => {
        operations.forEach((operation) => {
          if (
            !isNumericFormat(tag.format || tag.type || tag.valueType) &&
            operation.toUpperCase() !== 'COUNT'
          )
            return; // Acts as a continue

          dataSources.push({
            order: index,
            valueType: tag.valueType === 'NUMERIC' ? 'VARIABLE' : valueType,
            name: `${tag.name} ${i18nService.translate(`enum.${operation.toUpperCase()}`)}`,
            valueId: tag.id,
            operation,
            dataType: tag.dataType,
          });
          index++;
        });
      });

    return dataSources;
  } else
    return data
      .filter((t) => isNumericFormat(t.format || t.type || t.valueType))
      .sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase()
          ? -1
          : a.name.toLowerCase() > b.name.toLowerCase()
          ? 1
          : 0
      )
      .map((tag, index) => ({
        order: index,
        valueType: tag.valueType === 'NUMERIC' ? 'VARIABLE' : valueType,
        name: tag.name,
        valueId: tag.id,
        operation:
          widgetData?.type?.toLowerCase() === 'pie' && widgetData.scope === 'LAST_VALUE'
            ? 'SUM'
            : null,
        dataType: tag.dataType,
      }));
};

const singleUpdateAssetCalculationToServer = (calculation, widgetData) => {
  const { actionValue, action } = calculation;

  const symbol = action === 'MULTIPLY' ? '*' : '/';

  const expression = `{{ METRIC_0 }}${symbol}${actionValue}`;
  return {
    ...omit(calculation, 'action', 'actionValue'),
    expression,
    metrics: [
      {
        order: 0,
        valueType: widgetData.assetTypes.length > 1 ? 'TAG_TYPE' : 'TAG',
        valueId: calculation.valueId,
        operation: null,
      },
    ],
  };
};

const singleCalculationToServer = (calculation, widgetData) => {
  const { expression } = calculation;
  const tags = getDataSourcesWithDataType(widgetData.tags, 'assetTags');
  const tagTypes = getDataSourcesWithDataType(widgetData.tagTypes, 'tagTypes');
  const variables = getDataSourcesWithDataType(widgetData.variables, 'systemProperties');

  let newExpression = (expression && expression.trim()) || '';

  const metrics = getDataSources(
    widgetData.assetTypes.length > 1 ? tagTypes : [...tags, ...variables],
    widgetData,
    widgetData.assetTypes.length > 1 ? 'TAG_TYPE' : 'TAG'
  );

  const usedMetrics = [];

  if (newExpression && newExpression.startsWith("'")) newExpression = newExpression.substring(1);

  if (newExpression && newExpression.endsWith("'"))
    newExpression = newExpression.substring(0, newExpression.length - 1);

  let startIndex = 0;
  let nextDataSourceIndex = getNextIndex(newExpression, startIndex);
  while (nextDataSourceIndex >= 0) {
    const groupName = getGroupName(newExpression, nextDataSourceIndex);
    let bracketsCount = 0;
    for (let charIndex = nextDataSourceIndex; charIndex < newExpression.length; charIndex++) {
      if (newExpression[charIndex] === '[') bracketsCount++;
      if (newExpression[charIndex] === ']') bracketsCount--;

      if (bracketsCount === 0) {
        const tagName = newExpression.substring(
          nextDataSourceIndex + groupName.length + 2,
          charIndex
        );

        const matchingTag = metrics?.find((t) => {
          const valueType = getByValue(expressionBuilderGroupNamesVsValueType, groupName);
          if (t.name === tagName && t.valueType === valueType) {
            return t;
          }
        });

        if (matchingTag) {
          newExpression = newExpression.replaceAll(
            `[${groupName}.${tagName}]`,
            `{{ METRIC_${matchingTag.order} }}`
          );

          if (!usedMetrics.some((m) => m.order === matchingTag.order)) {
            usedMetrics.push(omit(matchingTag, ['name', 'dataType']));
          }
        } else newExpression = newExpression.replaceAll(`[${groupName}.${tagName}]`, '');

        break;
      }
    }
    startIndex = nextDataSourceIndex + 1;
    nextDataSourceIndex = getNextIndex(newExpression, startIndex);
  }

  return {
    ...calculation,
    expression: newExpression,
    metrics: usedMetrics,
  };
};

function checkCalculationInMetricsOrCustomizationMetrics(
  calculation,
  widgetData,
  metricSource: string
) {
  return widgetData[metricSource].some(
    (m) =>
      m.valueId === calculation.valueId &&
      m.valueType === calculation.valueType &&
      (calculation.operation ? m.operation && m.operation === calculation.operation : true)
  );
}

export const calculationsToServer = (calculations, widgetData) => {
  if (widgetData.type?.toLocaleLowerCase() === 'update_asset') {
    const calculationsData = (calculations || [])
      .filter((item) => item && item.action !== 'NONE')
      .map((item) => singleUpdateAssetCalculationToServer(item, widgetData));
    return calculationsData;
  } else {
    let calculationsData;

    if (widgetData.scope === 'RAW_DATA')
      calculationsData = (calculations || [])
        .filter((item) => item && item.expression && item.expression.trim())
        .map((item) => singleCalculationToServer(item, widgetData));
    else
      calculationsData = (calculations || [])
        .filter(
          (item) =>
            item &&
            item.expression &&
            item.expression.trim() &&
            (checkCalculationInMetricsOrCustomizationMetrics(item, widgetData, 'metrics') ||
              checkCalculationInMetricsOrCustomizationMetrics(
                item,
                widgetData,
                'customizationMetrics'
              ))
        )
        .map((item) => singleCalculationToServer(item, widgetData));
    return calculationsData;
  }
};

const sortsToServer = (sorts) => {
  const sortsData = sorts.map((item) => {
    return (
      item.valueId && {
        order: item.order,
        valueType: item.valueType,
        valueId: item.valueId,
        sortBy: item.sortBy,
        operation: item.operation && item.operation.toUpperCase(),
      }
    );
  });
  return compact(sortsData);
};

export function localToServer(widgetData): WidgetDataWithPosition {
  const dashboardEditor = getState().dashboardEditor;
  const canvasWidgets = dashboardEditor.canvasWidgets;
  const currentLayout = dashboardEditor.currentLayout;
  const isMultiLayout = dashboardEditor.states.length > 1;
  const customizationFormatter =
    widgetData.type &&
    getConfigValueFromWidgetSettings(widgetData.type, 'customizationServerChanges');

  return {
    ...omit(widgetData, ['copyId', 'isOpenReplaceTagsModalPrompted']),
    type: widgetData.type.toUpperCase(),
    status: widgetData.status,
    creationStage: widgetData.creationStage,
    assetTypes: widgetData.assetTypes.map((asset) => asset.id),
    assetProperties: widgetData.assetProperties.map((prop) => prop.id),
    variables: widgetData.variables.map((prop) => prop.id),
    localTags: widgetData.localTags,
    tags: widgetData.tags.map((tag) => tag.id),
    tagTypes: widgetData.tagTypes.map((tagType) => tagType.id),
    alarmInfos: widgetData.alarmInfos.map((alarmInfo) => alarmInfo.id),
    scope: widgetData.scope,
    groupBy: groupByToServer(widgetData.groupBy),
    metrics: metricsToServer(widgetData.metrics),
    filters: filtersToServer(widgetData.filters),
    sorts: sortsToServer(widgetData.sorts),
    calculations: calculationsToServer(widgetData.calculations, widgetData),
    customization:
      widgetData.customization && customizationFormatter
        ? customizationFormatter(widgetData.customization, widgetData)
        : widgetData.customization,
    exportFormats: widgetData.exportFormats,
    navigationDashboard: widgetData.navigationDashboard
      ? widgetData.navigationDashboard.value
        ? widgetData.navigationDashboard.value
        : widgetData.navigationDashboard[0]?.type === 'DASHBOARD'
        ? widgetData.navigationDashboard.map(({ id, assetTypeId, type }) => ({
            id,
            assetTypeId,
            type,
          }))
        : widgetData.navigationDashboard.map(({ url, assetTypeId, type }) => ({
            url,
            assetTypeId,
            type,
          }))
      : null,
    navigateFilterBy: widgetData.navigateFilterBy.map(({ valueId, valueType }) => ({
      valueId,
      valueType,
    })),
    states: getWidgetStatesForServer(widgetData, canvasWidgets, currentLayout, isMultiLayout),
  };
}

const getWidgetStatesForServer = (
  widgetData: any,
  canvasWidgets: Array<any>,
  currentLayout: string,
  isMultiLayout: boolean
) => {
  const layoutOptions = ['DESKTOP', 'TABLET', 'MOBILE'];
  if (isMultiLayout) {
    let states = [];
    states.push({ ...widgetData.state, layoutStateType: currentLayout });
    layoutOptions
      .filter((layout) => layout !== currentLayout)
      .forEach((layout) => {
        states.push({
          ...widgetData.state,
          y: calculateY(canvasWidgets, layout),
          x: 1,
          layoutStateType: layout,
        });
      });
    return states;
  } else {
    return [{ ...widgetData.state, layoutStateType: 'DESKTOP' }];
  }
};

const calculateY = (canvasWidgets: Array<any>, layout: string) => {
  let y = 0;

  for (let i = 0, len = canvasWidgets.length; i < len; i++) {
    const widgetState = canvasWidgets[i].states?.find((state) => state.layoutStateType === layout);
    if (widgetState && widgetState.y + widgetState.h >= y) {
      y = widgetState.y + widgetState.h;
    }
  }
  return y;
};

export const getIsInclude = (map, key, value) => map[key] && map[key].includes(value);

function getDataIsFloatForSingleValueWidget(widgetData: any) {
  const valueId = widgetData?.metrics?.length && widgetData?.metrics[0].valueId;
  return widgetData?.tagTypes?.length
    ? widgetData?.tagTypes.find((tagType) => tagType.id === valueId && tagType.type === 'FLOAT32')
    : widgetData?.tags?.length &&
        widgetData?.tags.find((tag) => tag.id === valueId && tag.format === 'FLOAT32');
}

export const getIsFloat = (operation, valueType, type, calculation?, widgetData?) =>
  valueType === 'CALCULATION' ||
  (singleValueWidgets.includes(widgetData?.type) &&
    (getDataIsFloatForSingleValueWidget(widgetData) ||
      widgetData?.metrics[0]?.type === 'floatType')) ||
  (operation && operation.toUpperCase() === 'AVERAGE') ||
  (operation
    ? type === 'floatType' && operation.toUpperCase() !== 'COUNT'
    : type === 'floatType') ||
  isFloatCalculation(calculation, widgetData);

export const isFloatCalculation = (calculation, widgetData) => {
  if (calculation && widgetData) {
    const serverCalculation = singleCalculationToServer(calculation, widgetData);
    const tags =
      widgetData.assetTypes?.length > 1 ? widgetData?.tagTypes || [] : widgetData.tags || [];
    return (
      serverCalculation &&
      serverCalculation.expression &&
      (serverCalculation.expression.includes('.') ||
        serverCalculation.expression.includes('/') ||
        serverCalculation.metrics?.some(
          (m) => m.operation && m.operation.toUpperCase() === 'AVERAGE'
        ) ||
        tags
          .filter((t) =>
            serverCalculation.metrics?.some((m) => m.valueId === t.id && m.operation !== 'COUNT')
          ) // filter all tags that appear on metrics and the operation is not Count
          .map((t) => t.format || t.type)
          .some((t) => t === 'FLOAT32' || t === 'floatType'))
    ); // Then check if those are floats
  }

  return false;
};

export function buildMetricsArray(ids, valueType, hide, metricsLength = 0) {
  return ids.map((i, idx) => ({
    name:
      valueType === 'ASSET_PROPERTY'
        ? `create-widget-page.create-widget.asset-properties.${assetsPropsMap[i]}`
        : i.name,
    order: idx + metricsLength,
    valueType: i.valueType || valueType,
    valueId: i.id || i,
    operation: i.operation || null,
    hide: i.hide || hide,
    type: valueType === 'ASSET_PROPERTY' ? assetProperiesFormatsMap[i] : i.type,
  }));
}

export const getDateTimeOptions = (tagType) => {
  const dateOptions = [
    {
      value: 'SHORT_DATE',
      label: 'create-widget-page.create-widget.step-four.date-time.short-date',
    },
    { value: 'DATE', label: 'create-widget-page.create-widget.step-four.date-time.date' },
    { value: 'LONG_DATE', label: 'create-widget-page.create-widget.step-four.date-time.long-date' },
  ];
  const timeOptions = [
    {
      value: 'TIME_FORMAT_ONE',
      label: 'create-widget-page.create-widget.step-four.date-time.time-1',
    },
    {
      value: 'TIME_FORMAT_TWO',
      label: 'create-widget-page.create-widget.step-four.date-time.time-2',
    },
  ];
  const dateTime = [
    {
      value: 'DATE_TIME_FORMAT_ONE',
      label: 'create-widget-page.create-widget.step-four.date-time.date-time-1',
    },
    {
      value: 'DATE_TIME_FORMAT_TWO',
      label: 'create-widget-page.create-widget.step-four.date-time.date-time-2',
    },
  ];
  if (tagType === 'DATE') {
    return dateOptions;
  } else if (tagType === 'TIME') {
    return timeOptions;
  } else if (tagType === 'DATETIME') {
    return [...dateOptions, ...timeOptions, ...dateTime];
  }
};

export const getTag = (widgetData, metric) => {
  const typesMap = {
    ALARM_INFO: 'alarmInfos',
    TAG: 'tags',
    ASSET_PROPERTY: 'assetProperties',
    TAG_TYPE: 'tagTypes',
    VARIABLE: 'variables',
    CALCULATION: 'localTags',
  };

  return widgetData[typesMap[metric.valueType]]?.find(
    (info) => (info.id || info.valueId) === metric.valueId
  );
};

export const findDateTimeTag = (data, all = false) =>
  data[all ? 'filter' : 'find']((d) =>
    ['DATE', 'dateTimeType', 'DATETIME'].includes(d.format || d.type)
  );

export const convertCalculationToObject = (calculations) =>
  calculations
    ? calculations.reduce(
        (res, item) =>
          item
            ? {
                ...res,
                [item.valueType]: {
                  ...(res[item.valueType] || {}),
                  [item.valueId]: {
                    ...(res[item.valueType] ? res[item.valueType][item.valueId] || {} : {}),
                    [item.operation]: item,
                  },
                },
              }
            : res,
        {}
      )
    : {};

export function getExpressionFromCalculations(calculations: Array<any>, valueId: number) {
  return calculations.find((calc) => calc.valueId === valueId)?.expression;
}

export function getOrder(widgetData: any) {
  const currentMaxOrder = Math.max(
    ...widgetData?.calculations?.map((calc) => {
      return calc.order;
    })
  );
  return isFinite(currentMaxOrder) ? currentMaxOrder + 1 : 0;
}

export function updateCustomizationCalculations(
  setWidgetData: any,
  widgetData,
  expression: string,
  valueType: 'CALCULATION' | 'VARIABLE',
  order = 1,
  operation = null
) {
  const valueId = getSharedIdForLocalTagsAndCustMetrics(widgetData);

  setWidgetData((prevState) => ({
    ...prevState,
    calculations: [
      ...widgetData.calculations.filter(
        (calc) => calc.valueId !== valueId || calc.valueType !== valueType
      ),
      {
        expression: expression,
        operation: operation,
        order: order,
        valueId: valueId,
        valueType: valueType,
      },
    ],
    customizationMetrics: [
      ...widgetData.customizationMetrics.filter((cm) => cm.valueId !== valueId),
      {
        order: order,
        valueType: valueType,
        valueId: valueId,
        operation: operation,
      },
    ],
  }));
}

export function removeCustomizationCalculation(
  widgetData: any,
  setWidgetData: any,
  valueId: number
) {
  const a = widgetData.calculations.filter(
    (calc) => calc.valueType !== 'CALCULATION' || calc.valueId !== valueId
  );
  const b = widgetData.customizationMetrics.filter(
    (cm) => cm.valueType !== 'CALCULATION' || cm.valueId !== valueId
  );
  setWidgetData((prevState) => ({
    ...prevState,
    calculations: widgetData.calculations.filter(
      (calc) => calc.valueType !== 'CALCULATION' || calc.valueId !== valueId
    ),
    customizationMetrics: widgetData.customizationMetrics.filter(
      (cm) => cm.valueType !== 'CALCULATION' || cm.valueId !== valueId
    ),
  }));
}

export function getCustomizationValueFromResults(
  previewData: any,
  customizationMetricValueId: number,
  result: any
) {
  const name = previewData.customizationMetricsColumns.find(
    (column) => column.valueId === customizationMetricValueId
  )?.name;
  return result[name];
}

export function getMetricName(widgetData: any, metric: { valueId: number; valueType: string }) {
  return (
    i18nService.translate(
      widgetData &&
        widgetData[propertyNameByValueTypeMap.get(metric.valueType)]?.find(
          (t) => t.id === metric.valueId
        )?.name
    ) || ''
  );
}

export function getSharedIdForLocalTagsAndCustMetrics(widgetData) {
  const customizationMetrics =
    widgetData.customizationMetrics?.map((cm) => ({ ...cm, id: cm?.valueId })) || [];
  return getNextNumberInArray([...widgetData.localTags, ...customizationMetrics], 'id');
}
