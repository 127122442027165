import React, { useMemo } from 'react';
import Select, { components } from 'react-select';

import { optionsMap } from '@components/SelectSharedComponents/optionsMap';
import Icon from '@components/Icon';
import styles from './SingleSelectWithChip.scss';
import { SingleSelectWithChipProps } from './SingleSelectWithChip.interface';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@redux/useSelector';
import {
  setPlaceholder,
  setValue,
  onSelectionChange,
  onFilterOption,
} from './SingleSelectWithChip.utils';

const handleSelectionChange = (e, onChange) => {
  onSelectionChange(e, onChange);
};

const modes = {
  thin: {
    multiValue: {
      height: '17px',
      alignItems: 'center',
      fontSize: '14px',
    },
    deleteIconHeight: '15px',
    deleteIconWidth: '24px',
    valuesChip: {
      height: '17px',
      alignItems: 'center',
      fontSize: '14px',
      boxSizing: 'inherit',
    },
  },
};

const customStyles = (props) => {
  const { menuPortal } = props.styles || {};

  return {
    option: (base, state) => ({
      ...base,
      height: '100%',
      padding: '4px 12px',
      backgroundColor: state.isSelected
        ? 'var(--multiSelectOptionSelected)'
        : state.isFocused
        ? 'var(--multiSelectOptionFocused)'
        : 'var(--white1)',
      color: 'var(--systemFont)',
      opacity: state.isDisabled ? 0.5 : 1,
    }),
    container: (base) => ({
      ...base,
      position: 'relative',
      height: 'inherit',
      width: '100%',
      maxHeight: '95px',
      display: 'flex',
    }),
    control: (base, state) => ({
      ...base,
      minHeight: '20px',
      width: '100%',
      height: state.isFocused ? 'none' : 'inherit',
      maxHeight: 96,
      position: 'absolute',
      overflow: 'hidden',
      border: props.errorStyle
        ? 'solid 1px var(--formikErrorBorder)'
        : state.isFocused
        ? 'solid 1px var(--darkerGrey)'
        : 'solid 1px var(--lightGrey)',
      boxShadow: 'none',
      zIndex: 1,
      '&:hover': {
        border: 'solid 1px var(--darkerGrey) !important',
      },
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: 12,
      color: 'var(--systemFont)',
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999999999999,
      ...menuPortal,
    }),
    menu: (base) => ({
      ...base,
      position: 'relative',
      top: 0,
      marginTop: 2,
      zIndex: 999999999999999,
    }),
    menuList: (base) => ({
      ...base,
    }),
    singleValue: (base, state) => ({
      ...base,
      display: 'flex',
      width: 'fit-content',
      padding: '0 3px 0 7px',
      borderRadius: `16px`,
      color: 'var(--systemFont)',
      backgroundColor: 'var(--multiSelectChip)',
      fontSize: '85%',
    }),
  };
};

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <div style={{ paddingTop: '2px' }}>{props.children}</div>
    <div
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}>
      {!props.isDisabled && <Icon
        className={styles.removeIcon}
        height={props.selectProps.mode && modes[props.selectProps.mode].deleteIconHeight}
        width={props.selectProps.mode && modes[props.selectProps.mode].deleteIconWidth}
        type="materialDelete"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleSelectionChange(null, props.onChange);
        }}
      />}
    </div>
  </components.SingleValue>
);

function SingleSelectWithChip(props: SingleSelectWithChipProps) {
  const {
    id,
    values = [],
    options,
    onChange,
    onInputChanged,
    filterOption,
    tableConfig,
    getOptionLabel,
    getOptionValue,
    isOptionDisabled,
    isDisabled,
    placeholder,
    optionType = 'default',
    maxMenuHeight,
    mode,
    selectType,
    showPlaceholder = true,
  } = props;

  const languageId = useSelector((state) => state.config.languageId);
  const OptionTemplate = optionsMap[optionType].option;
  const HeaderTemplate = optionsMap[optionType].header;

  const renderedPlaceholder = useMemo(() => {
    return setPlaceholder(placeholder, languageId);
  }, [placeholder, languageId]);

  const selectValue = useMemo(() => {
    return setValue(values, getOptionValue);
  }, [values, languageId]);

  const onChangedInput = (value) => {
    onInputChanged(value);
  };

  const defaultGetOptionLabel = (option) => {
    return i18nService.translate(option.label);
  };

  return (
    <Select
      id={id}
      closeMenuOnSelect
      components={{
        SingleValue: (props) => SingleValue({ ...props, onChange }),
        Option: OptionTemplate,
        MenuList: HeaderTemplate,
      }}
      styles={customStyles(props)}
      value={selectValue}
      onInputChange={onInputChanged ? onChangedInput : null}
      onChange={(e) => handleSelectionChange(e, onChange)}
      getOptionLabel={getOptionLabel || defaultGetOptionLabel}
      getOptionValue={getOptionValue}
      filterOption={(option, rawInput) => onFilterOption(filterOption, option, rawInput)}
      isDisabled={isDisabled}
      isOptionDisabled={isOptionDisabled}
      hideSelectedOptions={false}
      options={options}
      mode={mode}
      selectType={selectType}
      placeholder={showPlaceholder && renderedPlaceholder}
      tableConfig={tableConfig}
      menuPortalTarget={document.body}
      maxMenuHeight={maxMenuHeight}
      showCheckbox={false}
    />
  );
}
export default SingleSelectWithChip;
