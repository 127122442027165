import React, { useCallback } from 'react';
import { PLCData } from '@redux/redux.interface.d';
import Link from '@material-ui/core/Link';
import { modalService } from '@core/modals/ModalService';
import styles from '../PLCTable.scss';
import { plcService } from '@pages/DeviceManagment/PLC/PLCService';

function PLCAssetConnected(props: PLCData) {
  const { name, id } = props.asset;

  const openEditAssetModal = () => {
    modalService.openModal('editAsset', { rowData: { assetId: id } }).then(() => {
      plcService.refreshPLCTableData();
    });
  };

  return (
    <>
      {id && (
        <Link component="button" id={styles.linkBtn} title={name} onClick={openEditAssetModal}>
          {name}
        </Link>
      )}
    </>
  );
}

export default PLCAssetConnected;
