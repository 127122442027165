import React, { useEffect, useState, useMemo } from 'react';
import classNames from 'classnames';
import { ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import {
  getIsIncludeInSettings,
  getConfigValueFromWidgetSettings,
} from '@core/canvas/widget.utils';
import { WidgetSortingProps } from './WidgetSorting.interface.d';
import styles from './WidgetSorting.scss';
import WidgetSortItem from './WidgetSortItem';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { dndService } from '@core/DndService';
import {
  onSortRemoved,
  onSortSelected,
  setAvailableAndSortData,
  setAvailableAndSortByDataChanged,
  onDragAndDrop,
} from './WidgetSorting.utils';

const useStyles = {
  root: {
    '& .MuiExpansionPanelSummary-root': {
      minHeight: '44px !important',
      padding: '0 19px',
      maxWidth: '100%',
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: '10px 0 !important',
      maxWidth: '92%',
    },
    '& .MuiIconButton-root': {
      padding: 10,
    },
    '& .MuiCollapse-container': {
      borderTop: '1px solid var(--tableRowBorderBottom)',
    },
    '& .MuiExpansionPanelDetails-root': {
      padding: '15px 19px',
    },
  },
};

function WidgetSorting(props: WidgetSortingProps) {
  const { widgetData, setWidgetData, classes } = props;
  const { groupBy, metrics, filters, sorts, assetProperties, tags, tagTypes, variables, scope } =
    widgetData;
  const [availableSortColunms, setAvailableSortColunms] = useState([]);
  const [widgetColunms, setWidgetColunms] = useState([]);
  const [sortItems, setSortItems] = useState(sorts);

  const addNewSortItem = () =>
    setSortItems(
      sortItems.concat({
        order: sortItems.length,
      })
    );

  useEffect(() => {
    setAvailableAndSortByDataChanged(
      setWidgetData,
      setSortItems,
      setAvailableSortColunms,
      sortItems,
      setWidgetColunms,
      widgetData
    );
  }, [groupBy, metrics, assetProperties, tags, tagTypes, variables, scope]);

  useEffect(() => {
    setAvailableAndSortData(widgetColunms, sortItems, setAvailableSortColunms, sorts, setSortItems);
  }, [sorts]);

  useEffect(() => {
    const sub = dndService.dragEndSubscribe((result) => {
      onDragAndDrop(result, sortItems, setWidgetData);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [sorts, sortItems]);

  const isDefaultExpanded = useMemo(
    () => !groupBy.length && !metrics.length && !filters.length && !!sorts.length,
    [groupBy, metrics, filters, sorts]
  );

  return (
    <ExpansionPanel
      defaultExpanded={isDefaultExpanded}
      disabled={
        // Type validation is stronger then scope('RAW_DATA'...) validations
        getConfigValueFromWidgetSettings(widgetData.type, 'expansionsPanelDisable') &&
        getIsIncludeInSettings(widgetData.type, 'expansionsPanelDisable', 'sort')
      }
      id={styles.collapsePanel}
      className={classNames(styles.ep, classes.root)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={styles.epSummary}>
          <I18n element="div">create-widget-page.create-widget.step-three.sort</I18n>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={styles.epDetailsWrapper}>
          {sortItems && sortItems.length > 1 && (
            <I18n className={styles.reorderText} element="div">
              create-widget-page.create-widget.step-three.reorder-sorting
            </I18n>
          )}

          <Droppable droppableId="sorting">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {sortItems.map((item, idx) => (
                  <Draggable
                    key={`${item.order}sorting`}
                    draggableId={`${item.order}sorting`}
                    index={idx}
                    isDragDisabled={!item.name}>
                    {(provided) => (
                      <>
                        <WidgetSortItem
                          sortColunms={availableSortColunms}
                          sortSelected={(prevVal, sort) =>
                            onSortSelected(prevVal, sort, sortItems, setSortItems, setWidgetData)
                          }
                          sortRemoved={(data) =>
                            onSortRemoved(data, sortItems, setSortItems, setWidgetData)
                          }
                          item={item}
                          provided={provided}
                        />
                        {idx < sortItems.length - 1 && (
                          <I18n element="div" className={styles.thenBy}>
                            create-widget-page.create-widget.step-three.then-by
                          </I18n>
                        )}
                      </>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
          {availableSortColunms.length > 0
            ? (sortItems.length === 0 ||
                (sortItems.length > 0 && sortItems[sortItems.length - 1].valueId)) && (
                <div
                  className={classNames(
                    'pointer',
                    styles.addWrapper,
                    sortItems.length > 0 && styles.marginTop
                  )}
                  onClick={addNewSortItem}>
                  <div className={styles.addNew}>
                    <Icon className={styles.addNewIcon} type="create_new" />
                    <I18n>create-widget-page.create-widget.step-three.add-sort</I18n>
                  </div>
                </div>
              )
            : null}
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default withStyles(useStyles)(WidgetSorting);
