import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { modalService } from '@core/modals/ModalService';
import { useSelector } from '@src/redux/useSelector';
import { httpService } from '@core/http/HttpService';
import { assetsService } from '@pages/DeviceManagment/Assets/AssetsService';
import { getSubscriptions } from '@modals/EditAssetModal/EditAssetModal.utils';

const useStyles = makeStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    flex: 1,
  },
  disabledButton: {
    pointerEvents: 'none',
    opacity: 0.4,
  },
  btnGroup: {
    display: 'flex',
    textAlign: 'center',
    marginLeft: 24,
    alignItems: 'flex-end',
    maxWidth: 160,
  },
  iconBtn: {
    display: 'inline-block',
    marginRight: 3,
  },
  textBtn: {
    color: 'var(--systemFont)',
    fontSize: 12,
  },
}));

function ButtonsRow(props) {
  const { subscriptionsData, requiredPlan, setSubscriptionsData } = props;
  const { subscriptions = [] } = subscriptionsData || {};
  const editAssetGeneral = useSelector((state) => state.editAsset.general);
  const { assetId, assetName, isDemo } = editAssetGeneral;
  const classes = useStyles(props);
  const noneExpired = useMemo(
    () => subscriptions.filter((s) => s.status !== 'EXPIRED'),
    [subscriptions]
  );

  const removeSubscription = async () => {
    if (noneExpired.length === 1) {
      openRemoveSubscriptionModal(noneExpired[0]);
    } else {
      const res = await modalService.openModal('modalWithSelect', {
        options: noneExpired.sort((a, b) =>
          !a.createdDate
            ? -1
            : !b.createdDate
            ? 1
            : moment(a.createdDate, 'YYYY-MM-DD').isSameOrBefore(
                moment(b.createdDate, 'YYYY-MM-DD')
              )
            ? -1
            : 1
        ),
        title: 'asset-subscription.remove.title',
        text: 'asset-subscription.remove.select-text',
        assetName,
        changedKey: 'remove',
        selectText: 'asset-subscription.remove.select',
        condition: (d) => !d.remove,
        selectCfg: {
          defaultSelect: false,
          isMulti: false,
          getOptionLabel: (opt) => `${opt.subscriptionKey} - ${opt.description}`,
          getOptionValue: (opt) => opt.id,
        },
      });
      if (res.description) {
        openRemoveSubscriptionModal(res);
      }
    }
  };

  const openRemoveSubscriptionModal = async (selectedSubscription) => {
    try {
      const confirm = await modalService.openModal('confirm', {
        text: 'asset-subscription.remove.text',
        iconType: 'attention_image',
        confirmText: 'general.confirm',
        cancelText: 'general.cancel',
        showCloseBtn: true,
        description: selectedSubscription.description,
        assetName,
        headerText: 'asset-subscription.remove.confirm-title',
      });
      if (confirm) {
        await httpService.api({
          type: 'removeSubscriptionFromAsset',
          urlParams: { assetId, subscriptionId: selectedSubscription.id },
        });
        getSubscriptions(+assetId, setSubscriptionsData);
        assetsService.refreshAssetTableData();
      }
    } catch (e) {}
  };
  const assignSubscription = async () => {
    try {
      const subscriptions = await httpService.api({
        type: 'getAssetAvaliableSubscriptions',
        urlParams: { assetId },
      });

      const res = await modalService.openModal('addSubscriptionToAssetModal', {
        assetName,
        assetId,
        requiredPlan,
        subscriptions,
      });
      if (res) {
        getSubscriptions(+assetId, setSubscriptionsData);
        assetsService.refreshAssetTableData();
      }
    } catch (e) {}
  };

  return (
    <div className={classes.wrapper}>
      <div
        onClick={noneExpired.length && !isDemo ? removeSubscription : () => {}}
        className={classNames(
          'pointer',
          classes.btnGroup,
          (!noneExpired.length || isDemo) && classes.disabledButton
        )}>
        <Icon className={classes.iconBtn} type="deleteUser" color="var(--systemFont)"></Icon>
        <I18n className={classes.textBtn}>asset-subscription.remove</I18n>
      </div>
      <div
        onClick={!isDemo && assignSubscription}
        className={classNames('pointer', classes.btnGroup, isDemo && classes.disabledButton)}>
        <Icon className={classes.iconBtn} type="assign" color="var(--systemFont)"></Icon>
        <I18n className={classes.textBtn}>asset-subscription.assign</I18n>
      </div>
    </div>
  );
}

export default ButtonsRow;
