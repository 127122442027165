import React, { useState, useMemo } from 'react';
import { InputBase } from '@material-ui/core';
import { isEqual } from 'lodash';

//Components
import Select from '@components/Select';
import ColorInput from '@components/ColorInput';

// Services | Interfaces
import styles from './ColumnCustomizationRow.scss';
import {
  ColumnsCustomizationColumn,
  ColumnsCustomizationAxes,
} from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { ColumnCustomizationRowProps } from './ColumnCustomizationRow.interface';
import { getVar } from '@core/canvas/widget.utils';

// 3rd party
import classNames from 'classnames';
import { modalService } from '@core/modals/ModalService';
import { useEffect } from 'react';
import { i18nService } from '@core/i18n/I18nService';
import {
  handleNameBlur,
  handleNameChange,
  validateNameError,
} from './ColumnCustomizationRow.utils';
import NumberInput from '@components/NumberInput';

function ColumnCustomizationRow(props: ColumnCustomizationRowProps) {
  const { column, columnCfg, isFirst, isLast, colIndex, columnChanged, type, stacked, stackType } =
    props;
  const [editedColumn, setEditedColumn] = useState<any>({
    ...column,
    displayName: column.displayName ? column.displayName : null,
  });
  const [displayNameHasError, setDisplayNameHasError] = useState(false);

  const typeOptions = useMemo(
    () => [
      { label: 'enum.BAR', value: 'BAR' },
      { label: 'enum.LINE', value: 'LINE' },
      { label: 'enum.AREA', value: 'AREA' },
    ],
    []
  );

  const showOptions = useMemo(
    () => [
      { label: 'enum.NO', value: 'NO' },
      { label: 'enum.LEFT', value: 'LEFT' },
      { label: 'enum.RIGHT', value: 'RIGHT' },
    ],
    []
  );

  const scaleOptions = useMemo(
    () => [
      { label: 'enum.AUTO', value: 'AUTO' },
      { label: 'enum.MANUAL', value: 'MANUAL' },
    ],
    []
  );

  useEffect(() => {
    validateNameError(editedColumn.displayName, setDisplayNameHasError);
  }, []);

  useEffect(() => {
    if (!isEqual(column, editedColumn)) setEditedColumn(column);
  }, [column]);

  const handleChange = (value, property, onlyLocalChange = false) => {
    /*  switch (property) {
       case 'minValue':
       case 'maxValue':
         const numberReg = /^\d*\.?\d*$/;
         if (!numberReg.test(value) && value !== '') return;
     } */
    const updatedColumn = { ...editedColumn, [property]: value };
    setEditedColumn(updatedColumn);
    !onlyLocalChange && columnChanged(updatedColumn);
  };

  const handleShowChange = (option) => {
    if (isFirst && isLast && option.value === 'NO') {
      modalService.openModal('confirm', {
        headerText: 'create-widget-page.create-widget.step-four.columns.confirm-show-header',
        text: 'create-widget-page.create-widget.step-four.columns.confirm-show-text',
        showCloseBtn: true,
        confirmText: 'general.confirm',
      });
    } else {
      handleChange(option.value, 'show');
    }
  };

  const getColDefaultColor = () => {
    const varIndex = colIndex < 6 ? colIndex : colIndex % 6;
    return getVar(`widgetsGraphsColorsPalette${varIndex + 1}`);
  };

  const getColumnsTableTagNameForDisplay = () => {
    if (type === 'columns' && (editedColumn as ColumnsCustomizationColumn).specific_asset)
      return `${editedColumn.name} - (${i18nService.translate(
        'create-widget-page.create-widget.step-four.asset'
      )})`;

    return editedColumn.name;
  };

  const getColumnsTableFields = () => {
    const {
      type = 'BAR',
      displayName,
      decimalDigits,
      color = getColDefaultColor(),
    } = editedColumn as ColumnsCustomizationColumn;
    const columnType = useMemo(() => typeOptions.find((o) => o.value === type), [type]);

    const showDecimalDigits = decimalDigits || decimalDigits === 0;

    return (
      <>
        <div id={'e634d58f-3ec8-4daf-9c3d-8d370f60c941'} style={columnCfg[1].style}>
          <InputBase
            title={displayName}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              displayNameHasError && styles.containerInputError
            )}
            value={displayName}
            inputProps={{ maxLength: 25 }}
            onChange={(e) =>
              handleNameChange(
                e.target.value,
                editedColumn,
                setEditedColumn,
                setDisplayNameHasError
              )
            }
            onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
          />
        </div>
        <div id={'2be177c1-8d9f-4886-8820-75027c604ca6'} style={columnCfg[2].style}>
          <InputBase
            title={decimalDigits && decimalDigits.toString()}
            id={styles.inputStyle}
            className={styles.containerInput}
            disabled={!showDecimalDigits}
            value={decimalDigits}
            inputProps={{ maxLength: 1 }}
            onChange={(e) =>
              +e.target.value > -1 &&
              +e.target.value < 10 &&
              handleChange(e.target.value ? e.target.value : 0, 'decimalDigits', true)
            }
            onBlur={(e) =>
              +e.target.value > -1 &&
              +e.target.value < 10 &&
              handleChange(+e.target.value, 'decimalDigits')
            }
            type="number"
          />
        </div>
        <div id={'0f396969-1a53-4067-9dca-2f36fcd38869'} style={columnCfg[3].style}>
          <ColorInput
            value={color}
            customeMarginButton={0}
            popOverPosition={{ left: 1, top: 21 }}
            isParentRelative={false}
            colorChange={(value) => handleChange(value, 'color')}
          />
        </div>
        <div id={'91642657-b070-4d5e-9382-60a2be6082a9'} style={columnCfg[4].style}>
          <Select
            options={typeOptions}
            value={columnType}
            onChange={(option) => handleChange(option.value, 'type')}
            disabled={stacked && stackType === 'PERCENTAGE'}
          />
        </div>
      </>
    );
  };

  const getYAxesTableFields = () => {
    const {
      maxValue,
      minValue,
      displayName,
      decimalDigits,
      scale = 'AUTO',
      show = isFirst ? 'LEFT' : 'NO',
    } = editedColumn as ColumnsCustomizationAxes;
    const columnShow = useMemo(() => showOptions.find((o) => o.value === show), [show]);
    const columnScale = useMemo(() => scaleOptions.find((o) => o.value === scale), [scale]);
    const showDecimalDigits = decimalDigits || decimalDigits === 0;

    return (
      <>
        <div id={'43175f2e-3927-44cc-81cf-9c082304bcb9'} style={columnCfg[1].style}>
          <Select
            options={showOptions}
            value={columnShow}
            onChange={(option) => handleShowChange(option)}
          />
        </div>
        <div id={'c28d5405-0374-4ecb-b4d6-4575172be013'} style={columnCfg[2].style}>
          <InputBase
            disabled={show === 'NO'}
            title={displayName}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              displayNameHasError && styles.containerInputError
            )}
            value={displayName}
            inputProps={{ maxLength: 100 }}
            onChange={(e) =>
              handleNameChange(
                e.target.value,
                editedColumn,
                setEditedColumn,
                setDisplayNameHasError
              )
            }
            onBlur={(e) => handleNameBlur(editedColumn, setEditedColumn, props)}
          />
        </div>
        <div id={'87367970-7782-4f52-b765-43e8c9730f6c'} style={columnCfg[3].style}>
          <InputBase
            title={decimalDigits && decimalDigits.toString()}
            id={styles.inputStyle}
            disabled={!showDecimalDigits}
            className={styles.containerInput}
            value={decimalDigits}
            inputProps={{ maxLength: 1 }}
            onChange={(e) =>
              +e.target.value > -1 &&
              +e.target.value < 10 &&
              handleChange(e.target.value ? e.target.value : 0, 'decimalDigits', true)
            }
            onBlur={(e) =>
              +e.target.value > -1 &&
              +e.target.value < 10 &&
              handleChange(+e.target.value, 'decimalDigits')
            }
            type="number"
          />
        </div>
        <div id={'a5f3068a-2316-4013-ba14-7e73b4e212b3'} style={columnCfg[4].style}>
          <Select
            disabled={show === 'NO' || (stacked && stackType === 'PERCENTAGE')}
            options={scaleOptions}
            value={columnScale}
            onChange={(option) => handleChange(option.value, 'scale')}
          />
        </div>
        <div id={'36e127ad-fe85-4f71-bf6d-2207413492d7'} style={columnCfg[5].style}>
          <InputBase
            disabled={scale === 'AUTO'}
            title={minValue?.toString()}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              scale === 'MANUAL' && !minValue && minValue !== 0 && styles.containerInputError
            )}
            value={minValue || ''}
            type="number"
            onChange={(e) => handleChange(e.target.value, 'minValue')}
            onBlur={(e) => handleChange(e.target.value, 'minValue')}
          />
        </div>
        <div id={'bf441e5e-8887-4b99-acd1-bdfe96c6bf2e'} style={columnCfg[6].style}>
          <InputBase
            disabled={scale === 'AUTO'}
            title={maxValue?.toString()}
            id={styles.inputStyle}
            className={classNames(
              styles.containerInput,
              scale === 'MANUAL' && !maxValue && maxValue !== 0 && styles.containerInputError
            )}
            value={maxValue || ''}
            type="number"
            onChange={(e) => handleChange(e.target.value, 'maxValue')}
            onBlur={(e) => handleChange(e.target.value, 'maxValue')}
          />
        </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div
        title={getColumnsTableTagNameForDisplay()}
        style={columnCfg[0].style}
        className={classNames('ellipsis-overflow')}>
        {getColumnsTableTagNameForDisplay()}
      </div>
      {type === 'columns' && getColumnsTableFields()}
      {type === 'yAxes' && getYAxesTableFields()}
    </div>
  );
}

export default ColumnCustomizationRow;
