export const onInputFocus = (getLastSearches) => {
  getLastSearches && getLastSearches();
};

// In case of click on the search icon
export const onSearchClicked = (searchText, searchFunc, setSearchResultsText, minCharsToSearch) => {
  search(searchText, searchFunc, setSearchResultsText, minCharsToSearch);
};

// In case of choosing an option from the dropdown
export const onValueChanged = (e, setSearchText) => {
  const text = e.target.textContent;
  !!text && setSearchText(text);
};

export const handleKeyUp = (
  e,
  setSearchText,
  searchFunc,
  setSearchResultsText,
  minCharsToSearch
) => {
  const text = e.target.value;
  // In case of click on Enter or Backspace
  if (e.key === 'Enter' || !text.length)
    search(text, searchFunc, setSearchResultsText, minCharsToSearch);
  // In case of typing text
  if (e.key !== 'Enter') setSearchText(text);
};

const search = (text, searchFunc, setSearchResultsText, minCharsToSearch) => {
  if (text.length >= minCharsToSearch || !text.length) {
    searchFunc(text);
    setSearchResultsText(text);
  }
};

// In case of click on the clear icon
export const resetSearch = (setSearchText, searchFunc, setSearchResultsText) => {
  setSearchText('');
  searchFunc(null);
  setSearchResultsText('');
};
