import React, { useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import Icon from '@components/Icon';
import I18n from '@components/I18n';
import styles from './ReplaceTagsModal.scss';
import classnames from 'classnames';
import { i18nService } from '@core/i18n/I18nService';
import AssetTypeSection from './AssetTypeSection/AssetTypeSection';
import DataSection from './DataSection/DataSection';
import {
  removeNameFromCalculations,
  replaceOrganizeFields,
  replaceInExpression,
  setNamesAndLabels,
  openReplacementCompletedModal,
  prepareAssetOptions,
  replaceValueType,
  getNewCustomizationColumnValues,
  getTagDataSourceTarget,
  replaceTypeInMetrics,
  updateCustomizationValuesPerWidgetType,
  updateMetricsPerWidgetType,
} from './ReplaceTagsModal.utils';
import { isEqual, omit, remove } from 'lodash';
import { modalService } from '@core/modals/ModalService';
import { httpService } from '@core/http/HttpService';
import { AssetPropertiesResponse } from '@pages/CreateWidgetPage/DataStepComponent/StepOne/StepOne.interface';
import { decrementBusyCounter } from '@src/redux/config';
import { dispatch } from '@src/redux/store';

function ReplaceTagsModal(props) {
  const { args, dismiss } = props;
  const { widgetData, setWidgetData } = args;
  const { assetTypes, dashboardId } = widgetData;

  const confirmBtn = useRef(null);

  const [assetPropertiesOptions, setAssetPropertiesOptions] = useState([]);
  const [dataTagOptions, setDataTagOptions] = useState([]);

  const assetTypesSourceTarget = widgetData.allAssetTypes
    ? { source: 'all', replaceWith: 'all' }
    : { source: assetTypes, replaceWith: assetTypes };

  const assetPropertiesData = widgetData.assetProperties.map((assetProperty) => {
    return { ...assetProperty, name: i18nService.translate(assetProperty.name) };
  });
  const assetPropertiesDataSourceTarget = assetPropertiesData.map((assetProperty) => {
    return { source: assetProperty, replaceWith: assetProperty };
  });

  const [replacements, setReplacements] = useState({
    assetTypes: assetTypesSourceTarget,
    assetProperties: assetPropertiesDataSourceTarget,
    tagData: getTagDataSourceTarget(widgetData),
  });

  const isFinishButtonEnabled =
    replacements &&
    replacements.assetTypes?.replaceWith?.length &&
    !replacements?.assetProperties?.some((assetProperty) => assetProperty.replaceWith == null) &&
    !replacements?.tagData?.some((tag) => tag.replaceWith == null) &&
    (replacements?.assetProperties?.some(
      (assetProperty) => !isEqual(assetProperty.source, assetProperty.replaceWith)
    ) ||
      replacements?.tagData?.some((tag) => !isEqual(tag.source, tag.replaceWith)) ||
      !isEqual(replacements.assetTypes?.source, replacements.assetTypes?.replaceWith));

  function replaceData(field: string, id: number, replaceWith: any) {
    setReplacements({
      ...replacements,
      [field]: replacements[field].map((item) =>
        item.source.id === id
          ? {
              ...item,
              replaceWith: replaceWith?.length
                ? replaceWith[0].format
                  ? {
                      id: replaceWith[0].id,
                      name: replaceWith[0].name,
                      format: replaceWith[0].format,
                    }
                  : { id: replaceWith[0].id, name: replaceWith[0].name, type: replaceWith[0].type }
                : null,
            }
          : item
      ),
    });
  }

  function replaceAssetTypes(replaceWith: any, allSelected: boolean) {
    setReplacements({
      ...replacements,
      assetTypes: {
        ...replacements.assetTypes,
        replaceWith: allSelected
          ? 'all'
          : replaceWith.map((assetType) => omit(assetType, 'labels')),
      },
      tagData: allSelected
        ? []
        : !replaceWith?.length
        ? replacements.tagData?.map((tag) => {
            return { ...tag, replaceWith: null };
          })
        : replacements.tagData?.length
        ? replacements.tagData
        : getTagDataSourceTarget(widgetData),
    });
  }

  useEffect(() => {
    if (!isEqual(replacements.assetTypes.source, replacements.assetTypes.replaceWith)) {
      setReplacements({
        ...replacements,
        tagData: replacements.tagData.map((tag) => {
          return { ...tag, replaceWith: null };
        }),
      });
    } else {
      setReplacements({
        ...replacements,
        tagData: replacements.tagData.map((tag) => {
          return { ...tag, replaceWith: tag.source };
        }),
      });
    }
  }, [replacements.assetTypes.replaceWith]);

  useEffect(() => {
    if (widgetData.assetProperties?.length) {
      const request = httpService.api<AssetPropertiesResponse>({
        type: 'getAssetProperties',
      });
      request.then((res) => {
        let options = res.assetProperties?.filter(
          (prop) =>
            (widgetData.type == 'alarms' && prop.type != 'DATE') || widgetData.type !== 'alarms'
        );

        const indexOfPlcCatalogNumber = options.indexOf(
          options.find((option) => option.id === 13 || option.id === '13')
        );

        const plcSerialNumber = remove(
          options,
          options.find((option) => option.id === 19 || option.id === '19')
        );

        options.splice(indexOfPlcCatalogNumber + 1, 0, plcSerialNumber[0]);

        setAssetPropertiesOptions(prepareAssetOptions(options));
      });
      return () => {
        request.cancel();
      };
    }
  }, []);

  function close() {
    if (isFinishButtonEnabled) {
      modalService
        .openModal('confirm', {
          headerText:
            'create-widget-page.create-widget.step-one.replace-tags.cancel-confirmation-header',
          text: 'create-widget-page.create-widget.step-one.replace-tags.cancel-confirmation-message',
          iconType: 'attention_image',
          confirmText: 'general.confirm',
          cancelText: 'general.cancel',
          showCloseBtn: true,
        })
        .then((confirm) => {
          if (confirm) {
            dismiss(undefined);
          }
        });
      return;
    }
    dismiss(undefined);
  }

  async function finish() {
    const confirm = await modalService.openModal('confirm', {
      text: 'create-widget-page.create-widget.step-one.replace-tags.confirmation-message',
      iconType: 'attention_image',
      confirmText: 'general.confirm',
      cancelText: 'general.cancel',
      showCloseBtn: true,
      headerText: 'create-widget-page.create-widget.step-one.replace-tags.confirmation-header',
    });

    if (confirm) {
      let newWidgetData = {
        ...widgetData,
        assetTypes:
          replacements.assetTypes.replaceWith === 'all' ? [] : replacements.assetTypes.replaceWith,
        allAssetTypes: !!(replacements.assetTypes.replaceWith === 'all'),
        assetProperties: replacements.assetProperties.map(
          (assetProperty) => assetProperty.replaceWith
        ),
        tags:
          replacements.assetTypes.replaceWith === 'all'
            ? []
            : replacements.tagData.map((tag) => tag.replaceWith),
        tagTypes:
          replacements.assetTypes.replaceWith === 'all'
            ? []
            : replacements.tagData.map((tagType) => tagType.replaceWith),
      };

      newWidgetData =
        replacements.assetTypes?.replaceWith?.length === 1
          ? {
              ...newWidgetData,
              tags: replacements.tagData.map((tag) => tag.replaceWith),
              tagTypes: [],
            }
          : {
              ...newWidgetData,
              tags: [],
              variables: [],
              tagTypes: replacements.tagData.map((tagType) => tagType.replaceWith),
            };

      if (!isEqual(replacements.assetTypes.source, replacements.assetTypes.replaceWith)) {
        let navigationDashboard = newWidgetData.navigationDashboard.filter(
          (nav) =>
            nav.assetTypeId === null ||
            replacements.assetTypes.replaceWith.some(
              (assetType) => assetType.id === nav.assetTypeId
            )
        );
        if (navigationDashboard.length === 1) {
          navigationDashboard = [{ ...navigationDashboard[0], assetTypeId: null }];
        }

        newWidgetData = {
          ...newWidgetData,
          navigationDashboard: navigationDashboard,
          navigateFilterBy: navigationDashboard.length ? newWidgetData.navigateFilterBy : [],
        };
      }

      if (widgetData.metrics?.length) {
        newWidgetData = replaceTypeInMetrics(
          replaceValueType(replaceOrganizeFields(newWidgetData, 'metrics', replacements), 'metrics')
        );

        newWidgetData = {
          ...newWidgetData,
          metrics: updateMetricsPerWidgetType(
            widgetData.type,
            replacements,
            newWidgetData.metrics,
            widgetData
          ),
        };
      }

      if (widgetData.calculations?.length) {
        newWidgetData = removeNameFromCalculations(
          replaceInExpression(
            replaceOrganizeFields(newWidgetData, 'calculations', replacements),
            replacements
          )
        );
        if (
          replacements.assetTypes.replaceWith != 'all' &&
          replacements.assetTypes.source != 'all' &&
          !(
            replacements.assetTypes.replaceWith?.length === 1 &&
            replacements.assetTypes.source?.length === 1
          ) &&
          !(
            replacements.assetTypes.replaceWith?.length > 1 &&
            replacements.assetTypes.source?.length > 1
          )
        ) {
          newWidgetData = replaceValueType(newWidgetData, 'calculations');
        }
      }

      if (widgetData.filters?.length) {
        newWidgetData = replaceValueType(
          replaceOrganizeFields(newWidgetData, 'filters', replacements),
          'filters'
        );
        if (
          !isEqual(replacements.assetTypes.replaceWith, replacements.assetTypes.source) ||
          replacements.assetProperties.some(
            (assetProperty) => !isEqual(assetProperty.source, assetProperty.replaceWith)
          )
        ) {
          newWidgetData = {
            ...newWidgetData,
            filters: newWidgetData.filters.filter((f) => f.valueType != 'ASSET_PROPERTY'),
          };
        }
      }

      if (widgetData.sorts?.length) {
        newWidgetData = replaceValueType(
          setNamesAndLabels(replaceOrganizeFields(newWidgetData, 'sorts', replacements), 'sorts'),
          'sorts'
        );
      }

      if (widgetData.groupBy?.length) {
        newWidgetData = replaceValueType(
          setNamesAndLabels(
            replaceOrganizeFields(newWidgetData, 'groupBy', replacements),
            'groupBy'
          ),
          'groupBy'
        );
      }

      if (widgetData.customization?.columns) {
        const newColumns = widgetData.customization.columns.map((col) => {
          const relatedCalculation = widgetData.calculations?.find(
            (calc) => calc.valueId === col.id && calc.valueType === col.valueType
          );

          const newColumn = {
            ...col,
            ...getNewCustomizationColumnValues(col, replacements, relatedCalculation, widgetData),
          };

          return widgetData.type === 'alarms' || newColumn.tagType == null
            ? omit(newColumn, 'tagType')
            : newColumn;
        });
        newWidgetData = {
          ...newWidgetData,
          customization: { ...newWidgetData.customization, columns: newColumns },
        };
      }

      if (widgetData.customization?.yAxes) {
        const newYAxes = widgetData.customization.yAxes.map((yAxis) => {
          const relatedCalculation = widgetData.calculations?.find(
            (calc) => calc.valueId === yAxis.id && calc.valueType === yAxis.valueType
          );

          const newYAxis = {
            ...yAxis,
            ...getNewCustomizationColumnValues(yAxis, replacements, relatedCalculation, widgetData),
          };

          return widgetData.type === 'alarms' || newYAxis.tagType == null
            ? omit(newYAxis, 'tagType')
            : newYAxis;
        });
        newWidgetData = {
          ...newWidgetData,
          customization: { ...newWidgetData.customization, yAxes: newYAxes },
        };
      }

      if (widgetData.customization) {
        const newCustomization = updateCustomizationValuesPerWidgetType(
          widgetData.type,
          newWidgetData.customization,
          newWidgetData
        );
        newWidgetData = { ...newWidgetData, customization: newCustomization };
      }

      setWidgetData(
        widgetData.copyId
          ? { ...newWidgetData, isOpenReplaceTagsModalPrompted: true }
          : newWidgetData
      );
      dismiss(undefined);
      dispatch(decrementBusyCounter());
      openReplacementCompletedModal(replacements, widgetData);
    }
  }

  return (
    <div className={classnames(styles.wrapper)}>
      <div className={styles.modalHeader}>
        <I18n className={styles.modalHeader} element="div">
          create-widget-page.create-widget.step-one.replace-tags
        </I18n>
        <Icon type="close" onClick={close} className={'pointer'}></Icon>
      </div>
      <div className={styles.content}>
        <div className={styles.sectionHeader}>
          <I18n>create-widget-page.create-widget.step-one.asset-types-header</I18n>
        </div>
        <AssetTypeSection
          dashboardId={dashboardId}
          widgetData={widgetData}
          replacements={replacements}
          replaceAssetTypes={replaceAssetTypes}
        />
        <div className={styles.data}>
          {!!widgetData.assetProperties?.length && (
            <div id={'ba5f092b-6254-4f57-b5da-4791f7db5e0c'}>
              <DataSection
                dashboardId={dashboardId}
                widgetData={widgetData}
                type={'assetProperties'}
                replacements={replacements}
                replaceData={replaceData}
                assetPropertiesOptions={assetPropertiesOptions}
                dataTagOptions={dataTagOptions}
                setDataTagOptions={setDataTagOptions}
                defaultExpanded={!widgetData.tags?.length && !widgetData.tagTypes?.length}
              />
            </div>
          )}
          {!!(
            (widgetData.tags?.length || widgetData.tagTypes?.length) &&
            widgetData.type !== 'alarms' &&
            !widgetData.allAssetTypes &&
            replacements.assetTypes.replaceWith != 'all'
          ) && (
            <div id={'4833d652-db13-4deb-b92b-e11c4fa3505d'}>
              <DataSection
                dashboardId={dashboardId}
                widgetData={widgetData}
                type={'tagData'}
                replacements={replacements}
                replaceData={replaceData}
                assetPropertiesOptions={assetPropertiesOptions}
                dataTagOptions={dataTagOptions}
                setDataTagOptions={setDataTagOptions}
                defaultExpanded={!widgetData.assetProperties?.length}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.modalButtons}>
        <Button
          onClick={close}
          styles={{ marginLeft: 13 }}
          mode={['cancel', 'bigFont']}
          ref={confirmBtn}>
          <I18n>general.cancel</I18n>
        </Button>
        <Button
          onClick={finish}
          styles={{ marginLeft: 13 }}
          mode="bigFont"
          ref={confirmBtn}
          disabled={!isFinishButtonEnabled}>
          <I18n>general.finish</I18n>
        </Button>
      </div>
    </div>
  );
}

export default ReplaceTagsModal;
