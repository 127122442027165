import { httpService } from '@core/http/HttpService';
import { LoginRequestCredentials, LoginResponse, UserAccounts } from '@core/auth/auth.interface';
import { storageService } from '@core/StorageService';
import { set2FaStatus, setLoggedIn, setLoggedOut } from '@redux/login';
import { LoginCredentials } from '@redux/redux.interface.d';
import { dispatch, getState } from '@src/redux/store';
import history from '@core/history';
import { setConfig, setLanguage } from '@src/redux/config';
import { cssVarsService } from '@core/CssVarsService';
import { imageCacheService } from '@core/canvas/ImageCacheService';

class AuthService {
  /**
   * Initializes the login state from storage.
   */

  clientType: string;
  receivedCredentials: any;
  specificFunctionCalled = false;

  setCredentials(credentials) {
    // console.log('setCredentials - BEFORE');
    this.receivedCredentials = JSON.parse(credentials);
    this.specificFunctionCalled = true;
    // console.log('setCredentials - AFTER');
  }

  async waitForGetCredentials() {
    // console.log('waitForGetCredentials - BEFOR PROMISE');
    return await new Promise((resolve) => {
      const intervalId = setInterval(() => {
        // console.log('waitForGetCredentials - BEFORE IF');
        if (this.specificFunctionCalled) {
          // console.log('waitForGetCredentials - INSIDE IF');
          clearInterval(intervalId);
          resolve(this.receivedCredentials);
        }
      }, 100);
      // console.log('waitForGetCredentials - AFTER PROMISE');
    });
  }

  async init(clientType: string) {
    this.clientType = clientType;
    let credentials;

    if (this.clientType === 'uniCloudApp') {
      try {
        credentials = JSON.parse(uniCloudApp?.getCredentials());
      } catch (e) {
        console.log(`ERROR: ${e}`);
      }
    } else if (this.clientType === 'iOSUniCloudApp') {
      try {
        window['webkit']['messageHandlers']['getCredentials'].postMessage('');

        setTimeout(() => {
          this.specificFunctionCalled = true;
        }, 2000);

        // console.log('AFTER setTimeout - BEFORE await this.waitForGetCredentials');
        credentials = await this.waitForGetCredentials();
        // console.log('AFTER setTimeout - AFTER await this.waitForGetCredentials');
      } catch (e) {
        console.log('waitForGetCredentials', e);
      }
    } else {
      credentials = await storageService.get('AUTH_CREDENTIALS');
    }

    if (credentials) {
      dispatch(setLoggedIn(credentials));
    }
  }

  async login(credentials: LoginRequestCredentials) {
    try {
      const authCredentials = await httpService.api<LoginResponse>({
        type: 'login',
        data: credentials,
        disableErrorHandling: true,
      });
      let userAccounts;
      if (authCredentials.user_status === 'ACTIVE') {
        // When the user is not ACTIVE (didn't accepted the 'Terms and conditions'),
        // He should not call 'getAccounts' api, he is not permitted
        userAccounts = await httpService.api<UserAccounts>({
          type: 'getAccounts',
          headers: httpService.getAuthHeader(authCredentials.token),
        });
      }
      const loginCredentials = Object.assign({}, authCredentials, {
        userOrganizationId:
          userAccounts?.accounts?.length === 1 ? userAccounts.accounts[0].id : null,
      });

      this.setLoggedIn(loginCredentials);
      return userAccounts?.accounts || null;
    } catch (err) {
      if (err.status === 400) throw err;
    }
  }

  async logout() {
    dispatch(setLoggedOut());

    if (this.clientType === 'uniCloudApp') {
      try {
        uniCloudApp?.logout();
      } catch (e) {
        console.log(`ERROR in logout: ${e}`);
      }
      return;
    }

    if (this.clientType === 'iOSUniCloudApp') {
      try {
        window['webkit']['messageHandlers']['logout'].postMessage('');
      } catch (e) {
        console.log(`ERROR in logout: ${e}`);
      }
      return;
    }

    await storageService.remove('AUTH_CREDENTIALS');
    await storageService.remove('CLIPBOARD_WIDGET');

    imageCacheService.clearCache();

    const config: any = await httpService.api({
      type: 'getUnauthenticatedConfig',
    });
    config.whiteLabelDetails
      ? dispatch(setConfig({ whiteLabelDetails: config.whiteLabelDetails }))
      : cssVarsService.init();
    config.languageTerms && dispatch(setLanguage({ dictionary: config.languageTerms }));
    history.push('/login');
  }

  async setLoggedIn(credentials: LoginCredentials) {
    dispatch(setLoggedIn(credentials));

    await storageService.set('AUTH_CREDENTIALS', credentials);
  }

  async handle2FaStatus() {
    const mfaStatus: any = await httpService.api({
      type: 'get2FaStatus',
    });

    if (!mfaStatus['2faRequired']) {
      const token = mfaStatus.token;
      const refreshToken = mfaStatus.refreshToken;
      const credentials = getState().login;
      this.setLoggedIn({
        ...credentials,
        token,
        refreshToken,
        twoFaStatus: {
          isCompleted: true,
          mfaPreferredMethod: mfaStatus.mfaPreferredMethod,
        },
      });
      history.push('/main');
    } else {
      const mfaMethod = mfaStatus.mfaPreferredMethod || 'EMAIL';
      dispatch(set2FaStatus({ isCompleted: false, mfaPreferredMethod: mfaMethod }));
      // Remove the credentials from storage, so is browser is closed before 2FA was finished, user will need to enter credentials again.
      // The credntials in the redux store are preserved just for this session.
      await storageService.remove('AUTH_CREDENTIALS');

      history.push('/login/challenge');
    }
  }

  async getWhiteLabelAndLanguageFor2FaPage(userOrganizationId) {
    const config: any = await httpService.api({
      type: 'getLanguageAndWhiteLabel',
      urlParams: {
        tenantId: userOrganizationId,
      },
    });

    config.whiteLabelDetails
      ? dispatch(setConfig({ whiteLabelDetails: config.whiteLabelDetails }))
      : cssVarsService.init();

    const dictionary: any = await httpService.api({
      type: 'getLanguageById',
      urlParams: { languageId: config.languageId, tenantId: userOrganizationId },
    });

    dictionary &&
      dispatch(setLanguage({ dictionary, languageId: config.languageId, apexLangChange: false }));
  }

  // getReCaptchaAPIKey(isInvisible: boolean, baseURL: string) {
  //   switch (isInvisible) {
  //     case true:
  //       return '6LeSPn8pAAAAABqNJOCCkPz0Tv8DhynzS69bcsWI';
  //     case false:
  //       return '6LcA4ogoAAAAADXbiYT8bnbrDVg7qveZGOevnj0S';
  //     default:
  //       return '';
  //   }
  // }
  getReCaptchaAPIKey(isInvisible: boolean, baseURL: string) {
    switch (isInvisible) {
      case true:
        if (baseURL?.includes('portal-qa.unitronics.io'))
          return '6LfIbn8pAAAAAJOtAV-tNq3k97KWoTtm8yEkglcV';
        else if (baseURL?.includes('portal-stage.unitronics.io'))
          return '6LeSPn8pAAAAABqNJOCCkPz0Tv8DhynzS69bcsWI';
        return '6LcP138pAAAAABwMQicB_xmz_Mwk4UpZqz4ZpBMl';
      case false:
        if (baseURL?.includes('portal-qa.unitronics.io'))
          return '6Lcga40oAAAAAOhaPG4oj3yVR8mGbXf5Ry3BiZUJ';
        else if (baseURL?.includes('portal-stage.unitronics.io'))
          return '6LcA4ogoAAAAADXbiYT8bnbrDVg7qveZGOevnj0S';
        return '6LfDvY0oAAAAAJ80LsdwbI0CBrV6aG39Md40jfO4';
      default:
        return '';
    }
  }
  // getReCaptchaAPIKey(isInvisible: boolean, baseURL: string) {
  //   switch (isInvisible) {
  //     case true:
  //       if (baseURL?.includes('portal-qa.unitronics.io'))
  //         return '6LfIbn8pAAAAAJOtAV-tNq3k97KWoTtm8yEkglcV';
  //       else if (baseURL?.includes('portal-stage.unitronics.io'))
  //         return '6LeSPn8pAAAAABqNJOCCkPz0Tv8DhynzS69bcsWI';
  //       else if (
  //         baseURL?.includes('https://unitronics.io') ||
  //         baseURL?.includes('https://www.unitronics.io')
  //       )
  //         return '6LcP138pAAAAABwMQicB_xmz_Mwk4UpZqz4ZpBMl';
  //       else return '';
  //     case false:
  //       if (baseURL?.includes('portal-qa.unitronics.io'))
  //         return '6Lcga40oAAAAAOhaPG4oj3yVR8mGbXf5Ry3BiZUJ';
  //       else if (baseURL?.includes('portal-stage.unitronics.io'))
  //         return '6LcA4ogoAAAAADXbiYT8bnbrDVg7qveZGOevnj0S';
  //       else if (
  //         baseURL?.includes('https://unitronics.io') ||
  //         baseURL?.includes('https://www.unitronics.io')
  //       )
  //         return '6LfDvY0oAAAAAJ80LsdwbI0CBrV6aG39Md40jfO4';
  //       else return '';
  //     default:
  //       return '';
  //   }
  // }
}

export const authService = new AuthService();

window['myAuthService'] = authService;
