import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import { HeatmapCustomization } from '@pages/CreateWidgetPage/CreateWidgetPage.interface';
import { HeatmapProps } from './Heatmap.interface';
import { heatmapStyles, setColumnsDefaultData } from './Heatmap.utils';

import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import { getDateTimeOptions } from '@pages/CreateWidgetPage/widgets.utils';
import { updateWidgetCustomization } from '@core/canvas/widget.utils';
import RangesCustomization from './RangesCustomization';
import { cssVarsService } from '@core/CssVarsService';
import DataLabels from './DataLabels';
import Subtitle from './Subtitle';
import Tooltip from './Tooltip';
import DateFormat from './DateFormat';
import ChartType from './ChartType';
import ReverseNegativeShade from './ReverseNegativeShade';
import TagSettings from './TagSettings';

const useStyles = makeStyles(
  () =>
    heatmapStyles as {
      wrapper;
      titleWrapper;
      columnTitle;
      columnWidthTitle;
      columnsWrapper;
      section;
      chartType;
      rowSection;
      item;
      columnWidthItem;
      textField;
      numberField;
      cbLabel;
      checkboxText;
      radioLabel;
      colorItem;
      text;
      tableContainer;
      tableHeader;
      header;
      selectFullWidthCustomStyle;
      container;
    }
);

function Heatmap(props: HeatmapProps) {
  const classes = useStyles(props);
  const { widgetData, setWidgetData, previewData, defaultDecimalDigits } = props;
  const customization = cloneDeep(widgetData.customization);

  const dateOptions = getDateTimeOptions('DATE');

  const {
    chartType = 'REGULAR',
    columns = previewData?.columns,
    subtitle = {
      alignment: 'CENTER',
      text: '',
      textColor: cssVarsService.vars.widgetsFont,
    },
    tooltip = {
      visible: true,
      showAxisLegend: true,
      decimalDigits: defaultDecimalDigits,
    },
    dateFormat = 'DATE',
    dataLabels = {
      visible: true,
      textColor: cssVarsService.vars.widgetsFont,
      decimalDigits: defaultDecimalDigits,
    },
    reverseNegativeShade = false,
    rangesChart = {
      ranges: [
        {
          id: 1,
          fromValue: null,
          toValue: null,
          backgroundColor: '#008FFB',
          textColor: cssVarsService.vars.widgetsFont,
          remark: '',
        },
      ],
      legend: {
        visible: true,
        position: 'TOP',
        alignment: 'CENTER',
      },
      shaded: false,
    },
  } = (customization as HeatmapCustomization) || {};

  useEffect(() => {
    if (!customization) {
      updateWidgetCustomization(
        {
          chartType: 'REGULAR',
          dataLabels: dataLabels,
          subtitle: subtitle,
          tooltip: tooltip,
          rangesChart: rangesChart,
          dateFormat: 'DATE',
          reverseNegativeShade: false,
        },
        setWidgetData
      );
    }
  }, []);

  useEffect(() => {
    setColumnsDefaultData(customization, widgetData, setWidgetData, defaultDecimalDigits);
  }, [previewData]);

  return (
    <div className={classes.wrapper}>
      <ChartType classes={classes} chartType={chartType} setWidgetData={setWidgetData} />
      <TagSettings
        classes={classes}
        widgetData={widgetData}
        columns={columns}
        setWidgetData={setWidgetData}
      />

      {chartType === 'REGULAR' && (
        <ReverseNegativeShade
          classes={classes}
          chartType={chartType}
          reverseNegativeShade={reverseNegativeShade}
          shaded={rangesChart.shaded}
          setWidgetData={setWidgetData}
        />
      )}
      {chartType === 'RANGES' && (
        <div className={classes.section}>
          <div
            className={classNames(classes.titleWrapper, classes.columnTitle, 'ellipsis-overflow')}>
            <I18n>create-widget-page.create-widget.step-four.heatmap.ranges</I18n>
          </div>
          <RangesCustomization
            chartType={chartType}
            widgetData={widgetData}
            setWidgetData={setWidgetData}
            rangesChart={rangesChart}
            reverseNegativeShade={reverseNegativeShade}
            classes={classes}
          />
        </div>
      )}
      <Subtitle classes={classes} subtitle={subtitle} setWidgetData={setWidgetData} />
      <Tooltip classes={classes} tooltip={tooltip} setWidgetData={setWidgetData} />
      {dateOptions && widgetData.groupBy[0].operation === 'DAILY' && (
        <DateFormat
          classes={classes}
          dateFormat={dateFormat}
          dateOptions={dateOptions}
          setWidgetData={setWidgetData}
        />
      )}
      <DataLabels
        chartType={chartType}
        classes={classes}
        dataLabels={dataLabels}
        setWidgetData={setWidgetData}
      />
    </div>
  );
}

export default Heatmap;
