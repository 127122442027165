import { setisMobileView } from '@redux/viewport';
import store, { dispatch } from '@src/redux/store';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import * as momentTZ from 'moment-timezone';
import { httpService } from './http/HttpService';
import { setDontShowAgain } from '@src/redux/config';
import generalRegex from './regex';
import { i18nService } from './i18n/I18nService';
import { omit } from 'lodash';

export const ADMIN_GROUP = 'Admins';
export const CAPTCHA_SCRIPT_URL = 'https://www.google.com/recaptcha/enterprise.js';
export const phoneRegex = /^[+]*[0-9]+([-][0-9]{1,3})*([0-9])*$/;
export const onlyPositiveDigitsRegex = /^\d+([.]\d+)?$/;
export const emailRegex =
  /^(?:[a-z0-9-_]+(?:\.[a-z0-9-_]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i;
export const dateTimeRegex =
  /([0-9]){4}-([0-9]){2}-([0-9]){2}(T)? ?([0-9]){2}:([0-9]){2}:([0-9]){2}/;
export const dateRegex = /([0-9]){4}-([0-9]){2}-([0-9]){2}$/;

export function capitalizeFirstLetterOfEveryWord(input: string) {
  return (
    input?.replace(generalRegex.capitalizeFirstLettersOfEveryWordInSentenceRegex, (letter) =>
      letter.toUpperCase()
    ) || ''
  );
}

export const buildErrorObj = (keys, conversionFunction?) =>
  keys.reduce(
    (result, { name, value }) => ({
      ...result,
      [name]: conversionFunction ? conversionFunction(value) : value,
    }),
    {}
  );

export const convertToDefaultDateFormat = (date) =>
  date && moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');

export const buildImageUrl = (url) => (url.includes('http') ? url : `https://${url}`);

export const removeWindowSelection = () => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document['selection']) {
    // IE?
    document['selection'].empty();
  }
};

export const checkIsMobileView = (currentTarget) => {
  if ((currentTarget['innerWidth'] <= 740 || currentTarget['innerHeight'] <= 550) && isMobile) {
    // if (currentTarget['innerWidth'] <= 740 || currentTarget['innerHeight'] <= 600) {
    //  TODO: change after VPN via laptop finish, causing unexpected behevior on Shlomit's laptop, the innerHeight: 597
    dispatch(setisMobileView({ isMobileView: true }));
  } else {
    dispatch(setisMobileView({ isMobileView: false }));
  }
};

export const isSubDomain = () =>
  window.location.hostname !== 'www.unitronics.io' &&
  window.location.hostname !== 'unitronics.io' &&
  !window.location.host.includes('localhost') &&
  !window.location.hostname.includes('portal-qa') &&
  !window.location.hostname.includes('portal-stage');

export const convertImageToBase64 = (imageURL, saveImage) => {
  let downloadedImg = new Image();
  downloadedImg.crossOrigin = 'Anonymous';
  downloadedImg.addEventListener(
    'load',
    () => {
      let canvas = document.createElement('canvas');
      let context = canvas.getContext('2d');

      canvas.width = downloadedImg.width;
      canvas.height = downloadedImg.height;

      context.drawImage(downloadedImg, 0, 0);
      // imageBox.appendChild(canvas);

      try {
        saveImage(canvas.toDataURL());
      } catch (err) {
        console.log('Error: ' + err);
      }
    },
    false
  );
  downloadedImg.src = imageURL;
};

export const numberFormatter = (number, decimalDigits = 0) => {
  const numberFormatting = store.getState().config?.whiteLabelDetails.numberFormatting.content;
  const thousandsSeparator =
    numberFormatting.thousandsSeparator || numberFormatting.thousandsSeparator === ''
      ? numberFormatting.thousandsSeparator
      : ',';
  const decimalSeparator = numberFormatting.decimalSeparator || '.';

  let res = parseFloat(Number(number).toFixed(decimalDigits))
    .toLocaleString('en', {
      maximumFractionDigits: decimalDigits,
    })
    .split(',')
    .join('th')
    .replace('.', 'dec')
    .split('th')
    .join(thousandsSeparator)
    .replace('dec', decimalSeparator);
  if (res.toString() === '-0') {
    return 0;
  }
  return res;
};

export const updateOrAddToArray = (data: Array<any>, item: any, idKey: string) => {
  let tempData = [...data];
  const itemIdx = tempData?.findIndex((lang) => lang[idKey].toString() === item[idKey].toString());
  if (itemIdx > -1) {
    tempData[itemIdx] = { ...tempData[itemIdx], ...item };
  } else {
    tempData = [...tempData, item];
  }

  return tempData;
};

const compare = (a, b): number => {
  const valueOfA = typeof a === 'string' ? a.toUpperCase() : a;

  const valueOfB = typeof b === 'string' ? b.toUpperCase() : b;

  if (valueOfA > valueOfB) return 1;
  if (valueOfB > valueOfA) return -1;

  return 0;
};

export const dynamicSort = (property, sign, converter: (any) => any = null) => {
  let sortOrder = 1;

  if (sign === '-') sortOrder = -1;

  return (a, b) => {
    let result = 0;

    if (!a.hasOwnProperty(property) || !b.hasOwnProperty(property)) return 0;

    if (a[property] == null || b[property] == null) {
      if (a[property] == null) result--;
      if (b[property] == null) result++;
    } else if (converter != null) result = compare(converter(a[property]), converter(b[property]));
    else result = compare(a[property], b[property]);

    return result * sortOrder;
  };
};

export const dateTimezoneFormat = (time, selectOrgTimezone, orgTimezone) =>
  time
    ? momentTZ(time)
        .tz(orgTimezone || selectOrgTimezone)
        .format('DD/MM/YYYY')
    : '';

export const getDateTimeWLFormat = (time, timezone, whiteLabelDateFormat) => {
  return time
    ? momentTZ(time)
        .tz(timezone)
        .format(whiteLabelDateFormat || 'DD/MM/YYYY')
    : '';
};

export const onDontShowAgainChanged = async (
  apiType: string,
  value: boolean,
  updateGlobalState?
) => {
  await httpService.api({
    type: apiType,
    data: { show: !value },
  });
  if (updateGlobalState) updateGlobalState();
};

export const updateDontShowAgain = async (scope: string, type: string) => {
  await httpService.api({
    type: 'setDownShowAgain',
    data: { scope, type },
  });
  dispatch(setDontShowAgain(type));
};

export function getNextNumberInArray(data: any[], field: string) {
  return data.length
    ? Math.max(
        ...data.map((item) => {
          if (isFinite(item[field])) {
            return parseInt(item[field]);
          }
          return 0;
        })
      ) + 1
    : 1;
}

export function getUniqueNameWithIncrement(
  data: Array<any>,
  fieldName: string,
  prefix: string,
  inc: number
) {
  const tempName = `${prefix} ${inc}`;

  const isDuplication = data.some((item) => item[fieldName] === tempName);

  if (isDuplication) {
    return getUniqueNameWithIncrement(data, fieldName, prefix, inc + 1);
  }

  return tempName;
}

export function getArrayFromObjectWithOrder(obj: any, fieldName: string) {
  const keys = Object.keys(obj);
  const values: Array<{ order: number }> = Object.values(obj);

  return keys
    .map((key, idx) => {
      return { ...values[idx], [fieldName]: key };
    })
    .sort((item1, item2) => item1.order - item2.order);
}

export function getIsMachineBuilder(roles: Array<string>) {
  return !!roles.some((role) => role.includes('Machine Builder') && !role.includes('Channel'));
}

export function convertBooleanToTranslatedString(value: boolean) {
  return i18nService.translate(`general.${value.toString()}`);
}

export function omitProperties(obj, properties: Array<string>) {
  const keys = Object.keys(obj);
  let result = omit(obj, properties);
  keys.map((key) => {
    result = { ...result, [key]: omit(obj[key], properties) };
  });
  return result;
}

export const fixHyperlinksAndMentions = (html) => {
  const mentionsRegex =
    /<a href=\"datasource:\/\/(\d+)\" class=\"wysiwyg-mention\" data-mention data-value=\"(.*?)\">.*?<\/a>/g;
  const hyperLinkRegex = /<a href=\"(.*?)\" target=\"_self\">(.*?)<\/a>/g;

  let res = html.replace(
    mentionsRegex,
    '<a href="datasource://$1" class="wysiwyg-mention" data-mention data-value="$2" style="pointer-events: none;">#$2</a>'
  );

  res = res.replace(hyperLinkRegex, '<a href="$1" target="_blank">$2</a>');

  return res;
};

export const sharedStyles = { placeholder: { color: 'var(--systemFont)', opacity: 0.8 } };

export function downloadFile(url, fileName) {
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.append(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
}
