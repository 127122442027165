import React, { useMemo, useEffect, useState } from 'react';
import classNames from 'classnames';
import CanvasWidget from '@components/canvas/CanvasWidget';
import { isWidgetGroup, getConfigValueFromWidgetSettings } from '@core/canvas/widget.utils';
import LiveWidgetGroupItem from './LiveWidgetGroupItem';
import { LiveWidgetWrapperProps } from './LiveWidgetWrapper.interface';
import styles from './LiveWidgetWrapper.scss';
import LiveWidgetFrame from '../LiveWidgetFrame';
import {
  setLiveWidgetData,
  getMoreWidgetData,
  exportData,
  getPositionStyle,
} from './LiveWidgetWrapper.utils';
import { useSelector } from '@src/redux/useSelector';

function LiveWidgetWrapper(props: LiveWidgetWrapperProps) {
  const {
    widget,
    navigateDashboard,
    getWidgetData,
    userFilters,
    widgetFilters,
    widgetsDownloadStatus,
    setWidgetsDownloadStatus,
    dashboardFilters,
    applyButtonClicked,
    setApplyButtonClicked,
    timezone,
  } = props;
  const { position, type, hideWidgetName } = widget;
  const [widgetData, setWidgetData] = useState(null);
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const enableExportCsv = getConfigValueFromWidgetSettings(widget.type, 'enableExportCsv');
  const paging = useMemo(() => ({ p: 1, ps: type == 'columns' ? 2000 : 50 }), []);
  const isGroup = isWidgetGroup(widget);

  useEffect(() => {
    setLiveWidgetData(
      getWidgetData,
      isUnauthorized,
      userFilters,
      widget,
      paging,
      setWidgetData,
      setIsUnauthorized
    );
  }, [userFilters, widgetFilters]);

  const wrapperS = useMemo(() => {
    return getPositionStyle(position, hideWidgetName);
  }, [position, hideWidgetName]);

  if (!position) {
    return null;
  }

  return (
    <div className={classNames(styles.wrapper, isGroup && styles.group)} style={wrapperS}>
      {isGroup ? (
        widget.children.map((child) => (
          <LiveWidgetGroupItem parent={widget} widget={child} key={child.id} />
        ))
      ) : (
        <div className={styles.inner}>
          {(!hideWidgetName ||
            (widget.exportFormats && widget.exportFormats.length !== 0 && enableExportCsv)) && (
            <LiveWidgetFrame
              exportData={() =>
                exportData(widget, dashboardFilters, widgetFilters, setWidgetsDownloadStatus)
              }
              downloadStatus={widgetsDownloadStatus[widget.id]}
              widget={widget}
              widgetData={widgetData}
            />
          )}
          <div className={styles.canvasWidgetWrapper}>
            <CanvasWidget
              isLive
              userFilters={userFilters}
              getMoreWidgetData={() =>
                getMoreWidgetData(getWidgetData, widget, paging, setWidgetData)
              }
              dashboardFilters={dashboardFilters}
              widgetFilters={widgetFilters}
              navigateDashboard={navigateDashboard}
              widgetType={type}
              widget={widget}
              data={widgetData}
              setData={setWidgetData}
              applyButtonClicked={applyButtonClicked}
              setApplyButtonClicked={setApplyButtonClicked}
              timezone={timezone}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(LiveWidgetWrapper);
