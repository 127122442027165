import React, { useState, useEffect, useMemo, useRef } from 'react';
import { withRouter, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from '@redux/useSelector';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar, IconButton, CardMedia } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

// Components
import LogoIcon from '@components/LogoIcon';
import SideMenu from '@components/SideMenu';
import SelectLanguage from './SelectLanguage';
import UserMenuSelect from './UserMenuSelect';

// Services | Interfaces
import { buildImageUrl } from '@core/utils';
import { checkIsMobileView } from '@core/utils';
import { getFlagStatus } from '@core/ffAndPermissions';
import organizationTypeMap from '@pages/OrganizationsPage/OrganizationTypeMap';
import {
  routeMap,
  getRedirectToMap,
  getUserConfig,
  isAllow,
  getNoCacheEmptyImageUrl,
  openAppIsAvailableModal,
  downloadFileFromLink,
} from './MainPage.utils';

import { sessionService } from '@core/session/SessionService';
import styles from './MainPage.scss';

// 3rd party
import classNames from 'classnames';
import { httpService } from '@core/http/HttpService';
import { setEmptyStateImageUrls } from '@src/redux/config';
import DashboardReportHeader from '@pages/MainPage/DashboardReport/DashboardReportHeader/DashboardReportHeader';
import { MyDetailsData } from '@pages/MyAccountPage/MyDetails/MyDetails.interface';
import { setSelectionBox } from '@src/redux/dashboardEditor';
import { isMobile, isTablet } from 'react-device-detect';
import Icon from '@components/Icon';
import NotificationCenterIcon from './NotificationCenterIcon';
import NotificationsCenter from './NotificationCenter';

function MainPage() {
  const selectionBox = useSelector((state) => state.dashboardEditor.selectionBox);
  const { isLoggedIn, userOrganizationId } = useSelector((state) => state.login);
  const organizationDetails = useSelector((state) => state.config.organizationDetails);
  const unreadNotifications = useSelector((state) => state.config.unreadNotifications);
  const notificationsExist = useSelector((state) => state.config.notificationsExist);
  const dontShowAgain = useSelector((state) => state.config.dontShowAgain);
  const userDashboardId = useSelector((state) => state.config.dashboardRefId);
  const licensingType = useSelector((state) => state.config.licensingType);
  const roles = useSelector((state) => state.config.roles);
  const fileForDownload = useSelector((state) => state.config.fileForDownload);
  const whiteLabelDetails = useSelector((state) => state.config.whiteLabelDetails);
  const whatFix = useSelector((state) => state.config.whatFix);
  const featureFlags = useSelector((state) => state.config.featureFlags);
  const [openMenu, setOpenMenu] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [enableAnimation, setEnableAnimation] = useState(false);
  const [dashboardId, setDashboardId] = useState(null);
  const dispatch = useDispatch();

  const redirectToMap = getRedirectToMap(dashboardId);
  const isLiveDashboard = location.pathname.includes('/dashboard');
  const logoUrl = whiteLabelDetails[isLiveDashboard ? 'freeLogo' : 'premiumLogo']?.content?.logoUrl;
  const history = useHistory();
  const isPdfRender = useSelector((state) => state.viewport.isPdfRender);
  const urlSearchParams = new URLSearchParams(history.location.search);
  let otherOrganizationId = urlSearchParams.get('otherOgranizationId');
  const isUnitronicsAdminLiveDashboardSession = sessionService.getValue('otherOrganizationID', null)
    ? true
    : false;
  const isUnitronicsAdminUser = organizationTypeMap[organizationDetails?.type] === 1;
  const [myEmail, setMyEmail] = useState(null);

  const clientType = useSelector((state) => state.clientState.clientType);
  const isMobileView = useSelector((state) => state.viewport.isMobileView);
  const isMobileApp = ['uniCloudApp', 'iOSUniCloudApp'].includes(clientType);
  const isMobileDevice = isMobile || isTablet || isMobileView || isMobileApp;
  const isAndroid = !!navigator.userAgent?.match(/android/gi);

  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const isNotificationCenterOpen = Boolean(anchorElNotifications);
  const [notifications, setNotifications] = useState({
    allCount: 0,
    count: 0,
    next: null,
    previous: '',
    results: [],
  });
  const notificationDetailsRef = useRef(null);

  useEffect(() => {
    const dontSuggestMobileApp = dontShowAgain.includes('SUGGEST_MOBILE_APP');
    if (
      !isLoading &&
      isAndroid &&
      !dontSuggestMobileApp &&
      (isTablet || isMobile) &&
      !isMobileApp &&
      isLoggedIn &&
      userOrganizationId
    ) {
      if (isAndroid) {
        let isMobileAppInstalled = false;
        if (window.navigator['getInstalledRelatedApps']) {
          window.navigator['getInstalledRelatedApps']()
            .then((res) => {
              isMobileAppInstalled = res.some((app) => app.id === 'com.unitronics.unicloud');
              openAppIsAvailableModal(isMobileAppInstalled);
            })
            .catch((error) => {
              console.log(error);
              openAppIsAvailableModal(isMobileAppInstalled);
            });
        } else {
          openAppIsAvailableModal(isMobileAppInstalled);
        }
      }
    }
  }, [isLoading]);

  useEffect(() => {
    dispatch(
      setEmptyStateImageUrls({
        vnc: getNoCacheEmptyImageUrl('vnc'),
        web: getNoCacheEmptyImageUrl('web'),
        vpn: getNoCacheEmptyImageUrl('vpn'),
      })
    );
  }, []);

  useEffect(() => {
    whatFix?.whatFixEnabled &&
      roles?.some(
        (role) => role === 'Machine Builder Admin' || role === 'Machine Builder Dashboard Editor'
      ) &&
      httpService
        .api({
          type: 'getMyProfile',
        })
        .then((res: MyDetailsData) => {
          setMyEmail(res.email);
        });
  }, [whatFix]);

  useEffect(() => {
    const isIframe = inIframe();
    fileForDownload && downloadFileFromLink(fileForDownload);

    if (otherOrganizationId) {
      sessionService.setValue('otherOrganizationID', otherOrganizationId);
    }

    otherOrganizationId = sessionService.getValue('otherOrganizationID', null);

    getUserConfig(isLoggedIn, setIsLoading, otherOrganizationId, isIframe);
    checkIsMobileView(window);
  }, []);

  useEffect(() => {
    if (
      whatFix?.whatFixEnabled &&
      (roles?.includes('Machine Builder Admin') ||
        roles?.includes('Machine Builder Dashboard Editor')) &&
      !isPdfRender &&
      !urlSearchParams.get('dashboardHeight') &&
      !urlSearchParams.get('dashboardWidth') &&
      !isMobileDevice
    ) {
      const script = document.createElement('script');
      script.src = whatFix?.whatFixScript;
      script.async = true;
      script.id = 'whatfixId';

      document.body.appendChild(script);
    }
  }, [whatFix]);

  useEffect(() => {
    organizationDetails &&
      setDashboardId(userDashboardId || organizationDetails.dashboardRefId || 0);
    let _favicon = '/assets/favicon/unicloud_icon_48x48.ico';
    document.getElementById('mainFavicon')['href'] = _favicon;
  }, [organizationDetails, licensingType, userDashboardId]);

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  const toggleHamburger = () => {
    setEnableAnimation(true);
    setOpenMenu(!openMenu);
  };

  const isSideMenuVisible = () => {
    return !!(
      !isMobileApp &&
      !isMobile &&
      !isTablet &&
      (getFlagStatus('side-menu') ||
        Object.keys(featureFlags).filter((i) => {
          return i.startsWith('side-menu.') && i.valueOf;
        })?.length > 1)
    );
  };

  /**
    The function decide should the route need's to be render or not.
    * @param {AppRoute} route.
    * @returns return `boolean` is the route should be rendered.
  */
  const redirectTo = useMemo(
    () =>
      redirectToMap.find((route) =>
        isAllow(
          route,
          isMobile,
          isTablet,
          isUnitronicsAdminLiveDashboardSession,
          isUnitronicsAdminUser
        )
      ),
    [organizationDetails, dashboardId, isUnitronicsAdminLiveDashboardSession, isUnitronicsAdminUser]
  );

  if (!isLoggedIn) {
    return <Redirect to="/login" />;
  }

  if (!userOrganizationId) {
    return <Redirect to="/select-organization" />;
  }

  function onMouseUp() {
    dispatch(setSelectionBox(false));
  }

  async function openNotificationCenter(event) {
    setAnchorElNotifications(event.currentTarget);
  }

  function closeNotificationCenter() {
    setAnchorElNotifications(null);
    setNotifications({ allCount: 0, count: 0, next: null, previous: '', results: [] });
  }

  return (
    <div className={styles.wrapper} style={selectionBox ? { userSelect: 'none' } : undefined}>
      {organizationDetails && !isLoading ? (
        <>
          {myEmail && (
            <div style={{ display: 'none' }}>
              <div id="user_email" style={{ display: 'none' }}>
                {myEmail}
              </div>
              <div id="mb_id" style={{ display: 'none' }}>
                {organizationDetails.id}
              </div>
            </div>
          )}
          <AppBar position="static" id={styles.appBar} onMouseUp={onMouseUp}>
            <Toolbar
              className={classNames(
                isLiveDashboard && styles.liveDashboardColors,
                isMobileDevice && styles.mobileView
              )}
              id={styles.toolbar}
              style={selectionBox ? { userSelect: 'none' } : undefined}>
              {isPdfRender ? (
                <>
                  <DashboardReportHeader
                    logoUrl={logoUrl}
                    buildImageUrl={buildImageUrl}
                    orgName={organizationDetails.name}
                  />
                </>
              ) : isMobileDevice ? (
                <>
                  <div style={{ height: '40px', width: '40px', padding: '8px 5px 2px 5px' }}>
                    <Icon
                      type={'restart'}
                      color={'var(--systemFont)'}
                      width={'30px'}
                      height={'30px'}
                      onClick={() => window.location.reload()}
                    />
                  </div>
                  <CardMedia className={styles.logoMobile}>
                    <LogoIcon
                      logo={logoUrl ? buildImageUrl(logoUrl) : '/assets/images/uni_logo.jpg'}
                      maxWidth={100}
                      height={25}
                    />
                  </CardMedia>
                  <div className={styles.separateMobile} />
                  <div className={classNames(styles.orgNameMobile, 'ellipsis-overflow')}>
                    {organizationDetails.name}
                  </div>
                  <div className={styles.topIcons}>
                    {/* <NotificationCenterIcon
                      unreadNotifications={unreadNotifications}
                      openNotificationCenter={openNotificationCenter}
                      setAnchorElNotifications={setAnchorElNotifications}
                      notificationList={notifications.results}
                      setNotifications={setNotifications}
                      notificationsExist={notificationsExist}
                    />
                    {anchorElNotifications && (
                      <NotificationsCenter
                        notifications={notifications}
                        close={closeNotificationCenter}
                        anchorEl={anchorElNotifications}
                        whiteLabelDetails={whiteLabelDetails}
                        isNotificationCenterOpen={isNotificationCenterOpen}
                        setNotifications={setNotifications}
                        NotificationsCenter={NotificationsCenter}
                      />
                    )} */}
                    {/* <div className={styles.dividerMobile}></div> */}
                    <SelectLanguage
                      className={isUnitronicsAdminLiveDashboardSession ? styles.disabledIcon : ''}
                    />
                    <div className={styles.dividerMobile}></div>
                    <UserMenuSelect />
                  </div>
                </>
              ) : (
                <>
                  {isSideMenuVisible() && (
                    <IconButton onClick={toggleHamburger}>
                      <MenuIcon />
                    </IconButton>
                  )}
                  <CardMedia className={classNames(styles.logo)}>
                    <LogoIcon
                      logo={logoUrl ? buildImageUrl(logoUrl) : '/assets/images/uni_logo.jpg'}
                      maxWidth={150}
                      height={25}
                    />
                  </CardMedia>
                  <div className={styles.separate} />
                  <div className={classNames(styles.orgName, 'ellipsis-overflow')}>
                    {organizationDetails.name}
                  </div>
                  <div className={styles.topIcons}>
                    <NotificationCenterIcon
                      unreadNotifications={unreadNotifications}
                      openNotificationCenter={openNotificationCenter}
                      notificationsExist={notificationsExist}
                    />
                    {anchorElNotifications && (
                      <NotificationsCenter
                        notifications={notifications}
                        setNotifications={setNotifications}
                        close={closeNotificationCenter}
                        anchorEl={anchorElNotifications}
                        whiteLabelDetails={whiteLabelDetails}
                        isNotificationCenterOpen={isNotificationCenterOpen}
                        unreadNotifications={unreadNotifications}
                        notificationDetailsRef={notificationDetailsRef}
                      />
                    )}
                    <div className={styles.vDivider}></div>
                    <SelectLanguage
                      className={isUnitronicsAdminLiveDashboardSession ? styles.disabledIcon : ''}
                    />
                    <div className={styles.vDivider}></div>
                    <UserMenuSelect />
                  </div>
                </>
              )}
            </Toolbar>
          </AppBar>
          <div className={classNames(styles.content, isMobileDevice && styles.mobileView)}>
            {!isPdfRender && isSideMenuVisible() && (
              <SideMenu openMenu={openMenu} enableAnimation={enableAnimation}></SideMenu>
            )}
            <div className={styles.mainContent} id="mainContent">
              <Switch>
                {routeMap
                  .filter((item) =>
                    isAllow(
                      item,
                      isMobile,
                      isTablet,
                      isUnitronicsAdminLiveDashboardSession,
                      isUnitronicsAdminUser
                    )
                  )
                  .map((route) => {
                    return (
                      <Route
                        key={route.path}
                        exact={route.isExact}
                        path={route.path}
                        component={route.component}
                      />
                    );
                  })}
                {redirectTo && redirectTo.condition() && (
                  <Redirect to={redirectTo && redirectTo.path} />
                )}
              </Switch>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default withRouter(MainPage);
