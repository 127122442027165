import React, { useCallback } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { InputBase } from '@material-ui/core';
import classNames from 'classnames';
import { dashboardService } from '@core/canvas/DashboardService';
import { localToServer } from '@core/canvas/dashboard.utils';
import { httpService } from '@core/http/HttpService';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import { useSelector } from '@redux/useSelector';
import { getState } from '@redux/store';
import styles from './DeTopSection.scss';
import { cssVarsService } from '@core/CssVarsService';
import FiltersPopover from '../FiltersPopover';
import MultiLayoutButtons from '../MultiLayoutButtons';
import SettingsPopover from '../SettingsPopover';
import ArrangementPopover from '../ArrangementPopover';

/**
 * The top section of the Dashboard Editor page.
 */

const dashboardTitleMaxLength = 100;

function DeTopSection({
  isRefreshRateVisible,
  selectedWidgets,
  setSelectedWidgets,
  setSelectedWidgetIdsWithError,
  anchorElArrangement,
  setAnchorElArrangement,
  anchorElFilters,
  setAnchorElFilters,
  anchorElSettings,
  setAnchorElSettings,
  setApplyButtonClicked,
}) {
  const dashboardEditor = useSelector((state) => state.dashboardEditor);
  const selectionBox = dashboardEditor.selectionBox;
  const openFiltersPopover = Boolean(anchorElFilters);
  const openSettingsPopover = Boolean(anchorElSettings);
  const openArrangementsPopover = Boolean(anchorElArrangement);
  const params = useParams<{ id: string }>();

  const isArrangementEnabled = selectedWidgets?.length > 1;

  const openFilters = useCallback(
    (event) => setAnchorElFilters(event.currentTarget),
    [anchorElFilters]
  );
  const closeFilters = useCallback(
    (type) => {
      setAnchorElFilters(null);
      type === 'apply' && setDashboardFilters();
    },
    [anchorElFilters]
  );

  const openSettings = useCallback(
    (event) => setAnchorElSettings(event.currentTarget),
    [anchorElSettings]
  );

  const closeSettings = useCallback(() => {
    setAnchorElSettings(null);
  }, [anchorElSettings]);

  const openArrangements = useCallback(
    (event) => setAnchorElArrangement(event.currentTarget),
    [anchorElArrangement]
  );

  const closeArrangements = useCallback(() => {
    setAnchorElArrangement(null);
  }, [anchorElArrangement]);

  const changeName = useCallback((event) => {
    const dashboardName = event.target.value;
    if (dashboardName.length > dashboardTitleMaxLength) return;
    dashboardService.changeDashboardName(dashboardName);
  }, []);

  const openPreview = useCallback(() => {
    dashboardService.openDashboardPreview(params.id, 'editor');
  }, [params]);

  const publishDashboard = useCallback(() => {
    dashboardService.publishDashboard();
  }, []);

  const removeDashboard = useCallback(() => {
    dashboardEditor.status === 'DRAFT'
      ? dashboardService.removeDraftDashboard(dashboardEditor)
      : dashboardService.removePublishedDashboard(dashboardEditor);
  }, []);

  const setDashboardFilters = () => {
    const currentDashboardState = getState().dashboardEditor;
    httpService.api({
      type: 'setDashboard',
      urlParams: {
        id: currentDashboardState.id,
      },
      data: localToServer(currentDashboardState),
    });
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.midLevel}
        style={selectionBox ? { userSelect: 'none', pointerEvents: 'none' } : undefined}>
        <InputBase
          id={styles.title}
          placeholder="Dashboard Title"
          value={dashboardEditor.name}
          onChange={changeName}
        />
        <div className={styles.rightSide}>
          <div
            id={'a0790181-f4c8-41fa-8408-80441bf0a7ca'}
            className={
              isArrangementEnabled
                ? classNames(styles.actionBtn, 'ellipsis-overflow')
                : classNames(styles.disabledbutton, 'ellipsis-overflow')
            }
            onClick={isArrangementEnabled ? openArrangements : undefined}>
            <Icon
              type="arrange_widgets"
              width="17px"
              height="17px"
              color={cssVarsService.vars.systemEnableEditorIcons}
              style={{ cursor: isArrangementEnabled ? 'pointer' : 'default' }}
            />
            <I18n
              element="div"
              style={{ cursor: isArrangementEnabled ? 'pointer' : 'default' }}
              noUserSelect={true}>
              dashboard-editor-top-section.arrange-widgets
            </I18n>
          </div>
          {anchorElArrangement && (
            <ArrangementPopover
              open={openArrangementsPopover}
              close={closeArrangements}
              anchorEl={anchorElArrangement}
              selectedWidgets={selectedWidgets}
              setSelectedWidgets={setSelectedWidgets}
              setSelectedWidgetIdsWithError={setSelectedWidgetIdsWithError}
            />
          )}
          <div
            id={'181ee1a8-d78c-4d12-bf10-360eb00df03b'}
            className={classNames(styles.actionBtn, 'ellipsis-overflow')}
            onClick={removeDashboard}>
            <Icon
              type="trash"
              width="15px"
              height="15px"
              color={cssVarsService.vars.systemEnableEditorIcons}
            />
            <I18n element="div" noUserSelect={true}>
              dashboard-editor-top-section.remove
            </I18n>
          </div>
          <div>
            <div
              id={'5be3eab3-3774-40ac-81a2-cad80130718d'}
              className={classNames(styles.actionBtn, 'ellipsis-overflow')}
              onClick={openFilters}>
              <Icon type="filters" color={cssVarsService.vars.systemEnableEditorIcons} />
              <I18n element="div" noUserSelect={true}>
                dashboard-editor-top-section.filters
              </I18n>
            </div>
            {anchorElFilters && (
              <FiltersPopover
                openFiltersPopover={openFiltersPopover}
                closeFilters={closeFilters}
                anchorEl={anchorElFilters}
                setApplyButtonClicked={setApplyButtonClicked}
              />
            )}
          </div>
          <div>
            <div
              id={'a99487df-7bf5-42a8-a720-dcd114547513'}
              className={classNames(styles.actionBtn, 'ellipsis-overflow')}
              onClick={openSettings}>
              <Icon type="settings" color={cssVarsService.vars.systemEnableEditorIcons} />
              <I18n element="div" noUserSelect={true}>
                general.settings
              </I18n>
            </div>
            {anchorElSettings && (
              <SettingsPopover
                openSettingsPopover={openSettingsPopover}
                closeSettings={closeSettings}
                anchorEl={anchorElSettings}
                isRefreshRateVisible={isRefreshRateVisible}
              />
            )}
          </div>
          <div
            id={'07d18210-60b4-41b1-a5da-db341b9b773e'}
            className={classNames(styles.actionBtn, 'ellipsis-overflow')}
            onClick={openPreview}>
            <Icon type="preview" color={cssVarsService.vars.systemEnableEditorIcons} />
            <I18n element="div" noUserSelect={true}>
              dashboard-editor-top-section.preview
            </I18n>
          </div>
          <div
            id={'6644e631-2dc3-4028-86df-d180c3113390'}
            className={classNames(styles.actionBtn, 'ellipsis-overflow')}
            onClick={publishDashboard}>
            <Icon type="publish" color={cssVarsService.vars.systemEnableEditorIcons} />
            <I18n element="span" noUserSelect={true}>
              dashboard-editor-top-section.publish
            </I18n>
          </div>
          <div>
            <MultiLayoutButtons setSelectedWidgets={setSelectedWidgets} />
          </div>
          <div
            id={'fe282160-e4dd-4c24-bfe3-3e40d9a89f4c'}
            className={classNames(styles.actionBtn, 'ellipsis-overflow')}>
            <NavLink
              to={'/main/edit-dashboard/'}
              style={{ display: 'flex', textDecoration: 'none' }}>
              <Icon type="closeCustomColor" color={cssVarsService.vars.systemEnableEditorIcons} />
              <I18n
                element="div"
                style={{ color: 'var(--systemFont)', paddingTop: '1px' }}
                noUserSelect={true}>
                general.close
              </I18n>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(DeTopSection);
