import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { convertCalculationToObject } from '@pages/CreateWidgetPage/widgets.utils';
import I18n from '@components/I18n';
import styles from './Multiply.scss';
import {
  tableCfg,
  changeWidgetCalculations,
  getCalculationItemsInMultiply,
} from './Multiply.utils';
import { i18nService } from '@core/i18n/I18nService';
import MultiplayRow from './MultiplyRow';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiExpansionPanelSummary-root': {
      minHeight: '44px !important',
      padding: '0 19px',
      maxWidth: '100%',
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: '10px 0 !important',
      maxWidth: '92%',
    },
    '& .MuiIconButton-root': {
      padding: 10,
    },
    '& .MuiCollapse-container': {
      borderTop: '1px solid var(--tableRowBorderBottom)',
    },
    '& .MuiExpansionPanelDetails-root': {
      padding: '15px 19px',
    },
  },
  tableContainer: {
    height: '100%',
    backgroundColor: `var(--systemContentBackground)`,
    padding: '0px 10px',
  },
  tableHeader: {
    display: 'flex',
  },
  header: {
    display: 'flex',
    fontSize: 14,
    fontWeight: 'bold',
  },
  rowContainer: {
    display: 'flex',
    borderTop: 'solid 1px #c2cfe0',
  },
}));

function Multiply(props) {
  const { widgetData, setWidgetData } = props;
  const { metrics, calculations, assetProperties, tags, tagTypes, alarms, scope } = widgetData;
  const [calculationsItems, setCalculationsItems] = useState([]);

  const classes = useStyles(props);

  useEffect(() => {
    const calculationsObject = convertCalculationToObject(calculations);
    getCalculationItemsInMultiply(widgetData, setCalculationsItems, calculationsObject);
  }, [metrics, assetProperties, tags, tagTypes, alarms]);

  return (
    <ExpansionPanel
      defaultExpanded={false}
      id={styles.collapsePanel}
      className={classNames(styles.ep, classes.root)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div className={styles.epSummary}>
          <I18n element="div">create-widget-page.create-widget.step-three.multiply</I18n>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={styles.epDetailsWrapper}>
          <div className={classes.tableContainer}>
            <div className={classes.tableHeader}>
              {tableCfg.map((header, idx) => (
                <div
                  title={header.headerLabel && i18nService.translate(header.headerLabel)}
                  key={`table_${idx}`}
                  className={classes.header}
                  style={header.style}>
                  {header.headerLabel && (
                    <I18n className="ellipsis-overflow">{header.headerLabel}</I18n>
                  )}
                </div>
              ))}
            </div>
            {calculationsItems.map((item, idx) => (
              <div
                className={classes.rowContainer}
                key={`${item.valueId}_${item.valueType}_${item.operation}`}>
                <MultiplayRow
                  tableCfg={tableCfg}
                  item={item}
                  colIndex={idx}
                  widgetData={widgetData}
                  index={idx}
                  isFirst={idx === 0}
                  isLast={idx + 1 === calculationsItems.length}
                  columnChanged={(updatedRow) =>
                    changeWidgetCalculations(updatedRow, idx, setWidgetData)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default Multiply;
