import organizationTypeMap from '../OrganizationTypeMap';
import { httpService } from '@core/http/HttpService';
import { setDashboardRefId, setOrganizationDetails } from '@src/redux/config';
import { omit } from 'lodash';
import { setSelectedOrganization } from '@src/redux/organizations/organizations.actions';
import { modalService } from '@core/modals/ModalService';
import { buildErrorObj } from '@core/utils';
import { i18nService } from '@core/i18n/I18nService';
import { useDispatch } from 'react-redux';
import { getState } from '@src/redux/store';

export const editableParentCompany = (userOrganizationType, selectedOrganizationsType) => {
  const userTypeNumber = organizationTypeMap[userOrganizationType];
  const selectedOrgTypeNumber = organizationTypeMap[selectedOrganizationsType];
  if (
    (userTypeNumber === 3 && (selectedOrgTypeNumber === 4 || selectedOrgTypeNumber === 5)) ||
    ((userTypeNumber === 1 || userTypeNumber === 2) &&
      (selectedOrgTypeNumber === 2 || selectedOrgTypeNumber === 3))
  ) {
    return true;
  }
  return false;
};

export const getFieldsCfg = (
  Yup,
  owners,
  userOrganizationType,
  selectedOrganizationsType,
  orgType,
  showOrganizationIdField,
  isUnitronicsAdminUser
) => {
  return [
    {
      label: 'details.id',
      name: 'id',
      type: 'input',
      condition: () => showOrganizationIdField,
      editable: () => false,
    },
    {
      label: 'details.company-name',
      name: 'name',
      type: 'input',
      isRequired: true,
      validation: Yup.string()
        .required('validations.mandatory-field')
        .min(2, 'details.company-name-message')
        .max(100, 'details.company-name-message'),
    },
    {
      label: 'details.company-type',
      name: 'type',
      type: 'select',
      editable: () => false,
      enumValue: 'enum',
    },
    {
      label: 'details.company-website',
      validation: Yup.string().nullable().max(100, 'details.company-website-message'),
      name: 'website',
      type: 'input',
    },
    {
      label: 'details.company-address',
      name: 'address',
      type: 'googlePlacesInput',
      isRequired: orgType === 3 || orgType === 4 || orgType === 5,
      validation:
        (orgType === 3 || orgType === 4 || orgType === 5) &&
        Yup.string().required('validations.mandatory-field'),
    },
    {
      label: 'details.parent-company',
      name: 'parentCompanyId',
      type: 'select',
      editable: () => editableParentCompany(userOrganizationType, selectedOrganizationsType),
      isRequired: editableParentCompany(userOrganizationType, selectedOrganizationsType),
      validation:
        editableParentCompany(userOrganizationType, selectedOrganizationsType) &&
        Yup.string().nullable().required('validations.mandatory-field'),
      options: owners.map((opt) => ({
        value: opt.id,
        label: opt.name,
      })),
    },
    {
      label: 'details.status',
      name: 'status',
      type: 'input',
      editable: () => false,
      enumValue: 'organization-status',
    },
    {
      label: 'AWS IoT MB Events Quota',
      name: 'maxAwsIotEvent',
      validation: Yup.number().required().min(1).max(1000),
      type: 'input',
      editable: () => true,
      condition: () => isUnitronicsAdminUser && orgType === 3,
    },
    {
      label: 'Active Events',
      name: 'currentAwsEventCount',
      type: 'input',
      editable: () => false,
      condition: () => isUnitronicsAdminUser && orgType === 3,
    },
  ];
};

export const getFieldsCfgMainContact = (Yup, adminUsers) => {
  return [
    {
      label: 'details.admin-name',
      name: 'adminName',
      type: 'input',
      editable: () => false,
    },
    {
      label: 'details.admin-email',
      name: 'mainContactId',
      type: 'select',
      isRequired: true,
      validation: Yup.string().required('validations.mandatory-field'),
      options: adminUsers.map((opt) => ({
        value: opt.accountId,
        label: opt.email,
      })),
      onSelectChange: (selectedOpt, setFieldValue) => {
        const user = adminUsers.find((opt) => opt.accountId === selectedOpt.value);
        setFieldValue(['adminName'], `${user.firstName}  ${user.lastName}`);
        setFieldValue(['mainContactId'], user.accountId);
      },
    },
  ];
};

export const getFieldCfgPreferences = (
  Yup,
  languagesOptions,
  showLanguageField,
  editLanguageField
) => {
  return [
    {
      label: 'details.default-language',
      name: 'defaultLanguage',
      type: 'select',
      isRequired: true,
      options: languagesOptions,
      condition: () => {
        return showLanguageField;
      },
      editable: () => editLanguageField,
      validation: Yup.string().required('validations.mandatory-field'),
    },
  ];
};

export const getFieldCfgDefaultDashboards = (
  dashboardOptions,
  mbcDashboardOptions,
  customerDashboardOptions,
  orgType,
  userTypeNumber,
  showDefaultDashboardFields
) => {
  return [
    {
      label: orgType === 3 ? 'details.default-mb-dashboard' : 'details.default-dashboard',
      name: 'dashboard',
      type: 'select',
      options: dashboardOptions,
      condition: () => {
        return showDefaultDashboardFields && userTypeNumber === 3;
      },
    },
    {
      label: 'details.default-mbc-dashboard',
      name: 'mbcDefaultDashboard',
      type: 'select',
      options: mbcDashboardOptions,
      condition: () => {
        return showDefaultDashboardFields && userTypeNumber === 3 && orgType === 3;
      },
    },
    {
      label: 'details.default-customer-dashboard',
      name: 'customerDefaultDashboard',
      type: 'select',
      options: customerDashboardOptions,
      condition: () => {
        return showDefaultDashboardFields && userTypeNumber === 3 && orgType === 3;
      },
    },
  ];
};

export const mfaModeOptions = (twoFAModeNullAllowed) => {
  const twoFAModeOptions = [
    {
      value: 'ALL',
      label: i18nService.translate('details.2fa.mode.all'),
    },
    {
      value: 'ORGANIZATION_MANAGER',
      label: i18nService.translate('details.2fa.mode.organization-managers'),
    },
  ];

  if (twoFAModeNullAllowed)
    twoFAModeOptions.push({
      value: null,
      label: i18nService.translate('details.2fa.mode.none'),
    });

  return twoFAModeOptions;
};

export const mfaMethodOptions = [
  {
    value: 'EMAIL',
    label: i18nService.translate('details.2fa.notification-method.email'),
  },
  {
    value: 'SMS',
    label: i18nService.translate('details.2fa.notification-method.sms'),
  },
];

export const get2FAFieldCfg = (twoFAModeNullAllowed, isUnitronicsAdminUser, isUnassigned) => {
  return [
    {
      label: 'details.2fa.mode',
      name: 'mfaMode',
      type: 'select',
      options: mfaModeOptions(twoFAModeNullAllowed),
      editable: () => {
        return !isUnitronicsAdminUser;
      },
      onSelectChange: (selectedOpt, setFieldValue, values) => {
        const mfaMethod = values.mfaMethod;
        if (selectedOpt.value && !mfaMethod?.value)
          setFieldValue(
            ['mfaMethod'],
            mfaMethodOptions.find((m) => m.value === 'EMAIL')
          );
      },
    },
    {
      label: 'details.2fa.notification-method',
      name: 'mfaMethod',
      type: 'select',
      options: !isUnassigned
        ? mfaMethodOptions
        : mfaMethodOptions.filter((option) => option.value !== 'SMS'),
      condition: (values) => {
        return values.mfaMode?.value;
      },
      editable: () => !isUnassigned,
    },
  ];
};

export const getFieldCfg2 = (Yup, orgType, userOrgTypeNumber, isUserAdmin) => {
  return [
    {
      label: 'details.unitronic-org',
      name: 'rootRelatedOrg',
      type: 'checkbox',
      condition: (values) => {
        return (orgType === 2 || orgType === 3) && userOrgTypeNumber === 1 && isUserAdmin;
      },
    },
    {
      label: 'details.erp-instance-id',
      name: 'erpInstanceId',
      type: 'input',
      condition: (values) => {
        return (
          values['rootRelatedOrg'] &&
          (orgType === 2 || orgType === 3) &&
          userOrgTypeNumber === 1 &&
          isUserAdmin
        );
      },
      validation: Yup.string()
        .min(1, 'details.erp-instance-id-message')
        .max(50, 'details.erp-instance-id-message'),
    },
    {
      label: 'details.erp-customer-id',
      name: 'erpCustomerId',
      type: 'input',
      condition: (values) => {
        return (
          values['rootRelatedOrg'] &&
          (orgType === 2 || orgType === 3) &&
          userOrgTypeNumber === 1 &&
          isUserAdmin
        );
      },
      validation: Yup.string()
        .min(2, 'details.erp-customer-id-message')
        .max(50, 'details.erp-customer-id-message'),
    },
    {
      label: 'details.subscription-manager',
      name: 'subscriptionManagerOrg',
      type: 'checkbox',
      labelStyle: { width: 165 },
      condition: (values) => {
        return orgType === 2 && userOrgTypeNumber === 1 && isUserAdmin;
      },
    },
  ];
};

export const getInitialValues = (
  selectedOrganizations,
  setEditMode,
  languagesOptions,
  mfaModeOptions,
  mfaMethodOptions
) => {
  if (selectedOrganizations && selectedOrganizations.selectedOrganizationsId) {
    setEditMode(false);
    const user = selectedOrganizations.user;
    const selectedDefaultLanguage = languagesOptions.find(
      (lang) => lang.value == selectedOrganizations.defaultLanguage
    );
    const selected2FAMode = mfaModeOptions.find((op) => op.value == selectedOrganizations.mfaMode);
    const selected2FAMethod = mfaMethodOptions.find(
      (op) => op.value == selectedOrganizations.mfaMethod
    );
    return {
      id: selectedOrganizations.id || '',
      name: selectedOrganizations.name || '',
      type: selectedOrganizations.type || '',
      website: selectedOrganizations.website || null,
      address: selectedOrganizations.address || '',
      maxAwsIotEvent: selectedOrganizations.maxAwsIotEvent,
      currentAwsEventCount: selectedOrganizations.currentAwsEventCount,
      mfaNullAllowed: selectedOrganizations.mfaNullAllowed,
      mfaMode: selected2FAMode,
      mfaMethod: selected2FAMethod,
      parentCompanyId: selectedOrganizations.parentCompany
        ? {
            value: selectedOrganizations.parentCompany.id,
            label: selectedOrganizations.parentCompany.name,
          }
        : null,
      status: selectedOrganizations.status || '',
      adminName: (user && `${user.firstName}  ${user.lastName}`) || '',
      adminEmail: (user && user.email) || '',
      mainContactId: (user && user.id) || '',
      timezone: selectedOrganizations.timezone || '',
      defaultLanguage: selectedDefaultLanguage || '',
      dashboard: selectedOrganizations.dashboard || '',
      mbcDefaultDashboard: selectedOrganizations.mbcDefaultDashboard || '',
      customerDefaultDashboard: selectedOrganizations.customerDefaultDashboard || '',
      licensingType: selectedOrganizations.licensingType === 'PREMIUM',
      rootRelatedOrg: selectedOrganizations.rootRelatedOrg || false,
      erpInstanceId: selectedOrganizations.erpInstanceId || '',
      erpCustomerId: selectedOrganizations.erpCustomerId || '',
      subscriptionManagerOrg: selectedOrganizations.subscriptionManagerOrg || false,
    };
  }
};

export const setDefaultDashboardsOptions = async (
  selectedMBId,
  selectedOrganization,
  setDashboardOptions,
  setMbcDashboardOptions,
  setCustomerDashboardOptions,
  orgType
) => {
  const { results: publishedDashboards }: any = await httpService.api({
    type: 'getAllPublishDashboard',
    urlParams: { p: 1, ps: 99999, organizationId: selectedMBId || undefined },
  });

  setDefaultDashboardOptions(
    'dashboard',
    setDashboardOptions,
    selectedOrganization,
    publishedDashboards
  );
  // only MB has the options of MBC_DefaultDashboard and Customer_DefaultDashboard
  orgType === 3 &&
    setDefaultDashboardOptions(
      'mbcDefaultDashboard',
      setMbcDashboardOptions,
      selectedOrganization,
      publishedDashboards
    );
  orgType === 3 &&
    setDefaultDashboardOptions(
      'customerDefaultDashboard',
      setCustomerDashboardOptions,
      selectedOrganization,
      publishedDashboards
    );
};

const setDefaultDashboardOptions = (
  dashboardRefKey,
  setDashboardOptions,
  selectedOrganization,
  publishedDashboards
) => {
  let dashboardOptions = [];

  // The default dashbord that was selected for the organization
  const defaultDashboard = publishedDashboards.find(
    (m) => m.refId === selectedOrganization[dashboardRefKey]
  );

  // getting the options excluding the selected dashboard
  dashboardOptions = publishedDashboards
    .filter((m) => m.refId !== selectedOrganization[dashboardRefKey])
    .map((t) => ({
      value: t.refId,
      label: t.name,
    }));

  dashboardOptions.sort((a, b) => a.label.localeCompare(b.label));

  // unshift the selected dashboard to be first
  defaultDashboard &&
    dashboardOptions.unshift({
      value: defaultDashboard.refId,
      label: defaultDashboard.name,
    });

  setDashboardOptions(dashboardOptions);
};

export const onDeleteOrg = (selectedOrganization, dispatch) => {
  httpService
    .api({
      type: 'canDeleteOrg',
      urlParams: {
        organizationId: selectedOrganization.selectedOrganizationsId,
      },
    })
    .then((res: { canDelete: boolean; message: { code: 29 | 30; message: string; keys: [] } }) => {
      if (res.canDelete) {
        openConfirmDeletionModal(selectedOrganization, dispatch);
      } else {
        openErrorModal(res.message);
      }
    });
};

export const openConfirmDeletionModal = (selectedOrganization, dispatch) => {
  modalService
    .openConfirm(
      {
        headerText: 'details.delete-org-modal.header',
        text: 'details.delete-org-modal.text',
        showCloseBtn: true,
      },
      { companyName: selectedOrganization.name }
    )
    .then((confirm) => {
      confirm && deleteOrg(selectedOrganization, dispatch);
    });
};

export const deleteOrg = (selectedOrganization, dispatch) => {
  httpService
    .api({
      type: 'deleteOrg',
      urlParams: {
        organizationId: selectedOrganization.selectedOrganizationsId,
      },
    })
    .then(() => {
      dispatch(setSelectedOrganization({ status: 'DELETE', selectedOrganizationsId: null }));
    });
};

export const openErrorModal = (message: { code: 29 | 30; message: string; keys: [] }) => {
  const text = i18nService.translate(
    `errors.${message.code}`,
    undefined,
    buildErrorObj(message.keys)
  );
  modalService.openAlert({
    headerText: 'details.delete-org-modal.header',
    text,
    btnText: 'general.confirm',
    showCloseBtn: true,
  });
};

export const onSubmit = (
  values,
  userOrganizationType,
  dispatch,
  selectedOrganizations,
  setEditMode,
  userOrganizationId,
  selectedMBId
) => {
  [
    'dashboard',
    'mbcDefaultDashboard',
    'customerDefaultDashboard',
    'parentCompanyId',
    'defaultLanguage',
    'mfaMethod',
  ].forEach((key) => {
    values[key] = values[key] ? (values[key].value ? values[key].value : values[key]) : null;
  });

  values['mfaMode'] = values['mfaMode'].value;
  if (!values['mfaMode']) values['mfaMethod'] = null;

  const notRelevantFields = ['type', 'status', 'adminName', 'adminEmail', 'enable', 'id'];
  values['licensingType'] = values['licensingType'] ? 'PREMIUM' : 'FREE';
  values['address'] = values['address'] || null;
  if (organizationTypeMap[userOrganizationType] !== 1) {
    notRelevantFields.push('licensingType');
  }

  // Unitronics Admin is not allowed to edit default dashboards
  organizationTypeMap[userOrganizationType] == 1 &&
    notRelevantFields.push(...['mbcDefaultDashboard', 'customerDefaultDashboard']);

  httpService
    .api({
      type: 'editOrganization',
      data: omit(values, notRelevantFields),
    })
    .then((res: any) => {
      dispatch(setSelectedOrganization(res));
      if (selectedMBId === res.id) {
        const orgDetails = getState().config.organizationDetails;
        dispatch(
          setOrganizationDetails({
            ...orgDetails,
            country: res.address.country,
            googleLocationId: res.address.googleId,
          })
        );
      }
      selectedOrganizations &&
        selectedOrganizations.selectedOrganizationsId === parseInt(userOrganizationId) &&
        dispatch(setDashboardRefId({ dashboardRefId: values['dashboard'] }));
    });
  setEditMode(false);
};
