import I18n from '@components/I18n';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './ConditionalTrigger.scss';
import { i18nService } from '@core/i18n/I18nService';
import StartEnd from '../TriggerTimePicker/StartEnd/StartEnd';
import MultiSelect from '@components/MultiSelect';
import Select from '@components/Select';
import ConditionBuilderContainer from './ConditionBuilder/ConditionBuilderContainer';
import { ContextProvider } from './conditionalTriggerContext';
import moment from 'moment';
import { httpService } from '@core/http/HttpService';
import { AssetTypeResponse } from '@pages/CreateWidgetPage/DataStepComponent/StepOne/StepOne.interface';

function ConditionalTrigger(props) {
  const { templateData, setTemplateData, showEditOption = true } = props;
  const { trigger, startDate, endDate, conditionAssetTypes, conditionTagTypes } = templateData;
  const [assetTypeOptions, setAssetTypeOptions] = useState([]);
  const [selectedAssetTypes, setSelectedAssetTypes] = useState([]);
  const [areAssetTypesLoaded, setAreAssetTypesLoaded] = useState(false);
  const [areFieldsLoaded, setAreFieldsLoaded] = useState(false);
  const [fieldsCombined, setFieldCombined] = useState({});

  const getAssetTypeOptions = () => {
    httpService
      .api<AssetTypeResponse>({
        type: 'getAssetTypeOptionsInfo',
        query: {
          p: 1,
          ps: 1000,
        },
      })
      .then((res) => {
        setAssetTypeOptions(res.results);
      });
  };

  const findFullDetails = (assetTypeIds = [], findFrom = []) => {
    const details = assetTypeIds.map((id) => {
      return findFrom.find((assetType) => assetType.id === id);
    });
    return details;
  };

  useEffect(() => {
    getAssetTypeOptions();

    if (trigger === 'ALARM') {
      if (conditionAssetTypes && conditionAssetTypes.length > 1) {
        setTemplateData(null, 'conditionAssetTypes');
        setSelectedAssetTypes([]);
      }
    }
  }, []);

  useEffect(() => {
    if (conditionAssetTypes?.length > 0 && assetTypeOptions.length > 0) {
      setSelectedAssetTypes(findFullDetails(conditionAssetTypes, assetTypeOptions));
      setAreAssetTypesLoaded(true);
    }
  }, [conditionAssetTypes, assetTypeOptions]);

  if (startDate === null) {
    setTemplateData(moment().format('YYYY-MM-DD'), 'startDate');
  }

  return (
    <ContextProvider
      {...props}
      setAreFieldsLoaded={setAreFieldsLoaded}
      selectedAssetTypes={selectedAssetTypes}
      setFieldCombined={setFieldCombined}
      areAssetTypesLoaded={areAssetTypesLoaded}>
      <div className={styles.section}>
        <div className={styles.row}>
          <div className={styles.header}>
            {trigger === 'ALARM' && <I18n>events-template.edit-template.trigger-type.alarm</I18n>}
            {trigger === 'TELEMETRY' && (
              <I18n>events-template.edit-template.trigger-type.telemetry</I18n>
            )}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.leftSpacerWithLine}></div>
          <div className={styles.section}>
            <StartEnd
              startDate={startDate}
              endDate={endDate}
              startDateChanged={(e) => setTemplateData(e.dateFilterFrom, 'startDate')}
              endDateChanged={(e) => setTemplateData(e.dateFilterFrom, 'endDate')}
              xIconClicked={(e) => setTemplateData(null, 'endDate')}
              showEditOption={showEditOption}
            />
            <div className={styles.rowWithError}>
              <div className={classNames(styles.caption, 'ellipsis-overflow', 'asterisk')}>
                <I18n>events-template.edit-template.asset-type</I18n>
              </div>
              {trigger === 'ALARM' && (
                <Select
                  styles={{
                    container: { width: '400px' },
                    placeholder: { color: 'var(--systemFont)' },
                  }}
                  className={styles.field}
                  errorStyle={!selectedAssetTypes || selectedAssetTypes.length === 0}
                  value={selectedAssetTypes}
                  options={assetTypeOptions}
                  getOptionLabel={(options) => options.name}
                  getOptionValue={(option) => option.id}
                  placeholder={i18nService.translate(
                    'events-template.edit-template.select-asset-type'
                  )}
                  onChange={(option) => {
                    setTemplateData([option.id], 'conditionAssetTypes');
                    setSelectedAssetTypes([option]);
                  }}
                />
              )}
              {trigger === 'TELEMETRY' && (
                <>
                  <div className={styles.multiSelect}>
                    <MultiSelect
                      id="assetTypeOptionsPopover"
                      errorStyle={!selectedAssetTypes || selectedAssetTypes.length === 0}
                      values={selectedAssetTypes}
                      options={assetTypeOptions}
                      getOptionLabel={(options) => options.name}
                      getOptionValue={(option) => option.id}
                      placeholder={i18nService.translate('general.select-asset-types')}
                      onChange={(assetTypes) => {
                        setTemplateData(
                          assetTypes.map((assetType) => assetType.id),
                          'conditionAssetTypes'
                        );
                        setAreFieldsLoaded(false);
                        setSelectedAssetTypes(assetTypes);
                      }}
                      closeMenuOnSelectAll
                      optionType="checkbox"
                    />
                  </div>
                </>
              )}
            </div>
            <div className={classNames(styles.error, 'ellipsis-overflow')}>
              {conditionAssetTypes &&
                conditionAssetTypes.length > 1 &&
                areFieldsLoaded &&
                fieldsCombined &&
                (!fieldsCombined['tagTypes'] ||
                  Object.keys(fieldsCombined['tagTypes'].subfields)?.length === 0) && (
                  <I18n>events-template.edit-template.no-common-tags-error</I18n>
                )}
            </div>
            <div className={styles.row}>
              <ConditionBuilderContainer {...props} areFieldsLoaded={areFieldsLoaded} />
            </div>
          </div>
        </div>
      </div>
    </ContextProvider>
  );
}

export default ConditionalTrigger;
