import React from 'react';
import Panel from '../../Panel/Panel';
import DateTime from './DateTime';
import DelimiterSettings from './DateTime/DelimiterSettings/DelimiterSettings';
import NumberFormatting from '@src/pages/MyAccountPage/SharedComponents/NumberFormatting';

const panelsMap = [
  { label: 'white-labeling.date-time.title', Component: DateTime },
  { label: 'white-labeling.number-formatting.title', Component: NumberFormatting },
  { label: 'white-labeling.csv-delimiter-settings.title', Component: DelimiterSettings },
];

function RegionalSettings(props) {
  return panelsMap.map((panel) => <Panel {...panel} key={panel.label} {...props} />);
}

export default RegionalSettings;
