import React, { useState, useRef, useEffect } from 'react';
import { makeStyles, ClickAwayListener } from '@material-ui/core';
import { PhotoshopPicker } from 'react-color';
import I18n from '@components/I18n';

const useStyles = makeStyles((theme: any) => ({
  color: {
    width: 21,
    height: 21,
    borderRadius: 2,
    background: (props: any) =>
      props.disabled
        ? props.isDisplaySettings
          ? props.value
          : 'var(--defaultDisabledColor)'
        : props.value,
    cursor: (props: any) => !props.disabled && 'pointer',
    opacity: (props: any) => props.disabled && 0.7,
  },
  popover: {
    position: 'absolute',
    zIndex: 2,
    top: 28,
  },
  swatch: {
    padding: 2,
    background: (props: any) =>
      props.disabled ? (props.isDisplaySettings ? '#fff' : 'var(--defaultDisabledColor)') : '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: (props: any) => !props.disabled && 'pointer',
  },
  box: {
    width: 1101,
    height: 252,
    borderRadius: 5,
    backgroundColor: '#f9fafe',
  },
  fieldLabel: {
    width: (props) => props.labelWidth || 310,
    color: 'var(--systemFont)',
  },
  field: {
    display: 'flex',
    position: (props) => (props.isParentRelative ? 'unset' : 'relative'),
    marginBottom: (props) =>
      props.customeMarginButton || props.customeMarginButton == 0 ? props.customeMarginButton : 20,
  },
}));
function ColorInput(props) {
  const { label, value, colorChange, disabled, popOverPosition, isParentRelative, isDisplaySettings } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(value);
  const classes = useStyles(props);
  const inputRef = useRef(null);
  const colorBoxRef = useRef(null);

  useEffect(() => {
    if (inputRef && inputRef.current && displayColorPicker) {
      inputRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [inputRef, displayColorPicker]);

  const handleChange = (color) => {
    setColor(color);
  };

  const scrollToColorBox = () => {
    if (colorBoxRef && colorBoxRef.current) {
      colorBoxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onAccept = () => {
    setDisplayColorPicker(false);
    color.hex && colorChange(color.hex);
    scrollToColorBox();
  };

  const handleClose = () => {
    // When closing picker but chosen color was not saved (because permissions and etc...) -
    // set the color to saved value
    displayColorPicker && scrollToColorBox();
    value !== color && setColor(value);
    setDisplayColorPicker(false);
  };

  const handleClick = (e) => {
    !disabled && setDisplayColorPicker(!displayColorPicker);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={classes.field}>
        {label && <I18n className={classes.fieldLabel}>{label}</I18n>}
        <div className={classes.swatch} ref={colorBoxRef} onClick={handleClick}>
          <div className={classes.color} />
        </div>
        {displayColorPicker && (
          <div className={classes.popover} ref={inputRef} style={popOverPosition}>
            <PhotoshopPicker
              color={color}
              onChange={handleChange}
              onAccept={onAccept}
              onCancel={handleClose}
            />
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
}

export default ColorInput;
