import { i18nService } from '@core/i18n/I18nService';
import {
  Field,
  FieldGroup,
  FieldGroupExt,
  FieldStruct,
  TypedMap,
} from 'react-awesome-query-builder';
import { camelCase } from 'lodash';
import { alarmInfosMap, assetsPropsMap } from '@pages/CreateWidgetPage/widgets.utils';
import { httpService } from '@core/http/HttpService';

export const assetPropertiesToFieldArray = (
  trigger,
  assetPropertiesFromApi,
  languageId?,
  assetPropertiesData?
) => {
  return assetPropertiesFromApi.map((item) => {
    let name: string;
    let field: ExtendedFields;
    const groupName = assetsPropsMap[item.id];

    name = i18nService.translate(
      `create-widget-page.create-widget.asset-properties.${groupName}`,
      languageId
    );

    switch (item.id) {
      case 1: // ASSET_ID
        field = {
          [camelCase(groupName)]: {
            label: name,
            id: item.id,
            type: 'select',
            valueSources: ['value', 'field'],
            fieldSettings: {
              listValues: assetPropertiesData.assetIds.map((assetId) => {
                return { value: assetId, title: assetId };
              }),
            },
            preferWidgets: ['select'],
          },
        };
        return field;
      case 2: // ASSET_NAME
        field = {
          [camelCase(groupName)]: {
            label: name,
            id: item.id,
            type: 'select',
            valueSources: ['value', 'field'],
            fieldSettings: {
              listValues: assetPropertiesData.assetNames
                .map((assetName, i) => {
                  return {
                    value: assetName,
                    title: `${assetPropertiesData.assetIds[i]} - ${assetName}`,
                  };
                })
                .map((item) => {
                  return { value: item.value, title: item.title };
                }),
            },
            preferWidgets: ['select'],
          },
        };
        return field;
      case 4: // ORGANIZATION
        field = {
          [camelCase(groupName)]: {
            label: name,
            id: item.id,
            type: 'select',
            valueSources: ['value', 'field'],
            fieldSettings: {
              listValues: assetPropertiesData.organizations.map((organization) => {
                return { value: organization.name, title: organization.name };
              }),
            },
            preferWidgets: ['select'],
          },
        };
        return field;
      case 5: // STATUS
        if (trigger === 'TELEMETRY') {
          field = {
            [camelCase(groupName)]: {
              label: name,
              id: item.id,
              type: 'select',
              valueSources: ['value', 'field'],
              fieldSettings: {
                listValues: [
                  { value: 2, title: 'Connected' },
                  { value: 4, title: 'Warning' },
                  { value: 5, title: 'Minor Alarm' },
                  { value: 6, title: 'Major Alarm' },
                  { value: 7, title: 'Critical Alarm' },
                ],
              },
              preferWidgets: ['select'],
            },
          };
          return field;
        } else {
          break;
        }
      case 8: // ASSET_TYPE
        if (assetPropertiesData.assetTypes.length === 1) {
          field = {
            [camelCase(groupName)]: {
              label: name,
              id: item.id,
              type: 'select',
              valueSources: ['value', 'field'],
              defaultValue: assetPropertiesData.assetTypes[0].id,
              fieldSettings: {
                listValues: assetPropertiesData.assetTypes.map((assetType) => {
                  return { value: assetType.id, title: assetType.name };
                }),
              },
              preferWidgets: ['select'],
            },
          };
        } else {
          field = {
            [camelCase(groupName)]: {
              label: name,
              id: item.id,
              type: 'select',
              valueSources: ['value', 'field'],
              fieldSettings: {
                listValues: assetPropertiesData.assetTypes.map((assetType) => {
                  return { value: assetType.id, title: assetType.name };
                }),
              },
              preferWidgets: ['select'],
            },
          };
        }
        return field;
      default:
        break;
    }
  });
};

export const alarmInfosToFieldArray = async (alarmInfos, languageId?, assetType?) => {
  let alarmNames: any[];
  await httpService
    .api<{
      next: any;
      results: any;
      allCount: string;
      count: string;
    }>({
      type: 'getFilterAutocomplete',
      query: {
        p: 1,
        ps: 1000,
        property: 'NAME',
        assetTypes: assetType,
      },
    })
    .then((res) => {
      alarmNames = ['Communication Error', ...res.results];
      console.log(`res.results: ${res.results}`);
      console.log(`alarmNames: ${alarmNames}`);
    });

  return alarmInfos.map((item) => {
    let name: string;
    let field: ExtendedFields;
    const groupName = alarmInfosMap[item.id];

    name = i18nService.translate(
      `events-template.edit-template.alarm.${alarmInfosMap[item.id]}`,
      languageId
    );
    if (item.id === 2) {
      // ACTIVE_ALARM
      field = {
        [camelCase(groupName)]: {
          label: name,
          id: item.id,
          type: 'boolean',
          valueSources: ['value', 'field'],
          preferWidgets: ['boolean'],
        },
      };
    } else if (item.id === 7) {
      // NAME
      field = {
        [camelCase(groupName)]: {
          label: name,
          id: item.id,
          type: 'select',
          valueSources: ['value', 'field'],
          fieldSettings: {
            listValues: alarmNames.map((alarmName) => {
              return { value: alarmName, title: alarmName };
            }),
          },
          preferWidgets: ['select'],
        },
      };
    }
    return field;
  });
};

export type ExtendedFields = TypedMap<ExtendedFieldOrGroup>;

export interface ExtendedField extends Field {
  id?: number;
  dataType?: string;
}

export interface ExtendedFieldGroupExt extends FieldGroupExt {
  id?: number;
}

export interface ExtendedFieldGroup extends FieldGroup {
  id?: number;
}

export interface ExtendedFieldStruct extends FieldStruct {
  id?: number;
}

type ExtendedFieldOrGroup =
  | ExtendedFieldStruct
  | ExtendedFieldGroup
  | ExtendedFieldGroupExt
  | ExtendedField;

export const tagsToFieldArray = (data, dataType) => {
  return data?.map((item) => {
    let name = item.name;
    let id = item.id;
    let field: ExtendedFields;

    if (item.type === 'STRING' || item.format === 'STRING' || item.valueType === 'TEXT') {
      field = {
        [id]: {
          label: name,
          id: id,
          type: 'text',
          valueSources: ['value', 'field'],
          preferWidgets: ['text'],
          dataType,
          hideForSelect: dataType === 'variable',
        },
      };
    } else if (
      item.type === 'DATE' ||
      item.format === 'DATE' ||
      item.type === 'DATETIME' ||
      item.format === 'DATETIME' ||
      item.valueType === 'DATETIME'
    ) {
      field = {
        [id]: {
          label: name,
          id: id,
          type: 'datetime',
          valueSources: ['value', 'field'],
          preferWidgets: ['datetime'],
          dataType,
          hideForSelect: dataType === 'variable',
        },
      };
    } else if (
      item.type === 'INT8' ||
      item.format === 'INT8' ||
      item.type === 'UINT8' ||
      item.format === 'UINT8' ||
      item.type === 'INT16' ||
      item.format === 'INT16' ||
      item.type === 'UINT16' ||
      item.format === 'UINT16' ||
      item.type === 'INT32' ||
      item.format === 'INT32' ||
      item.type === 'UINT32' ||
      item.format === 'UINT32' ||
      item.type === 'FLOAT32' ||
      item.format === 'FLOAT32' ||
      item.type === 'DURATION' ||
      item.format === 'DURATION' ||
      item.valueType === 'NUMERIC'
    ) {
      field = {
        [id]: {
          label: name,
          id: id,
          type: 'number',
          valueSources: ['value', 'field', 'func'],
          preferWidgets: ['number'],
          funcs: ['SIGMA'],
          dataType,
          hideForSelect: dataType === 'variable',
        },
      };
    } else if (
      item.type === 'BOOLEAN' ||
      item.format === 'BOOLEAN' ||
      item.valueType === 'BOOLEAN'
    ) {
      field = {
        [id]: {
          label: name,
          id: id,
          type: 'boolean',
          valueSources: ['value', 'field'],
          preferWidgets: ['boolean'],
          dataType,
          hideForSelect: dataType === 'variable',
        },
      };
    }
    return field;
  });
};

export const arrayToFieldGroup = (
  fieldArray: ExtendedFields[],
  groupName: string,
  groupKey: string
) => {
  const tempObject = fieldArray?.reduce((target, source) => Object.assign(target, source), {});
  const fields: ExtendedFields = {
    [groupKey]: {
      type: '!struct',
      label: groupName,
      subfields: {
        ...tempObject,
      },
    },
  };
  return fields;
};
