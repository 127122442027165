import { httpService } from '@core/http/HttpService';
import {
  setSelectedOrganization,
  deleteOrganizatioData,
} from '@src/redux/organizations/organizations.actions';
import { walk, changeNodeAtPath, removeNodeAtPath } from 'react-sortable-tree';
import { dispatch } from '@src/redux/store';
import { removePLCData } from '@src/redux/deviceManagment/plc/plc.actions';
import { removeAllAssetsData } from '@src/redux/deviceManagment/asset/asset.actions';
import { modalService } from '@core/modals/ModalService';
import organizationTypeMap from '../OrganizationTypeMap';
import { i18nService } from '@core/i18n/I18nService';

export const getNodeKey = ({ treeIndex, node }) => treeIndex;

/**
 * reqursive func to find MB(type 3) id of a node.
 * @param mbMap - includes key(child id) and value(parent id) of all types 4, 5 that where opened
 * @param nodeId - the node that we want to find his MB(type 3) id.
 */
export const getCurrentMB = (mbMap, nodeId) => {
  const findMBId = (id) => {
    return mbMap[id] ? findMBId(mbMap[id]) : id;
  };

  return findMBId(nodeId);
};

export const getChildren = async ({ done, node }) => {
  try {
    const res: any = await httpService.api({
      type: 'getOrganizations',
      urlParams: { organizationId: node.id },
      disableBI: true,
    });
    if (res) {
      done(res.map((org) => ({ ...org, children: getChildren })));
    }
  } catch (err) {
    console.error(err);
  }
};

export const getOrganizationDetailsById = (organizationId, selectedMBId) => {
  httpService
    .api({
      type: 'getOrganizationDetails',
      urlParams: { organizationId },
    })
    .then((res: any) => {
      let newRes = res;
      if (res?.address?.formatted === 'Unknown') {
        newRes = {
          ...res,
          address: {
            ...res.address,
            formatted: i18nService.translate('details.company-address.unknown'),
          },
        };
      }
      dispatch(
        setSelectedOrganization({
          ...newRes,
          selectedMBId,
          selectedOrganizationsId: organizationId,
        })
      );
    })
    .catch(() => {
      dispatch(setSelectedOrganization({ selectedMBId, selectedOrganizationsId: organizationId }));
    });
};

export const findNodeAndParentById = (treeData, selected) => {
  let current: any = {};
  let parent: any = {};
  //going through all the open nodes in the tree
  walk({
    treeData,
    //finding the selected node and its parent
    callback: ({ path, node }) => {
      selected.id === node.id && (current = { path, node });
      selected.parentId === node.id && (parent = { path, node });
    },
    getNodeKey,
  });
  return { current, parent };
};

export const removeNode = (treeData, selected) => {
  const { current, parent } = findNodeAndParentById(treeData, selected);

  //change the parent node (discounting children length and collapse it if empty)
  let data = changeNodeAtPath({
    treeData,
    path: parent.path,
    newNode: {
      ...parent.node,
      childCount: parent.node.childCount - 1,
      expanded: parent.node.childCount - 1 !== 0,
    },
    getNodeKey,
  });
  //rmove the selected node
  data = removeNodeAtPath({
    treeData: data,
    path: current.path,
    getNodeKey,
  });

  return data;
};

//remove all prev selected organization data.
export const cleanOrganizationData = () => {
  dispatch(deleteOrganizatioData());
  dispatch(removePLCData());
  dispatch(removeAllAssetsData());
};

export const deleteOrganization = (
  treeData,
  setTreeData,
  selected,
  setSelected,
  organizationDetails,
  machinBuilderMap,
  userOrganizationId,
  history
) => {
  const newTreeData = removeNode(treeData, selected);
  setTreeData(newTreeData);
  onNodeClicked(
    { node: organizationDetails },
    organizationDetails,
    machinBuilderMap,
    userOrganizationId,
    history,
    setSelected
  );
};

export const updateTreeChanges = (
  treeData,
  setTreeData,
  selected,
  setSelected,
  selectedOrganizationDetails,
  organizationDetails
) => {
  if (
    selected &&
    selectedOrganizationDetails &&
    selectedOrganizationDetails.selectedOrganizationsId
  ) {
    const { parentCompany, name, address, selectedOrganizationsId, status } =
      selectedOrganizationDetails;
    //if parent is change
    if (
      parentCompany &&
      parentCompany.id !== selected.parentId &&
      selectedOrganizationsId !== organizationDetails.id
    ) {
      //remove node from tree
      const newTreeData = removeNode(treeData, selected);
      //find the new parent and update him
      const { current } = findNodeAndParentById(newTreeData, {
        id: parentCompany.id,
      });
      let data = newTreeData;
      if (current.path) {
        data = changeNodeAtPath({
          treeData: newTreeData,
          path: current.path,
          newNode: {
            ...current.node,
            expanded: true,
            childCount: current.node.childCount + 1,
            children:
              typeof current.node.children !== 'function'
                ? [
                    ...current.node.children,
                    {
                      ...selected,
                      parentId: parentCompany.id,
                      name,
                      country: address && address.country,
                    },
                  ]
                : current.node.children,
          },
          getNodeKey,
        });
        setSelected({ ...selected, parentId: parentCompany.id });
      }
      setTreeData(data);
    } else if (
      name !== selected.name ||
      status !== selected.status ||
      (address && address.country !== selected.country)
    ) {
      //if name or country or status changed, find current and update him.
      const { current } = findNodeAndParentById(treeData, selected);
      const newNode = {
        ...current.node,
        name,
        status,
        country: address && address.country,
      };
      const data = changeNodeAtPath({
        treeData,
        path: current.path,
        newNode,
        getNodeKey,
      });
      setTreeData(data);
      setSelected({ ...newNode });
    }
  }
};

export const updateMBMap = ({ parentId, id, type }, machinBuilderMap) => {
  if (['MACHINE_BUILDER_CHANNEL', 'END_CUSTOMER'].includes(type)) {
    machinBuilderMap[id] = parentId;
  }
};

/**
 * update the selcted node.
 * fetch org details from server.
 * update redux with org details and selected MB id.
 */
export const onNodeClicked = (
  { node },
  organizationDetails,
  machinBuilderMap,
  userOrganizationId,
  history,
  setSelected
) => {
  let selectedMBId = null;
  node.parentId && updateMBMap(node, machinBuilderMap);

  if (organizationDetails.type === 'UNITRONICS_MAIN') {
    selectedMBId = getCurrentMB(machinBuilderMap, node.id);
  } else if (organizationDetails.type !== 'UNITRONICS_CHANNEL') {
    selectedMBId = +userOrganizationId;
  }

  if (history.location.pathname !== '/main/organizations/details') {
    history.push('/main/organizations/details');
  }
  cleanOrganizationData();
  setSelected({ id: node.id, ...node });
  getOrganizationDetailsById(node.id, selectedMBId);
};

export const deleteSelected = async (selected, treeData, setTreeData, setSelected) => {
  const confirm = await modalService.openConfirm(
    {
      text: 'organizations-page.delete-wraning',
    },
    { companyName: selected.name }
  );
  if (confirm) {
    const data = removeNode(treeData, selected);

    setTreeData(data);
    setSelected(undefined);
    dispatch(deleteOrganizatioData());
  }
};

export const onAddingOrg = async (
  rowData,
  selectedOrganizationDetails,
  setTreeData,
  treeData,
  selected
) => {
  const res = await modalService.openModal('newOrganizationModal', {
    organizationType: rowData.node.type,
    rootRelatedOrg: selectedOrganizationDetails
      ? selectedOrganizationDetails.rootRelatedOrg
      : rowData.node.rootRelatedOrg,
  });
  if (res && res.id) {
    const { current } = findNodeAndParentById(treeData, selected);
    const data = changeNodeAtPath({
      treeData,
      path: current.path,
      newNode: {
        ...current.node,
        childCount: current.node.childCount + 1,
        //adding the org to the tree only if node was opened and already get his children
        children:
          // when "node.children" === 'function' node children need to be fetch from server
          typeof current.node.children !== 'function'
            ? [...current.node.children, { ...res, children: getChildren }]
            : current.node.children,
      },
      getNodeKey,
    });
    setTreeData(data);
  }
};

export const setFirstNode = (
  organizationDetails,
  setTreeData,
  setSelected,
  machinBuilderMap,
  userOrganizationId,
  history
) => {
  if (organizationDetails && organizationDetails.id) {
    setTreeData([{ ...organizationDetails, children: getChildren }]);
    onNodeClicked(
      { node: organizationDetails },
      organizationDetails,
      machinBuilderMap,
      userOrganizationId,
      history,
      setSelected
    );
  }
};

export const getNodeColor = (orgType) => {
  switch (organizationTypeMap[orgType]) {
    case 1:
    case 2:
      return '#ffffff';
    case 3:
      return 'var(--systemMachineBuilderOrgTextColor)';
    case 4:
      return 'var(--systemMachineBuilderChannelOrgTextColor)';
    case 5:
      return 'var(--systemCustomerOrgTextColor)';
  }
};
