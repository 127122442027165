import PieWidget from '@components/widgets/PieWidget';
import { stepThreeDefaultValidation } from '../widget.utils';
import { WidgetMapItem } from '../widgetMap.interface';

export const pie: WidgetMapItem = {
  id: 'pie',
  name: 'Pie',
  component: PieWidget,
  minWidth: 240,
  minHeight: 210,
  images: {
    canvas: '/assets/images/empty-pie.svg',
    thumbnail: 'pie',
  },
  editModalSize: {},
  settings: {
    dataChangesToHandle: ['groupBy', 'metrics', 'filters', 'sorts', 'calculations', 'localTags'],
    stepOneHidden: [],
    stepValidation: {
      3: (widgetData) =>
        !widgetData.groupBy.length || stepThreeDefaultValidation(widgetData)('metrics'),
    },
    dragAndDropRules: {
      maxGrouping: (scope) => (scope === 'LAST_VALUE' ? 1 : 2),
      groupingDataValidation: (scope, draggableItem) => {
        if (
          scope === 'AGGREGATED_LAST_VALUE' &&
          (draggableItem?.id === 7 || draggableItem?.valueId === 7) &&
          (draggableItem?.tagType === 'ASSET_PROPERTY' ||
            draggableItem?.valueType === 'ASSET_PROPERTY')
        ) {
          return 'noTimeStampOnAggregateByLastValue';
        }
        if (
          scope === 'LAST_VALUE' &&
          (draggableItem?.id === 6 || draggableItem?.valueId === 6) &&
          (draggableItem?.tagType === 'ASSET_PROPERTY' ||
            draggableItem?.valueType === 'ASSET_PROPERTY')
        ) {
          return 'noDateOnLastValue';
        }
        return null;
      },
      maxMetrics: 1,
      metricsDataValidation: (scope, draggableItemType) => {
        if (
          scope === 'LAST_VALUE' &&
          draggableItemType !== 'numberType' &&
          draggableItemType !== 'floatType'
        ) {
          return 'nonNumerical';
        }
        return null;
      },
    },
    showPreviewData: ['metrics', 'groupBy'],
    disabledScope: ['RAW_DATA'],
    similarWidgets: () => [{ widgetType: 'columns' }],
    languageKeys: ['subtitle_text'],
    customizationServerChanges: (customization) => ({
      ...customization,
      showAs: customization.showAs.toUpperCase(),
    }),
    hideFilterNavigation: (widgetData) => false,
    allowAssetTypeNavigation: (widgetData) =>
      widgetData.groupBy.find(
        (gb) => gb.valueType === 'ASSET_PROPERTY' && (gb.valueId === 1 || gb.valueId === 8)
      ) || widgetData.scope === 'LAST_VALUE', // id 1 is Asset ID, id 8 is Asset Type or scope is last value
  },
};
