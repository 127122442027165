import RemoteAccessWidget from '@components/widgets/RemoteAccessWidget';
import { WidgetMapItem } from '../widgetMap.interface';

export const web: WidgetMapItem = {
  id: 'web',
  name: 'web',
  component: RemoteAccessWidget,
  minWidth: 136,
  minHeight: 50,
  fullSize: true,
  images: {
    canvas:
      'https://unicloud-public-prod-eu-west-1.s3.amazonaws.com/widgets_empty_states/empty_web.svg',
    thumbnail: 'web',
  },
  editModalSize: {},
  settings: {
    enableSaveAsDraft: true,
    // hideHideOptions: true,
    hidePreviewTable: true,
    useSteps: [4],
    languageKeys: ['buttonText'],
    allowAssetTypeNavigation: (widgetData) => false,
    hideFilterNavigation: (widgetData) => true,
  },
};
