import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import styles from './ExpansionPanelWithRows.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@components/Table';
import { ExpansionPanelWithTableProps } from './ExpansionPanelWithRows.interface';

const useStyles = makeStyles((theme: any) => ({
  panel: (props: any) => {
    return props?.customStyles?.panel
      ? props.customStyles.panel
      : {
          marginBottom: 13,
          boxShadow: theme.app.boxShadowGrey,
          borderRadius: 8,
          backgroundColor: 'var(--systemContentBackground)',
          '&:before': {
            height: 0,
          },
          width: '100%',
        };
  },
  panelSummary: (props: any) => {
    return props?.customStyles?.panelSummary ? props.customStyles.panelSummary : { height: 78 };
  },
  panelDetails: {
    backgroundColor: theme.app.myAccountPanelsColor,
    width: '100%',
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
  },
  collapseTitle: (props: any) => {
    return props?.customStyles?.collapseTitle
      ? props.customStyles.collapseTitle
      : {
          fontSize: '14px',
          lineHeight: 2.14,
          color: 'var(--systemFont)',
          fontWeight: 900,
          fontFamily: 'var(--defaultFont)',
        };
  },
}));

function ExpansionPanelWithRows(props: ExpansionPanelWithTableProps) {
  const { tableData, columnsCfg, title, defaultExpanded = false } = props;
  const classes = useStyles(props);

  return (
    <ExpansionPanel className={classes.panel} defaultExpanded={defaultExpanded}>
      <ExpansionPanelSummary className={classes.panelSummary} expandIcon={<ExpandMoreIcon />}>
        <div className={styles.epSummary}>
          <div className={classes.collapseTitle}>
            <I18n>{title}</I18n>
          </div>
        </div>
      </ExpansionPanelSummary>
      <div className={styles.header}>
        {columnsCfg.map((column, columnIdx) => {
          return (
            <div key={columnIdx} className={styles.headerCol} style={column.style}>
              <I18n>{column.label}</I18n>
            </div>
          );
        })}
      </div>
      <div className={styles.body}>
        <div className={styles.divider}></div>
        {tableData.map((row, rowIdx) => {
          const keys = Object.keys(row);
          return (
            <div key={rowIdx} className={styles.rowCol}>
              {Object.keys(row).map((column, columnIdx) => {
                const data = columnsCfg.find((col) => col.field === column);
                return (
                  <div
                    key={columnIdx}
                    // className={styles.rowCol}
                    style={columnsCfg[columnIdx].style}>
                    {data && (
                      <data.Component
                        {...row[column]}
                        {...row}
                        {...props}
                        {...columnsCfg[columnIdx].props}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </ExpansionPanel>
  );
}

export default ExpansionPanelWithRows;
