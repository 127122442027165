import React, { useEffect, useMemo, useState } from 'react';
import {
  Query,
  Builder,
  Utils as QbUtils,
  ActionMeta,
  Conjunctions,
  RuleValue,
} from 'react-awesome-query-builder';
import { JsonGroup, Config, ImmutableTree, BuilderProps } from 'react-awesome-query-builder';
import { Fields, Settings, Funcs, Widgets } from 'react-awesome-query-builder';
import './QueryBuilder.css';
import styles from './QueryBuilder.scss';
import classNames from 'classnames';
import MaterialConfig from 'react-awesome-query-builder/lib/config/material';
import { useLocalContext } from '../../conditionalTriggerContext';
import { getOperators } from '../Config/operators';
import { types } from '../Config/types';
import { Switch } from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { i18nService } from '@core/i18n/I18nService';
import {
  changeSigmaToBEFormat,
  checkConditionLength,
  convertLocalConditionTreeToServer,
  convertServerConditionTreeToLocal,
  editExpression,
  fixNullValueForField,
  formatField,
  replaceTagIdsWithNames,
} from './QueryBuilder.utils';
import moment from 'moment';
import BasicDatePicker from '@components/BasicDatePicker';
import { buildDateTime } from '@components/widgets/charts.utils';
import { useSelector } from '@src/redux/useSelector';
import Icon from '@components/Icon';
import { omit } from 'lodash';

const InitialConfig = MaterialConfig;

// You can load query value from your backend storage (for saving see `Query.onChange()`)
const queryValue: JsonGroup = { id: QbUtils.uuid(), type: 'group' };

const QueryBuilder = ({
  queryString,
  onQueryStringChange,
  setTemplateData,
  conditionTree,
  areFieldsLoaded,
  templateData,
  setConditionLength,
}) => {
  const languageId = useSelector((state) => state.config.languageId);
  const { conditionAssetTypes } = templateData;
  const { fieldContext } = useLocalContext();
  const fields: Fields = { ...fieldContext };
  const operators = useMemo(() => getOperators(), [languageId]);

  const conjunctions: Conjunctions = {
    ...InitialConfig.conjunctions,
    AND: {
      ...InitialConfig.conjunctions.AND,
      label: i18nService.translate('events-template.edit-template.and'),
    },
    OR: {
      ...InitialConfig.conjunctions.OR,
      label: i18nService.translate('events-template.edit-template.or'),
    },
  };

  const formatExpressionForDisplay = (expression, fields): string => {
    let newExpression = replaceTagIdsWithNames(expression, fields);
    if (newExpression) {
      newExpression = newExpression.replace(
        /assetTags/g,
        i18nService.translate('events-template.edit-template.asset-tags')
      );
      newExpression = newExpression.replace(
        /tagTypes/g,
        i18nService.translate('events-template.edit-template.tag-types')
      );
      newExpression = newExpression.replace(
        /systemProperties/g,
        i18nService.translate('events-template.edit-template.variables')
      );
      newExpression = newExpression.replace(/[\[\]]/g, '');
    }

    return newExpression;
  };

  const funcs: Funcs = {
    SIGMA: {
      label: 'Σ',
      returnType: 'number',
      formatFunc: (expression) =>
        expression && fields
          ? `(${formatExpressionForDisplay(expression?.expression, fields)})`
          : '',
      sqlFormatFunc: (expression) => `${expression}`,
      mongoFormatFunc: (expression) => `${expression}`,
      jsonLogic: (expression) => `${expression}`,
      spelFunc: 'Calculate',
      renderBrackets: ['', ''],
      args: {
        expression: {
          label: 'Expression',
          type: 'text',
          valueSources: ['value'],
        },
      },
    },
  };

  const widgets: Widgets = {
    ...InitialConfig.widgets,
    func: {
      ...InitialConfig.widgets.func,
      factory: (props) => {
        const { value } = props;
        var arg = value ? value.getIn(['args', 'expression']) : null;
        var expression = replaceTagIdsWithNames(arg ? arg.get('value') : undefined, fields);
        return (
          <div className={styles.expressionWrapper}>
            <Icon
              type="calculation"
              color="var(--systemFont)"
              tooltipText={i18nService.translate(
                'events-template.edit-template.press-sigma-button-to-edit-calculation'
              )}
              width={'28px'}
              height={'28px'}
              style={{ marginTop: '2px', verticalAlign: 'center' }}
              onClick={() => {
                editExpression(
                  expression,
                  props,
                  conditionAssetTypes?.length == 1 ? 'assetTags' : 'tagTypes'
                );
              }}
            />
            <div className={classNames(styles.inputBase, 'ellipsis-overflow')} title={expression}>
              {expression ||
                i18nService.translate(
                  'events-template.edit-template.press-sigma-button-to-edit-calculation'
                )}
            </div>
          </div>
        );
      },
    },
    boolean: {
      ...InitialConfig.widgets.boolean,
      factory: (props) => {
        const { value } = props;
        return (
          <div className={styles.switchWrapper}>
            <div className={styles.switchInnerContainer}>
              <div
                className={styles.switchText}
                title={i18nService.translate('events-template.edit-template.off')}>
                {i18nService.translate('events-template.edit-template.off')}
              </div>
              <Switch
                checked={value}
                onChange={(e) => {
                  props.setValue(e.target.checked);
                }}
                size="medium"
              />
              <div
                className={styles.switchText}
                title={i18nService.translate('events-template.edit-template.on')}>
                {i18nService.translate('events-template.edit-template.on')}
              </div>
            </div>
          </div>
        );
      },
    },
    number: {
      ...InitialConfig.widgets.number,
      valuePlaceholder: i18nService.translate('events-template.edit-template.enter-value'),
      spelFormatValue: (val: RuleValue) => {
        return val;
      },
    },
    text: {
      ...InitialConfig.widgets.text,
      valuePlaceholder: i18nService.translate('events-template.edit-template.enter-value'),
      spelFormatValue: (val: RuleValue) => {
        return `'${val?.replaceAll("'", "\\'")}'`;
      },
    },
    textarea: {
      ...InitialConfig.widgets.textarea,
      valuePlaceholder: i18nService.translate('events-template.edit-template.enter-value'),
      spelFormatValue: (val: RuleValue) => {
        return `'${val?.replaceAll("'", "\\'")}'`;
      },
    },
    date: {
      ...InitialConfig.widgets.date,
      valuePlaceholder: i18nService.translate('events-template.edit-template.enter-value'),
    },
    time: {
      ...InitialConfig.widgets.time,
      valuePlaceholder: i18nService.translate('events-template.edit-template.enter-value'),
    },
    datetime: {
      ...InitialConfig.widgets.datetime,
      spelFormatValue: (val: RuleValue) => {
        return `'${val}'`;
      },
      valuePlaceholder: i18nService.translate('events-template.edit-template.enter-value'),
      factory: (props) => {
        const { value } = props;
        return (
          <div className={styles.datepickerWrapper}>
            <BasicDatePicker
              singleDatePicker={true}
              displayRanges={false}
              disableCustomLabel={true}
              openDirection="up"
              selectedChanged={(date) => dateChanged(date, props)}
              value={value}
              timePicker={true}
              height={32}
              inputClassname={styles.datepickerInput}
              allowNullValue={true}
              buildFormat={(val) => buildDateTime(val, 'DATE_TIME_FORMAT_ONE', 'momentFormat')}
            />
          </div>
        );
      },
    },
  };

  const dateChanged = (date, props) => {
    const momentDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
    props.setValue(momentDate);
  };

  const settings: Settings = {
    ...InitialConfig.settings,
    valueSourcesInfo: {
      value: {
        label: i18nService.translate('events-template.edit-template.value'),
      },
      field: {
        label: i18nService.translate('events-template.edit-template.field'),
        widget: 'field',
      },
      func: {
        label: i18nService.translate('events-template.edit-template.calculation'),
        widget: 'func',
      },
    },
    valuePlaceholder: i18nService.translate('events-template.edit-template.enter-value'),
    valueSourcesPopupTitle: i18nService.translate(
      'events-template.edit-template.select-value-source'
    ),
    addGroupLabel: i18nService.translate('events-template.edit-template.add-group'),
    addRuleLabel: i18nService.translate('events-template.edit-template.add-rule'),
    notLabel: i18nService.translate('events-template.edit-template.not'),
    canLeaveEmptyGroup: false, //UC-6125
    fieldPlaceholder: i18nService.translate('events-template.edit-template.select-field'),
  };

  const config: Config = {
    settings,
    fields,
    operators,
    types,
    funcs,
    widgets,
    conjunctions,
  };

  const deleteButtonBackground = getComputedStyle(document.documentElement).getPropertyValue(
    '--conditionBuilderButtonBackground'
  );

  const theme = useMemo(
    () =>
      createMuiTheme({
        palette: {
          secondary: {
            main: deleteButtonBackground,
          },
        },
        props: {
          MuiButton: {
            disableRipple: true,
          },
        },
        overrides: {
          MuiButton: {
            root: {
              backgroundColor: 'var(--conditionBuilderButtonBackground)', // background color on add rule / group
              color: 'var(--conditionBuilderButtonText)', // color of text (on Add rule) on mouse is not over
              '&:focus': {
                outline: 'none',
              },
              '&:hover': {
                color: 'var(--conditionBuilderButtonTextHover)', // color of text on hover - Also affects the color of the clicked button (on add rule / group) when ripple effect is enabled
                backgroundColor: 'var(--conditionBuilderButtonBackground)', // overrides the background color on hover (But not for add group)
              },
              margin: '2px',
            },
            textPrimary: {
              color: 'var(--conditionBuilderButtonText)', // Color of Add group is taken from textPrimary
              '&:hover': {
                backgroundColor: 'var(--conditionBuilderButtonBackground)', // fix: Add group button color becomes transparent on hover
              },
            },
            contained: {
              backgroundColor: 'var(--conditionBuilderButtonBackground)',
              color: 'var(--conditionBuilderButtonText)',
              '&:hover': {
                color: 'var(--conditionBuilderButtonTextHover)',
                backgroundColor: 'var(--conditionBuilderButtonBackground)',
              },
            },
            containedPrimary: {
              backgroundColor: 'var(--conditionBuilderAndOrButtonBackgroundSelected)',
              color: 'var(--conditionBuilderAndOrButtonTextSelected)',
              '&:hover': {
                color: 'var(--conditionBuilderAndOrButtonTextSelected)',
                backgroundColor: 'var(--conditionBuilderAndOrButtonBackgroundSelected)',
              },
            },
            containedSecondary: {
              backgroundColor: 'var(--conditionBuilderNotButtonBackgroundSelected)',
              color: 'var(--conditionBuilderNotButtonTextSelected)',
              '&:hover': {
                color: 'var(--conditionBuilderNotButtonTextSelected)',
                backgroundColor: 'var(--conditionBuilderNotButtonBackgroundSelected)',
              },
            },
          },
          MuiPickersToolbarButton: {
            toolbarBtn: {
              backgroundColor: 'transparent',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            },
          },
          MuiIconButton: {
            colorSecondary: {
              color: 'var(--conditionBuilderDeleteButtonText)' /* delete button foreground */,
              backgroundColor:
                'var(--conditionBuilderDeleteButtonBackground)' /* delete button backround */,
            },
          },
          MuiInputBase: {
            root: {
              backgroundColor: 'var(--conditionBuilderInputBackground)',
              color: 'var(--conditionBuilderText)',
            },
          },
          MuiRadio: {
            root: {
              color: 'unset',
              backgroundColor: 'transparent',
              '&.Mui-checked': {
                color: 'unset !important',
              },
            },
          },
          MuiAutocomplete: {
            inputRoot: {
              width: '200px',
            },
            clearIndicator: {
              display: 'none',
            },
          },
        },
      } as any), // MuiPickersToolbarButton is not familiar by the overrides, but it helps of preventing the date time picker buttons from having a white background

    [deleteButtonBackground]
  );

  const [state, setState] = useState({
    tree: QbUtils.checkTree(QbUtils.loadTree(queryValue), config),
    config: config,
  });

  function flatten(input) {
    const stack = [...input];
    const res = [];
    while (stack.length) {
      // pop value from stack
      const next = stack.pop();
      if (Array.isArray(next)) {
        // push back array items, won't modify the original input
        stack.push(...next);
      } else {
        res.push(next);
      }
    }
    // reverse to restore input order
    return res.reverse();
  }

  const onChange = (immutableTree: ImmutableTree, config: Config, actionMeta?: ActionMeta) => {
    // Tip: for better performance you can apply `throttle` - see `examples/demo`
    //setState({ tree: immutableTree, config: config });
    if (actionMeta && actionMeta.srcKey === 'func') {
      // We don't give the user the option to select which function to run.
      // Therefore we need to somehow set the value of the func to SIGMA
      // Changing the tree using setIn, and then setState would cause an error on ruleValidation (value.toJS() is not a function),
      // so I'm converting it to JSON, and then loading the tree from it, and setting the state from the new tree
      // The actionMeta contains a path, which is an array of guids. A rule cannot contain another rule.
      // The first id is the id of the outer group. All the other ids exept for the last one must be groups, so we need to access
      // their sub property called children1
      const path = actionMeta.path as any[];
      const delta = actionMeta.delta;
      const pathToRule = flatten([
        'children1',
        path.slice(1, -1).map((a) => [a, 'children1']),
        path.slice(-1),
      ]);
      let value = immutableTree.getIn([...pathToRule, 'properties', 'value']).toJS();
      value[delta] = {
        args: {
          expression: {
            value: '',
            valueSrc: 'value',
          },
        },
        func: 'SIGMA',
      };

      let newTree = immutableTree.setIn([...pathToRule, 'properties', 'value'], value);
      newTree = newTree.setIn([...pathToRule, 'properties', 'valueType'], ['number']);
      setState({ tree: newTree, config: config });
      let jsonTree = QbUtils.getTree(newTree, true);
      setState({
        tree: QbUtils.checkTree(QbUtils.loadTree(jsonTree), config),
        config: config,
      });
      jsonTree = convertLocalConditionTreeToServer(jsonTree, config.fields);
      setTemplateData(jsonTree, 'conditionTree');
    } else {
      setState({ tree: immutableTree, config: config });
      let jsonTree = QbUtils.getTree(immutableTree, true);
      jsonTree = convertLocalConditionTreeToServer(jsonTree, config.fields);
      setTemplateData(jsonTree, 'conditionTree');
    }
  };

  useEffect(() => {
    let json = JSON.stringify(QbUtils.queryString(state.tree, state.config, true));

    if (json && json.startsWith('"')) json = json.substring(1);

    if (json && json.endsWith('"')) json = json.substring(0, json.length - 1);

    let conditionForBE = '';

    try {
      conditionForBE = (
        JSON.stringify(
          QbUtils.spelFormat(state.tree, {
            ...state.config,
            settings: {
              ...state.config.settings,
              formatSpelField: formatField,
            },
            widgets: {
              ...state.config.widgets,
              datetime: {
                ...state.config.widgets.datetime,
                spelFormatValue: (val: RuleValue) => {
                  var ts = moment(val, 'YYYY-MM-DD HH:mm:ss').unix();
                  return `${ts}`;
                },
              },
            },
          })
        ) as any
      )?.replaceAll("\\'", "'");
    } catch (e) {
      // console.log(e);
    }

    const usedTagsIds: Array<number> = [];
    const usedVariablesIds: Array<number> = [];
    const beCondition = changeSigmaToBEFormat(
      conditionForBE,
      state.config,
      usedTagsIds,
      usedVariablesIds
    );
    setTemplateData(beCondition, 'condition');

    if (beCondition) setTemplateData(true, 'isCheckingConditionLength'); // disable Next button until check length API finished;
    const newTemplateData = {
      ...templateData,
      conditionTags: conditionAssetTypes?.length == 1 ? usedTagsIds : [],
      conditionVariables: conditionAssetTypes?.length == 1 ? usedVariablesIds : [],
      conditionTagTypes: conditionAssetTypes?.length > 1 ? usedTagsIds : [],
    };
    checkConditionLength(beCondition, newTemplateData, setConditionLength, setTemplateData);

    if (!conditionAssetTypes?.length) {
      setTemplateData([], 'conditionTags');
      setTemplateData([], 'conditionTagTypes');
    } else if (conditionAssetTypes?.length === 1) {
      setTemplateData(usedTagsIds, 'conditionTags');
      setTemplateData(usedVariablesIds, 'conditionVariables');
      setTemplateData([], 'conditionTagTypes');
    } else {
      setTemplateData([], 'conditionTags');
      setTemplateData([], 'conditionVariables');
      setTemplateData(usedTagsIds, 'conditionTagTypes');
    }
    onQueryStringChange(json);
  }, [state.tree]);

  useEffect(() => {
    if (areFieldsLoaded && conditionTree) {
      const fixedTree = convertServerConditionTreeToLocal(
        fixNullValueForField(conditionTree),
        omit(config.fields, 'variables')
      );
      setState({
        tree: QbUtils.checkTree(QbUtils.loadTree(fixedTree ? fixedTree : queryValue), {
          ...config,
          fields: omit(fields, 'variables'),
        }),
        config: config,
      });
    }
  }, [areFieldsLoaded]);

  const renderBuilder = (props: BuilderProps) => (
    <div className="query-builder-container" style={{ padding: '10px 0' }}>
      <div className="query-builder qb-lite">
        <Builder {...props} />
      </div>
    </div>
  );

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Query {...config} value={state.tree} onChange={onChange} renderBuilder={renderBuilder} />
      </ThemeProvider>
    </div>
  );
};

export default QueryBuilder;
