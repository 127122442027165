import React, { useState, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { ExpansionPanelSummary, ExpansionPanelDetails, ExpansionPanel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import I18n from '@components/I18n';
import Icon from '@components/Icon';
import Tooltip from '@components/Tooltip';
import {
  getConfigValueFromWidgetSettings,
  getIsIncludeInSettings,
} from '@core/canvas/widget.utils';
import { dndService } from '@core/DndService';
import LocalSearch from '@components/LocalSearch';
import GroupBy from './GroupBy';
import Metrics from './Metrics';
import styles from './GroupingAndMeasures.scss';
import { concat, compact } from 'lodash';
import {
  buildOptions,
  getAvailableOperations,
  getFilteredData,
  onDragAndDropGroupingAndMeasures,
} from './GroupingAndMeasures.utils';
import { singleValueWidgets } from '@core/mapsAndDefinitions';
import { i18nService } from '@core/i18n/I18nService';
import { useSelector } from '@src/redux/useSelector';

const useStyles = {
  root: {
    '& .MuiExpansionPanelSummary-root': {
      minHeight: '44px !important',
      padding: '0 19px',
      maxWidth: '100%',
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: '10px 0 !important',
      maxWidth: '92%',
    },
    '& .MuiIconButton-root': {
      padding: 10,
    },
    '& .MuiCollapse-container': {
      borderTop: '1px solid var(--tableRowBorderBottom)',
    },
    '& .MuiExpansionPanelDetails-root': {
      padding: '15px 19px',
    },
  },
};

function GroupingAndMeasures(props) {
  const { widgetData, setWidgetData, classes } = props;
  const customization = widgetData.customization ? widgetData.customization : null;
  const {
    alarmInfos,
    variables,
    assetProperties,
    tags,
    tagTypes,
    groupBy,
    metrics,
    filters,
    sorts,
    type,
    scope,
  } = widgetData;
  const languageId = useSelector((state) => state.config.languageId);

  const hiddenAssetProperties = getConfigValueFromWidgetSettings(type, 'hiddenAssetProperties');

  const disabled =
    getConfigValueFromWidgetSettings(widgetData.type, 'expansionsPanelDisable') &&
    getIsIncludeInSettings(widgetData.type, 'expansionsPanelDisable', 'groupingAndMeasures');

  const data = compact(
    concat(
      assetProperties.map((a) => buildOptions(a, 'ASSET_PROPERTY')),
      tags.map((a) => buildOptions(a, 'TAG')),
      tagTypes.map((a) => buildOptions(a, 'TAG_TYPE')),
      alarmInfos.map((a) => buildOptions(a, 'ALARM_INFO')),
      variables.map((a) => buildOptions(a, 'VARIABLE'))
    )
  );

  const dataWithAvailableOperations = data.map((item) => {
    return { ...item, availableOperations: getAvailableOperations(metrics, item, scope) };
  });

  useEffect(() => {
    let setDefaultData = getConfigValueFromWidgetSettings(type, 'setDefaultDataByStep');
    setDefaultData && setDefaultData['3'] && setDefaultData['3'](scope, setWidgetData, widgetData);
  }, []);

  const isDefaultExpanded = useMemo(() => !disabled, [groupBy, metrics, filters, sorts, disabled]);

  const shouldExcludeAssetProperties: boolean = useMemo(
    () =>
      getConfigValueFromWidgetSettings(type, 'dragAndDropRules').excludeAssetProperties &&
      getConfigValueFromWidgetSettings(type, 'dragAndDropRules').excludeAssetProperties(scope),
    [widgetData, type]
  );

  const shouldExcludeNonNumericVariables: boolean = useMemo(
    () =>
      getConfigValueFromWidgetSettings(type, 'dragAndDropRules').excludeNonNumericVariables &&
      getConfigValueFromWidgetSettings(type, 'dragAndDropRules').excludeNonNumericVariables(scope),
    [type]
  );

  const [filteredData, setFilteredData] = useState(
    getFilteredData(
      scope,
      dataWithAvailableOperations,
      groupBy,
      metrics,
      shouldExcludeAssetProperties,
      shouldExcludeNonNumericVariables,
      singleValueWidgets.includes(type),
      hiddenAssetProperties
    )
  );
  const [availableData, setAvailableData] = useState(filteredData);
  const [expanded, setExpanded] = useState(isDefaultExpanded);
  const dndDisabled = disabled || !expanded;

  useEffect(() => {
    setFilteredData(
      getFilteredData(
        scope,
        dataWithAvailableOperations,
        groupBy,
        metrics,
        shouldExcludeAssetProperties,
        shouldExcludeNonNumericVariables,
        singleValueWidgets.includes(type),
        hiddenAssetProperties
      )
    );
  }, [metrics, groupBy]);

  useEffect(() => {
    setAvailableData(filteredData);
  }, [filteredData]);

  useEffect(() => {
    setAvailableData(
      (prevState: any[]) =>
        Array.isArray(prevState) &&
        prevState?.map((data) => ({
          ...data,
          title:
            ['AGGREGATED_DATA', 'AGGREGATED_LAST_VALUE'].includes(scope) &&
            data.availableOperations?.length
              ? `${i18nService.translate(data.name)}: ${data.availableOperations
                  .map((op) => i18nService.translate(`enum.${op}`))
                  ?.join(', ')}`
              : data.name,
        }))
    );
  }, [filteredData, languageId]);

  const searchFunc = (value) => {
    const available = getFilteredData(
      scope,
      data,
      groupBy,
      metrics,
      shouldExcludeAssetProperties,
      shouldExcludeNonNumericVariables,
      singleValueWidgets.includes(type),
      hiddenAssetProperties
    );
    const results = available.filter((item) => {
      return item.name.toLowerCase().includes(value.toLowerCase());
    });
    setAvailableData(results);
  };

  useEffect(() => {
    const sub = dndService.dragEndSubscribe((result) => {
      onDragAndDropGroupingAndMeasures(
        result,
        customization,
        availableData,
        setWidgetData,
        setAvailableData,
        widgetData
      );
    });
    return () => {
      sub.unsubscribe();
    };
  }, [availableData, groupBy, metrics]);

  return (
    <ExpansionPanel
      defaultExpanded={!disabled && expanded}
      expanded={!disabled && expanded}
      onChange={() => setExpanded(!expanded)}
      disabled={disabled}
      id={styles.collapsePanel}
      className={classNames(styles.ep, classes.root)}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <div>
          <I18n className={styles.groupingAndMeasures} element="div">
            create-widget-page.create-widget.step-three.grouping-and-measures
          </I18n>
        </div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div className={styles.epDetailsWrapper}>
          <div className={styles.availableData}>
            <div className={styles.infoGroup}>
              <I18n className={styles.subTitle}>
                create-widget-page.create-widget.step-three.available-data
              </I18n>
              <Tooltip
                text={
                  !widgetData.eventTemplateId
                    ? 'create-widget-page.create-widget.step-three.available-data-info'
                    : 'create-event-data-source-page.create-event-data-source.step-three.available-data-info'
                }
                placement="right"
                children={
                  <div>
                    <Icon className={styles.iconInfo} type="info"></Icon>
                  </div>
                }></Tooltip>
            </div>
            <LocalSearch disableClick={true} searchFunc={searchFunc}></LocalSearch>
            <Droppable droppableId="availableData" isDropDisabled={dndDisabled}>
              {(provided) => (
                <div
                  className={styles.dataTriangle}
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  <div className={styles.data}>
                    {availableData.map((tag, idx) => (
                      <Draggable
                        key={`${tag.id}_${tag.tagType}_data_${idx}`}
                        draggableId={`${tag.id}_${tag.tagType}_data_${idx}`}
                        index={idx}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={styles.tagData}>
                            <div className={styles.dragIndicatorTag} {...provided.dragHandleProps}>
                              <Icon type="drag" />
                            </div>
                            <div className={styles.tag}>
                              <Icon
                                className={styles.iconType}
                                type={
                                  tag.tagType === 'ASSET_PROPERTY' && tag.id === 3
                                    ? 'geoType'
                                    : tag.type
                                }
                              />
                              <div className={classNames(styles.tagName, 'ellipsis-overflow')}>
                                <I18n title={tag.title}>{tag.name}</I18n>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </div>
          <div className={styles.groupAndMetrics}>
            <GroupBy
              disabled={dndDisabled}
              groupBy={groupBy}
              widgetType={widgetData?.type}
              eventTemplateId={widgetData?.eventTemplateId}
              setWidgetData={setWidgetData}
              scope={scope}></GroupBy>
            <Metrics
              disabled={dndDisabled}
              metrics={metrics}
              availableData={availableData}
              scope={widgetData.scope}
              type={widgetData.type}
              widgetData={widgetData}
              setWidgetData={setWidgetData}></Metrics>
          </div>
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default withStyles(useStyles)(GroupingAndMeasures);
