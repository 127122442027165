import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, makeStyles } from '@material-ui/core';
import I18n from '@components/I18n';
import styles from './ExpansionPanelWithTable.scss';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@components/Table';
import { ExpansionPanelWithTableProps } from './ExpansionPanelWithTable.interface';

const useStyles = makeStyles((theme: any) => ({
  panel: (props: any) => {
    return props?.customStyles?.panel
      ? props.customStyles.panel
      : {
          marginBottom: 13,
          boxShadow: theme.app.boxShadowGrey,
          borderRadius: 8,
          backgroundColor: 'var(--systemContentBackground)',
          '&:before': {
            height: 0,
          },
          width: '100%',
        };
  },
  panelSummary: (props: any) => {
    return props?.customStyles?.panelSummary ? props.customStyles.panelSummary : { height: 78 };
  },
  panelDetails: {
    backgroundColor: theme.app.myAccountPanelsColor,
    width: '100%',
  },
  label: {
    fontSize: 14,
    fontWeight: 900,
  },
  collapseTitle: (props: any) => {
    return props?.customStyles?.collapseTitle
      ? props.customStyles.collapseTitle
      : {
          fontSize: '14px',
          lineHeight: 2.14,
          color: 'var(--systemFont)',
          fontWeight: 900,
          fontFamily: 'var(--defaultFont)',
        };
  },
}));

function ExpansionPanelWithTable(props: ExpansionPanelWithTableProps) {
  const { tableData, columnsCfg, title, defaultExpanded = false, getData } = props;
  const classes = useStyles(props);

  return (
    <ExpansionPanel className={classes.panel} defaultExpanded={defaultExpanded}>
      <ExpansionPanelSummary className={classes.panelSummary} expandIcon={<ExpandMoreIcon />}>
        <div className={styles.epSummary}>
          <div className={classes.collapseTitle}>
            <I18n>{title}</I18n>
          </div>
        </div>
      </ExpansionPanelSummary>
      <Table
        colunmsCfg={columnsCfg}
        data={tableData}
        getData={getData ? getData : () => {}}
        style={{ top: 'auto' }}
      />
    </ExpansionPanel>
  );
}

export default ExpansionPanelWithTable;
