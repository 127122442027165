import React, { useCallback, useEffect } from 'react';
import styles from './DataSection.scss';
import { i18nService } from '@core/i18n/I18nService';
import {
  getConfigValueFromWidgetSettings,
  getIsIncludeInSettings,
} from '@core/canvas/widget.utils';
import { getAssetPropertiesCfg, getTagOrTagTypesCfg } from './DataSection.utils';
import { httpService } from '@core/http/HttpService';
import { AssetTagsResponse } from '@pages/CreateWidgetPage/DataStepComponent/StepOne/StepOne.interface';
import ExpansionPanelWithRows from '@components/ExpansionPanelWithRows/ExpansionPanelWithRows';

const collapseTitle = {
  fontWeight: 'bold',
};

const panelSummary = {
  height: 70,
};

function DataSection(props) {
  const {
    type,
    widgetData,
    assetProperties,
    onSelectedAssetProperty,
    replaceData,
    replacements,
    assetPropertiesOptions,
    dataTagOptions,
    setDataTagOptions,
    defaultExpanded,
  } = props;

  const widgetType = widgetData?.type;

  const assetPropertiesSection = {
    title: 'create-widget-page.create-widget.step-one.asset-properties',
    config: getAssetPropertiesCfg(assetPropertiesOptions, replaceData, widgetData, replacements),
    data: replacements.assetProperties,
  };

  const tagDataSection = {
    title: i18nService
      .translate('create-widget-page.create-widget.step-one.tag-data')
      .concat('/', i18nService.translate('create-widget-page.create-widget.step-one.tag-types')),
    config: getTagOrTagTypesCfg(dataTagOptions, replaceData, widgetData, replacements),
    data: replacements.tagData,
  };

  const data = type === 'assetProperties' ? assetPropertiesSection : tagDataSection;

  const getAssetTags = useCallback((assetTypeId, widgetType) => {
    httpService
      .api({
        type: 'getAssetTags',
        urlParams: { assetId: assetTypeId },
        query: { p: 1, ps: 1000, widgetType: widgetType },
        disableBI: true,
      })
      .then((tags: AssetTagsResponse) => {
        setDataTagOptions(tags.results);
      });
  }, []);

  const getAssetTagTypes = useCallback((assetTypeIds, widgetType) => {
    httpService
      .api({
        type: 'getAssetTagTypes',
        query: { p: 1, ps: 1000, assetTypeIds: assetTypeIds.join(','), widgetType },
        disableBI: true,
      })
      .then((tagTypes: AssetTagsResponse) => {
        setDataTagOptions(tagTypes.results);
      });
  }, []);

  useEffect(() => {
    if (replacements.assetTypes.replaceWith.length === 1)
      getAssetTags(replacements.assetTypes.replaceWith[0].id, widgetType);
    if (
      Array.isArray(replacements.assetTypes.replaceWith) &&
      replacements.assetTypes.replaceWith.length > 1
    ) {
      getAssetTagTypes(
        replacements.assetTypes.replaceWith.map((asset) => asset.id),
        widgetType
      );
    } else setDataTagOptions([]);
  }, [replacements.assetTypes.replaceWith]);

  return (
    <div className={styles.wrapper}>
      <ExpansionPanelWithRows
        title={data.title}
        columnsCfg={data.config}
        tableData={data.data}
        customStyles={{
          panelSummary: panelSummary,
          collapseTitle: collapseTitle,
        }}
        defaultExpanded={defaultExpanded}
      />
    </div>
  );
}

export default DataSection;
